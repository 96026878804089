export const softwareAdmins = ["Jmlv0ajWF9RoGv4jWsvosBIBS1s1", "zKujdi84l8VGkKSI3cz44EGIaFu2"];

export const admins = [
  "Jmlv0ajWF9RoGv4jWsvosBIBS1s1",
  "zKujdi84l8VGkKSI3cz44EGIaFu2",
  "6C9V6estHoaROpdlQtjnRUFrR9j2",
  "92KyqbbybQhL0akfyEOiKJNtmDz1",
];

export const CEOs = ["ALa04mRF1xMOdLVI58jcE5JtY5y1", "3i6qefxD5desUzalExlyNhT6FPa2"];
export const HRSyria = "6C9V6estHoaROpdlQtjnRUFrR9j2";
export const HRTurkey = "92KyqbbybQhL0akfyEOiKJNtmDz1";
