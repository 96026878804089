import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Button, Popconfirm, Select, DatePicker } from "antd";
const { Option } = Select;

import { userRef, officeRef, positionRef, deductionRef } from "../../db/collectionsRef";

import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import AddNewDeduction from "./addNewDeductionDialog";
import { currentUserData } from "../../db/userGlobal";
import { uniq } from "lodash";
import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
const { MonthPicker } = DatePicker;

export default function DeductionTable() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [officeId, setOfficeId] = useState("");
  const [month, setMonth] = useState(moment());
  const [deductionSN] = useCollection(
    deductionRef.where("office", "==", officeId).where("month", "==", month.format("MM-YYYY").toString())
  );
  const [officeSN] = useCollection(officeRef);

  if (!deductionSN || !officeSN) return <Spin />;

  const data = deductionSN.docs
    .filter((d) => currentUserData.HRProjectsManagersOffices.includes(d.data()?.office))
    .map((d) => {
      return { ...d.data(), id: d.id };
    });
  const UserOffices = [...(currentUserData.HRProjectsManagersOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map((id) => {
    const officeName = officeSN?.docs?.find((d) => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });
  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid].firstName + " " + globalUsersData[row.uid].lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by].firstName + " " + globalUsersData[row.by].lastName,
    },
    {
      name: "percentage",
      title: "Deduction %",
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office].officeName,
    },

    {
      name: "month",
      title: "Month",
    },
    {
      name: "notes",
      title: "Notes",
    },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              deductionRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid].firstName + " " + globalUsersData[row.uid].lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by].firstName + " " + globalUsersData[row.by].lastName,
    },
    {
      name: "percentage",
      title: "Deduction %",
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office].officeName,
    },

    {
      name: "month",
      title: "Month",
    },
    {
      name: "notes",
      title: "Notes",
    },
  ];

  return (
    <div>
      <MonthPicker
        size="large"
        style={{ margin: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e) => setMonth(moment(e))}
      />
      <Select
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={[...UserListOffices].find((office) => office.id == officeId)?.officeName}
        onChange={(e) => {
          const id = [...UserListOffices].find((office) => office.officeName == e)?.id as string;
          setOfficeId(id);
        }}
      >
        {[...UserListOffices].map((d) => {
          return <Option value={d.officeName}>{d.officeName}</Option>;
        })}
      </Select>
      <AddNewDeduction modalState={modalState} setModalState={setModalState} />

      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewDeduction")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="deduction"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
