import React from "react";
import { Modal } from "antd";
import ReactPlayer from "react-player";

interface Props {
  url: string;
  modalState: boolean;
  setModalState: any;
  type: "image"|"video"
}

function MediaViewDialog(props: Props) {
  const { url, modalState, setModalState,type } = props;
  return (
    <Modal
      closable={true}
      width={700}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
      }}
      footer={[]}
    >
      <div>
        {type=="video" && <ReactPlayer controls={true} url={url} />}
        {type=="image" && <img width={650} src={url} />}

      </div>
    </Modal>
  );
}

export default MediaViewDialog;
