import { ChangeSet, DataTypeProvider, EditingState } from "@devexpress/dx-react-grid";
import { Grid, TableEditColumn, TableEditRow } from "@devexpress/dx-react-grid-material-ui";
import { Button, Checkbox, Divider, Icon, Popconfirm, Select, Spin, Tag, message } from "antd";
const { Option, OptGroup } = Select;
import { map, uniqBy } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { task } from "../../db/interfaces";

import DefaultDevExtremeGridComponents from "../../helpers/defaultDevExtremeGridComponents";
import AddNewTask from "./addNewTaskDialog";
import { useCollection } from "react-firebase-hooks/firestore";
import { departmentPositionsRef, positionTasksRef, tagsRef, tasksRef } from "../../db/collectionsRef";
import { auth } from "firebase";
import EditTask from "./EditTaskDialog";

export default function TasksTable({ tableName, departmentId, position, department }: any) {
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const [modalStateTask, setModalStateTask] = useState(false);
  const [modalStateEditTask, setModalStateEditTask] = useState(false);
  const [task, setTask] = useState({} as task);
  const [tasksSN] = useCollection(tasksRef.where("departmentId", "==", departmentId));
  const [sharableTasksSN] = useCollection(tasksRef.where("sharable", "==", true));

  const [tagsSN] = useCollection(tagsRef);
  const [positionTasksSN, loading] = useCollection(positionTasksRef.where("positionId", "==", position.id));

  const departmentTasks = tasksSN?.docs.map((task) => {
    return { ...task?.data(), id: task.id } as task;
  });

  const sharableTasks = sharableTasksSN?.docs.map((task) => {
    return { ...task?.data(), id: task.id } as task;
  });

  const tasks = uniqBy([...(departmentTasks ?? []), ...(sharableTasks ?? [])], (r) => r.id);
  const tags = tagsSN?.docs.map((tag) => {
    return { id: tag.id };
  });
  const positionTasks = positionTasksSN?.docs?.map((positionTask) => {
    return { ...positionTask?.data(), id: positionTask.id };
  });
  const addTags = (newTag: string) => {
    if (tags?.find((tag) => tag.id == newTag)?.id) {
      return;
    } else {
      tagsRef.doc(newTag).set({
        tag: newTag,
        createdAt: new Date(),
        by: uid,
      });
    }
  };

  const commitChanges = ({ added, changed, deleted }: any) => {
    if (added) {
      departmentPositionsRef
        .doc(position.id)
        .collection("positionTasks")
        .add({
          taskId: added[0].taskId,
          task: tasks?.find((task) => task.id == added[0].taskId),
          positionId: position.id,
          position: position,
          departmentId,
          department: department,
          ...added[0],
        });
    }

    if (changed) {
      map(changed, async (changedFields, docId) => {
        departmentPositionsRef.doc(position.id).collection("positionTasks").doc(docId).update(changedFields);
      });
    }

    if (deleted) {
      departmentPositionsRef.doc(position.id).collection("positionTasks").doc(deleted[0]).delete();
    }
  };

  const COLORS = ["#00C49F", "#FFBB28", "#0088FE", "#FF8042", "#8B008B", "#FF1493", "#B22222", "#ADD8A9"];
  const [defaultColumnWidths] = useState([
    { columnName: "taskId", width: 250 },
    { columnName: "role", width: 175 },
    { columnName: "tags", width: 175 },
  ]);

  const columns = [
    {
      name: "taskId",
      title: "Responsibility",
      getCellValue: (row: any) => tasks?.find((task) => task.id == row.taskId)?.title,
    },
    {
      name: "role",
      title: "Role",
      getCellValue: (row: any) => {
        return row.role?.map((r: any, index: any) => (
          <Tag style={{ marginBottom: "1%", marginTop: "1%" }} color={COLORS[index]} ref={r}>
            {r}
          </Tag>
        ));
      },
    },
    {
      name: "tags",
      title: "Tags",
      getCellValue: (row: any) => {
        return row.tags?.map((r: any) => {
          const randomIndex = Math.floor(Math.random() * COLORS.length);
          return (
            <Tag style={{ marginBottom: "1%", marginTop: "1%" }} color={COLORS[randomIndex]} ref={r}>
              {r}
            </Tag>
          );
        });
      },
    },
  ];

  const CustomTaskEditor = ({ value, onValueChange }: any) => {
    return (
      <Select
        filterOption={(inputValue, option) => {
          return (option.props.children as any)[2]?.toLowerCase()?.indexOf(inputValue?.toLowerCase()) >= 0;
        }}
        showSearch
        value={value}
        onChange={(e) => onValueChange(e)}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <div
              style={{ padding: "4px 8px", cursor: "pointer" }}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => setModalStateTask(true)}
            >
              <Icon type="plus" /> Add Responsibility
            </div>
          </div>
        )}
      >
        {(tasks ?? [])?.map((task) => {
          return (
            <Option key={task.id} style={{ whiteSpace: "break-spaces" }} value={task.id}>
              {task.sharable && <Icon type="star" />}
              {"  "}
              {`${task.code} - ${task.title}`}

              <div style={{ float: "right" }}>
                <Button
                  type="link"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    setModalStateEditTask(true);
                    setTask(task);
                  }}
                >
                  <Icon type="edit" />
                </Button>
              </div>
            </Option>
          );
        })}
      </Select>
    );
  };

  const CustomRoleEditor = ({ value, onValueChange }: any) => {
    const newValue = value?.map((val: any) => val?.ref);
    return (
      <Select mode="multiple" value={newValue} onChange={(e) => onValueChange(e)}>
        <Option value="R">Responsible</Option>
        <Option value="A">Accountable</Option>
        <Option value="C">Consulted</Option>
        <Option value="I">Informed</Option>
      </Select>
    );
  };

  const CustomTagsEditor = ({ value, onValueChange }: any) => {
    const newValue = value?.map((val: any) => val?.ref);
    return (
      <Select
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Tags Mode"
        value={newValue}
        onSelect={addTags}
        onChange={(e) => {
          onValueChange(e);
        }}
      >
        {tags?.map((task) => {
          return <Option value={task.id}>{task.id}</Option>;
        })}
      </Select>
    );
  };

  return (
    <div>
      {modalStateEditTask ? (
        <EditTask
          departmentId={departmentId}
          task={task}
          setModalState={setModalStateEditTask}
          modalState={modalStateEditTask}
        />
      ) : null}

      {modalStateTask ? (
        <AddNewTask departmentId={departmentId} setModalState={setModalStateTask} modalState={modalStateTask} />
      ) : null}
      <Grid rows={positionTasks ? positionTasks : []} columns={columns} getRowId={(r) => r.id}>
        <EditingState onCommitChanges={commitChanges} />
        <DataTypeProvider
          for={["taskId", "role", "tags"]} // Include "role" and "tags" fields here
          editorComponent={({ value, onValueChange, column }) => {
            if (column.name == undefined) {
              return null;
            }
            if (column.name == "role") {
              return <CustomRoleEditor value={value} onValueChange={onValueChange} />;
            }
            if (column.name == "taskId") {
              return <CustomTaskEditor value={value} onValueChange={onValueChange} />;
            }
            if (column.name == "tags") {
              return <CustomTagsEditor value={value} onValueChange={onValueChange} />;
            }
            if (column.name == "delete") {
              return null;
            }
            return <input value={value} onChange={(e) => onValueChange(e.target.value)} />;
          }}
        />
        {DefaultDevExtremeGridComponents({
          typicalTable: true,
          pagination: true,
          pageSize: 10,
          data: positionTasks,
          tableName: tableName,
          defaultColumnWidths: defaultColumnWidths,
        })}
        <TableEditRow />
        <TableEditColumn showAddCommand showEditCommand showDeleteCommand width={150} />
      </Grid>
    </div>
  );
}
