import React, { useState } from "react";
import analysis from "./analysis.json"
import { Tree, Icon ,Col} from 'antd';



export default function Analysis() {
const [valueList, setValueList] = useState([])
const [normalValue, setNormalValue] = useState([])
const [unit, setUnit] = useState()

const arr = analysis.map(d=>d)

    return (
      <div>
         <Col span={5}>
              <Tree
                  showLine
                  onSelect={(e, x)=>{
                    setValueList(x.node.props["Value list"] as [])
                    setNormalValue(x.node.props["Normal value"] as [])
                    setUnit(x.node.props["Unit"])
                  }}
              treeData={arr} 
               />
         </Col>  
        <Col span={7}>
        <div>Value List:</div>
        <div>
          {valueList.map((d, index)=>{
            return <div>{`${index+1}- ${d}`}</div>
          })}
        </div>
        </Col>  
        <Col span={7}>
          <div>Normal Value:</div>
        <div>
          {normalValue.map((d, index)=>{
            return <div>{`${index+1}- ${d}`}</div>
          })}
        </div>
        </Col> 
        <Col span={3}>
          <div>Unit:</div>
        <div>
       {unit}
        </div>
        </Col> 
      </div>
    );
  }

