import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Button, Popconfirm, Select, DatePicker } from "antd";

import { accountantRef, officeRef, workOfficeRef } from "../../db/collectionsRef";

import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";

import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import AddNewAccountant from "./addNewAccountantDialog";

export default function AccountantsTable() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [accountantsSN] = useCollection(accountantRef);
  const [officeSN] = useCollection(officeRef);

  if (!accountantsSN || !officeSN) return <Spin />;

  const data = accountantsSN.docs.map((d) => {
    return { ...d.data(), id: d.id };
  });

  const columns = [
    {
      name: "uid",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid].firstName + " " + globalUsersData[row.uid].lastName,
    },
    
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office].officeName,
    },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              accountantRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid].firstName + " " + globalUsersData[row.uid].lastName,
    },
    
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office].officeName,
    },
  ];

  return (
    <div>
      <AddNewAccountant modalState={modalState} setModalState={setModalState} />

      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewAccountant")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="accountants"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
