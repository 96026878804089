import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { useHistory } from "react-router-dom";
import { Button, DatePicker, Drawer, Form, InputNumber, message, Select, Input, Descriptions, Icon } from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { userRef, orderRef, vacationRef, officeRef } from "../../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { users } from "../../../db/interfaces";
import { currentUserData } from "../../../db/userGlobal";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../../global/usersList";
import { values } from "lodash";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  vacationStartOn: Yup.date().required("*"),
});

function AddNewVacationForUser(props: Props) {
  const { t } = useTranslation();
  const [vacationType, setVacationType] = useState("");
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const [halfDay, setHalfDay] = useState("morning");
  const history = useHistory();
  const newId = orderRef.doc().id;

  const formik = useFormik({
    initialValues: {
      uid: "",
      vacationType: "",
      notes: "",
      vacationStartOn: moment(),
      vacationEndOn: moment(),
      startWorkOn: moment(),
      vocationPeriodDays: 0,
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      const typeScore =
        vacationType == "Annual Leave"
          ? vacationAnnualScore
          : vacationType == "Sick Leave"
          ? vacationSickScore
          : vacationType == "Compensation Leave"
          ? vacationCompensationScore
          : 120;
      if (values.vocationPeriodDays > typeScore || values.vocationPeriodDays == 0 || vacationType == "") {
        alert(`Your ${vacationType} Score is not enough as requested!`);
        setLoading(false);
      } else {
        const promise = vacationRef.doc(newId).set(
          {
            employeeUID: values.uid,
            position: position ?? "",
            managerUID: managerUID,
            vacationStartOn: values.vacationStartOn.toDate() as any,
            vacationEndOn: values.vacationEndOn.toDate() as any,
            startWorkOn: values.startWorkOn.toDate() as any,
            employeeSignatureDate: firebase.firestore.Timestamp.fromDate(new Date()),
            managerSignatureDate: "",
            adminSignatureDate: "",
            HRSignatureDate: "",
            // HRUserUID: "",
            office: office,
            vacationType: vacationType,
            vocationPeriodDays: values.vocationPeriodDays,
            // adminUID: "",
            vacationAnnualScore: vacationAnnualScore,
            vacationSickScore: vacationSickScore,
            vacationCompensationScore: vacationCompensationScore,
            vacationDeathScore: vacationDeathScore,
            vacationMarriageScore: vacationMarriageScore,
            vacationPaternityScore: vacationPaternityScore,
            vacationMaternityScore: vacationMaternityScore,
            status: "Requested",
            requestedAt: firebase.firestore.Timestamp.fromDate(new Date()),
            notes: values.notes,
            halfDay: values.vocationPeriodDays == 0.5 ? halfDay : "",
            employeeSignatureURL: signatureURL == undefined ? "" : signatureURL,
            managerSignatureURL: "",
            HRSignatureURL: "",
            adminSignatureURL: "",
            version: 2,
          },
          { merge: true }
        );
        promise
          .then(() => {
            changeOnScore(values.vocationPeriodDays);
          })
          .then(() => {
            message.success("Your request has been submitted successfully");
            console.log("Document successfully written!");
            setModalState(false);
            setLoading(false);
            history.push(`/vacation/${newId}`);
            setLoading(false);
          })

          .then(() => form.resetForm());
        promise.catch((error) => {
          console.error("Error writing document: ", error);
          message.error("Failed");
        });
      }
    },
  });

  const uid = formik.values.uid;
  const {
    vacationAnnualScore,
    vacationSickScore,
    vacationCompensationScore,
    position,
    managerUID,
    office,
    vacationDeathScore,
    vacationMarriageScore,
    vacationPaternityScore,
    vacationMaternityScore,
    signatureURL,
  } = globalUsersData[uid] ?? {};

  const changeOnScore = async (vocationPeriodDays: number) => {
    if (vacationType == "Annual Leave") {
      userRef.doc(uid).update({
        vacationAnnualScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Sick Leave") {
      userRef.doc(uid).update({
        vacationSickScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Compensation Leave") {
      userRef.doc(uid).update({
        vacationCompensationScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Death Leave") {
      userRef.doc(uid).update({
        vacationDeathScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Marriage Leave") {
      userRef.doc(uid).update({
        vacationMarriageScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Paternity Leave") {
      userRef.doc(uid).update({
        vacationPaternityScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    } else if (vacationType == "Maternity Leave") {
      userRef.doc(uid).update({
        vacationMaternityScore: firebase.firestore.FieldValue.increment(-vocationPeriodDays),
      });
    }
  };

  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);
  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  let maxNumber = 0;
  switch (vacationType) {
    case "Annual Leave":
      maxNumber = vacationAnnualScore;
      break;
    case "Sick Leave":
      maxNumber = vacationSickScore;
      break;
    case "Death Leave":
      maxNumber = vacationDeathScore;
      break;
    case "Marriage Leave":
      maxNumber = vacationMarriageScore;
      break;
    case "Paternity Leave":
      maxNumber = vacationPaternityScore;
      break;
    case "Maternity Leave":
      maxNumber = vacationMaternityScore;
      break;
    case "Compensation Leave":
      maxNumber = vacationCompensationScore;
      break;

    default:
      maxNumber = 120;
      break;
  }

  return (
    <Drawer title="Vacation Request" width={600} visible={modalState} onClose={() => setModalState(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={"Employee Name"} span={6}>
                <Select
                  filterOption={(inputValue, option) => {
                    return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                  }}
                  showSearch
                  value={formik.values.uid}
                  onChange={(e) => {
                    formik.setFieldValue("uid", e);
                  }}
                >
                  {USERS_ID_FULL_NAME?.map((d: any) => {
                    return <Option value={d.uid}>{d.fullName}</Option>;
                  })}
                </Select>
              </Descriptions.Item>

              <Descriptions.Item label={t("general.vacationType")} span={6}>
                <Select value={vacationType} onChange={(e: string) => setVacationType(e)}>
                  <Option value={"Annual Leave"}>Annual Leave</Option>
                  <Option value={"Unpaid Leave"}>Unpaid Leave</Option>
                  <Option value={"Sick Leave"}>Sick Leave</Option>
                  <Option value={"Death Leave"}>Death Leave</Option>
                  <Option value={"Marriage Leave"}>Marriage Leave</Option>
                  <Option value={"Paternity Leave"}>Paternity Leave</Option>
                  <Option value={"Maternity Leave"}>Maternity Leave</Option>
                  <Option value={"Compensation Leave"}>Compensation Leave</Option>
                </Select>
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationStartOn")} span={6}>
                <DatePicker
                  value={formik.values.vacationStartOn}
                  format={dateFormat}
                  onChange={(e: any) => {
                    if (currentUserData?.fullControlOffices?.length != 0) {
                      formik.setFieldValue("vacationStartOn", e);
                    } else {
                      if (vacationType == "Sick Leave") {
                        formik.setFieldValue("vacationStartOn", e);
                      } else {
                        if (moment(e) >= moment()) {
                          formik.setFieldValue("vacationStartOn", e);
                        } else {
                          formik.setFieldValue("vacationStartOn", moment());
                        }
                      }
                    }
                  }}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vacationEndOn")} span={6}>
                <DatePicker
                  value={formik.values.vacationEndOn}
                  format={dateFormat}
                  onChange={(e: any) => {
                    if (currentUserData?.fullControlOffices?.length != 0) {
                      formik.setFieldValue("vacationEndOn", e);
                    } else {
                      if (vacationType == "Sick Leave") {
                        formik.setFieldValue("vacationEndOn", e);
                      } else {
                        if (moment(e) >= moment()) {
                          formik.setFieldValue("vacationEndOn", e);
                        } else {
                          formik.setFieldValue("vacationEndOn", moment());
                        }
                      }
                    }
                  }}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.startWorkOn")} span={6}>
                <DatePicker
                  value={formik.values.startWorkOn}
                  format={dateFormat}
                  onChange={(e: any) => {
                    formik.setFieldValue("startWorkOn", e);
                  }}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.vocationPeriodDays")} span={6}>
                <InputNumber
                  max={maxNumber}
                  min={0}
                  value={formik.values.vocationPeriodDays}
                  onChange={(e: any) => {
                    if (e > maxNumber) {
                      formik.setFieldValue("vocationPeriodDays", maxNumber == 0.5 ? maxNumber : Math.floor(maxNumber));
                    } else {
                      formik.setFieldValue("vocationPeriodDays", e == 0.5 ? e : Math.floor(e));
                    }
                  }}
                />
              </Descriptions.Item>
              {formik.values.vocationPeriodDays == 0.5 ? (
                <Descriptions.Item label={t("general.halfDay")} span={6}>
                  <Select value={halfDay} onChange={(e) => setHalfDay(e)}>
                    <Option value={"morning"}>Morning</Option>
                    <Option value={"evening"}>Evening</Option>
                  </Select>
                </Descriptions.Item>
              ) : null}

              <Descriptions.Item label={t("general.notes")} span={6}>
                <TextArea
                  value={formik.values.notes}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={(e) => formik.setFieldValue("notes", e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewVacationForUser;
