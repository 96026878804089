import React, { useState } from "react";
import { Card, Button, Collapse, Popconfirm, Icon, message, List, Tag } from "antd";
const { Panel } = Collapse;
import TasksTable from "./tasksTable";
import { department, departmentPosition } from "../../db/interfaces";
import EditDepartmentPosition from "./editDepartmentPositionDialog";
import { departmentPositionsRef, positionTasksRef } from "../../db/collectionsRef";
import { globalUsersData } from "../../global/usersList";

interface Props {
  data: departmentPosition[];
  departmentId: string;
  department: department;
}

export default function PositionsTable(props: Props) {
  const { data, departmentId, department } = props;
  const [modalState, setModalState] = useState(false);
  const [positionId, setPositionId] = useState("");

  const handlePositionDelete = (id: any) => {
    departmentPositionsRef
      .doc(id)
      .delete()
      .then(() => message.success("Deleted Successfully!"));
  };

  const header = (position: departmentPosition) => {
    return (
      <>
        <b>{position.title}</b> <a>{globalUsersData[position?.uid ?? ""]?.fullName}</a>
      </>
    );
  };
  return (
    <div>
      {modalState ? (
        <EditDepartmentPosition
          departmentId={departmentId}
          departmentPositionId={positionId}
          modalState={modalState}
          setModalState={setModalState}
        />
      ) : null}
      {data.map((position) => (
        <Collapse accordion onChange={(id) => {}}>
          <Panel
            key={position.id}
            header={header(position)}
            extra={
              <div onClick={(event) => event.stopPropagation()}>
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    setPositionId(position.id);
                    setModalState(true);
                  }}
                  type="link"
                >
                  Edit
                </Button>
                <Popconfirm
                  title={"Are you sure?"}
                  onConfirm={() => handlePositionDelete(position.id)}
                  onCancel={() => console.log("cancel")}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link" style={{ color: "red" }}>
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              </div>
            }
          >
            <div style={{ margin: "1%" }} dangerouslySetInnerHTML={{ __html: position.overview }} />

            {position.attached_job_description.length > 0 && (
              <List
                size="small"
                itemLayout="vertical"
                dataSource={position.attached_job_description}
                renderItem={(file: any) => (
                  <List.Item>
                    <div>
                      <a href={file.url} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                    </div>
                  </List.Item>
                )}
              />
            )}

            <TasksTable departmentId={departmentId} department={department} position={position} />
          </Panel>
        </Collapse>
      ))}
      <div style={{ padding: "2%" }}>
        <Tag color={"#00C49F"}>R: Responsible </Tag>
        <Tag color="#FFBB28">A: Accountable </Tag>
        <Tag color="#0088FE">C: Consulted </Tag>
        <Tag color="#FF8042">I: Informed </Tag>
      </div>
    </div>
  );
}
