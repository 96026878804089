import React, { useState, useRef, useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase, { firestore, auth, User } from "firebase/app";
import { Spin, Button, Select, Popconfirm } from "antd";
const { Option } = Select;
import UsersInformation from "../models/usersInformation";
import { userRef, officeRef, positionRef, projectRef, allocationRef, budgetLinesRef } from "../../db/collectionsRef";
import { currentUserData } from "../../db/userGlobal";
import { uniq } from "lodash";
import NewUser from "../models/newUser";
import moment from "moment";
import { admins } from "../../db/admin";
import DevTable from "../../helpers/devex-table";
import { allocation, users } from "../../db/interfaces";

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function UsersDashboardByProject() {

  const [projectId, setProjectId] = useState("notYet");
  const [UID, setUID] = useState("");
  const [modalState, setModalState] = useState(false);
  const [modalStateNewUser, setModalStateNewUser] = useState(false);
  const [defaultHiddenColumnNames] = useState([
    "gender",
    "department",
    "vacationPaternityScore",
    "vacationMaternityScore",
    "vacationMarriageScore",
    "vacationDeathScore",
  ]);

  const [allocationsSN, loadingAllocation] = useCollection(
    allocationRef.where("projectId", "==", projectId).where("endDate", ">=", moment().endOf("day").toDate())
  );
  const [AllUsers] = useCollection(usersRef);
  const [officeSN] = useCollection(officeRef);
  const [projectSN] = useCollection(projectRef);
  const [budgetLineSN, loading2, error2] = useCollection(budgetLinesRef.orderBy("createdAt"));

  if (!budgetLineSN || !allocationsSN || !projectSN || !AllUsers || !officeSN || !currentUserData.fullControlOffices)
    return <Spin />;

  const projectList = projectSN?.docs.map((p) => {
    return { id: p.id, projectCode: p?.data()?.projectCode };
  });

  const allocationsUids = allocationsSN?.docs.map((al) => al.data() as allocation);

  const users = AllUsers?.docs
    .filter((d) => currentUserData.fullControlOffices.includes(d.data()?.office))
    .filter((d) => uniq(allocationsUids).filter((i) => i.uid == d.id).length > 0)
    .map((d, index) => {
      return {
        ...d.data(),
        id: d.id,
        index: index + 1,
      };
    });

  const userAllocation = users.map((u) => {
    const allocation = allocationsUids.find((a) => a.uid == u.id) as allocation;
    return { ...u, ...allocation };
  });
  const columnsExport = [
    { name: "resourceNO", title: "Resource NO" },
    {
      name: "budgetLineId",
      title: "BudgetLine",
      getCellValue: (row: any) => {
        return budgetLineSN.docs.find((d) => d.id == row.budgetLineId)?.data().budgetLine;
      },
    },
    {
      name: "idaAllocationPercentage",
      title: "Contract %",
      getCellValue: (r) => r.idaAllocationPercentage + " " + "%",
    },
    { name: "percentage", title: "Allocation %", getCellValue: (r) => r.percentage + " " + "%" },
    {
      name: "startDate",
      title: "Start %",
      getCellValue: (row: any) => {
        return row.startDate ? moment(row.startDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "endDate",
      title: "End %",
      getCellValue: (row: any) => {
        return row.endDate ? moment(row.endDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },

    { name: "region", title: "Region" },
    { name: "firstName", title: "firstName" },
    { name: "lastName", title: "lastName" },
    { name: "gender", title: "gender" },
    { name: "email", title: "email" },
    // { name: "password", title: "password" },
    { name: "admin", title: "admin" },
    { name: "position", title: "position" },
    { name: "managerUID", title: "managerUID" },
    { name: "office", title: "office" },
    { name: "vacationAnnualScore", title: "vacationAnnualScore" },
    { name: "vacationSickScore", title: "vacationSickScore" },
    { name: "vacationCompensationScore", title: "vacationCompensationScore" },
    { name: "vacationDeathScore", title: "vacationDeathScore" },
    { name: "vacationMarriageScore", title: "vacationMarriageScore" },
    { name: "vacationPaternityScore", title: "vacationPaternityScore" },
    { name: "vacationMaternityScore", title: "vacationMaternityScore" },
    { name: "department", title: "department" },
    { name: "adminHR", title: "adminHR" },
    { name: "token", title: "token" },
    { name: "adminLaw", title: "adminLaw" },
    { name: "CEO", title: "CEO" },
    { name: "idaAllocationPercentage", title: "Contract Percentage" },
    {
      name: "status",
      title: "Status",
    },

    { name: "adminOffices", title: "adminOffices" },
    { name: "HROffices", title: "HROffices" },
    { name: "personalPhoto", title: "personalPhoto" },
    { name: "refNO", title: "refNO" },
    { name: "arabicFullName", title: "arabicFullName" },
    { name: "fatherName", title: "fatherName" },
    { name: "motherName", title: "motherName" },
    { name: "dateOfBirth", title: "dateOfBirth" },
    { name: "placeOfBirth", title: "placeOfBirth" },
    { name: "permanentAddress", title: "permanentAddress" },
    { name: "currentAddress", title: "currentAddress" },
    { name: "salary", title: "salary" },
    { name: "currency", title: "currency" },
    { name: "startingDate", title: "startingDate" },
    { name: "endingDate", title: "endingDate" },
    { name: "phoneNumber", title: "phoneNumber" },
    { name: "identityNo", title: "identityNo" },
    { name: "emergencyContact1", title: "emergencyContact1" },
    { name: "emergencyContact2", title: "emergencyContact2" },
    { name: "bankAccountName", title: "bankAccountName" },
    { name: "bankName", title: "bankName" },
    { name: "IBAN", title: "IBAN" },
    { name: "bankAccountNumber", title: "bankAccountNumber" },
    { name: "status", title: "status" },
    { name: "signatureURL", title: "signatureURL" },
  ];
  const columns = [
    { name: "index", title: "NO" },
    { name: "resourceNO", title: "Resource NO" },
    {
      name: "budgetLineId",
      title: "BudgetLine",
      getCellValue: (row: any) => {
        return budgetLineSN.docs.find((d) => d.id == row.budgetLineId)?.data().budgetLine;
      },
    },
    {
      name: "idaAllocationPercentage",
      title: "Contract %",
      getCellValue: (r) => r.idaAllocationPercentage + " " + "%",
    },
    { name: "percentage", title: "Allocation %", getCellValue: (r) => r.percentage + " " + "%" },
    {
      name: "startDate",
      title: "Start %",
      getCellValue: (row: any) => {
        return row.startDate ? moment(row.startDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "endDate",
      title: "End %",
      getCellValue: (row: any) => {
        return row.endDate ? moment(row.endDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },

    { name: "region", title: "Region" },

    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "identityNo", title: "Identity No" },

    { name: "email", title: "Email" },
    // { name: "password", title: "Password" },
    { name: "department", title: "Department" },
    { name: "fatherName", title: "Mother Name" },
    { name: "arabicFullName", title: "Arabic FullName" },
    {
      name: "dateOfBirth",
      title: "Date Of Birth",
      getCellValue: (row: any) => {
        return row.dateOfBirth ? moment(row.dateOfBirth.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    { name: "placeOfBirth", title: "Place Of Birth" },
    { name: "permanentAddress", title: "Permanent Address" },
    { name: "currentAddress", title: "Current Address" },
    { name: "phoneNumber", title: "Phone Number" },
    { name: "emergencyContact1", title: "EmergencyContact1" },
    { name: "emergencyContact2", title: "EmergencyContact2" },
    { name: "bankAccountName", title: "Bank AccountName" },
    { name: "IBAN", title: "IBAN" },
    { name: "salary", title: "Salary" },
    { name: "currency", title: "Currency" },
    {
      name: "startingDate",
      title: "Starting Date",
      getCellValue: (row: any) => {
        return row.startingDate ? moment(row.startingDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "endingDate",
      title: "Ending Date",
      getCellValue: (row: any) => {
        return row.endingDate ? moment(row.endingDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },

    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) =>
        `${AllUsers.docs?.find((d) => d.id == row.managerUID)?.data()?.firstName} ${
          AllUsers.docs?.find((d) => d.id == row.managerUID)?.data()?.lastName
        }`,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (e) => officeSN.docs.find((d) => d.id == e.office)?.data().officeName,
    },
    { name: "position", title: "Position" },
    { name: "vacationAnnualScore", title: "Annual Score", getCellValue: (e) => e.vacationAnnualScore.toFixed(3) },
    { name: "vacationSickScore", title: "Sick Score" },
    {
      name: "vacationCompensationScore",
      title: "Compensation Score",
      getCellValue: (e) => e.vacationCompensationScore.toFixed(3),
    },
    { name: "vacationDeathScore", title: "Death Leave Score", getCellValue: (e) => e.vacationDeathScore.toFixed(3) },
    { name: "vacationMarriageScore", title: "Marriage Score", getCellValue: (e) => e.vacationMarriageScore.toFixed(3) },
    {
      name: "vacationMaternityScore",
      title: "Maternity Score",
      getCellValue: (e) => e.vacationMaternityScore.toFixed(3),
    },
    {
      name: "vacationPaternityScore",
      title: "Paternity Score",
      getCellValue: (e) => e.vacationPaternityScore.toFixed(3),
    },
    { name: "idaAllocationPercentage", title: "Contract Percentage" },
    {
      name: "status",
      title: "Status",
    },

    {
      name: "action",
      title: "Edit User Info",
      getCellValue: (r) => {
        return currentUserData.HRProjectsManagersOffices.includes(r.office) && (
          <Button
            onClick={() => {
              setModalState(true);
              setUID(r.id);
            }}
          >
            Add More
          </Button>
        );
      },
    },
    {
      name: "reset",
      title: "Reset",
      getCellValue: (r) => {
        return  (
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={async () => {
              await firebase.auth().sendPasswordResetEmail(r.email);
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Reset</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "disable",
      title: "Disable/Enable",
      getCellValue: (r) => {
        return currentUserData.HRProjectsManagersOffices.includes(r.office) && (
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={async () => {
              await userRef.doc(r.id).update({
                status: r.status == "active" || !r.status ? "disable" : "active",
              });
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type={r.status == "disable" ? "primary" : "danger"}>
              {r.status == "disable" ? "Active" : "Disable"}
            </Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "delete",
      title: "Delete",
      getCellValue: (r) => {
        return currentUserData.HRProjectsManagersOffices.includes(r.office)  ? (
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={async () => {
              await userRef
                .doc(r.id)
                .update({
                  status: "delete",
                })
                .then(() => {
                  positionRef
                    .where("uid", "==", r.id)
                    .onSnapshot((sn) => sn.docs?.map((d) => positionRef.doc(d.id).delete()));
                });
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        ) : null;
      },
    },
  ];

  return (
    <div>
      {modalState ? <UsersInformation modalState={modalState} setModalState={setModalState} UID={UID} /> : null}
      {modalStateNewUser ? <NewUser modalState={modalStateNewUser} setModalState={setModalStateNewUser} /> : null}
      <Select
        size="default"
        placeholder="Search for project!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={projectList.find((p) => p.id == projectId)?.projectCode}
        onChange={(e) => {
          const id = projectList.find((p) => p.projectCode == e)?.id;
          setProjectId(id);
        }}
      >
        {projectList.map((d) => {
          return <Option value={d.projectCode}>{d.projectCode}</Option>;
        })}
      </Select>
      <Paper>
        <DevTable
          data={userAllocation}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName={`Users-List`}
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
