import React from "react";
import EditorJs from "@natterstefan/react-editor-js";
import { defaultData, EDITOR_JS_TOOLS } from "./constants";
import { API, OutputData } from "@editorjs/editorjs";
import "./editor.css";
import { currentUserData } from "../../db/userGlobal";
import { Divider } from "antd";
interface Props {
  data: OutputData;
  setInstance: (args: API) => void;
  minHeight: number;
  holder: string;
}
function Editor(props: Props) {
  const { data, setInstance, minHeight, holder } = props;
  const user = currentUserData;
  return (
    <div>
      <EditorJs
        holder={holder}
        placeholder="Write here..."
        minHeight={minHeight}
        tools={EDITOR_JS_TOOLS}
        onChange={(instanceEdit) => setInstance(instanceEdit)}
        data={data ?? defaultData({ firstName: user.firstName })}
      />
    </div>
  );
}

export default Editor;
