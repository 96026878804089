import React, { useRef, useState } from "react";
import firebase, { auth } from "firebase/app";
import { Button, Spin, Icon, Modal, Popconfirm, Row, Col, Divider } from "antd";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import FileUploader from "react-firebase-file-uploader";
import { calendar } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import SendMOM from "./SendMOMDialog";
import { Calendar } from "../../db/interfaces";
import Editor from "../Editor/Editor";
import { API, OutputData } from "@editorjs/editorjs";
import moment from "moment";
import { orderBy } from "lodash";
import { dataToHTML } from "../Editor/constants";

interface Props {
  modalStateMOM: boolean;
  setModalStateMOM: React.Dispatch<React.SetStateAction<boolean>>;
  doc: any;
  MOM: string;
}

function AddMOM(props: Props) {
  const { t } = useTranslation();
  const { modalStateMOM, setModalStateMOM, doc } = props;
  const [file, setFile] = useState("");
  const [modalStateSendMOM, setModalStateSendMOM] = useState(false);
  const [files, setFiles] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [mentions, setMentions] = useState(Array);
  const [uploadingAttach, setUploadingAttach] = useState(false);
  const [instance, setInstance] = useState({} as API);
  const [editorContent, setEditorContent] = useState(null as any);

  const [instancePrevious, setInstancePrevious] = useState({} as API);
  const [instanceAgenda, setInstanceAgenda] = useState({} as API);

  const uid = auth().currentUser!.uid;
  const MOMObj = doc.MOMObj;
  const MOM = doc.MOM;
  const originalId = doc.originalId;

  const [meetingsSN] = useCollection(
    calendar
      .where("originalId", "==", originalId ?? "notYet")
      .where("startDate", "<", moment(doc.startDate.toDate()).toDate())
      .orderBy("startDate", "desc")
      .limit(3)
  );

  const handleEditorSave = async () => {
    const outputData: OutputData = await instance?.saver?.save();
    setEditorContent(outputData);
  };

  const handlePreviousEditorSave = async () => {
    const outputData: OutputData = await instancePrevious?.saver?.save();
    if (lastMeetingWithMOM && outputData) {
      await calendar.doc(lastMeetingWithMOM.id).update({
        MOM: dataToHTML(outputData as OutputData),
        MOMObj: outputData,
        MOMWriter: uid,
        sendMOM: false,
      });
    }
  };

  const handleAgendaEditorSave = async () => {
    const outputData: OutputData = await instanceAgenda?.saver?.save();
    if (outputData) {
      await calendar.doc(doc.id).update({
        agenda: dataToHTML(outputData as OutputData),
        agendaObj: outputData,
      });
    }
  };

  if (!meetingsSN) {
    return <p>loadingmm</p>;
  }
  const allMeetingsDataFromOriginal = meetingsSN?.docs?.map((d) => {
    return { ...d.data(), id: d.id };
  });
  const meetingsWithMOM = allMeetingsDataFromOriginal?.filter((meeting: any) => meeting.MOM !== undefined);

  const sortedMeetingsWithMOM = orderBy(meetingsWithMOM, ["startDate"], ["desc"]);
  const lastMeetingWithMOM = sortedMeetingsWithMOM?.[0] as Calendar;
  const momAttachStorage = firebase.storage().ref(uid);

  const attachments = doc.momAttach ?? [];
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  const MOM_TO_EDITOR = (MOM: string, MOMObj: OutputData) => {
    if (MOMObj == undefined) {
      if (MOM == undefined) {
        const emptyMOM: OutputData = {
          blocks: [],
        };
        return emptyMOM;
      } else {
        const newMOM: OutputData = {
          blocks: [
            {
              type: "paragraph",
              data: {
                text: MOM,
              },
            },
          ],
        };
        return newMOM;
      }
    } else {
      return MOMObj;
    }
  };

  return (
    <div>
      {modalStateSendMOM ? (
        <SendMOM
          setModalStateMOM={setModalStateMOM}
          modalStateSendMOM={modalStateSendMOM}
          setModalStateSendMOM={setModalStateSendMOM}
          files={files}
          downloadURLs={downloadURLs}
          MOM={editorContent}
          MOMWriter={uid}
          mentions={mentions}
          docId={doc.id}
        />
      ) : null}
      <Modal
        title={t("general.addMOM")}
        width={"60%"}
        visible={modalStateMOM}
        closable={false}
        footer={[
          <Popconfirm
            title={"Are you sure?"}
            okText="Yes"
            cancelText="No"
            onConfirm={() => {
              setModalStateMOM(false);
            }}
          >
            <Button>Cancel</Button>
          </Popconfirm>,
          <Button
            type="primary"
            disabled={false}
            onClick={() => {
              if (instance?.saver) {
                handleEditorSave();
                setModalStateSendMOM(true);
                handleAgendaEditorSave();
                handlePreviousEditorSave();
              } else {
                handleAgendaEditorSave();
                handlePreviousEditorSave();
                setModalStateMOM(false);
              }
            }}
          >
            Submit
          </Button>,
        ]}
      >
        <div>
          <Row gutter={24}>
            <Divider orientation="left">
              <b>Current Meeting : {moment(doc?.startDate.toDate()).format("DD / MM / YYYY hh:mm a").toString()}</b>
            </Divider>
            <Col
              span={9}
              style={{
                border: "solid 1px #31a0b5",
                borderRadius: "5px",
                margin: "1%",
                marginBottom: "2%",
                padding: "1%",
              }}
            >
              <div>
                <b>Agenda</b>
                <Row>
                  <Editor
                    holder={"agenda_meeting"}
                    data={MOM_TO_EDITOR(doc.agenda, doc.agendaObj)}
                    setInstance={setInstanceAgenda}
                    minHeight={100}
                  />
                </Row>
              </div>
            </Col>

            <Col
              span={14}
              style={{
                border: "1px solid rgb(51 193 51)",
                borderRadius: "5px",
                margin: "1%",
                marginBottom: "2%",
                padding: "1%",
              }}
            >
              <div>
                <b>MOM</b>
                <Row>
                  <Editor
                    holder={"current_meeting"}
                    data={MOM_TO_EDITOR(MOM, MOMObj)}
                    setInstance={setInstance}
                    minHeight={100}
                  />
                </Row>
              </div>
            </Col>
          </Row>

          {allMeetingsDataFromOriginal.length != 0 && (
            <div>
              <Divider orientation="left">
                <b>
                  Previous Meeting :{" "}
                  {moment(lastMeetingWithMOM?.startDate.toDate()).format("DD / MM / YYYY hh:mm a").toString()}
                </b>
              </Divider>
              <Row
                style={{
                  border: "solid 1px orange",
                  borderRadius: "5px",
                  margin: "1%",
                  marginBottom: "3%",
                  padding: "1%",
                }}
              >
                <Editor
                  holder={"previous_meeting"}
                  data={MOM_TO_EDITOR(lastMeetingWithMOM?.MOM, lastMeetingWithMOM?.MOMObj)}
                  setInstance={setInstancePrevious}
                  minHeight={100}
                />
              </Row>
            </div>
          )}

          <label
            style={{
              background: "rgb(100 181 246)",
              padding: "1%",
              marginRight: "1%",
              borderColor: "rgb(100 181 246)",
              borderRadius: "6px",
              color: "white",
            }}
          >
            <Icon type="upload" />
            <span style={{ padding: "2%" }}>{t("general.uploadAttach")}</span>
            {uploadingAttach ? <Spin indicator={antIcon} style={{ color: "#fff" }} /> : null}
            {doc.momAttach == undefined || doc.momAttach.length == 0 ? null : (
              <Icon type="check" style={{ color: "#fff", padding: "1%" }} />
            )}

            <FileUploader
              hidden
              storageRef={momAttachStorage}
              onUploadStart={(e: any) => {
                setFile(e.name);
                setUploadingAttach(true);
              }}
              onUploadSuccess={async (fileName: string, args: any) => {
                const downloadURL = await momAttachStorage.child(fileName).getDownloadURL();
                setUploadingAttach(false);
                console.log("file", fileName);
                setFiles([...files, fileName] as any);
                setDownloadURLs([...downloadURLs, downloadURL] as any);
              }}
            />
          </label>
          <label
            style={{
              background: "#fff",
              padding: "1%",
              marginRight: "1%",
              border: "solid thin",
              borderColor: "rgb(100 181 246)",
              borderRadius: "6px",
              color: "rgb(100 181 246)",
            }}
          >
            {`Uploaded File Number: ${[...attachments, ...downloadURLs].length}`}
          </label>
          <Button
            onClick={() => {
              calendar.doc(doc.id).update({
                momAttach: [],
                filesNames: [],
                sendMOM: false,
              });
              setFiles([]);
            }}
            type="danger"
          >
            Delete Attach
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default AddMOM;
