import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col
} from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { notificationsRef, userRef, orderRef, meetingRoomList } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  roomName: Yup.string().required("*")
  // location: Yup.string().required("*"),
  // capacity: Yup.number().required("*")
  //   contains: Yup.string().required("*")
});

function AddNewRoomModel(props: Props) {
  const { t } = useTranslation();

  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const newId = meetingRoomList.doc().id;
  const uid = auth().currentUser!.uid;

  const formik = useFormik({
    initialValues: {
      roomName: "",
      location: "",
      capacity: 0,
      contains: []
    },
    validationSchema: projectSchema,
    onSubmit: async values => {
      const promise = meetingRoomList.doc(newId).set(
        {
          roomName: values.roomName,
          location: values.location,
          capacity: values.capacity,
          contains: values.contains,
          createdAt: new Date()
        },
        { merge: true }
      );
      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch(error => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    }
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };

  return (
    <Drawer
      title={t("general.addNewRoom")}
      width={1000}
      height={350}
      visible={modalState}
      placement={"bottom"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Row gutter={16}>
          <Col span={10}>
            <Form.Item label={t("general.roomName")}>
              <Input value={formik.values.roomName} onChange={e => formik.setFieldValue("roomName", e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label={t("general.location")}>
              <Input value={formik.values.location} onChange={e => formik.setFieldValue("location", e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={t("general.capacitySits")}>
              <InputNumber value={formik.values.capacity} onChange={e => formik.setFieldValue("capacity", e)} />
            </Form.Item>
          </Col>
          {/* <Col span={12}>
            <Form.Item label={t("general.contains")}>
              <TextArea
                value={formik.values.contains}
                onChange={e => formik.setFieldValue("contains", e.target.value)}
              />
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={16}>
          <Col span={16}>
            <Form.Item label={t("general.contains")}>
              <TextArea
                value={formik.values.contains}
                onChange={e => formik.setFieldValue("contains", e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewRoomModel;
