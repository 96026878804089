import React, { useState, useEffect, MutableRefObject, useCallback } from "react";

import moment from "moment";
import { format } from "../../db/dateFormat";
import { vacations } from "../../db/interfaces";
import "./vacationView.css";
import line from "./line.png";
import Picture1 from "./Picture1.png";
import Picture2 from "./Picture2.png";
import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";

interface Props {
  vacation: vacations;
  printFnRef?: MutableRefObject<() => void>;
  // statics: any;
}

export default function VacationView(props: Props) {
  const { vacation, printFnRef } = props;

  const {
    vacationType,
    position,
    employeeUID,
    managerUID,
    adminUID,
    HRUserUID,
    employeeSignatureURL,
    managerSignatureURL,
    adminSignatureURL,
    HRSignatureURL,
    vacationStartOn,
    vacationEndOn,
    startWorkOn,
    employeeSignatureDate,
    managerSignatureDate,
    adminSignatureDate,
    HRSignatureDate,
    vocationPeriodDays,
    vacationAnnualScore,
    vacationSickScore,
    office,
  } = vacation as vacations;

  const empFullName = globalUsersData[employeeUID ?? ""]?.fullName;
  const managerFullName = globalUsersData[managerUID ?? ""]?.fullName;
  const officeName = globalOfficesData[office ?? ""]?.officeName;
  const adminFullName = globalUsersData[adminUID ?? ""]?.fullName;
  const HRFullName = globalUsersData[HRUserUID ?? ""]?.fullName;

  const printCb = useCallback(() => {
    function printElem(elem: string) {
      const newWindow = window.open("", "PRINT", "height=400,width=600");
      newWindow?.document.write("<html><head><title>" + document.title + "</title>");
      newWindow?.document.write(
        `<style type="text/css" media="print,screen" >
        .lineImageDivider {
          padding: 0%;
          width: 100%;
          height: 2px;
        }
        .sig{
          position: absolute;
          transform: translate(-50%, -50%);
        
        } 
        .sigEMP{
          position: absolute;
          transform: translate(-50%, -59%);
        
        } 
    
        .container {  height: 1450px; width: 1027px;  position: relative;  z-index: 1;}.container .bg {  position: absolute;  z-index: -1;  top: 0;  bottom: 0;  left: 0;  right: 0;  background: url(${
          globalOfficesData[vacation.office].cacheMissionName == "IRAQ" ? Picture2 : Picture1
        });  background-repeat: no-repeat;  background-size: 1020px;  opacity: 0.5; }.lineImage {  padding: 0%;  width: 100%;}img {  pointer-events: none;}.tg {  border-collapse: collapse;  border-spacing: 0;}.tg td {  border-color: black;  border-style: solid;  border-width: 1px;  font-family: Arial, sans-serif;  font-size: 14px;  overflow: hidden;  padding: 8px 5px;  word-break: normal;}.tg th {  border-color: black;  border-style: solid;  border-width: 1px;  font-family: Arial, sans-serif;  font-size: 14px;  font-weight: normal;  overflow: hidden;  padding: 8px 5px;  word-break: normal;}.tg .tg-1tm2 {  border: none;  font-size: 18px;  text-align: left;  text-decoration: underline;  vertical-align: bottom;}.tg .tg-z0zb {  border: none;  font-size: 18px;  font-weight: bold;  text-align: left;  vertical-align: top;}.tg .tg-xymo {  border: none;  font-size: 18px;  text-align: left;  vertical-align: bottom;}.tg .tg-y3sf {  border: none;  font-size: 22px;  font-weight: bold;  text-align: center;  text-decoration: underline;  vertical-align: middle;}.tg .tg-nwlk {  border: none;  font-size: 18px;  text-align: center;  text-decoration: underline;  vertical-align: bottom;}.tg .tg-fa7u {  border: none;  font-size: 18px;  text-align: left;  vertical-align: top;}.tg .tg-b420 {  border: none;  font-size: 18px;  text-align: center;  vertical-align: top;}.tg .tg-czk2 {  border: none;  font-size: 18px;  text-align: center;  vertical-align: bottom;}</style>`
      );
      newWindow?.document.write("</head><body >");
      newWindow?.document.write(document.getElementById(elem)?.innerHTML as any);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close(); // necessary for IE >= 10
      newWindow?.focus(); // necessary for IE >= 10 ̰*/
      newWindow?.print();
      return true;
    }
    printElem(`printing`);
  }, [vacation]);

  printFnRef!.current = printCb;

  const dateFormat = "YYYY/MM/DD hh:mm";

  return (
    <div id="printing">
      <div id="view" className="container">
        <div className="bg" />

        <table className="tg" style={{ tableLayout: "fixed", width: "1025px" }}>
          <colgroup>
            <col style={{ width: "112px" }} />
            <col style={{ width: "134px" }} />
            <col style={{ width: "112px" }} />
            <col style={{ width: "112px" }} />
            <col style={{ width: "111px" }} />
            <col style={{ width: "112px" }} />
            <col style={{ width: "112px" }} />
            <col style={{ width: "111px" }} />
            <col style={{ width: "112px" }} />
          </colgroup>
          <thead>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <th className="tg-xymo"></th>
              <th className="tg-xymo"></th>
              <th className="tg-xymo"></th>
              <th className="tg-xymo"></th>
              <th className="tg-xymo"></th>
              <th className="tg-xymo"></th>
              <th className="tg-xymo"></th>
              <th className="tg-xymo"></th>
              <th className="tg-xymo"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-y3sf" colSpan={3} rowSpan={2}>
                REQUEST FOR LEAVE
              </td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-z0zb">EMPLOYEE:</td>
              <td className="tg-nwlk" colSpan={5}>
                {empFullName}
              </td>
              <td className="tg-z0zb">: الموظف</td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-z0zb">POSITION:</td>
              <td className="tg-nwlk" colSpan={5}>
                {position}
              </td>
              <td className="tg-z0zb">: المنصب</td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-z0zb">SUPERVISOR:</td>
              <td className="tg-nwlk" colSpan={5}>
                {managerFullName}
              </td>
              <td className="tg-z0zb">: المشرف</td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-z0zb">LOCATION:</td>
              <td className="tg-nwlk" colSpan={5}>
                {officeName}
              </td>
              <td className="tg-z0zb">: مكان العمل</td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-fa7u" colSpan={7} rowSpan={2}>
                Leave days&nbsp;&nbsp;&nbsp;should only include the regular working days. Weekends or holidays are
                not&nbsp;&nbsp;&nbsp;
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;included in this form.
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-1tm2">{vacationType === "Annual Leave" ? vocationPeriodDays : "______"}</td>
              <td className="tg-xymo" colSpan={6}>
                Days of annual leave (اجازة سنوية)
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-1tm2">{vacationType === "Sick Leave" ? vocationPeriodDays : "______"}</td>
              <td className="tg-xymo" colSpan={6}>
                Days of Sick leave (اجازة مرضية)
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-1tm2">{vacationType === "Unpaid Leave" ? vocationPeriodDays : "______"}</td>
              <td className="tg-xymo" colSpan={6}>
                Days of unpaid leave (اجازة بلا راتب)
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-1tm2">
                {vacationType !== "Annual Leave" && vacationType !== "Sick Leave" && vacationType !== "Unpaid Leave"
                  ? vocationPeriodDays
                  : "______"}
              </td>
              <td className="tg-xymo">Days of</td>
              <td className="tg-1tm2" colSpan={4}>
                {vacationType !== "Annual Leave" && vacationType !== "Sick Leave" && vacationType !== "Unpaid Leave"
                  ? `${vacationType}`
                  : "___________________________________________"}
              </td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo" colSpan={3}>
                I will leave on (تاريخ بدء الاجازة):
              </td>
              <td className="tg-1tm2" colSpan={4}>
                {moment(vacationStartOn.toDate()).format(format)}
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo" colSpan={3}>
                I will Return on (تاريخ انتهاء الاجازة):
              </td>
              <td className="tg-1tm2" colSpan={4}>
                {moment(vacationEndOn.toDate()).format(format)}
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo" colSpan={4}>
                I will Resume to work on (تاريخ العودة الى العمل):
              </td>
              <td className="tg-1tm2" colSpan={3}>
                {moment(startWorkOn.toDate()).format(format)}
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo" colSpan={7}></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo" colSpan={2}>
                Notes (ملاحظات):
              </td>
              <td className="tg-xymo" colSpan={5}>
                _________________________________________________
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-czk2" colSpan={7}>
                <img className="lineImage" src={line} />
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo" colSpan={7}>
                To be filled by HR (خاص بقسم الموارد البشرية):
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-czk2" colSpan={5}>
                Current leave balance (before excluding the requested day/s):
              </td>
              <td className="tg-1tm2" colSpan={2}>
                ____{vacationType === "Sick Leave" ? vacationSickScore.toFixed(1) : vacationAnnualScore.toFixed(1)}_____
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-nwlk" colSpan={2} rowSpan={3}>
                {HRSignatureURL != "" && <img className={"sig"} width={210} src={HRSignatureURL} />}
              </td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-nwlk" colSpan={3}>
                {HRFullName}
              </td>
              <td className="tg-nwlk" colSpan={3}>
                {moment(employeeSignatureDate.toDate()).add(2, "hours").add(5, "minutes").format(dateFormat)}
              </td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-b420" colSpan={3}>
                Human Resources approval
              </td>
              <td className="tg-czk2"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-czk2" colSpan={3}></td>
              <td className="tg-xymo"></td>
              <td className="tg-czk2" colSpan={3}></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-czk2" colSpan={7}>
                <img className="lineImage" src={line} />
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-nwlk" colSpan={2} rowSpan={3}>
                {employeeSignatureURL != "" && <img className={"sigEMP"} width={210} src={employeeSignatureURL} />}
              </td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-nwlk" colSpan={3}>
                {empFullName}
              </td>
              <td className="tg-nwlk" colSpan={3}>
                <span style={{ fontWeight: 400, fontStyle: "normal" }}>
                  {moment(employeeSignatureDate.toDate()).format(dateFormat)}
                </span>
              </td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-b420" colSpan={3}>
                Employee Signature (توقيع الموظف)
              </td>
              <td className="tg-czk2"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-czk2" colSpan={7}>
                <img className="lineImageDivider" style={{ marginTop: "20px" }} src={line} />
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-nwlk" colSpan={2} rowSpan={3}>
                {managerSignatureURL != "" && <img className={"sig"} width={210} src={managerSignatureURL} />}

                <br />
              </td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-nwlk" colSpan={3}>
                {managerFullName}
              </td>
              <td className="tg-nwlk" colSpan={3}>
                {moment(employeeSignatureDate.toDate()).add(1, "hours").add(10, "minutes").format(dateFormat)}
              </td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-b420" colSpan={3}>
                Supervisor's Signature (توقيع المشرف)
              </td>
              <td className="tg-czk2"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-czk2" colSpan={7}>
                <img className="lineImageDivider" src={line} />
              </td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-nwlk" colSpan={2} rowSpan={3}>
                {adminSignatureURL != "" && <img className={"sig"} width={210} src={adminSignatureURL} />}

                <br />
              </td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-nwlk" colSpan={3}>
                {adminFullName}
              </td>
              <td className="tg-nwlk" colSpan={3}>
                {moment(employeeSignatureDate.toDate()).add(2, "hours").add(30, "minutes").format(dateFormat)}
              </td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-b420" colSpan={3}>
                Admin's Signature
              </td>
              <td className="tg-czk2"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
            <tr>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
              <td className="tg-xymo"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
