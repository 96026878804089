import { departmentsRef } from "../db/collectionsRef";
import { department } from "../db/interfaces";

interface DepartmentsWithId extends department {
  id: string;
}

export const globalDepartmentsData: Record<string, DepartmentsWithId> & { loaded?: boolean } = {};

departmentsRef.onSnapshot((data) => {
  Object.defineProperty(globalDepartmentsData, "loaded", { enumerable: false, value: true });
  data.docs.forEach((doc) => {
    globalDepartmentsData[doc.id] = { ...doc.data(), id: doc.id } as DepartmentsWithId;
  });
});
