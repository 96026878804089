import moment, { Moment } from "moment";
import { paymentTypes } from "./userGlobal";
import _ from "lodash";

export const currentPaymentType = (userUID: string, date: Moment) => {
  // Filter the logs by the userUID using Lodash's _.filter
  const userLogs = _.filter(paymentTypes, { uid: userUID });

  // Sort the logs by the startDate using Lodash's _.sortBy
  const sortedLogs = _.sortBy(userLogs, (log) => log.startDate.valueOf());

  // Find the log that matches the targetDate using Lodash's _.findLast
  const paymentTypeLog = _.findLast(sortedLogs, (log) =>
    date.isSameOrAfter(moment(log.startDate.toDate()).startOf("day"))
  );

  return paymentTypeLog ? paymentTypeLog?.paymentType : "";
};
