import { Comment, Avatar, Form, Button, List, Input, Spin } from "antd";
import moment from "moment";
import { useState } from "react";
import React from "react";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { discussionsRef, userRef, notificationsRef, officeRef } from "../db/collectionsRef";
import { auth } from "firebase";
import { sortBy, uniq } from "lodash";
import { useTranslation } from "react-i18next";
import { currentUserData } from "../db/userGlobal";
import { CEOs } from "../db/admin";

const { TextArea } = Input;

interface Props {
  orderId: string;
  userId: any;
  type: string;
  officeId?: string;
}

export default function Discussion(props: Props) {
  const { t } = useTranslation();
  const { userId, orderId, type, officeId } = props;
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const uid = auth().currentUser!.uid;

  const [discussionSN, loading] = useCollection(discussionsRef.where("orderId", "==", orderId));
  const [userSN, loading2] = useCollection(userRef);
  const [userLawSN] = useCollection(userRef.where("adminLaw", "==", true));
  const [officeSN] = useDocument(officeRef.doc(officeId ?? ""));

  if (!userSN || !discussionSN || !officeSN) {
    return <Spin />;
  }
  const OfficeAdminWithCEO = uniq([...(officeSN?.data()?.admins ?? []), ...(officeSN?.data()?.HRs ?? [])]);
  const OfficeAdmins = OfficeAdminWithCEO.filter((id) => !CEOs.includes(id));
  const handleSubmit = () => {
    setSubmitting(true);
    discussionsRef
      .add({
        datetime: "few min",
        postedAt: new Date(),
        author: uid,
        content: value,
        orderId: orderId,
        notifyTo: userId,
        avatar: currentUserData.personalPhoto ?? "",
        type: type,
        cacheAuthor: currentUserData.firstName + " " + currentUserData.lastName,
      })
      .then(() => {
        setSubmitting(false);
        setValue("");
      })
      .then(() => {
        userId != uid
          ? notificationsRef.add({
              notifyTo: userId,
              notifyFrom: uid,
              orderId: orderId,
              read: false,
              kind: type == "order" ? "messageOrder" : type == "vacation" ? "messageVacation" : "messageOvertime",
              content: value,
              postedAt: new Date(),
            })
          : type == "order"
          ? userLawSN?.docs?.map((doc: any) => {
              const adminID = doc.id;
              notificationsRef.add({
                notifyTo: adminID, //admin
                notifyFrom: uid,
                orderId: orderId,
                kind: type == "order" ? "messageOrder" : type == "vacation" ? "messageVacation" : "messageOvertime",
                read: false,
                content: value,
                postedAt: new Date(),
              });
            })
          : OfficeAdmins?.map((id: any) => {
              const adminID = id;
              notificationsRef.add({
                notifyTo: adminID, //admin
                notifyFrom: uid,
                orderId: orderId,
                kind: type == "order" ? "messageOrder" : type == "vacation" ? "messageVacation" : "messageOvertime",
                read: false,
                content: value,
                postedAt: new Date(),
              });
            });
      });
  };

  const comments = discussionSN === undefined ? [] : discussionSN.docs;

  const sortedCommentsSnapshot = sortBy(comments, (item) => item.data().postedAt.toDate());

  const dataSource = sortedCommentsSnapshot.map((item) => {
    const userData = userSN?.docs?.find((d) => d.id == item.data?.().author)?.data?.();
    return (
      <div
        style={{
          padding: "20px",
          color: "rgba(0, 0, 0, 0.65)",
          background: "#ffff",
          borderRadius: "19px",
          boxShadow: "6px 7px 17px 3px rgba(0, 0, 0, 0.09)",
          margin: "25px 0px",
        }}
        key={item.id}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            WebkitBoxPack: "start",
            justifyContent: "flex-start",

            fontSize: "14px",
            alignItems: "center",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              lineHeight: "14px",
              marginRight: "10px",
              fontFamily: "initial",
            }}
          >
            <span>
              <Avatar
                size="large"
                src={userData?.personalPhoto}
                style={{
                  backgroundColor: userData?.gender == "male" ? "#87d068" : "#db7093",
                }}
              >
                {userData?.personalPhoto ?? userData?.firstName.slice(0, 1) + userData?.lastName.slice(0, 1)}
              </Avatar>
            </span>{" "}
            <span>
              <a>{userData?.firstName} </a>
              <a>{userData?.lastName} </a>
            </span>
          </span>
          <span style={{ fontSize: "12px", color: "#ccc", whiteSpace: "nowrap" }}>
            <span>{item?.data()?.postedAt.toDate().toLocaleString()}</span>
          </span>
        </div>
        <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: item.data().content }} />
      </div>
    );
  });

  return (
    <div>
      {dataSource}
      <Comment
        avatar={
          <Avatar
            src={currentUserData.personalPhoto}
            style={{
              backgroundColor: currentUserData.gender == "male" ? "#87d068" : "#db7093",
            }}
          >
            {" "}
            {currentUserData.personalPhoto ??
              currentUserData.firstName.slice(0, 1) + currentUserData.lastName.slice(0, 1)}
          </Avatar>
        }
        content={
          <div>
            <Form.Item>
              <TextArea rows={4} onChange={(e) => setValue(e.target.value)} value={value} />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" loading={submitting} onClick={handleSubmit} type="primary">
                {t("general.addComment")}
              </Button>
            </Form.Item>
          </div>
        }
      />
    </div>
  );
}
