import React, { useState, useEffect } from "react";
import firebase, { auth } from "firebase/app";
import { Button, Drawer, Form, message, Select, Input, Upload, Icon, Row, Spin, Checkbox } from "antd";
const { TextArea } = Input;
import { useFormik } from "formik";
import * as Yup from "yup";
import { tasksRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { task } from "../../db/interfaces";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  departmentId: string;
}
const projectSchema = Yup.object().shape({
  // departmentName: Yup.string().required("Department Name is required"),
  // HOD: Yup.string().required("Head of department is required"),
});

function AddNewTask(props: Props) {
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const { t } = useTranslation();
  const { modalState, setModalState, departmentId } = props;
  const [loading, setLoading] = useState(false);
  const [uploadTaskFiles, setUploadTaskFiles] = useState([] as any);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());

  const newId = tasksRef.doc().id;
  const uid = auth().currentUser!.uid;

  const formik = useFormik({
    initialValues: {
      departmentId: "",
      code: "",
      title: "",
      description: "",
      attached_files: [],
      duration: 0,
      sharable: false,
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const promise = uploadJobDescription();
      promise.then((files) => {
        tasksRef
          .doc(newId)
          .set(
            {
              departmentId: departmentId,
              code: values.code,
              title: values.title,
              description: html,
              attached_files: files ?? [],
              duration: values.duration,
              sharable: values.sharable,

              createdAt: new Date(),
              by: uid,
            },
            { merge: true }
          )
          .then(() => {
            setModalState(false);
            setLoading(false);
            message.success("New Responsibility is added successfully!");
            formik.resetForm();
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error writing document: ", error);
            message.error("Failed");
          });
      });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const handleFileUpload = async (files: any) => {
    try {
      const downloadURLs = await Promise.all(
        files.map(async (file: any) => {
          const fileRef = storageRef.child(`departments/${departmentId}/tasks/${newId}/${file.originFileObj.name}`);
          await fileRef.put(file.originFileObj);
          return {
            name: file.originFileObj.name,
            url: await fileRef.getDownloadURL(),
          };
        })
      );
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      return [];
    }
  };

  const uploadJobDescription = async () => {
    setLoading(true);
    const promise = handleFileUpload(uploadTaskFiles);
    return promise;
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;
  return (
    <Drawer
      title={t("general.addNewResponsibility")}
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Spin spinning={loading} size="large" indicator={antIcon}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label={t("general.code")}>
            <Input value={formik.values.code} onChange={(e) => formik.setFieldValue("code", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.title")}>
            <Input value={formik.values.title} onChange={(e) => formik.setFieldValue("title", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.description")}>
            <DraftText
              onValueChange={() => {}}
              onMentionChange={() => {}}
              html={""}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </Form.Item>
          <Form.Item label={t("general.sharable")}>
            <Checkbox
              value={formik.values.sharable}
              onChange={(e) => {
                formik.setFieldValue("sharable", e.target.checked);
              }}
            >
              Is this Responsibility sharable with other departments?
            </Checkbox>
          </Form.Item>
          <Form.Item label="Responsibility Files">
            <Row gutter={12}>
              {/* <Col span={6}> */}
              <Upload
                name="attached_files"
                beforeUpload={() => {
                  return false;
                }}
                onChange={(e) => {
                  setUploadTaskFiles(e.fileList);
                }}
                fileList={uploadTaskFiles}
              >
                <Button>
                  <Icon type="upload" /> Click to upload
                </Button>
              </Upload>
            </Row>
          </Form.Item>

          <div>
            <Button
              className="btn blue lighten-1 z-depth-0"
              style={{ marginTop: "2%" }}
              // loading={loading}
              type="primary"
              htmlType="submit"
              // disabled={!formik.isValid}
            >
              {t("general.create")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default AddNewTask;
