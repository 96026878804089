import React, { useState, useRef, useCallback, useMemo } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase, { auth } from "firebase/app";
import { Spin, Button, Select } from "antd";
import moment from "moment";
import { useRouteMatch } from "react-router-dom";
import { delay, users } from "../../db/interfaces";
import { currentUserData } from "../../db/userGlobal";
import DelayDisableDialog from "./delayDisableDialog";
import DevTable from "../../helpers/devex-table";

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function userRecord() {
  const [modalState, setModalState] = useState(false);
  const [delay, setDelay] = useState({} as delay);
  const userUID = useRouteMatch<{ id: string }>().params.id;


  const [defaultHiddenColumnNames] = useState([]);
  const [totalSummaryItems] = useState([{ columnName: "min", type: "sum" }]);
  const [value] = useCollection(usersRef.doc(userUID).collection("delay"));
  const [userSN] = useDocument(usersRef.doc(userUID));
  const data: any = useMemo(
    () =>
      value?.docs?.map((d, index) => {
        return {
          ...d.data(),
          id: d.id,
          index: index + 1,
        };
      }),
    [value]
  );
  if (value == undefined || !userSN) return <Spin />;

  const userData = userSN.data() as users;
  const fullName = userData.firstName + " " + userData.lastName;

  const columnsExport = [
    { name: "index", title: "NO" },
    { name: "dayDate", title: "Date", getCellValue: (r: any) => moment(r.dayDate?.toDate()).format("DD.MM.YYYY") },
    { name: "min", title: "Minutes" },
    { name: "notes", title: "Notes" },
    { name: "month", title: "Month" },
  ];
  const columns = [
    { name: "index", title: "NO" },
    { name: "dayDate", title: "Date", getCellValue: (r: any) => moment(r.dayDate?.toDate()).format("DD.MM.YYYY") },
    { name: "min", title: "Minutes" },
    { name: "notes", title: "Notes" },
    { name: "month", title: "Month" },

    {
      name: "action",
      title: "Disable/Enable",
      getCellValue: (r: delay) => {
        return currentUserData.fullControlOffices.includes(userData.office) == true ? (
          <Button
            type="primary"
            onClick={() => {
              if (r.status == "disabled") {
                usersRef.doc(userUID).collection("delay").doc(r.id).update({
                  status: "enabled",
                });
              } else {
                setDelay(r);
                setModalState(true);
              }
            }}
          >
            {r.status == "disabled" ? "Enable" : "Disable"}
          </Button>
        ) : null;
      },
    },
    {
      name: "d",
      title: "Disable/Enable",
      getCellValue: (r: delay) => {
        return currentUserData.fullControlOffices.includes(userData.office) == true ? (
          <Button
            type="primary"
            onClick={() => {
              usersRef.doc(userUID).collection("delay").doc(r.id).delete();
            }}
          >
            delete
          </Button>
        ) : null;
      },
    },
  ];
  return (
    <div>
      {modalState ? <DelayDisableDialog modalState={modalState} setModalState={setModalState} delay={delay} /> : null}
      <div
        style={{
          padding: "1%",
          background: "#fff",
          marginBottom: "1%",
          border: "thin",
          borderRadius: "10px",
          boxShadow: "2px 2px 2px 0px #c2c2c2",
        }}
      >
        <h2>{fullName}</h2>
      </div>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName="My_Delays"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
