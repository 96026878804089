import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, DatePicker, Form, Input, message, Modal, Select } from "antd";
import { missionRef, userRef, usersRef } from "../../db/collectionsRef";
import { delay, mission, users } from "../../db/interfaces";
import { useFormik } from "formik";
import { auth } from "firebase";
import moment from "moment";
import { useCollection } from "react-firebase-hooks/firestore";
const { Option } = Select;

interface Props {
  mission?: mission;
  modalState: boolean;
  setModalState: any;
}

function MissionDialog(props: Props) {
  const { mission, modalState, setModalState } = props;
  const uid = auth().currentUser?.uid;
  const [usersSN] = useCollection(userRef);
  const USERS_ID_FULL_NAME = usersSN?.docs.map((u) => {
    const fullName = u.data()?.firstName + " " + u.data()?.lastName + " _ " + u.data().resourceNO;
    const uid = u.id;
    return { uid, fullName };
  });

  const formik = useFormik({
    initialValues: {
      missionName: "",
      missionLocation: "",
      weekendDays: [],
      holidayDates: [],
      logo: "",
      style: "",
      missionCEO: [],
    },
    onSubmit: async (values, form) => {
      if (mission) {
        await missionRef
          .doc(mission.id)
          .update({
            missionName: values.missionName as string,
            missionLocation: values.missionLocation as string,
            weekendDays: values.weekendDays as string[],
            holidayDates: values.holidayDates as string[],
            createdBy: uid,
            createdAt: moment().toDate() as any,
            missionCEO: values.missionCEO as string[],
          } as mission)
          .then(() => {
            message.success("Updated Successfully!");
            setModalState(false);
          });
      } else {
        await missionRef
          .add({
            missionName: values.missionName as string,
            missionLocation: values.missionLocation as string,
            weekendDays: values.weekendDays as string[],
            holidayDates: values.holidayDates as string[],
            createdBy: uid,
            createdAt: moment().toDate() as any,
            missionCEO: values.missionCEO as string[],
          } as mission)
          .then(() => {
            message.success("Added Successfully!");
            setModalState(false);
          });
      }
    },
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (mission && loaded) {
      formik.setValues({
        missionName: mission.missionName,
        missionLocation: mission.missionLocation,
        weekendDays: mission.weekendDays,
        holidayDates: mission.holidayDates,
        missionCEO: mission.missionCEO as string[],
      } as any);
      setLoaded(false);
    }
  }, [mission, formik, loaded]);

  return (
    <Modal
      closable={true}
      width={1200}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
      }}
      onOk={() => {
        formik.submitForm();
      }}
    >
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label={"Mission CEO"} {...formItemLayout} required={false}>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              mode="multiple"
              showSearch
              value={formik.values.missionCEO}
              onChange={(e: any) => {
                formik.setFieldValue("missionCEO", e);
              }}
            >
              {USERS_ID_FULL_NAME?.map((d) => {
                return (
                  <Option key={d.uid} value={d.uid}>
                    {d.fullName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label={"Mission Name"} {...formItemLayout} required={false}>
            <Input
              disabled={mission != undefined}
              value={formik.values.missionName}
              placeholder={"Mission Name"}
              onChange={(e) => formik.setFieldValue("missionName", e.target.value)}
            />
          </Form.Item>
          <Form.Item label={"Mission Location"} {...formItemLayout} required={false}>
            <Input
              value={formik.values.missionLocation}
              placeholder={"Mission Location"}
              onChange={(e) => formik.setFieldValue("missionLocation", e.target.value)}
            />
          </Form.Item>
          <Form.Item label={"Weekend Days"} {...formItemLayout} required={false}>
            <Select
              mode="multiple"
              showSearch
              value={formik.values.weekendDays}
              onChange={(e: any) => formik.setFieldValue("weekendDays", e)}
            >
              <Option value={"Sa"}>Saturday</Option>
              <Option value={"Su"}>Sunday</Option>
              <Option value={"Mo"}>Monday</Option>
              <Option value={"Tu"}>Tuesday</Option>
              <Option value={"We"}>Wednesday</Option>
              <Option value={"Th"}>Thursday</Option>
              <Option value={"Fr"}>Friday</Option>
            </Select>
          </Form.Item>

          <Form.Item label={"Official Holidays"} {...formItemLayout} required={false}>
            <Select
              mode="tags"
              value={formik.values.holidayDates}
              onChange={(e: any) => formik.setFieldValue("holidayDates", e)}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default MissionDialog;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};
