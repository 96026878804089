import * as XLSX from "xlsx";
import moment from "moment";


export default function ReadExcelFile(file) {
  const promise = new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = e => {
      const bufferArray = e.target.result;
      const workbook = XLSX.read(bufferArray, { type: "buffer" });
      const workSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[workSheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);

      resolve(data);
    };
    fileReader.onerror = e => {
      reject(e);
    };
  });

  const x = promise.then(data => {
    const newData =data.slice(1)

    var acceptUpload = { status: true, rowIndex: 0, data: newData };
    newData.map((row, index) => {
      if (row.uid == null ) {
        acceptUpload = { status: false, rowIndex: index + 1, data: [] };
      }
    });
    console.log({newData})
    return acceptUpload;
  });

  return x;
}

