import React, { useState, useEffect } from "react";
import { auth } from "firebase/app";
import { Button, DatePicker, Drawer, Form, message, Select } from "antd";
import moment from "moment";
const { Option } = Select;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { userRef, paymentTypesRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { paymentType } from "../../db/interfaces";
import { USERS_ID_FULL_NAME } from "../../global/usersList";

interface Props {
  paymentTypeId: string;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function EditPaymentType(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const uid = auth().currentUser!.uid;
  const [paymentTypeSN] = useDocument(paymentTypesRef.doc(props.paymentTypeId));
  const paymentTypeData = paymentTypeSN?.data() as paymentType;

  const formik = useFormik({
    initialValues: {
      uid: "",
      office: "",
      startDate: moment(),
      // endDate: moment(),
      paymentType: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = paymentTypesRef.doc(props.paymentTypeId).update({
        paymentType: values.paymentType,
        startDate: values.startDate.toDate() as any,
        // endDate: values.endDate.toDate() as any,
        createdAt: new Date(),
        by: uid,
      });
      // .then(() => {
      //   userRef.doc(values.uid).update({
      //     paymentType: values.paymentType,
      //   });
      // });
      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (paymentTypeSN && loaded) {
      formik.setValues({
        uid: paymentTypeData.uid,
        paymentType: paymentTypeData.paymentType,
        startDate: moment(paymentTypeData.startDate.toDate()),
        // endDate: moment(paymentTypeData.endDate.toDate()),
      } as any);
      setLoaded(false);

      // setItemLineCode(procurementPlan.itemLineCode)
    }
  }, [paymentTypeSN, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.editPaymentType")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.paymentType")}>
          <Select
            placeholder="Select Payment Type"
            showSearch
            value={formik.values.paymentType}
            onChange={(e) => {
              formik.setFieldValue("paymentType", e);
            }}
          >
            <Option value={"transfer"}>Transfer</Option>
            <Option value={"cash"}>Cash</Option>
          </Select>
        </Form.Item>

        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>
        {/* <Form.Item label={t("general.endDate")}>
          <DatePicker
            value={formik.values.endDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("endDate", e)}
          />{" "}
        </Form.Item> */}

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditPaymentType;
