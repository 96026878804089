import React, { Fragment } from "react";

interface Props {
  data: any;
  onClickCard: (id: string) => void;
}
export const DepartmentCard = (props: Props) => {
  const { data, onClickCard } = props;

  return (
    <ul>
      {data.map((item: any, index: any) => {
        return (
          <Fragment key={item.id}>
            <li
              onClick={(event) => {
                event.stopPropagation(); // Stop event propagation here
                onClickCard(item.id);
              }}
            >
              <div className="department-card" style={{ opacity: item.opacity }}>
                {/* <div className="image">
                  <img
                    src={
                      item.avatar ??
                      "https://firebasestorage.googleapis.com/v0/b/administrationapp-cdc55.appspot.com/o/Screenshot%202023-09-18%20at%2016.39.32.png?alt=media&token=2bcd03d6-17c8-42f3-9d9f-7521aa2aeeec"
                    }
                    style={{ borderColor: item.filtersColor }}
                  />
                </div> */}
                <div className="card-body">
                  <h4>{item.shortcut}</h4>
                  <p> {item.name}</p>
                </div>
                <div className="card-footer" style={{ background: item.filtersColor }}>
                  {item.position}
                </div>
                <div></div>
              </div>
              {item.children?.length > 0 && <DepartmentCard data={item.children} onClickCard={onClickCard} />}
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
};
