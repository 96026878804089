import React, { useState } from "react";
import firebase from "firebase/app";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Form, Input, Button, Checkbox, message, Icon } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./style.css";
import { currentUserData } from "../../db/userGlobal";
import { userRef } from "../../db/collectionsRef";
import ResetEmail from "./ResetPasswordByEmail";
import ResetPasswordByUsername from "./ResetPasswordByUsername";

export default function ResetPassword() {
  return (
    <div className={"boxForm"}>
      {currentUserData.signInType == "byUsername" ? <ResetPasswordByUsername /> : <ResetEmail />}
    </div>
  );
}
