import { missionRef } from "../db/collectionsRef";
import { mission, users } from "../db/interfaces";

interface MissionsWithId extends mission {
  id: string;
}

export const globalMissionsData: Record<string, MissionsWithId> & { loaded?: boolean } = {};

missionRef.onSnapshot(data => {
  Object.defineProperty(globalMissionsData, "loaded", { enumerable: false, value: true });
  data.docs.forEach(doc => {
    globalMissionsData[doc.id] = { ...doc.data(), id: doc.id } as MissionsWithId;
  });
});
