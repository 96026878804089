import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { Spin, Tabs, Button } from "antd";
import { orderRef, userRef } from "../../../db/collectionsRef";
import { Link } from "react-router-dom";
import DevTable from "../../../helpers/devex-table";

export default function Table2() {
  const storage = firebase.storage();
  const [orderSN, loading, error] = useCollection(orderRef.where("orderType", "==", "AFAD Kart"));
  const [usersSN, loading1, error1] = useCollection(userRef);
  if (orderSN == undefined || loading || usersSN == undefined || loading1) return <Spin />;

  const data = orderSN.docs.map(d => ({ ...d.data(), id: d.id }));

  const columns = [
    {
      name: "requestedAt",
      title: "Requested At",
      getCellValue: row => {
        return row.requestedAt.toDate().toLocaleDateString();
      }
    },
    { name: "status", title: "Status" },
    { name: "TC", title: "TC" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "fatherName", title: "Father Name" },
    { name: "motherName", title: "Mother Name" },
    { name: "gender", title: "Gender" },
    { name: "birthPlace", title: "Birth Place" },
    {
      name: "birthDate",
      title: "Birth Date",
      getCellValue: row => {
        return row.birthDate.toDate().toLocaleDateString();
      }
    },
    { name: "marriedStatus", title: "Married Status" },
    { name: "nationality", title: "Nationality" },
    { name: "femaleChildrenNumber", title: "Female Children Number" },
    { name: "maleChildrenNumber", title: "Male Children Number" },
    { name: "wifeNationality", title: "Wife Nationality" },
    { name: "wifeFirstName", title: "Wife FirstName" },
    { name: "wifeLastName", title: "Wife LastName" },
    { name: "wifeTC", title: "Wife TC" },
    { name: "phone", title: "Phone" },
    { name: "email", title: "Email" },
    { name: "residenceAddress", title: "Residence Address" },
    { name: "universityNameAddress", title: "University Name Address" },
    { name: "degree", title: "Degree" },
    { name: "degreeField", title: "Degree Field" },
    { name: "degreeFaculty", title: "Degree Faculty" },
    { name: "position", title: "Position" },
    { name: "salary", title: "Salary" },
    { name: "cityInSyria", title: "CityInSyria" },
    { name: "durationInTurkey", title: "Duration In Turkey" },
    { name: "language", title: "Language" },
    {
      name: "manager",
      title: "Submitted By",
      getCellValue: row => {
        return (
          usersSN.docs.find(d => d.id === row.manager)?.data().firstName +
          " " +
          usersSN.docs.find(d => d.id === row.manager)?.data().lastName
        );
      }
    },
    {
      name: "startWorkDate",
      title: "Start Date",
      getCellValue: row => {
        return row.startWorkDate == undefined ? "Not Active Yet" : row.startWorkDate.toDate().toLocaleDateString();
      }
    },
    {
      name: "endWorkDate",
      title: "Leave Date",
      getCellValue: row => {
        return row.endWorkDate == undefined ? null : row.endWorkDate.toDate().toLocaleDateString();
      }
    },
    {
      name: "permitExpiryDate",
      title: "Permit Expiry Date",
      getCellValue: row => {
        return row.permitExpiryDate == undefined ? null : row.permitExpiryDate.toDate().toLocaleDateString();
      }
    },
    {
      name: "control",
      title: "Control",
      getCellValue: row => {
        return <Link to={`/order/${row.id}`}>View</Link>;
      }
    },
    {
      name: "signedContractURL",
      title: "Signed Contract",
      getCellValue: row => {
        return row.signedContractURL == "" || row.signedContractURL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              const U = storage.refFromURL(row.signedContractURL);
              U.getDownloadURL().then(url => {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                  link.setAttribute("href", url);
                  link.setAttribute("target", "_blank");
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              });
            }}
          >
            Download
          </Button>
        );
      }
    },
    {
      name: "imgURL",
      title: "New Permit Card",
      getCellValue: row => {
        return row.imgURL == "" || row.imgURL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              const U = storage.refFromURL(row.imgURL);
              U.getDownloadURL().then(url => {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                  link.setAttribute("href", url);
                  link.setAttribute("target", "_blank");
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              });
            }}
          >
            Download
          </Button>
        );
      }
    },
    {
      name: "IDURL",
      title: "Identify Card",
      getCellValue: row => {
        return row.IDURL == "" || row.IDURL == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              const U = storage.refFromURL(row.IDURL);
              U.getDownloadURL().then(url => {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                  link.setAttribute("href", url);
                  link.setAttribute("target", "_blank");
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              });
            }}
          >
            Download
          </Button>
        );
      }
    },
    {
      name: "personalPhoto",
      title: "Personal Photo",
      getCellValue: row => {
        return row.personalPhoto == "" || row.personalPhoto == undefined ? (
          "Nothing Uploaded"
        ) : (
          <Button
            onClick={() => {
              const U = storage.refFromURL(row.personalPhoto);
              U.getDownloadURL().then(url => {
                var link = document.createElement("a");
                if (link.download !== undefined) {
                  link.setAttribute("href", url);
                  link.setAttribute("target", "_blank");
                  link.style.visibility = "hidden";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              });
            }}
          >
            Download
          </Button>
        );
      }
    }
  ];
  const columnsExport = [
    {
      name: "requestedAt",
      title: "Requested At",
      getCellValue: row => {
        return row.requestedAt.toDate().toLocaleDateString();
      }
    },
    { name: "status", title: "Status" },
    { name: "TC", title: "TC" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "fatherName", title: "Father Name" },
    { name: "motherName", title: "Mother Name" },
    { name: "gender", title: "Gender" },
    { name: "birthPlace", title: "Birth Place" },
    {
      name: "birthDate",
      title: "Birth Date",
      getCellValue: row => {
        return row.birthDate.toDate().toLocaleDateString();
      }
    },
    { name: "marriedStatus", title: "Married Status" },
    { name: "nationality", title: "Nationality" },
    { name: "femaleChildrenNumber", title: "Female Children Number" },
    { name: "maleChildrenNumber", title: "Male Children Number" },
    { name: "wifeNationality", title: "Wife Nationality" },
    { name: "wifeFirstName", title: "Wife FirstName" },
    { name: "wifeLastName", title: "Wife LastName" },
    { name: "wifeTC", title: "Wife TC" },
    { name: "phone", title: "Phone" },
    { name: "email", title: "Email" },
    { name: "residenceAddress", title: "Residence Address" },
    { name: "universityNameAddress", title: "University Name Address" },
    { name: "degree", title: "Degree" },
    { name: "degreeField", title: "Degree Field" },
    { name: "degreeFaculty", title: "Degree Faculty" },

    { name: "position", title: "Position" },
    { name: "salary", title: "Salary" },
    { name: "cityInSyria", title: "CityInSyria" },
    { name: "durationInTurkey", title: "Duration In Turkey" },
    { name: "language", title: "Language" },
    {
      name: "manager",
      title: "Submitted By",
      getCellValue: row => {
        return (
          usersSN.docs.find(d => d.id === row.manager)?.data().firstName +
          " " +
          usersSN.docs.find(d => d.id === row.manager)?.data().lastName
        );
      }
    },
    {
      name: "startWorkDate",
      title: "Start Date",
      getCellValue: row => {
        return row.startWorkDate == undefined ? null : row.startWorkDate.toDate().toLocaleDateString();
      }
    },
    {
      name: "endWorkDate",
      title: "Leave Date",
      getCellValue: row => {
        return row.endWorkDate == undefined ? null : row.endWorkDate.toDate().toLocaleDateString();
      }
    },
    {
      name: "permitExpiryDate",
      title: "Permit Expiry Date",
      getCellValue: row => {
        return row.permitExpiryDate == undefined ? null : row.permitExpiryDate.toDate().toLocaleDateString();
      }
    },
    {
      name: "signedContractURL",
      title: "Signed Contract"
    },
    {
      name: "imgURL",
      title: "New Permit Card"
    },
    {
      name: "IDURL",
      title: "Identify Card"
    },
    {
      name: "personalPhoto",
      title: "Personal Photo"
    }
  ];

  return (
    <Paper>
      <DevTable
        data={data}
        columns={columns}
        defaultHiddenColumnNames={[]}
        tableName="Afad_List"
        selected={false}
        typicalTable={false}
        columnsExport={columnsExport}
      />
    </Paper>
  );
}
