import React from "react";
import OrderList from "../orders/OrderList";
import { Route, RouteComponentProps } from "react-router";
import OrderDetails from "../orders/OrderDetails";
import { Row, Col } from "antd";
import Services from "./Services";
import { Container } from "@material-ui/core";
import RightBar from "./rightBar";

export default function Dashboard() {
  return (
    <div>
      <Container maxWidth="xl">
        <div>
          <Row gutter={24} style={{ alignItems: "center" }}>
            <Col span={4}>
              <OrderList />
            </Col>
            <Col span={12} style={{ background: "white", borderRadius: "5px" }}>
              <Route exact path="/" component={(props: RouteComponentProps<{ id: string }>) => <RightBar />} />
              <Route
                path="/order/:id"
                component={(props: RouteComponentProps<{ id: string }>) => (
                  <OrderDetails key={props.match.params.id} {...props} />
                )}
              />
            </Col>
            <Col span={7} style={{ borderRadius: "5px" }}>
              <Services />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
