import React, { useState, useEffect } from "react";
import firebase, { auth } from "firebase/app";
import { Button, DatePicker, Drawer, Form, message, Select, Input } from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;

import { useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { userRef, officeRef, workOfficeRef, accountantRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { sortBy, uniq } from "lodash";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { currentUserData } from "../../db/userGlobal";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function AddNewAccountant(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const newId = accountantRef.doc().id;
  const uid = auth().currentUser!.uid;
  const [usersSN] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);



  const formik = useFormik({
    initialValues: {
      uid: "",
      office: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = accountantRef.doc(newId).set(
        {
          uid: values.uid,
          office: values.office,
          createdAt: new Date(),
          by: uid,
        },
        { merge: true }
      );

      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };
  const UserOffices = [...(currentUserData.fullControlOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map((id) => {
    const officeName = officeSN?.docs?.find((d) => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });
  return (
    <Drawer
      title={t("general.addNewAccountant")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            placeholder="Select Employee"
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.office")}>
          <Select
            size="default"
            placeholder="Search for Office!"
            showSearch
            value={UserListOffices.find((office) => office.id == formik.values.office)?.officeName}
            onChange={(e) => {
              const id = UserListOffices.find((office) => office.officeName == e)?.id as string;
              formik.setFieldValue("office", id);
            }}
          >
            {UserListOffices.map((d) => {
              return <Option value={d.officeName}>{d.officeName}</Option>;
            })}
          </Select>
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewAccountant;
