import { Comment, Avatar, Form, Button, List, Input, Spin } from "antd";
import moment from "moment";
import { useState } from "react";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { userRef, notificationsRef } from "../../db/collectionsRef";

import { auth } from "firebase";
import { sortBy } from "lodash";
import { Link } from "react-router-dom";

export default function NotificationList() {
  const uid = auth().currentUser!.uid;
  const [xLimit, setXLimit] = useState(30);
  const [notificationsSN, loadingN] = useCollection(
    notificationsRef.where("notifyTo", "==", uid).where("read", "==", true).limit(xLimit)
  );
  const [userSN, loading2] = useCollection(userRef);

  if (!userSN || !notificationsSN) {
    return <Spin />;
  }

  const notifications = notificationsSN === undefined ? [] : notificationsSN.docs;

  const sortedCommentsSnapshot = sortBy(notifications, (item) => item.data().postedAt.toDate()).reverse();

  const dataSource = sortedCommentsSnapshot.map((item) => {
    const rout =
      item.data().kind == "mention" || item.data().kind == "calendarInvitation"
        ? "calenderDetails"
        : item.data().kind == "newRequestVacation"
        ? "vacation"
        : item.data().kind == "messageVacation"
        ? "vacation"
        : item.data().content == "New Compensation request is waiting for your Approval!"
        ? "overtime"
        : item.data().kind == "messageOvertime" || item.data().kind == "newRequestOvertime"
        ? "overtime"
        : item.data().kind == "newRequest" || item.data().kind == "messageOrder"
        ? "order"
        : item.data().kind;
    return (
      <Link to={`/${rout}/${item.data().orderId}`}>
        <div
          style={{
            padding: "20px",
            color: "rgba(0, 0, 0, 0.65)",
            background: "#ffff",
            borderRadius: "5px",
            // boxShadow: "6px 7px 17px 3px rgba(0, 0, 0, 0.09)",
            margin: "25px 0px",
          }}
          key={item.id}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              WebkitBoxPack: "start",
              justifyContent: "flex-start",

              fontSize: "14px",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#01aced" }}>{`${
              userSN?.docs?.find((d) => d.id == item.data()?.notifyFrom)?.data().firstName
            } ${userSN?.docs?.find((d) => d.id == item.data()?.notifyFrom)?.data().lastName} `}</span>

            <span style={{ fontSize: "12px", color: "#ccc", whiteSpace: "nowrap", padding: "0.5%" }}>
              <span>{item?.data()?.postedAt.toDate().toLocaleString()}</span>
            </span>
          </div>
          <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: item.data().content }} />
        </div>
      </Link>
    );
  });

  return (
    <div>
      <Button style={{ marginLeft: "0.2%", marginRight: "0.2%" }} type="primary" onClick={() => setXLimit(1000)}>
        Show All
      </Button>
      <Button style={{ marginLeft: "0.2%", marginRight: "0.2%" }} onClick={() => setXLimit(30)}>
        Show Last 30
      </Button>
      <div>{dataSource}</div>
    </div>
  );
}
