import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import { Button, DatePicker, Drawer, Form, InputNumber, message, Select, Input } from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;

import { useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
const { MonthPicker } = DatePicker;

import { userRef, positionRef, deductionRef, officeRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";

import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function AddNewDeduction(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const newId = deductionRef.doc().id;
  const uid = auth().currentUser!.uid;

  const formik = useFormik({
    initialValues: {
      uid: "",
      by: "",
      percentage: 0,
      notes: "",
      month: moment(),
      office: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = deductionRef.doc(newId).set(
        {
          createdAt: new Date(),
          uid: values.uid,
          by: uid,
          percentage: values.percentage,
          notes: values.notes ?? "",
          month: moment(values.month).format("MM-YYYY").toString(),
          office: globalUsersData[values.uid].office ?? "",
        },
        { merge: true }
      );

      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };

  return (
    <Drawer
      title={t("general.addNewDeduction")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.percentage")}>
          <InputNumber
            value={formik.values.percentage}
            onChange={(e) => {
              formik.setFieldValue("percentage", e);
            }}
          />
        </Form.Item>

        <Form.Item label={t("general.month")}>
          <MonthPicker
            size="large"
            style={{ margin: "1%" }}
            placeholder="Select month"
            value={moment(formik.values.month)}
            format={"MM-YYYY"}
            onChange={(e) => formik.setFieldValue("month", e)}
          />
        </Form.Item>

        <Form.Item label={t("general.notes")}>
          <Input
            value={formik.values.notes}
            onChange={(e) => {
              formik.setFieldValue("notes", e.target.value);
            }}
          />
        </Form.Item>
        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewDeduction;
