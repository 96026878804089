import React, { useState, useEffect } from "react";
import firebase, from "firebase/app";
import { Button, Drawer, Form, message, Select, Input, Upload, Icon, Row, Col, List, Popconfirm, Spin } from "antd";
const { Option } = Select;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { departmentPositionsRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { departmentPosition } from "../../db/interfaces";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  departmentPositionId: string;
  departmentId: string;
}
const projectSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  // HOD: Yup.string().required("Head of department is required"),
});

function EditDepartmentPosition(props: Props) {
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const { t } = useTranslation();
  const { modalState, setModalState, departmentPositionId, departmentId } = props;
  const [loading, setLoading] = useState(false);
  const [uploadJobDescriptionFiles, setUploadJobDescriptionFiles] = useState([] as any);

  const [departmentPositionsSN] = useCollection(departmentPositionsRef);
  const [departmentPositionSN] = useDocument(departmentPositionsRef.doc(departmentPositionId));
  const departmentPositionData = departmentPositionSN?.data() as departmentPosition;
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());

  const formik = useFormik({
    initialValues: {
      uid: null,
      title: "",
      referencePositionId: null,
      departmentId: "",
      overview: "",
      attached_job_description: [],
      positionTasks: [],
      orientational_video: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const promise = departmentPositionsRef.doc(departmentPositionId).update({
        uid: values.uid ?? null,
        title: values.title,
        referencePositionId: values.referencePositionId ?? null,
        overview: html,
        attached_job_description: values.attached_job_description ?? [],
        positionTasks: [],
        orientational_video: "",
      });
      promise.then(() => {
        setModalState(false);
        setLoading(false);
        message.success("New Position is Updated successfully!");
        formik.resetForm();
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (departmentPositionSN && loaded) {
      formik.setValues({
        uid: departmentPositionData.uid ?? null,
        title: departmentPositionData.title,
        referencePositionId: departmentPositionData.referencePositionId ?? null,
        overview: departmentPositionData.overview,
        attached_job_description: departmentPositionData.attached_job_description ?? [],
        positionTasks: [],
        orientational_video: "",
      } as any);
      setLoaded(false);
      setEditorState(EditorState.createWithContent(stateFromHTML(departmentPositionData?.overview)));
    }
  }, [departmentPositionSN, formik, loaded]);

  const positions = departmentPositionsSN?.docs?.map((pos) => {
    const positionData = pos.data() as departmentPosition;
    return { ...positionData, id: pos.id };
  });

  const handleFileUpload = async (files: any) => {
    try {
      const downloadURLs = await Promise.all(
        files.map(async (file: any) => {
          const fileRef = storageRef.child(
            `departments/${departmentId}/positions/${departmentPositionId}/${file.originFileObj.name}`
          );

          await fileRef.put(file.originFileObj);
          return {
            name: file.originFileObj.name,
            url: await fileRef.getDownloadURL(),
          };
        })
      );
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      return [];
    }
  };

  const uploadJobDescription = async () => {
    setLoading(true);
    const promise = handleFileUpload(uploadJobDescriptionFiles);
    promise
      .then((newPositionFile) => {
        formik.setFieldValue("attached_job_description", [
          ...formik.values.attached_job_description,
          ...newPositionFile,
        ]);
      })
      .then(() => {
        setUploadJobDescriptionFiles([]);
        message.success("Files are uploaded successfully!");
        setLoading(false);
      });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const handleDeleteFile = async (fileName: string, attachmentFolder: string) => {
    try {
      setLoading(true);
      const fileRef = storageRef.child(`departments/${departmentId}/positions/${departmentPositionId}/${fileName}`);
      await fileRef.delete().then(async () => {
        const currentFiles = (formik.values as any)[attachmentFolder];
        const filteredCurrentFiles = currentFiles.filter((r: any) => r.name != fileName);
        formik.setFieldValue(attachmentFolder, filteredCurrentFiles);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      setLoading(false);
    }
  };
  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;
  return (
    <Drawer
      title={t("general.editPosition")}
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Spin spinning={loading} size="large" indicator={antIcon}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label={t("general.positionTitle")}>
            <Input value={formik.values.title} onChange={(e) => formik.setFieldValue("title", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.referencePosition")}>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              allowClear
              size="default"
              placeholder="Parent Position"
              showSearch
              value={formik.values.referencePositionId}
              onChange={(e) => {
                formik.setFieldValue("referencePositionId", e);
              }}
            >
              {positions?.map((d) => {
                return (
                  <Option value={d.id}>{`${d.title} - ${
                    d.uid != null ? globalUsersData[d.uid ?? ""]?.fullName : "Vacant"
                  }`}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label={t("general.overview")}>
            <DraftText
              onValueChange={() => {}}
              onMentionChange={() => {}}
              html={""}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </Form.Item>

          <Form.Item label={t("general.assignTo")}>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              allowClear
              placeholder="Assign to Employee"
              showSearch
              value={formik.values.uid}
              onChange={(e) => {
                formik.setFieldValue("uid", e);
              }}
            >
              {USERS_ID_FULL_NAME?.map((d) => {
                return <Option value={d.uid}>{d.fullName}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Job Description File">
            <Row gutter={12}>
              <Col span={6}>
                <Upload
                  name="attached_job_description"
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={(e) => {
                    setUploadJobDescriptionFiles(e.fileList);
                  }}
                  fileList={uploadJobDescriptionFiles}
                >
                  <Button>
                    <Icon type="upload" /> Click to upload
                  </Button>
                </Upload>
              </Col>
              <Col span={6}>
                <Button
                  onClick={uploadJobDescription}
                  disabled={uploadJobDescriptionFiles.length == 0}
                  className="btn blue lighten-1 z-depth-0"
                  type="primary"
                >
                  {t("general.upload")}
                </Button>
              </Col>
            </Row>
          </Form.Item>
          {formik.values?.attached_job_description?.length > 0 && (
            <div>
              <List
                itemLayout="horizontal"
                bordered
                dataSource={formik.values?.attached_job_description}
                renderItem={(file: any) => (
                  <List.Item>
                    <div>
                      <a href={file.url} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                      {/* <p style={{ margin: "0px" }}>{file.attachedDescription}</p> */}
                    </div>
                    <Popconfirm
                      title={"Are you sure?"}
                      onConfirm={() => handleDeleteFile(file.name, "attached_job_description")}
                      onCancel={() => console.log("cancel")}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="link" style={{ color: "red" }}>
                        <Icon type="delete" />
                      </Button>
                    </Popconfirm>
                  </List.Item>
                )}
              />
            </div>
          )}

          <div>
            <Button
              className="btn blue lighten-1 z-depth-0"
              style={{ marginTop: "2%" }}
              // loading={loading}
              type="primary"
              htmlType="submit"
              // disabled={!formik.isValid}
            >
              {t("general.save")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditDepartmentPosition;
