import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  notification,
  Modal
} from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileUploader from "react-firebase-file-uploader";

import { notificationsRef, userRef, orderRef, vacationRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
// import { openNotification } from "../layout/openNotification";
import { generateAndUploadWord } from "../orders/generateAndUploadWord";
import { users } from "../../db/interfaces";
import { usersRef } from "../User/usersTable";
import { sortBy } from "lodash";
// import contract from "../orders/contract.docx";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  UID: string;
}
const projectSchema = Yup.object().shape({
  dayDate: Yup.date().required("*"),
  min: Yup.number().required("*")
});

function DelayModel(props: Props) {
  const { t } = useTranslation();
  const [vacationType, setVacationType] = useState("");
  const { modalState, setModalState, UID } = props;
  const [loading, setLoading] = useState(false);
  const [managerName, setManagerName] = useState("");
  const uid = auth().currentUser!.uid;
  const [usersSNDoc] = useDocument(userRef.doc(UID));



  const data = usersSNDoc?.data() as users;
  const staffFullName = `${data?.firstName} ${data?.lastName}`;
  const formik = useFormik({
    initialValues: {
      dayDate: moment(),
      min: 0,
      notes: ""
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      const promise = usersRef
        .doc(UID)
        .collection("delay")
        .doc()
        .set(
          {
            month: moment().format("MMM.YYYY"),
            dayDate: values.dayDate.toDate() as any,
            min: values.min,
            notes: values.notes,
            userUID: UID,
            office: data.office
          },
          { merge: true }
        );
      promise
        .then(async () => {
          await notificationsRef.add({
            notifyTo: UID,
            notifyFrom: auth().currentUser!.uid,
            orderId: "",
            read: false,
            kind: "delay",
            content: "Alert, New delay minutes have been added to your score!",
            postedAt: new Date()
          });
        })
        .then(() => {
          message.success("Done!");
          console.log("Document successfully written!");
          setModalState(false);
          setLoading(false);
        
        })

        .then(() => form.resetForm());
      promise.catch(error => {
        console.error("Error writing document: ", error);
        message.error("Failed");
      });

    
    }
  });


  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  const orderStorageRef = firebase.storage().ref(uid);
  return (
    <Modal
      title={staffFullName}
      //   placement={"left"}
      width={600}
      visible={modalState}
      onCancel={() => setModalState(false)}
      onOk={() => formik.handleSubmit()}
    >
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label={t("general.dayDate")} span={6}>
                <DatePicker
                  value={formik.values.dayDate}
                  format={dateFormat}
                  onChange={e => formik.setFieldValue("dayDate", e)}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.min")} span={6}>
                <InputNumber
                  max={1000}
                  min={0}
                  value={formik.values.min}
                  onChange={e => formik.setFieldValue("min", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.notes")} span={6}>
                <TextArea
                  value={formik.values.notes}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  onChange={e => formik.setFieldValue("notes", e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
  
      </Form>
    </Modal>
  );
}

export default DelayModel;
