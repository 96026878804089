import React from "react";
const { TabPane } = Tabs;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Icon, PageHeader } from "antd";
import { departmentsRef, departmentPositionsRef, vacationRef } from "../../db/collectionsRef";
import { Container } from "@material-ui/core";
import Overview from "./overview";
import Structure from "./structure";
import Tasks from "./tasks";
import Policies from "./policies";
import SOPs from "./SOPs";
import Templates from "./templates";
import { useHistory, useRouteMatch } from "react-router-dom";
import { department, departmentPosition } from "../../db/interfaces";

export default function StructureDashboard() {
  const departmentId = useRouteMatch<{ id: string }>().params.id;
  const history = useHistory();

  const [departmentSN] = useDocument(departmentsRef.doc(departmentId));
  const [departmentsSN] = useCollection(departmentsRef.where("referenceDepartment", "==", departmentId));
  const [departmentPositionsSN] = useCollection(departmentPositionsRef.where("departmentId", "==", departmentId));

  const departmentData = departmentSN?.data() as department;

  const subDepartmentsData = departmentsSN?.docs.map((department) => {
    return { ...department?.data(), id: department.id };
  });

  const departmentPositions = departmentPositionsSN?.docs?.map((position) => {
    return { ...position?.data(), id: position.id } as departmentPosition;
  });

  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;

  return (
    <div>
      <Spin spinning={!departmentSN || !departmentsSN} size="large" indicator={antIcon}>
        <PageHeader
          style={{
            border: "1px solid rgb(235, 237, 240)",
            background: "white",
            borderRadius: "6px",
            marginBottom: "1%",
          }}
          onBack={() => history.goBack()}
          title={departmentData?.shortcut}
          subTitle={departmentData?.departmentName}
        />
        <Container maxWidth="xl" style={{ background: "white", borderRadius: "6px", padding: "4%" }}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Overview" key="1">
              <Overview
                departmentData={departmentData ?? {}}
                subDepartmentsData={(subDepartmentsData ?? []) as department[]}
              />
            </TabPane>
            <TabPane tab="Structure" key="2">
              <Structure departmentPositions={departmentPositions ?? []} />
            </TabPane>
            <TabPane tab="Responsibilities" key="3">
              <Tasks departmentId={departmentId} />
            </TabPane>
            <TabPane tab="Policies" key="4">
              <Policies departmentData={departmentData ?? {}} />
            </TabPane>
            <TabPane tab="SOPs" key="5">
              <SOPs departmentData={departmentData ?? {}} />
            </TabPane>
            <TabPane tab="Templates and Forms" key="6">
              <Templates departmentData={departmentData ?? {}} />
            </TabPane>
          </Tabs>
        </Container>
      </Spin>
    </div>
  );
}
