import * as firebase from "firebase/app";
import "firebase/firestore";

export const orderRef = firebase.firestore().collection("orders");
export const usersRef = firebase.firestore().collection("users");
export const userRef = firebase.firestore().collection("users"); //TODO: delete all the others userRef
export const projectRef = firebase.firestore().collection("projects");
export const meetingRoomList = firebase.firestore().collection("roomsList");
export const calendar = firebase.firestore().collection("calendar");
export const vacationRef = firebase.firestore().collection("vacations");
export const overtimeRef = firebase.firestore().collection("overtime");
export const projectsRef = firebase.firestore().collection("projects");
export const budgetLinesRef = firebase.firestore().collection("budgetLines");
export const discussionsRef = firebase.firestore().collection("discussions");
export const notificationsRef = firebase.firestore().collection("notifications");
export const allocationRef = firebase.firestore().collection("allocations");
export const delayRef = firebase.firestore().collectionGroup("delay");
export const officeRef = firebase.firestore().collection("offices");
export const positionRef = firebase.firestore().collection("positions");
export const deductionRef = firebase.firestore().collection("deductions");
export const salariesRef = firebase.firestore().collection("salaries");
export const workOfficeRef = firebase.firestore().collection("workoffice");
export const importShiftRef = firebase.firestore().collection("importShift");
export const usersShiftRef = firebase.firestore().collection("usersShift");
export const staticRef = firebase.firestore().collection("static");
export const caseRef = firebase.firestore().collection("cases");
export const missionRef = firebase.firestore().collection("missions");
export const carRef = firebase.firestore().collection("cars");
export const accountantRef = firebase.firestore().collection("accountants");
export const supervisorsRef = firebase.firestore().collection("supervisors");
export const paymentTypesRef = firebase.firestore().collection("paymentTypes");
export const contractPercentageRef = firebase.firestore().collection("contractPercentage");

export const departmentsRef = firebase.firestore().collection("departments");
export const departmentPositionsRef = firebase.firestore().collection("departmentPositions");
export const tasksRef = firebase.firestore().collection("tasks");
export const tagsRef = firebase.firestore().collection("tags");
export const positionTasksRef = firebase.firestore().collectionGroup("positionTasks");
export const accessRef = firebase.firestore().collectionGroup("access");

export const SOPs = firebase.firestore().collection("SOP");
export const outdoorTasksRef = firebase.firestore().collection("outdoorTasks");
export const shiftsRef = firebase.firestore().collection("shifts");
export const sheetsRef = firebase.firestore().collection("sheets");
