import React from "react";
import { Link } from "react-router-dom";
import { Card } from "antd";

interface Props {
  departmentId: string;
  departmentName: string;
}

export default function DepartmentCard(props: Props) {
  const { departmentId, departmentName } = props;
  return (
    <Link to={`/department/${departmentId}`}>
      <Card
        size="small"
        bordered={false}
        style={{
          width: "100%",
          marginTop: "10%",
          borderRadius: "10px",
          boxShadow: "6px 7px 17px 3px rgba(0, 0, 0, 0.09)",
          background: "#80A0B0",
        }}
      >
        <h4 style={{ marginTop: "0px", color: "white" }}>
          <b>Head of {departmentName}</b>
        </h4>

        <h6 style={{ float: "right", color: "#e6f7ff" }}>Open dashboard {">>"}</h6>
      </Card>
    </Link>
  );
}
