import React from "react";
import { departmentPosition } from "../../db/interfaces";
import PositionChart from "./position-chart/position-chart";

interface Props {
  departmentPositions: departmentPosition[];
}
export default function Structure(props: Props) {
  const { departmentPositions } = props;
  return (
    <div>
      <PositionChart departmentPositions={departmentPositions} />
    </div>
  );
}
