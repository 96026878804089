import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { Spin, Tabs, Button } from "antd";

import Table1 from "./table1";
import Table2 from "./table2";
import Table3 from "./table3";
const { TabPane } = Tabs;

export default function orderTable() {
  return (
    <Tabs>
      <TabPane tab={"Ikamet kart"} key="IkametKart">
        <Table1 />
      </TabPane>
      <TabPane tab={"AFAD Kart"} key="AFADKart">
        <Table2 />
      </TabPane>
      <TabPane tab={"Turk Vatandasi"} key="TurkVatandasi">
        <Table3 />
      </TabPane>
    </Tabs>
  );
}
