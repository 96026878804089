import React, { useState } from "react";
// import OrderList from "../orders/OrderList";
import { Route, RouteComponentProps } from "react-router";
// import OrderDetails from "../orders/OrderDetails";
import { Row, Col, Button } from "antd";
// import AddNewOrder from "../models/AddNewOrder";

import AddNewOvertime from "./OverTimeModules/AddNewOvertime";
import OvertimeList from "./overtimeList";
import OvertimeDetails from "./overtimeDetails";

//import ProjectDetails from "../projects/ProjectDetails";

export default function OvertimeDashboard() {
  const [modalState, setModalState] = useState(false);

  return (
    <div>
      <AddNewOvertime modalState={modalState} setModalState={setModalState} />

      <div style={{ marginTop: "-1%" }}>
        <Row gutter={30} style={{ alignItems: "center" }}>
          <Col span={16}>
            {/* <Route exact path="/" component={(props: RouteComponentProps<{ id: string }>) => <Services />} /> */}
            <Route
              path="/overtime/:id"
              component={(props: RouteComponentProps<{ id: string }>) => (
                <OvertimeDetails key={props.match.params.id} {...props} />
              )}
            />
          </Col>
          <Col span={1}>
            <Button
              type="primary"
              shape="circle"
              size="large"
              onClick={() => {
                setModalState(true);
              }}
            >
              +
            </Button>{" "}
          </Col>
          <Col span={5}>
            {/* <div
              style={{
                marginLeft: "35%",
                width: "450px",
                marginTop: "4%",
                padding: "2%",
                border: "solid",
                borderWidth: "thin",
                borderColor: "rgb(128 128 128 / 17%)",
                borderRadius: "11px",
                background: "rgb(245 245 245 / 36%)",
                boxShadow: "0px 1px 10px 2px rgba(0, 0, 0, 0.18)"
              }}
            >
              omar
            </div> */}
            <OvertimeList />
          </Col>
        </Row>
      </div>
    </div>
  );
}
