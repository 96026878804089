import React from "react";
import ReactDOM from "react-dom";
import "./config/firebase";
import "./index.css";
import App from "./App";
import "./db/userGlobal";
// import "./import/importVacation"
// import "./import/importUsers"
// import "./import/importAllocations"
// import "./import/importMonthlyAnnualVacationScores"
// import "./import/importYearlySickVacationScores"
// import "./import/EditUsersProps"

import * as serviceWorker from "./serviceWorker";
import firebase from "firebase";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
navigator.serviceWorker.register("./firebase-messaging-sw.js").then((registration) => {
  firebase.messaging().useServiceWorker(registration);
});
