import React, { useState } from "react";
import moment from "moment";
import { DatePicker, Spin, Button, Select, Icon, Checkbox } from "antd";
const { MonthPicker } = DatePicker;
import { useCollection } from "react-firebase-hooks/firestore";
import {
  userRef,
  vacationRef,
  projectRef,
  budgetLinesRef,
  allocationRef,
  officeRef,
  shiftsRef,
} from "../../db/collectionsRef";

import { users, vacations } from "../../db/interfaces";
import printJS from "print-js";
import _, { uniq } from "lodash";
import firebase from "firebase/app";
const { Option } = Select;
import { currentUserData } from "../../db/userGlobal";

import TimeSheetComponent from "./timeSheetComponent";
export default function TimeSheetByOffice() {
  const [activeDelay, setActiveDelay] = useState(true);
  const [officeId, setOfficeId] = useState("");
  const [month, setMonth] = useState(moment().subtract(1, "months").startOf("month").startOf("day"));
  const [uid, setUid] = useState("");
  const [vacationSN] = useCollection(
    vacationRef
      .where("office", "==", officeId)
      .where("vacationStartOn", "<=", month.endOf("month").toDate())
      .where("vacationStartOn", ">=", month.startOf("month").toDate())
  );
  const [projectSN] = useCollection(projectRef);
  const [budgetLinesSN] = useCollection(budgetLinesRef);
  const [allocationSN] = useCollection(allocationRef.where("office", "==", officeId));
  const [usersSN] = useCollection(userRef.where("office", "==", officeId));
  const [officeSN] = useCollection(officeRef);
  const [delaySN] = useCollection(
    firebase.firestore().collectionGroup("delay").where("month", "==", month.format("MMM.YYYY").toString())
  );
  const [shiftSN] = useCollection(shiftsRef.where("month", "==", month.format("MM-YYYY").toString()));

  if (!vacationSN || !projectSN || !budgetLinesSN || !allocationSN || !usersSN || !delaySN || !officeSN || !shiftSN)
    return <Spin />;
  const vacationsMap = vacationSN.docs.map((vacation) => {
    return { ...vacation.data(), id: vacation.id };
  });

  const UserOffices = [...(currentUserData.fullControlOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map((id) => {
    const officeName = officeSN?.docs?.find((d) => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });
  const USERS_ID_FULL_NAME = usersSN.docs
    .filter((e) => currentUserData.fullControlOffices.includes(e.data()?.office))
    .filter((user) => user.data().status != "disable")
    .map((u) => {
      const fullName =
        u.data().firstName + " " + u.data().lastName + " _ " + u.data().arabicFullName + " _ " + u.data().resourceNO;
      const uid = u.id;
      return { uid, fullName };
    });
  const usersData = usersSN.docs
    .filter((d) => currentUserData.fullControlOffices.includes(d.data()?.office))
    .map((user) => {
      return { uid: user.id, userData: user.data() };
    });

  const print = async () => {
    await printJS({
      printable: "timeSheet2",
      type: "html",
      targetStyles: ["*"],
      scanStyles: true,
      font_size: "15",
      showModal: true,
    });
  };

  return (
    <div>
      <MonthPicker
        allowClear={false}
        size="default"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      <Select
        showSearch
        placeholder="User"
        style={{ width: "20%", paddingBottom: "1%" }}
        value={[...USERS_ID_FULL_NAME, { fullName: "All", uid: "all" }].find((d) => d.uid == uid)?.fullName}
        onChange={(e: string) => {
          const id = [...USERS_ID_FULL_NAME, { fullName: "All", uid: "all" }].find((d) => d.fullName == e)
            ?.uid as string;
          setUid(id);
        }}
      >
        {[...USERS_ID_FULL_NAME, { fullName: "All", uid: "all" }]?.map((d) => {
          return <Option value={d.fullName}>{d.fullName}</Option>;
        })}
      </Select>
      <Select
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={UserListOffices.find((office) => office.id == officeId)?.officeName}
        onChange={(e) => {
          const id = UserListOffices.find((office) => office.officeName == e)?.id as string;
          setOfficeId(id);
        }}
      >
        {UserListOffices.map((d) => {
          return <Option value={d.officeName}>{d.officeName}</Option>;
        })}
      </Select>
      {currentUserData?.fullControlOffices.length != 0 ? (
        <Checkbox
          style={{ marginLeft: "1%" }}
          checked={activeDelay}
          value={activeDelay}
          onChange={(e) => {
            setActiveDelay(!activeDelay);
          }}
        >
          Active
        </Checkbox>
      ) : null}
      <Button
        style={{ marginLeft: "1%" }}
        type="primary"
        onClick={async () => {
          print();
        }}
        htmlType="submit"
      >
        Print
      </Button>

      <div id="timeSheet2">
        {usersData
          .filter((user) => user.userData.status != "disable")
          .filter((user) => (uid == "" || uid == "all" ? true : user.uid == uid))
          .map((user) => (
            <div>
              <TimeSheetComponent
                month={month}
                uid={user.uid}
                userData={user.userData as users}
                vacationsMap={vacationsMap as vacations[]}
                projectSN={projectSN}
                budgetLinesSN={budgetLinesSN}
                allocationSN={allocationSN}
                usersSN={usersSN}
                officeSN={officeSN}
                delaySN={delaySN}
                shiftSN={shiftSN}
                activeDelay={activeDelay}
              />

              <div style={{ pageBreakAfter: "always" }} />
            </div>
          ))}
        <br />
      </div>
    </div>
  );
}
