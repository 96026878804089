import React from "react";
import { auth } from "firebase/app";
import { message, Modal } from "antd";
import { notificationsRef, calendar } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { OutputData } from "@editorjs/editorjs";
import { dataToHTML } from "../Editor/constants";

interface Props {
  setModalStateMOM: React.Dispatch<React.SetStateAction<boolean>>;
  modalStateSendMOM: boolean;
  setModalStateSendMOM: React.Dispatch<React.SetStateAction<boolean>>;
  docId: string;
  MOM: string | OutputData;
  mentions: any;
  MOMWriter: string;
  files: any[];
  downloadURLs: any[];
}

function SendMOM(props: Props) {
  const { t } = useTranslation();
  const { modalStateSendMOM, setModalStateSendMOM, MOM, mentions, MOMWriter, files, downloadURLs, setModalStateMOM } =
    props;

  const uid = auth().currentUser!.uid;

  return (
    <Modal
      title={t("general.addMOM")}
      okText={t("general.yes")}
      cancelText={t("general.no")}
      visible={modalStateSendMOM}
      onCancel={async () => {
        try {
          await calendar.doc(props.docId).update({
            momAttach: downloadURLs,
            filesNames: files,
            MOM: dataToHTML(MOM as OutputData),
            MOMObj: MOM,
            MOMWriter: MOMWriter,
            sendMOM: false,
          });
          await mentions.map((id: string) => {
            notificationsRef.add({
              notifyTo: id,
              notifyFrom: uid,
              orderId: props.docId,
              read: false,
              kind: "mention",
              content: "Someone mentioned you in Meetings!",
              postedAt: new Date() as any,
            });
          });
          message.success(t("general.success"));

          setModalStateSendMOM(false);
          setModalStateMOM(false);
        } catch (e) {
          console.log(e);
          message.error(t("general.error"));
        }
      }}
      onOk={async () => {
        try {
          await calendar.doc(props.docId).update({
            momAttach: downloadURLs,
            filesNames: files,
            MOM: dataToHTML(MOM as OutputData),
            MOMObj: MOM,
            MOMWriter: MOMWriter,
            sendMOM: true,
          });

          await mentions.map((id: string) => {
            notificationsRef.add({
              notifyTo: id,
              notifyFrom: uid,
              orderId: props.docId,
              read: false,
              kind: "mention",
              content: "Someone mentioned you in Meetings!",
              postedAt: new Date() as any,
            });
          });
          message.success(t("general.success"));

          setModalStateSendMOM(false);
          setModalStateMOM(false);
        } catch (e) {
          console.log(e);
          message.error(t("general.error"));
        }
      }}
      closable={false}
    >
      <div>Do you want to send MOM via Email?</div>
    </Modal>
  );
}

export default SendMOM;
