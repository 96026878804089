import React from "react";
import { Empty, List } from "antd";
import { flatten } from "lodash";

interface Props {
  filters: any;
  departmentData: any[];
}
export default function AllTemplates(props: Props) {
  const { filters, departmentData } = props;

  const departments = departmentData.filter((p) => p.templates).map((p) => p.templates);

  const templates = flatten(departments);

  const filteredData = templates?.filter((item: any) => {
    const titleLower = item.name?.toLowerCase();
    const descriptionLower = item.attachedDescription?.toLowerCase();
    const searchStringLower = filters.task?.toLowerCase();
    const taskMatches = titleLower.includes(searchStringLower) || descriptionLower.includes(searchStringLower);
    return taskMatches;
  });

  return (
    <div style={{ paddingTop: "2%" }}>
      {filteredData?.length > 0 ? (
        <div>
          <List
            itemLayout="horizontal"
            bordered
            dataSource={filteredData ?? []}
            renderItem={(file: any) => (
              <List.Item>
                <div>
                  <a href={file.url} target="_blank" rel="noopener noreferrer">
                    {file.name}
                  </a>
                  <p style={{ margin: "0px" }}>{file.attachedDescription}</p>
                </div>
              </List.Item>
            )}
          />
        </div>
      ) : (
        <Empty description={"No Attachments"} />
      )}
    </div>
  );
}
