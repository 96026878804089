import React, { useState } from "react";
const { TabPane } = Tabs;
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Button, Popconfirm, Select, DatePicker, Tabs, Icon, Tag } from "antd";
import { officeRef, departmentsRef, positionTasksRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";

import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";

import { globalDepartmentsData } from "../../global/departmentList";
import { Container, Paper } from "@material-ui/core";
import { globalTasksData } from "../../global/taskList";
import { globalDepartmentPositionsData } from "../../global/departmentPositionList";
import ViewTaskDialog from "./viewTaskDialog";

interface Props {
  departmentId: string;
}
export default function Tasks(props: Props) {
  const { departmentId } = props;
  const [modalState, setModalState] = useState(false);
  const [taskData, setTaskData] = useState({});

  const [tasksSN] = useCollection(positionTasksRef.where("departmentId", "==", departmentId));

  if (!tasksSN) return <Spin />;
  const data = tasksSN?.docs?.map((task) => {
    return { ...task?.data(), id: task?.id };
  });
  const COLORS = ["#00C49F", "#FFBB28", "#0088FE", "#FF8042", "#8B008B", "#FF1493", "#B22222", "#ADD8A9"];

  const columns = [
    {
      name: "code",
      title: "Code",
      getCellValue: (row: any) => globalTasksData[row.taskId]?.code,
    },
    {
      name: "title",
      title: "Responsibility Title",
      getCellValue: (row: any) => globalTasksData[row.taskId]?.title,
    },

    {
      name: "positionTitle",
      title: "Position",
      getCellValue: (row: any) => globalDepartmentPositionsData[row.positionId]?.title,
    },
    {
      name: "assignedTo",
      title: "Assigned To",
      getCellValue: (row: any) =>
        globalDepartmentPositionsData[row.positionId]?.uid
          ? globalUsersData[globalDepartmentPositionsData[row.positionId]?.uid ?? ""]?.fullName
          : "Vacant",
    },
    {
      name: "role",
      title: "Roles",
      getCellValue: (row: any) => {
        return row.role?.map((r: any, index: any) => (
          <Tag style={{ marginBottom: "1%", marginTop: "1%" }} color={COLORS[index]} ref={r}>
            {r}
          </Tag>
        ));
      },
    },
    {
      name: "tags",
      title: "Tags",
      getCellValue: (row: any) => {
        return row.tags?.map((r: any) => {
          const randomIndex = Math.floor(Math.random() * COLORS.length);
          return (
            <Tag style={{ marginBottom: "1%", marginTop: "1%" }} color={COLORS[randomIndex]} ref={r}>
              {r}
            </Tag>
          );
        });
      },
    },

    {
      name: "view",
      title: "View",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalState(true);
              setTaskData(row);
            }}
            type="link"
          >
            View
          </Button>
        );
      },
    },
  ];
  const columnsExport = [];

  return (
    <div style={{ paddingTop: "2%" }}>
      <Paper>
        {modalState && <ViewTaskDialog modalState={modalState} setModalState={setModalState} taskData={taskData} />}
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          defaultColumnWidths={[]}
          tableName="Responsibilities"
          selected={false}
          typicalTable={true}
          columnsExport={[]}
        />
      </Paper>
    </div>
  );
}
