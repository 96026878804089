import React, { useState, useEffect } from "react";
import { auth } from "firebase/app";
import { Button, DatePicker, Drawer, Form, Input, InputNumber, message, Select } from "antd";
import moment from "moment";
const { Option } = Select;
import { useDocument } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { salariesRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";
import { salary } from "../../db/interfaces";

interface Props {
  salaryId: string;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function EditSalary(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const uid = auth().currentUser!.uid;
  const [salarySN] = useDocument(salariesRef.doc(props.salaryId));
  const salaryData = salarySN?.data() as salary;

  const formik = useFormik({
    initialValues: {
      uid: "",
      salary: 0,
      currency: "",
      startDate: moment(),
      // endDate: moment(),
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = salariesRef.doc(props.salaryId).update({
        salary: values.salary,
        currency: values.currency,
        startDate: values.startDate.toDate() as any,
        // endDate: values.endDate.toDate() as any,
        createdAt: new Date(),
        by: uid,
        office: globalUsersData[values.uid].office ?? "",
      });

      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (salarySN && loaded) {
      formik.setValues({
        uid: salaryData.uid,
        salary: salaryData.salary,
        currency: salaryData.currency ?? "",
        startDate: moment(salaryData.startDate.toDate()),
        // endDate: moment(salaryData.endDate.toDate()),
      } as any);
      setLoaded(false);
    }
  }, [salarySN, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.addNewSalary")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.salary")}>
          <InputNumber
            value={formik.values.salary}
            onChange={(e) => {
              formik.setFieldValue("salary", e);
            }}
          />
        </Form.Item>
        <Form.Item label={t("general.currency")}>
          <Input
            value={formik.values.currency}
            onChange={(e) => {
              formik.setFieldValue("currency", e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>
        {/* <Form.Item label={t("general.endDate")}>
          <DatePicker
            value={formik.values.endDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("endDate", e)}
          />{" "}
        </Form.Item> */}

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditSalary;
