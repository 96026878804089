import React, { useState, useEffect } from "react";
import { auth } from "firebase/app";
import { Button, DatePicker, Drawer, Form, message, Select } from "antd";
import moment from "moment";
const { Option } = Select;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { workOfficeRef, officeRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { workOffice } from "../../db/interfaces";
import { currentUserData } from "../../db/userGlobal";
import { uniq } from "lodash";
import { USERS_ID_FULL_NAME } from "../../global/usersList";

interface Props {
  workOfficeId: string;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function EditWorkOffice(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const uid = auth().currentUser!.uid;
  const [officeSN] = useCollection(officeRef);
  const [workOfficeSN] = useDocument(workOfficeRef.doc(props.workOfficeId));
  const workOfficeData = workOfficeSN?.data() as workOffice;

  const UserOffices = [...(currentUserData.fullControlOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map((id) => {
    const officeName = officeSN?.docs?.find((d) => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });
  const formik = useFormik({
    initialValues: {
      uid: "",
      office: "",
      startDate: moment(),
      // endDate: moment(),
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = workOfficeRef.doc(props.workOfficeId).update({
        office: values.office,
        startDate: values.startDate.toDate() as any,
        // endDate: values.endDate.toDate() as any,
        createdAt: new Date(),
        by: uid,
      });

      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (workOfficeSN && loaded) {
      formik.setValues({
        uid: workOfficeData.uid,
        office: workOfficeData.office,
        startDate: moment(workOfficeData.startDate.toDate()),
        // endDate: moment(workOfficeData.endDate.toDate()),
      } as any);
      setLoaded(false);

      // setItemLineCode(procurementPlan.itemLineCode)
    }
  }, [workOfficeSN, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.editWorkOffice")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.office")}>
          <Select
            size="default"
            placeholder="Search for Office!"
            showSearch
            value={UserListOffices.find((office) => office.id == formik.values.office)?.officeName}
            onChange={(e) => {
              const id = UserListOffices.find((office) => office.officeName == e)?.id as string;
              formik.setFieldValue("office", id);
            }}
          >
            {UserListOffices.map((d) => {
              return <Option value={d.officeName}>{d.officeName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>
        {/* <Form.Item label={t("general.endDate")}>
          <DatePicker
            value={formik.values.endDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("endDate", e)}
          />{" "}
        </Form.Item> */}

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditWorkOffice;
