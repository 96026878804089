import React from "react";
import { Link } from "react-router-dom";
import { Card, Badge } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  firstName: string;
  lastName: string;
  permitExpiryDate: any;
  jobStartDate: any;
  status: string;
  firstTime: string;
}

export default function OrderSummary(props: Props) {
  const { t } = useTranslation();
  const { id, firstName, lastName, permitExpiryDate, firstTime, jobStartDate, status } = props;
  const statusBadge =
    status == "Received" || status == "Temporarily Active"
      ? "warning"
      : status == "Processing"
      ? "processing"
      : status == "Rejected" || status == "Expired"
      ? "default"
      : status == "Approved and Waiting for Payment" || status == "Active"
      ? "success"
      : "error";
  const cardDate =
    firstTime == "yes"
      ? `${t("general.startJobOn")}: ${jobStartDate}`
      : `${t("general.expiryDate")}: ${permitExpiryDate}`;
  return (
    <Link to={`/order/${id}`}>
      <Card
        title={`${firstName} ${lastName}`}
        bordered={false}
        style={{
          width: "100%",
          marginTop: "10%",
          borderRadius: "10px",
          boxShadow: "6px 7px 17px 3px rgba(0, 0, 0, 0.09)"
        }}
      >
        <Badge status={statusBadge} text={status} />
        <p>{cardDate}</p>
      </Card>
    </Link>
  );
}
