import React, { MutableRefObject, useCallback } from "react";
import moment from "moment";
import "./payrollToPrint.css";
import { sumBy } from "lodash";
import { currentUserData } from "../../db/userGlobal";

interface Props {
  printFnRef: MutableRefObject<() => void>;
  data: any[];
  location: string;
  month: any;
  days: any[];
}

export default function PayrollToPrint(props: Props) {
  const { data, printFnRef, location, month, days } = props;

  const printCb = useCallback(() => {
    function printElem(elem: string) {
      const newWindow = window.open("", "PRINT", "height=400,width=600");
      newWindow?.document.write("<html><head><title>" + document.title + "</title>");
      newWindow?.document.write(
        `<style type="text/css" media="print,screen" >
        .tg {
          border-collapse: collapse;
          border-spacing: 0;
        }
        .tg td {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          overflow: hidden;
          padding: 10px 5px;
          word-break: normal;
        }
        .tg th {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
          overflow: hidden;
          padding: 10px 5px;
          word-break: normal;
        }
        .tg .tg-yg9r {
          border-color: black;
          font-size: 18px;
          font-weight: bold;
          text-align: left;
          vertical-align: top;
        }
        .tg .tg-zzzz {
            border-color: black;
            border-width: 2px;
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            vertical-align: top;
          }
        .tg .tg-1op2 {
          border-color: black;
          color: #00f;
          font-size: 18px;
          text-align: center;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-ho95 {
          background-color: #fff;
          border-color: #ffffff;
          font-size: 18px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-3vfl {
          background-color: #fff;
          border-color: black;
          font-size: 18px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-cji8 {
          border-color: black;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-jl80 {
          background-color: #ddebf7;
          border-width: 2px;
          border-color: black;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-6ao9 {
          border-color: #ffffff;
          font-size: 18px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-shup {
          border-color: black;
          font-size: 18px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-u8v2 {
          background-color: #fff;
          border-color: black;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-yyyy {
            background-color: #fff;
            border-color: black;
            border-width: 2px;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            vertical-align: middle;
          }
        .tg .tg-hyun {
          background-color: #fff;
          border-color: black;
          font-size: 18px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-arpe {
          border-color: #ffffff;
          font-size: 18px;
          font-weight: bold;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-r5qm {
          border-color: black;
          font-size: 18px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-sl9l {
          border-color: #ffffff;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-hi3z {
          border-color: #ffffff;
          font-size: 18px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-xxxx {
          border-color: #ffffff;
          border-bottom: #000000;
        
          font-size: 18px;
          text-align: right;
          vertical-align: middle;
        }
        
        .tg .tg-dcx2 {
          border-color: #ffffff;
          font-size: 18px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-sdug {
          border-color: #ffffff;
          font-size: 18px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-6z68 {
          background-color: #ddebf7;
          border-color: black;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          vertical-align: top;
        }
        .tg .tg-aqi8 {
          background-color: #fff;
          border-color: black;
          font-size: 18px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-h52e {
          border-color: black;
          color: #00f;
          font-size: 18px;
          text-align: center;
          text-decoration: underline;
          vertical-align: top;
        }
        .tg .tg-7jts {
          border-color: black;
          font-size: 18px;
          text-align: center;
          vertical-align: top;
        }
        .tg .tg-hnlb {
          background-color: #fff;
          border-color: black;
          font-size: 18px;
          text-align: center;
          vertical-align: top;
        }
        .tg .tg-3avy {
          border-color: #ffffff;
          font-size: 18px;
          text-align: center;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-38nm {
          border-color: #ffffff;
          color: #00f;
          font-size: 18px;
          text-align: center;
          text-decoration: underline;
          vertical-align: middle;
        }
        
        
        </style>`
      );
      newWindow?.document.write("</head><body >");
      newWindow?.document.write(document.getElementById(elem)?.innerHTML as any);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close(); // necessary for IE >= 10
      newWindow?.focus(); // necessary for IE >= 10 ̰*/
      newWindow?.print();
      return true;
    }
    printElem(`printingPayroll`);
  }, [data]);

  printFnRef.current = printCb;


  return (
    <div id="printingPayroll">
      <div id="view" className="container">
        <div className="bg" />

        <table className="tg" style={{ tableLayout: "fixed", width: "2762px" }}>
          <colgroup>
            <col style={{ width: "70px" }} />
            <col style={{ width: "294px" }} />
            <col style={{ width: "308px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col style={{ width: "120px" }} />
            <col  style={{ width: "120px" }}  />
            <col  style={{ width: "120px" }}  />

          </colgroup>
          <thead>
            <tr>
              <th className="tg-zzzz" colSpan={2}>
                National Staff Payroll
              </th>
              <th className="tg-zzzz"> </th>
              <th className="tg-6ao9"> </th>
              <th className="tg-6ao9"> </th>
              <th className="tg-6ao9"></th>
              <th className="tg-6ao9"></th>
              <th className="tg-6ao9"> </th>
              <th className="tg-6ao9"> </th>
              <th className="tg-6ao9"></th>
              <th className="tg-6ao9"> </th>
              <th className="tg-6ao9"> </th>
              <th className="tg-6ao9"> </th>
              <th className="tg-sl9l"> </th>
              <th className="tg-6ao9"> </th>
              <th className="tg-6ao9"> </th>
              <th className="tg-6ao9"> </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="tg-zzzz" colSpan={2}>
                Location:
              </td>
              <td className="tg-zzzz">{location ?? "All"}</td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"></td>
              <td className="tg-6ao9"></td>
              <td className="tg-sl9l"> </td>
              <td className="tg-sl9l"> </td>
              <td className="tg-6ao9"></td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9" colSpan={2}></td>
              <td className="tg-sl9l"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
            </tr>
            <tr>
              <td className="tg-zzzz" colSpan={2}>
                Month:
              </td>
              <td className="tg-zzzz">{month.format("MMMM, YYYY")}</td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-arpe"></td>
              <td className="tg-arpe"></td>
              <td className="tg-sl9l"> </td>
              <td className="tg-sl9l"> </td>
              <td className="tg-arpe"></td>
              <td className="tg-arpe"> </td>
              <td className="tg-6ao9" colSpan={2}></td>
              <td className="tg-sl9l"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
            </tr>
            <tr>
              <td className="tg-zzzz" colSpan={2}>
                Start date:
              </td>
              <td className="tg-zzzz">{moment(month).startOf("month").format("DD.MM.YYYY")}</td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-hi3z"></td>
              <td className="tg-hi3z"></td>
              <td className="tg-sl9l"> </td>
              <td className="tg-sl9l"> </td>
              <td className="tg-hi3z"></td>
              <td className="tg-hi3z"> </td>
              <td className="tg-6ao9" colSpan={2}></td>
              <td className="tg-sl9l"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
            </tr>
            <tr>
              <td className="tg-zzzz" colSpan={2}>
                End date:
              </td>
              <td className="tg-zzzz">{moment(month).endOf("month").format("DD.MM.YYYY")}</td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-hi3z"></td>
              <td className="tg-hi3z"></td>
              <td className="tg-sl9l"> </td>
              <td className="tg-sl9l"> </td>
              <td className="tg-hi3z"></td>
              <td className="tg-hi3z"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
            </tr>
            <tr>
              <td className="tg-zzzz" colSpan={2}>
                Work days in a month:
              </td>
              <td className="tg-zzzz">{days.length}</td>
              <td className="tg-6ao9"></td>
              <td className="tg-6ao9"> </td>
              <td className="tg-hi3z"></td>
              <td className="tg-hi3z"></td>
              <td className="tg-sl9l"> </td>
              <td className="tg-sl9l"> </td>
              <td className="tg-hi3z"></td>
              <td className="tg-hi3z"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
            </tr>
            <tr>
              <td className="tg-dcx2"> </td>
              <td className="tg-6ao9"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-hi3z"></td>
              <td className="tg-hi3z"></td>
              <td className="tg-sl9l"> </td>
              <td className="tg-sl9l"> </td>
              <td className="tg-hi3z"></td>
              <td className="tg-hi3z"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-6ao9"> </td>
            </tr>
            <tr>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"></td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"></td>
              <td className="tg-xxxx"></td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"></td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"> </td>
              <td className="tg-xxxx"> </td>
              
            </tr>
            <tr>
              <td className="tg-jl80">No.</td>
              <td className="tg-jl80">Name</td>
              <td className="tg-jl80">Postion</td>
              <td className="tg-jl80">
                Work days
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;in a month
              </td>
              <td className="tg-jl80">Deduction</td>
              <td className="tg-jl80">Leave Without Pay</td>
              <td className="tg-jl80">Upsent Days</td>
              <td className="tg-jl80">
                Days
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; worked
              </td>
              <td className="tg-jl80">Monthly salary</td>
              <td className="tg-jl80">Currency</td>

              <td className="tg-jl80">Contract Percentage</td>
              <td className="tg-jl80">Contract Salary</td>

              <td className="tg-jl80">
                Number of
                <br />
                Hours Late
              </td>
              <td className="tg-jl80">Hours Late Deduction</td>
              <td className="tg-jl80">Total Salary for current month</td>
              <td className="tg-jl80">Difference</td>
              <td className="tg-jl80"> Project</td>
              <td className="tg-jl80">Budget Line</td>
              <td className="tg-jl80">Percentage</td>
              <td className="tg-jl80">Amount</td>

              {/* <td className="tg-jl80">Allocated Salary</td> */}
            </tr>
            {/* <tr>
              <td className="tg-aqi8">IDA Turkey STAFF</td>
              <td className="tg-r5qm"> </td>
              <td className="tg-3vfl"> </td>
              <td className="tg-3vfl"> </td>
              <td className="tg-3vfl"> </td>
              <td className="tg-r5qm"> </td>
              <td className="tg-r5qm"> </td>
              <td className="tg-3vfl"> </td>
              <td className="tg-hyun"> </td>
              <td className="tg-r5qm"> </td>
              <td className="tg-3vfl"> </td>
              <td className="tg-3vfl"> </td>
              <td className="tg-3vfl"> </td>
              <td className="tg-hyun"> </td>
              <td className="tg-cji8"> </td>
              <td className="tg-h52e"> </td>
              <td className="tg-3vfl"> </td>
            </tr> */}
            {data.map((row) => (
              <tr>
                <td className="tg-cji8">{row.index}</td>
                <td className="tg-cji8">{row.firstName + " " + row.lastName}</td>
                <td className="tg-cji8">{row.position}</td>
                <td className="tg-cji8">{row.workingDayInMonth}</td>
                <td className="tg-cji8">{row.deduction}</td>
                <td className="tg-cji8"> {row.leaveWithoutPay}</td>
                <td className="tg-cji8"> {row.absentDays}</td>
                <td className="tg-cji8"> {row.daysWorked}</td>
                <td className="tg-cji8">{row.monthlySalary}</td>
                <td className="tg-cji8">{row.currency}</td>

                <td className="tg-cji8">{row.idaContractPercentage}</td>
                <td className="tg-cji8">{row.contractSalary}</td>

                <td className="tg-cji8">{row.numberOfHoursLate}</td>
                <td className="tg-cji8">{row.hoursLateDeduction}</td>
                <td className="tg-cji8">{row.totalSalaryForCurrentMonth}</td>
                <td className="tg-cji8">{row.difference} </td>
                <td className="tg-cji8">{row.project}</td>
                <td className="tg-cji8">{row.budgetLineId}</td>
                <td className="tg-cji8">{row.percentage}</td>
                <td className="tg-cji8">{row.allocatedSalary}</td>

                {/* <td className="tg-1op2">{row.allocatedSalary}</td> */}
              </tr>
            ))}

            <tr>
              <td className="tg-yyyy" colSpan={2}>
                TOTAL PAYMENTS
              </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy">{sumBy(data, (d) => d.salaryAfterDeduction).toFixed(2)}</td>
              <td className="tg-yyyy">{sumBy(data, (d) => d.differenceNumber).toFixed(2)}</td>
              <td className="tg-yyyy"></td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy"> </td>
              <td className="tg-yyyy">{sumBy(data, (d) => d.allocatedSalaryNumber).toFixed(2)} </td>
            </tr>
            <tr>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"></td>
              <td className="tg-sdug"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-6ao9"> </td>
              <td className="tg-sl9l"> </td>
              <td className="tg-sdug"></td>
              <td className="tg-3avy"></td>
              <td className="tg-6ao9"> </td>
            </tr>
            <tr>
              <td className="tg-dcx2">Prepared By: </td>
            <td className="tg-dcx2">{currentUserData.fullName}</td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2">Approved By: </td>
              <td className="tg-dcx2">
               Hazem Almenla
              </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2">Approved By:</td>
              <td className="tg-dcx2">  Mahmud Mustafa </td>
              <td className="tg-sdug"> </td>
              <td className="tg-sdug"></td>
              <td className="tg-38nm"></td>
              <td className="tg-ho95"> </td>
            </tr>
            <tr>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-sdug"></td>
              <td className="tg-38nm"></td>
              <td className="tg-ho95"> </td>
            </tr>
            <tr>
              <td className="tg-dcx2">HR Manger</td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2" colSpan={2}>
                Deputy&nbsp;&nbsp;&nbsp;General Director for Operation
              </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2">General Director</td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-sdug"></td>
              <td className="tg-38nm"></td>
              <td className="tg-ho95"> </td>
            </tr>
            <tr>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-sdug"></td>
              <td className="tg-38nm"></td>
              <td className="tg-ho95"> </td>
            </tr>
            <tr>
              <td className="tg-dcx2">Sign:</td>
              <td className="tg-dcx2">
                ……………………
                <br />
              </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2">Sign:</td>
              <td className="tg-dcx2">
                <span style={{ fontWeight: 400, fontStyle: "normal" }}>……………………</span>
              </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2">Sign:……………………</td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-sdug"></td>
              <td className="tg-38nm"></td>
              <td className="tg-ho95"> </td>
            </tr>
            <tr>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-sdug"></td>
              <td className="tg-38nm"></td>
              <td className="tg-ho95"> </td>
            </tr>
            <tr>
              <td className="tg-dcx2">Date:</td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2">Date:</td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2">Date:</td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-sdug"></td>
              <td className="tg-38nm"></td>
              <td className="tg-ho95"> </td>
            </tr>
            <tr>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"></td>
              <td className="tg-sdug"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-dcx2"></td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-dcx2"> </td>
              <td className="tg-sdug"> </td>
              <td className="tg-sdug"></td>
              <td className="tg-38nm"></td>
              <td className="tg-ho95"> </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
