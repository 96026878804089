import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Button } from "antd";
import { meetingRoomList, calendar } from "../../db/collectionsRef";
import { useHistory } from "react-router-dom";
import { auth } from "firebase/app";
import { useTranslation } from "react-i18next";
import AddMOM from "./AddMOM";
import DevTable from "../../helpers/devex-table";
import { globalUsersData } from "../../global/usersList";
import { Calendar } from "../../db/interfaces";

const MOMStatusChange = (id: string) => {
  calendar.doc(id).update({
    MOMStatus: true,
  });
};
export default function MeetingsTable() {
  const [rowDoc, setRowDoc] = useState({} as any);
  const history = useHistory();
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const [MOM, setMOM] = useState("");
  const [showAll, setShowAll] = useState(true);
  const [modalStateMOM, setModalStateMOM] = useState(false);
  const [defaultHiddenColumnNames] = useState(["agenda", "allDay", "attendees", "endDate", "id"]);

  const [roomsSN] = useCollection(meetingRoomList);
  const [calendarSN] = useCollection(calendar.where("members", "array-contains", uid));

  if (!calendarSN || !roomsSN) return <p>loading</p>;

  const data = calendarSN?.docs?.map((d, index) => {
    return { ...d.data(), id: d.id, index: index + 1 };
  });
  const columns = [
    {
      name: "id",
      title: "ID",
    },
    {
      name: "index",
      title: "NO",
    },

    { name: "title", title: "Title" },
    {
      name: "uid",
      title: "Organizer",
      getCellValue: (row: Calendar) => globalUsersData[row.uid ?? ""]?.fullName,
    },
    {
      name: "roomId",
      title: "Location",
      getCellValue: (row: any) => roomsSN.docs?.find((d) => d.id == row.roomId)?.data()?.roomName,
    },
    {
      name: "allDay",
      title: "All Day",
      getCellValue: (row: any) => (row.allDay == false ? "No" : "Yes"),
    },
    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => row.startDate.toDate().toLocaleString(),
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => row.endDate.toDate().toLocaleString(),
    },
    {
      name: "attendees",
      title: "Attendees",
      getCellValue: (row: any) => {
        return row.members?.map((m: string) => {
          return globalUsersData[m ?? ""]?.fullName;
        });
      },
    },

    {
      name: "agenda",
      title: "Agenda",
    },

    {
      name: "more",
      title: "More Information",
      getCellValue: (row: any) => {
        return <Button onClick={() => history.push(`/calenderDetails/${row.id}`)}> {t("general.view")}</Button>;
      },
    },
    {
      name: "MOM",
      title: "MOM",
      getCellValue: (row: any) => {
        const now = new Date();
        const oldDate = row.startDate.toDate();
        if (now >= oldDate && (row.MOM == undefined || row.MOM == "")) {
          MOMStatusChange(row.id);
          return (
            <Button
              onClick={() => {
                setRowDoc(row);
                setMOM(row.MOM);
                setModalStateMOM(true);
              }}
            >
              {t("general.addMOMButton")}
            </Button>
          );
        } else if (now >= oldDate && row.MOM !== undefined) {
          return (
            <div>
              <Button
                onClick={() => {
                  setRowDoc(row);
                  setModalStateMOM(true);
                }}
              >
                {t("general.addMOMButton")}
              </Button>
              <div style={{ color: "green", float: "right", paddingTop: "3%", paddingRight: "6%" }}>
                {" "}
                {t("general.submitted")}
              </div>
            </div>
          );
        } else {
          return "Not Yet";
        }
      },
    },
  ];
  const columnsExport = [
    { name: "title", title: "Title" },
    {
      name: "uid",
      title: "Organizer",
      getCellValue: (row: any) => globalUsersData[row.uid ?? ""]?.fullName,
    },
    {
      name: "roomId",
      title: "Location",
      getCellValue: (row: any) => roomsSN.docs?.find((d) => d.id == row.roomId)?.data()?.roomName,
    },
    {
      name: "allDay",
      title: "All Day",
      getCellValue: (row: any) => (row.allDay == false ? "No" : "Yes"),
    },
    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => row.startDate.toDate().toLocaleString(),
    },
    {
      name: "endDate",
      title: "End Date",
      getCellValue: (row: any) => row.endDate.toDate().toLocaleString(),
    },
    {
      name: "attendees",
      title: "Attendees",
      getCellValue: (row: any) => {
        return row.members.map((m: string) => {
          return globalUsersData[m ?? ""]?.fullName;
        });
      },
    },
  ];

  return (
    <div>
      <div style={{ backgroundColor: "#fff", padding: "1%", marginBottom: "1%", border: "white", borderRadius: "4px" }}>
        <b>
          <h3>Meetings Table Details</h3>
        </b>
      </div>
      <div>
        <Button
          type="primary"
          style={{ marginBottom: "1%", marginRight: "0.2%" }}
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {!showAll ? "Show All" : "Show Finished"}
        </Button>
      </div>

      {modalStateMOM ? (
        <AddMOM modalStateMOM={modalStateMOM} setModalStateMOM={setModalStateMOM} doc={rowDoc} MOM={MOM} />
      ) : null}
      <div>
        <Paper>
          <DevTable
            data={data}
            columns={columns}
            defaultHiddenColumnNames={defaultHiddenColumnNames}
            tableName="My_Meetings"
            selected={false}
            typicalTable={false}
            columnsExport={columnsExport}
          />
        </Paper>
      </div>
    </div>
  );
}
