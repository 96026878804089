export default {
  common: {
    workPermit: "Çalışma izini",
    ok: "Tamam",
    firstName: "Adı",
    lastName: "Soyadı",
    fatherName: "Baba Adı",
    motherName: "Ana Adı",
    gender: "Cinsiyeti :",
    birthPlace: "Doğum Yeri",
    birthDate: "Doğum Tarihi",
    marriedStatus: "Medeni Hali",
    nationality: "Uyruğu",
    femaleChildrenNumber: "Kız Çocuk Sayısı :",
    maleChildrenNumber: "Erkek Çocuk Sayısı :",
    address: "Adresi:",
    wifeNationality: "Eşinin Uyruğu :",
    wifeFirstName: "Eşinin Adı :",
    wifeLastName: "Eşinin Soyadı :",
    wifeTC: "Eşinin TC Kimlik No :",
    phone: "Cep Telefon :",
    email: "E-Posta Adresi :",
    universityNameAddress: "Mezun Olunan Okul(Adı / Yeri):",
    degree: "Tahsil :",
    degreeField: "Diploma Mesleği :",
    degreeSpecialization: "İhtisas Konusu :",
    degreeFaculty: "Yabancının Üniversite Fakültesi :",

    passportNO: "Pasaport No :",
    passportExpiryDate: "Son Geçerlilik Tarihi :",
    passportAuth: "Pasaportun Verildiği Makam :",
    position: "Görevi :",
    salary: "Ücreti :",
    cityInSyria: "Yabancının Suriyede Yaşadığı Bölge-Şehir:",
    durationInTurkey: "Türkiye'de Bulunulan Süre :",
    language: "Dil"
  },
  attachments: {
    permitCardPhoto: "İkamet İzni Fotokopisi",
    passportPhoto: "Pasaport Fotokopisi",
    personalPhoto: "Fotograf",
    diplomaNotarized: "Türkçe Tercüme Edilmiş Noter Tasdikli Diploma Sureti -Asıl",
    IDandPhoto: "KİMLİK FOTOKOPİSİ VE 1 ADET FOTOGRAF EKLENECEKTİR",
    ID: "KİMLİK FOTOKOPİSİ"
  }
};
