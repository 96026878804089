import { tasksRef } from "../db/collectionsRef";
import { task } from "../db/interfaces";

interface TasksWithId extends task {
  id: string;
}

export const globalTasksData: Record<string, TasksWithId> & { loaded?: boolean } = {};

tasksRef.onSnapshot((data) => {
  Object.defineProperty(globalTasksData, "loaded", { enumerable: false, value: true });
  data.docs.forEach((doc) => {
    globalTasksData[doc.id] = { ...doc.data(), id: doc.id } as TasksWithId;
  });
});
