import React, { useState } from "react";
import { Route, RouteComponentProps } from "react-router";
import { Row, Col, Button } from "antd";
import VacationList from "./VacationList";
import VacationDetails from "./vacationDetails";
import AddNewVacation from "./VacationsModules/AddNewVacation";
import { currentUserData } from "../../db/userGlobal";
import AddNewVacationForUser from "./VacationsModules/AddNewVacationForUsers";

export default function VacationDashboard() {
  const [modalState, setModalState] = useState(false);
  const [modalStateNewVacationByHR, setModalStateNewVacationByHR] = useState(false);

  return (
    <div>
      {modalState ? <AddNewVacation modalState={modalState} setModalState={setModalState} /> : null}
      {modalStateNewVacationByHR ? (
        <AddNewVacationForUser modalState={modalStateNewVacationByHR} setModalState={setModalStateNewVacationByHR} />
      ) : null}

      <div style={{ marginTop: "-1%" }}>
        <Row gutter={30} style={{ alignItems: "center" }}>
          <Col span={16}>
            {/* <Route exact path="/" component={(props: RouteComponentProps<{ id: string }>) => <Services />} /> */}
            <Route
              path="/vacation/:id"
              component={(props: RouteComponentProps<{ id: string }>) => (
                <VacationDetails key={props.match.params.id} {...props} />
              )}
            />
          </Col>
          <Col span={1}>
            <Button
              disabled={currentUserData.vacationAbility == undefined || currentUserData.vacationAbility ? false : true}
              type="primary"
              shape="circle"
              size="large"
              onClick={() => {
                setModalState(true);
              }}
            >
              +
            </Button>

            {currentUserData.HROffices.length != 0 ? (
              <Button
                style={{ margin: "1%" }}
                disabled={
                  currentUserData.vacationAbility == undefined || currentUserData.vacationAbility ? false : true
                }
                type="danger"
                shape="circle"
                size="large"
                onClick={() => {
                  setModalStateNewVacationByHR(true);
                }}
              >
                HR+
              </Button>
            ) : null}
          </Col>

          <Col span={5}>
            <VacationList />
          </Col>
        </Row>
      </div>
    </div>
  );
}
