import { departmentPositionsRef } from "../db/collectionsRef";
import { departmentPosition } from "../db/interfaces";

interface DepartmentPositionsWithId extends departmentPosition {
  id: string;
}

export const globalDepartmentPositionsData: Record<string, DepartmentPositionsWithId> & { loaded?: boolean } = {};

departmentPositionsRef.onSnapshot((data) => {
  Object.defineProperty(globalDepartmentPositionsData, "loaded", { enumerable: false, value: true });
  data.docs.forEach((doc) => {
    globalDepartmentPositionsData[doc.id] = { ...doc.data(), id: doc.id } as DepartmentPositionsWithId;
  });
});
