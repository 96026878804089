import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Button, Spin, Row, Col, Divider } from "antd";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { Calendar } from "../../db/interfaces";
import { userRef, meetingRoomList, calendar } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { RRule } from "rrule";
import printJS from "print-js";
import { dataToHTML } from "../Editor/constants";
import { OutputData } from "@editorjs/editorjs";

export default function MeetingDetails() {
  const id = useRouteMatch<{ id: string }>().params.id;
  const [calendarSN] = useDocument(calendar.doc(id || "notyet"));
  const [userSN] = useCollection(userRef);
  const [meetingRoomListSN] = useCollection(meetingRoomList);

  if (!calendarSN || !userSN) {
    return <Spin />;
  }
  const {
    title,
    uid,
    startDate,
    endDate,
    rRule,
    members,
    agenda,
    MOM,
    roomId,
    MOMStatus,
    MOMWriter,
    momAttach,
    filesNames,
    agendaAttach,
    agendaFileNames,
  } = calendarSN?.data() as Calendar;
  const MOMWriterIs =
    MOMWriter == ""
      ? "Not Written Yet"
      : `${userSN.docs.find((d) => d.id == MOMWriter)?.data()?.firstName} ${
          userSN.docs.find((d) => d.id == MOMWriter)?.data()?.lastName
        }`;
  const rule = RRule.fromString(rRule);
  const ruleString = rule.toText();
  const attendees = members.map((id, index) => {
    return (
      <div>{`${index + 1}- ${userSN.docs.find((d) => d.id == id)?.data().firstName} ${
        userSN.docs.find((d) => d.id == id)?.data().lastName
      }`}</div>
    );
  });

  return (
    <div>
      <Button
        type="danger"
        onClick={() =>
          printJS({
            header: "Meeting Details",
            printable: "printing",
            type: "html",
            showModal: true,
            // honorMarginPadding: true,
            // honorColor: true,
            headerStyle: "color:#00aeef ",
            scanStyles: false,
            targetStyles: ["*"],
          })
        }
      >
        Print
      </Button>
      <div id="printing" style={{ backgroundColor: "#fff", margin: "2%", borderRadius: "5px", paddingBottom: "2%" }}>
        <div style={{ backgroundColor: "#e8e8e8", padding: "2%" }}>
          <h2>
            <b> Meeting Title:</b> {title}
          </h2>
        </div>
        <div style={{ margin: "2%" }}>
          <Row>
            <Col span={12}>
              <div style={{ backgroundColor: "#fff", margin: "2%", padding: "2%", borderRadius: "10px" }}>
                <div>
                  <b>Organizer:</b>{" "}
                  {`${userSN.docs.find((d) => d.id == uid)?.data()?.firstName} ${
                    userSN.docs.find((d) => d.id == uid)?.data()?.lastName
                  }`}
                </div>
                <div>
                  <b>Start Date:</b> {startDate.toDate().toLocaleString()}
                </div>
                <div>
                  <b>End Date:</b> {endDate.toDate().toLocaleString()}
                </div>
                <div>
                  <b>Location:</b> {meetingRoomListSN?.docs.find((d) => d.id == roomId)?.data().roomName}
                </div>
                <div>
                  <b>MOM Written by:</b> {MOMWriterIs}
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  backgroundColor: "#d8f1f7",
                  padding: "2%",
                  borderRadius: "5px",
                  margin: "2%",
                }}
              >
                <div>
                  <b>Repeat:</b> {ruleString}
                </div>
                <div>
                  <b>Attendees:</b>
                  <div style={{ padding: "2%" }}>{attendees}</div>
                </div>
                <div>
                  <b>Status:</b>{" "}
                  {MOMStatus ? <b style={{ color: "green" }}>Done</b> : <b style={{ color: "red" }}>Not Yet</b>}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <br />
        <Divider orientation="left">
          <b>
            <h2 style={{ color: "#00aeef" }}>Agenda</h2>
          </b>
        </Divider>
        <div>
          {agendaAttach?.map((attach, index) => (
            <a style={{ margin: "2%" }} href={agendaAttach[index]}>
              {agendaFileNames[index]}
            </a>
          ))}
        </div>
        <div>
          <div style={{ margin: "2%" }} dangerouslySetInnerHTML={{ __html: agenda }} />
        </div>
        <br />

        <Divider orientation="left">
          <b>
            <h2 style={{ color: "#00aeef" }}>Minutes of Meeting</h2>
          </b>
        </Divider>
        <div>
          {momAttach?.map((attach, index) => (
            <a style={{ margin: "2%" }} href={momAttach[index]}>
              {filesNames[index]}
            </a>
          ))}
        </div>

        <div>
          <div
            style={{ margin: "2%" }}
            dangerouslySetInnerHTML={{ __html: typeof MOM == "string" ? MOM : dataToHTML(MOM as OutputData) }}
          />
        </div>
      </div>
    </div>
  );
}
