import { officeRef } from "../db/collectionsRef";
import {office } from "../db/interfaces";

interface OfficesWithId extends office {
  id: string;
}

export const globalOfficesData: Record<string, OfficesWithId> & { loaded?: boolean } = {};

officeRef.onSnapshot(data => {
  Object.defineProperty(globalOfficesData, "loaded", { enumerable: false, value: true });
  data.docs.forEach(doc => {
    globalOfficesData[doc.id] = { ...doc.data(), id: doc.id } as OfficesWithId;
  });
});
