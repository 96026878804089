import React, { useState, useRef, useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase, { firestore, auth, User } from "firebase/app";
import { Spin, Button, Select, DatePicker, Checkbox, Icon } from "antd";
const { Option } = Select;
import UsersInformation from "../models/usersInformation";
import {
  officeRef,
  projectRef,
  allocationRef,
  budgetLinesRef,
  vacationRef,
  deductionRef,
  missionRef,
} from "../../db/collectionsRef";
import { currentUserData } from "../../db/userGlobal";
import { sortBy, sumBy, uniq, uniqBy } from "lodash";
import NewUser from "../models/newUser";
import moment from "moment";
const { MonthPicker } = DatePicker;
import DevTable from "../../helpers/devex-table";
import { allocation, deduction, users, access, project, mission } from "../../db/interfaces";
import { currentSalary } from "../../db/currentSalary";
import { currentP } from "../../db/currentPosition";
import { globalOfficesData } from "../../global/officeList";
import { CheckBox } from "@material-ui/icons";
import PaySlipToPrint from "./paySlipToPrint";
import { globalProjectsData } from "../../global/projectList";
import { globalBudgetLinesData } from "../../global/budgetLineList";

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function PayslipByProject() {
  const [filteredPosition, setFilteredPosition] = useState([] as string[]);
  const [month, setMonth] = useState(moment());
  const [missionId, setMissionId] = useState("all");
  const [fingerPrintActive, setFingerPrintActive] = useState(true);
  const uid = auth().currentUser!.uid;
  const [projectId, setProjectId] = useState("notYet");
  const [modalState, setModalState] = useState(false);
  const [modalStateNewUser, setModalStateNewUser] = useState(false);
  const [defaultColumnWidths] = useState([
    { columnName: "index", width: 150 },
    { columnName: "resourceNO", width: 150 },
    { columnName: "firstName", width: 150 },
    { columnName: "lastName", width: 150 },
    { columnName: "position", width: 150 },
    { columnName: "workingDayInMonth", width: 150 },
    { columnName: "deduction", width: 150 },
    { columnName: "leaveWithoutPay", width: 250 },
    { columnName: "absentDays", width: 150 },
    { columnName: "daysWorked", width: 150 },
    { columnName: "monthlySalary", width: 250 },
    { columnName: "numberOfHoursLate", width: 250 },
    { columnName: "hoursLateDeduction", width: 250 },
    { columnName: "totalSalaryForCurrentMonth", width: 250 },
    { columnName: "difference", width: 150 },
    { columnName: "donor", width: 150 },
    { columnName: "project", width: 150 },
    { columnName: "budgetLineId", width: 150 },
    { columnName: "percentage", width: 150 },
    { columnName: "allocatedSalary", width: 200 },
    { columnName: "print", width: 200 },
  ]);
  const [row, setRow] = useState({} as any);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [paymentOffice, setPaymentOffice] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [allocationsSN] = useCollection(
    allocationRef
    .where("projectId", "==", projectId)
    .where("endDate", ">=", month.endOf("day").toDate())
  );
  const [AllUsers] = useCollection(usersRef);
  const [officeSN] = useCollection(officeRef);
  const [projectSN] = useCollection(projectRef);
  const [missionSn] = useCollection(missionRef);
  const [budgetLineSN] = useCollection(budgetLinesRef.orderBy("createdAt"));
  const [vacationSN] = useCollection(
    vacationRef
      .where("vacationStartOn", "<=", month.endOf("month").toDate())
      .where("vacationStartOn", ">=", month.startOf("month").toDate())
  );
  const [deductionSN] = useCollection(deductionRef.where("month", "==", month.format("MM-YYYY").toString()));

  const [accessSN] = useCollection(
    db
      .collectionGroup("access")
      .where("date", "<=", month.endOf("month").toDate())
      .where("date", ">=", month.startOf("month").toDate())
  );
  const [delaySN] = useCollection(
    db.collectionGroup("delay").where("month", "==", month.format("MMM.YYYY").toString())
  );

  const print2CbRef = useRef<() => void>();
  const printPayslip = useCallback(() => {
    setTimeout(() => {
      print2CbRef.current();
    }, 1500);
  }, []);
  const printCb = useCallback(() => {
    function printElem(elem: string) {
      const newWindow = window.open("", "PRINT", "height=400,width=600");
      newWindow?.document.write("<html><head><title>" + document.title + "</title>");
      newWindow?.document.write(
        `<style type="text/css" media="print,screen" >
        .pagebreak {
          page-break-before:always;
        }
        .tg {
          border-collapse: collapse;
          border-spacing: 0;
        }
        .tg td {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg th {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg .tg-jqmv {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-z4i2 {
          border-color: #ffffff;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-w7c0 {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-zv4m {
          border-color: #ffffff;
          text-align: left;
          vertical-align: top;
        }
        .tg .tg-pgku {
          border-color: #ffffff;
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-9ksr {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u6id {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-u6id1 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-fmc2 {
          border-color: #ffffff;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-lh7k {
          border-color: #ffffff;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-sl9l {
          border-color: #ffffff;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2urc {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-top-width: 3px;
          border-right-width: 3px;
        
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ui32 {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd0 {
          border-color: black;
          border-top-width: 3px;
        
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ai0l {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: top;
        }
        .tg .tg-f3so {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-f3sox {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-9o1m {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2776 {
          border-color: black;
          border-width: 1px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776x {
          border: none;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776xy {
          border-color: black;
          border-width: 0;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27762 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27760 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-277601 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-u9r8 {
          border-color: black;
          border-top-width: 3px;
        
          border-width: 1px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r80 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r81 {
          border-color: black;
          border-width: 1px;
          border-right-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r82 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r83 {
          border-color: black;
          border-top-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-mlkz {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-oedm {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-0326 {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: right;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-ttyt {
          border-color: #ffffff;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-ttytx {
          border-color: black;
          border-width:0 ;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-8srf {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        
        
        </style>`
      );
      newWindow?.document.write("</head><body >");
      newWindow?.document.write(document.getElementById(elem)?.innerHTML as any);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close(); // necessary for IE >= 10
      newWindow?.focus(); // necessary for IE >= 10 ̰*/
      newWindow?.print();
      return true;
    }
    printElem(`multiplePage`);
  }, []);
  print2CbRef.current = printCb;

  const print3CbRef = useRef<() => void>();
  const printPayslipPerRow = useCallback(() => {
    setTimeout(() => {
      print3CbRef.current();
    }, 1500);
  }, []);
  const printPerRow = useCallback(() => {
    function printElem(elem: string) {
      const newWindow = window.open("", "PRINT", "height=400,width=600");
      newWindow?.document.write("<html><head><title>" + document.title + "</title>");
      newWindow?.document.write(
        `<style type="text/css" media="print,screen" >
        .pagebreak {
          page-break-before:always;
        }
        .tg {
          border-collapse: collapse;
          border-spacing: 0;
        }
        .tg td {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg th {
          border-color: black;
          border-style: solid;
          border-width: 1px;
          font-family: Arial, sans-serif;
          font-size: 14px;
          font-weight: normal;
          overflow: hidden;
          padding: 6px 4px;
          word-break: normal;
        }
        .tg .tg-jqmv {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-z4i2 {
          border-color: #ffffff;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-w7c0 {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-zv4m {
          border-color: #ffffff;
          text-align: left;
          vertical-align: top;
        }
        .tg .tg-pgku {
          border-color: #ffffff;
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-9ksr {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u6id {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-u6id1 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-fmc2 {
          border-color: #ffffff;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-lh7k {
          border-color: #ffffff;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-sl9l {
          border-color: #ffffff;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2urc {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-top-width: 3px;
          border-right-width: 3px;
        
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ui32 {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-1zvd0 {
          border-color: black;
          border-top-width: 3px;
        
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: middle;
        }
        .tg .tg-ai0l {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          vertical-align: top;
        }
        .tg .tg-f3so {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-f3sox {
          background-color: #c0c0c0;
          border-color: black;
          border-left-width: 3px;
          border-right-width: 3px;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-9o1m {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: middle;
        }
        .tg .tg-2776 {
          border-color: black;
          border-width: 1px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776x {
          border: none;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-2776xy {
          border-color: black;
          border-width: 0;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27762 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-27760 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-277601 {
          border-color: black;
          border-width: 3px;
        
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-u9r8 {
          border-color: black;
          border-top-width: 3px;
        
          border-width: 1px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r80 {
          border-color: black;
          border-width: 1px;
          border-top-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r81 {
          border-color: black;
          border-width: 1px;
          border-right-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r82 {
          border-color: black;
          border-width: 3px;
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-u9r83 {
          border-color: black;
          border-top-width: 3px;
          border-right-width: 3px;
        
          font-size: 16px;
          text-align: center;
          vertical-align: bottom;
        }
        .tg .tg-mlkz {
          border-color: black;
          border-width: 1px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-oedm {
          border-color: #ffffff;
          font-size: 16px;
          text-align: left;
          vertical-align: bottom;
        }
        .tg .tg-0326 {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: right;
          text-decoration: underline;
          vertical-align: middle;
        }
        .tg .tg-ttyt {
          border-color: #ffffff;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-ttytx {
          border-color: black;
          border-width:0 ;
          border-bottom-width: 3px;
          font-size: 16px;
          text-align: right;
          vertical-align: middle;
        }
        .tg .tg-8srf {
          border-color: #ffffff;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          vertical-align: bottom;
        }
        
        
        </style>`
      );
      newWindow?.document.write("</head><body >");
      newWindow?.document.write(document.getElementById(elem)?.innerHTML as any);
      newWindow?.document.write("</body></html>");
      newWindow?.document.close(); // necessary for IE >= 10
      newWindow?.focus(); // necessary for IE >= 10 ̰*/
      newWindow?.print();
      return true;
    }
    printElem(`perRow`);
  }, []);
  print3CbRef.current = printPerRow;

  if (
    !missionSn ||
    !delaySN ||
    !accessSN ||
    !deductionSN ||
    !vacationSN ||
    !budgetLineSN ||
    !allocationsSN ||
    !projectSN ||
    !AllUsers ||
    !officeSN 
    // ||
    // !currentUserData.fullControlOffices
  )
    return <Spin />;
  const missions = missionSn.docs.map((m) => {
    return { ...m.data(), id: m.id } as mission;
  });
  const delays = delaySN.docs
    .map((d) => {
      return { ...d.data(), id: d.id, uid: d.data().userUID, min: d.data().min, status: d.data().status };
    })
    .filter((d) => d.status != "disabled");

  const VacationDates = vacationSN.docs
    .filter((vacation) => vacation.data().status != "Rejected" && vacation.data().status != "Canceled")
    .map((d) => {
      return {
        ...d.data(),
        employeeUID: d.data().employeeUID,
        start: d.data().vacationStartOn,
        end: d.data().vacationEndOn,
        duration: d.data().vocationPeriodDays,
        type: d.data().vacationType,
        halfDay: d.data().halfDay,
      };
    });
  function daysInMonth(m: any) {
    var count = moment().year(m.year()).month(m.month()).daysInMonth();
    var days = [];
    for (var i = 1; i < count + 1; i++) {
      days.push(moment().year(m.year()).month(m.month()).date(i));
    }
    return days;
  }
  var days = daysInMonth(month);
  const deductionData = deductionSN.docs.map((d) => {
    return { ...d.data(), id: d.id } as deduction;
  });
  const projectList = projectSN?.docs.map((p) => {
    return { id: p.id, projectCode: p?.data()?.projectCode, donor: p?.data().donor } as project;
  });

  const allocationsUids = allocationsSN?.docs.map((al) => al.data() as allocation);

  const users = AllUsers?.docs
    .filter((d) => currentUserData.fullControlOffices.includes(d.data()?.office))
    .filter((d) => uniq(allocationsUids).filter((i) => i.uid == d.id).length > 0)
    .map((d, index) => {
      return {
        ...d.data(),
        id: d.id,
        index: index + 1,
      };
    });

  const userAllocation = users.map((u) => {
    const allocation = allocationsUids.find((a) => a.uid == u.id) as allocation;
    return { ...u, ...allocation };
  });
  const access = accessSN.docs?.map((a) => {
    return { ...a.data(), id: a.id } as access;
  });

  const absentDaysArr = userAllocation.map((u: any) => {
    const absentDays = days
      .map((day) => {
        let absent = false;

        const accessInDay = access
          .filter((a) => a.uid == u.id)
          .filter((a) => {
            return (
              moment(a.date.toDate()).format("DD-MM-YYYY").toString() == moment(day).format("DD-MM-YYYY").toString()
            );
          }).length;

        const vacationsFiltered = VacationDates.filter((v) => v.employeeUID == u.id).filter((vacation: any) => {
          const vacationStartOn = moment(vacation?.vacationStartOn?.toDate()).startOf("day");
          const vacationEndOn = moment(vacation?.vacationEndOn?.toDate()).endOf("day");

          return moment(day).isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
        }).length;

        if (vacationsFiltered == 0 && accessInDay == 0) {
          absent = true;
        }

        return { absent, day };
      })
      .filter((ab) => ab.absent == true).length;

    return { uid: u.id, absentDays };
  });

  const data = sortBy(
    userAllocation.map((row: any) => {
      const id = row.id;
      const missionId = globalOfficesData[row.office].missionId;
      const position = currentP(row.uid, month) == "" ? row.position : currentP(row.uid, month);

      const workingDayInMonth = days.length;

      const deduction = deductionData.find((d) => d.uid == row.uid)?.percentage ?? 0;

      const leaveWithoutPay = VacationDates.filter((v) => v.employeeUID == row.uid && v.type == "Unpaid Leave").length;

      const absentDays = (fingerPrintActive ? absentDaysArr.find((ab) => ab.uid == row.uid)?.absentDays : 0) as number;
      const daysWorked =
        days.length -
        absentDays * 2 -
        VacationDates.filter((v) => v.employeeUID == row.uid && v.type == "Unpaid Leave").length;

      const monthlySalary = currentSalary(row.uid, month) == "" ? row.salary : currentSalary(row.uid, month);
      const numberOfHoursLate = (
        sumBy(
          delays.filter((d) => d.uid == row.uid),
          (d) => d.min
        ) / 60
      ).toFixed(2);

      ///
      const salary = currentSalary(row.uid, month) == "" ? row.salary : currentSalary(row.uid, month);

      const delaysHours =
        sumBy(
          delays.filter((d) => d.uid == row.uid),
          (d) => d.min
        ) / 60;

      const salaryInHour = salary / days.length / 8;
      const deductionByHour = salaryInHour * (delaysHours * 2);
      const hoursLateDeduction = deductionByHour.toFixed(3);
      ///
      const salaryInDay = salary / days.length;
      const deductionPercentage = deductionData.find((d) => d.uid == row.uid)?.percentage ?? 0;
      const salaryAfterDeduction =
        salaryInDay * daysWorked - deductionByHour - (deductionPercentage * salary * row.percentage) / 10000;
      const totalSalaryForCurrentMonth = parseInt(salaryAfterDeduction.toFixed(3));
      ///
      const differenceNumber = salary - salaryAfterDeduction;
      const difference = parseInt(differenceNumber.toFixed(3));
      ///
      const donor = globalProjectsData[row.projectId]?.donor;
      const project = globalProjectsData[row.projectId]?.projectCode;
      const budgetLineId =globalBudgetLinesData[row.budgetLineId]?.budgetLine;
      const percentage = row.percentage + " " + "%";
      const allocatedSalaryNumber = (row.percentage * salaryAfterDeduction) / 100;
      const allocatedSalary = parseInt(allocatedSalaryNumber.toFixed(3));
      // const allocations = allocationsUids.filter((a) => a.uid == row.uid);
      return {
        ...row,
        position,
        workingDayInMonth,
        deduction,
        leaveWithoutPay,
        absentDays,
        daysWorked,
        monthlySalary,
        numberOfHoursLate,
        hoursLateDeduction,
        totalSalaryForCurrentMonth,
        salaryAfterDeduction,
        difference,
        differenceNumber,
        donor,
        project,
        budgetLineId,
        percentage,
        missionId,
        allocatedSalary,
        allocatedSalaryNumber,
        id,
        allocations:[] as any[],
      };
    }),
    ["firstName", "lastName"]
  )
    .map((d, index) => {
      return { ...d, index: index + 1 };
    })
    .filter((row) => {
      if (missionId == "all") {
        return true;
      } else {
        return row.missionId == missionId;
      }
    });

  const positions = uniq(data.map((d) => d.position));
  const tableData = data.filter((d: any) => {
    if (filteredPosition.length == 0) {
      return true;
    } else {
      if (filteredPosition.includes(d.position)) {
        return true;
      } else {
        return false;
      }
    }
  });
  const columns = [
    { name: "index", title: "NO" },
    { name: "resourceNO", title: "Resource NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    {
      name: "position",
      title: "Position",
    },
    {
      name: "workingDayInMonth",
      title: "Work days in a month",
    },
    {
      name: "deduction",
      title: "Deduction",
    },
    {
      name: "leaveWithoutPay",
      title: "Leave Without Pay",
    },
    {
      name: "absentDays",
      title: "Absent Days",
    },
    {
      name: "daysWorked",
      title: "Days worked",
    },
    {
      name: "monthlySalary",
      title: "Monthly salary ( TL/ USD )",
    },
    {
      name: "numberOfHoursLate",
      title: "Number of Hours Late",
    },
    {
      name: "hoursLateDeduction",
      title: "Hours Late Deduction",
    },
    {
      name: "totalSalaryForCurrentMonth",
      title: "Total Salary for current month",
    },
    {
      name: "difference",
      title: "Difference",
    },
    {
      name: "donor",
      title: "Donor",
    },
    {
      name: "project",
      title: "Project",
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
    },
    {
      name: "percentage",
      title: "Percentage",
    },
    {
      name: "allocatedSalary",
      title: "Allocated Salary",
    },
  ];

  return (
    <div>
      <MonthPicker
        size="default"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      <Select
        size="default"
        placeholder="Search for project!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={globalProjectsData[projectId]?.projectCode}
        onChange={(e) => {
          const id = projectList.find((p) => p.projectCode == e)?.id;
          setProjectId(id);
        }}
      >
        {projectList.map((d) => {
          return <Option value={d.projectCode}>{d.projectCode}</Option>;
        })}
      </Select>
      <Select
        size="default"
        placeholder="Search for Mission!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        value={missions.find((m) => m.id == missionId)?.missionName ?? "All"}
        onChange={(e) => {
          setMissionId(e);
        }}
      >
        {[...missions, { id: "all", missionName: "All" }].map((d) => {
          return <Option value={d.id}>{d.missionName}</Option>;
        })}
      </Select>
      <Select
        placeholder="Filter for Positions!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        mode="multiple"
        showSearch
        value={filteredPosition}
        onChange={(e: any) => {
          setFilteredPosition(e);
        }}
      >
        {positions?.map((d) => {
          return (
            <Option key={d} value={d}>
              {d}
            </Option>
          );
        })}
      </Select>
      <div>
        <Checkbox
          checked={fingerPrintActive}
          onChange={(e) => {
            setFingerPrintActive(e.target.checked);
          }}
        >
          Finger-print Activation
        </Checkbox>

        <Button
          size="large"
          icon="print"
          type="primary"
          onClick={() => printPayroll()}
          style={{ marginBottom: "1%", marginRight: "1%" }}
        >
          <Icon type="print" />
          Payroll
        </Button>
      </div>
      <Paper>
        <DevTable
          data={tableData}
          columns={columns}
          defaultHiddenColumnNames={[]}
          defaultColumnWidths={defaultColumnWidths}
          tableName={`Users-List`}
          selected={false}
          typicalTable={false}
          columnsExport={columns}
          summaryColumn={true}
          totalItems={[
            { columnName: "allocatedSalary", type: "sum" },
            { columnName: "difference", type: "sum" },
            { columnName: "totalSalaryForCurrentMonth", type: "sum" },
          ]}
        />
      </Paper>

      <div id={"multiplePage"} style={{ display: "none" }}>
        {uniqBy(tableData, (u) => u.uid).map((row, index) => (
          <>
            <PaySlipToPrint
              index={index + 1}
              row={row}
              key={index}
              printFnRef={print2CbRef as any}
              month={month}
              days={days}
              paymentOffice={paymentOffice as any}
              paymentMethod={paymentMethod as any}
              exchangeRate={exchangeRate}
            />
            <div className="pagebreak" style={{ pageBreakBefore: "always" }} />
          </>
        ))}
      </div>
    </div>
  );
}
