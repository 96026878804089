import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { Spin, Button, Select, DatePicker, Checkbox, Icon } from "antd";
const { Option } = Select;
import {
  officeRef,
  projectRef,
  allocationRef,
  budgetLinesRef,
  vacationRef,
  deductionRef,
  missionRef,
} from "../../db/collectionsRef";
import { currentUserData } from "../../db/userGlobal";
import { sumBy, uniq, uniqBy } from "lodash";
import moment from "moment";
const { MonthPicker } = DatePicker;
import DevTable from "../../helpers/devex-table";
import { allocation, deduction, users, access, project, mission } from "../../db/interfaces";
import { currentSalary } from "../../db/currentSalary";
import { currentP } from "../../db/currentPosition";
import PayrollToPrint from "./payrollToPrint";
import { globalOfficesData } from "../../global/officeList";
import _ from "lodash";
import { currentContractPercentage } from "../../db/currentContractPercentage";
import { globalUsersData } from "../../global/usersList";

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function PayrollByProject() {
  const [filteredPosition, setFilteredPosition] = useState([] as string[]);
  const [month, setMonth] = useState(moment());
  const [missionId, setMissionId] = useState("all");
  const [fingerPrintActive, setFingerPrintActive] = useState(false);
  const [projectId, setProjectId] = useState("notYet");
  const [defaultColumnWidths] = useState([
    { columnName: "index", width: 150 },
    { columnName: "resourceNO", width: 150 },
    { columnName: "firstName", width: 150 },
    { columnName: "lastName", width: 150 },
    { columnName: "position", width: 150 },
    { columnName: "workingDayInMonth", width: 150 },
    { columnName: "deduction", width: 150 },
    { columnName: "leaveWithoutPay", width: 250 },
    { columnName: "absentDays", width: 150 },
    { columnName: "daysWorked", width: 150 },
    { columnName: "monthlySalary", width: 250 },
    { columnName: "currency", width: 200 },
    { columnName: "numberOfHoursLate", width: 250 },
    { columnName: "hoursLateDeduction", width: 250 },
    { columnName: "totalSalaryForCurrentMonth", width: 250 },
    { columnName: "difference", width: 150 },
    { columnName: "donor", width: 150 },
    { columnName: "project", width: 150 },
    { columnName: "budgetLineId", width: 150 },
    { columnName: "percentage", width: 150 },
    { columnName: "allocatedSalary", width: 200 },
    { columnName: "idaContractPercentage", width: 200 },
    { columnName: "contractSalary", width: 200 },
  ]);

  const [allocationsSN] = useCollection(
    allocationRef.where("projectId", "==", projectId)
    // .where("endDate", ">=", month.endOf("day").toDate())
  );
  const [projectSN] = useCollection(projectRef);
  const [missionSn] = useCollection(missionRef);
  const [budgetLineSN] = useCollection(budgetLinesRef.orderBy("createdAt"));
  const [vacationSN] = useCollection(
    vacationRef
    // .where("vacationStartOn", "<=", month.add(4, "month").endOf("month").toDate())
    // .where("vacationStartOn", ">=", month.subtract(4, "month").startOf("month").toDate())
  );
  const [deductionSN] = useCollection(deductionRef.where("month", "==", month.format("MM-YYYY").toString()));

  const [accessSN] = useCollection(
    db
      .collectionGroup("access")
      .where("date", "<=", month.endOf("month").toDate())
      .where("date", ">=", month.startOf("month").toDate())
  );
  const [delaySN] = useCollection(
    db.collectionGroup("delay").where("month", "==", month.format("MMM.YYYY").toString())
  );

  const print1CbRef = useRef<() => void>();

  const printPayroll = useCallback(() => {
    setTimeout(() => {
      print1CbRef.current();
    }, 1500);
  }, []);

  if (
    !missionSn ||
    !delaySN ||
    !accessSN ||
    !deductionSN ||
    !vacationSN ||
    !budgetLineSN ||
    !allocationsSN ||
    !projectSN ||
    !currentUserData.HRProjectsManagersOffices
  )
    return <Spin />;
  const missions = missionSn.docs.map((m) => {
    return { ...m.data(), id: m.id } as mission;
  });
  const delays = delaySN.docs
    .map((d) => {
      return { ...d.data(), id: d.id, uid: d.data().userUID, min: d.data().min, status: d.data().status };
    })
    .filter((d) => d.status != "disabled");

  const VacationDates = vacationSN.docs
    .filter((vacation) => vacation.data().status != "Rejected" && vacation.data().status != "Canceled")
    .map((d) => {
      return {
        ...d.data(),
        employeeUID: d.data().employeeUID,
        start: d.data().vacationStartOn,
        end: d.data().vacationEndOn,
        duration: d.data().vocationPeriodDays,
        type: d.data().vacationType,
        halfDay: d.data().halfDay,
        day: d.data().halfDay ? 0.5 : 1,
      };
    });

  function daysInMonth(m: any) {
    var count = moment().year(m.year()).month(m.month()).daysInMonth();
    var days = [];
    for (var i = 1; i < count + 1; i++) {
      days.push(moment().year(m.year()).month(m.month()).date(i));
    }
    return days;
  }
  var days = daysInMonth(month);

  const deductionData = deductionSN.docs.map((d) => {
    return { ...d.data(), id: d.id } as deduction;
  });
  const projectList = projectSN?.docs.map((p) => {
    return { id: p.id, projectCode: p?.data()?.projectCode, donor: p?.data().donor } as project;
  });

  // const allocationsUids = allocationsSN?.docs.map((al) => al.data() as allocation);
  const allocationsUids = allocationsSN?.docs
    .filter((d) => {
      const startDate = d.data().startDate.toDate();
      return startDate < month.endOf("month");
    })
    .filter((d) => {
      const endDate = d.data().endDate.toDate();
      return month.startOf("month") < endDate;
    })
    .map((al) => {
      return { ...al.data(), id: al.id } as allocation;
    });

  const users = uniqBy(allocationsUids, (x) => x.uid)
    .filter((al) => globalUsersData[al.uid ?? ""])
    .map((al, index) => {
      const userData = globalUsersData[al.uid ?? ""];
      return {
        ...userData,
        id: userData.id ?? al.uid,
        index: index + 1,
      };
    });

  const userAllocation = users.map((u) => {
    const allocation = allocationsUids.find((a) => a.uid == u.id) as allocation;
    return { ...u, ...allocation };
  });
  const access = accessSN.docs?.map((a) => {
    return { ...a.data(), id: a.id } as access;
  });

  const absentDaysArr = userAllocation.map((u: any) => {
    const absentDays = days
      .map((day) => {
        let absent = false;

        const accessInDay = access
          .filter((a) => a.uid == u.id)
          .filter((a) => {
            return (
              moment(a.date.toDate()).format("DD-MM-YYYY").toString() == moment(day).format("DD-MM-YYYY").toString()
            );
          }).length;

        const vacationsFiltered = VacationDates.filter((v) => v.employeeUID == u.id).filter((vacation: any) => {
          const vacationStartOn = moment(vacation?.vacationStartOn?.toDate()).startOf("day");
          const vacationEndOn = moment(vacation?.vacationEndOn?.toDate()).endOf("day");

          return moment(day).isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
        }).length;

        if (vacationsFiltered == 0 && accessInDay == 0) {
          absent = true;
        }

        return { absent, day };
      })
      .filter((ab) => ab.absent == true).length;

    return { uid: u.id, absentDays };
  });

  const data = userAllocation
    .map((row: any) => {
      const missionId = globalOfficesData[row.office].missionId;
      const position = currentP(row.uid, month) == "" ? row.position : currentP(row.uid, month);

      const allocationStartDate = row.startDate;
      const allocationEndDate = row.endDate;

      const selectedMonth = month;
      const startDate = moment(allocationStartDate.toDate());
      const endDate = moment(allocationEndDate.toDate());

      const selectedMonthDays = _.range(
        startDate.month() === selectedMonth.month() && startDate.year() === selectedMonth.year() ? startDate.date() : 1,
        endDate.month() === selectedMonth.month() && endDate.year() === selectedMonth.year()
          ? endDate.date() + 1
          : moment(startDate).year(selectedMonth.year()).month(selectedMonth.month()).endOf("month").date() + 1
      ).length;

      const monthDays = days.length;
      const workingDayInMonth = selectedMonthDays;

      const deduction = deductionData.find((d) => d.uid == row.uid)?.percentage ?? 0;

      const unpaidVacationMap = days.map((day) => {
        const unpaidLeaveFiltered = VacationDates.filter(
          (v) => v.employeeUID == row.uid && v.type == "Unpaid Leave"
        ).filter((vacation: any) => {
          const vacationStartOn = moment(vacation?.vacationStartOn?.toDate()).startOf("day");
          const vacationEndOn = moment(vacation?.vacationEndOn?.toDate()).endOf("day");
          return moment(day).isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
        });

        const sum = _.sumBy(unpaidLeaveFiltered, "day");
        return sum;
      });
      const leaveWithoutPay = _.sumBy(unpaidVacationMap);

      const absentDays = (fingerPrintActive ? absentDaysArr.find((ab) => ab.uid == row.uid)?.absentDays : 0) as number;
      const daysWorked = workingDayInMonth - absentDays * 2 - leaveWithoutPay;

      const monthlySalary =
        currentSalary(row.uid, month).salary == "" ? row.salary : currentSalary(row.uid, month).salary;

      const idaContractPercentage =
        currentContractPercentage(row.uid, month) == ""
          ? globalUsersData[row.uid]?.idaAllocationPercentage
          : currentContractPercentage(row.uid, month);
      const contractSalary = (monthlySalary * idaContractPercentage) / 100;

      const delaysMin = sumBy(
        delays.filter((d) => d.uid == row.uid),
        (d) => d.min
      );

      const delaysHours = delaysMin >= 120 ? delaysMin / 60 : 0;
      const numberOfHoursLate = delaysHours.toFixed(2);

      const salaryInHour = contractSalary / monthDays / 8;
      const deductionByHour = salaryInHour * (delaysHours * 2);
      const hoursLateDeduction = deductionByHour.toFixed(3);
      ///
      const salaryInDay = contractSalary / monthDays;
      const deductionPercentage = deductionData.find((d) => d.uid == row.uid)?.percentage ?? 0;
      const salaryAfterDeduction =
        salaryInDay * daysWorked - deductionByHour - (deductionPercentage * contractSalary * row.percentage) / 10000;
      const totalSalaryForCurrentMonth = parseFloat(salaryAfterDeduction.toFixed(2));
      ///
      const differenceNumber = contractSalary - salaryAfterDeduction;
      const difference = parseFloat(differenceNumber.toFixed(2));
      ///
      const donor = projectList.find((p) => p.id == projectId)?.donor;
      const project = projectList.find((p) => p.id == projectId)?.projectCode;
      const budgetLineId = budgetLineSN.docs.find((d) => d.id == row.budgetLineId)?.data().budgetLine;
      const percentage = row.percentage + " " + "%";
      const allocatedSalaryNumber = (row.percentage * salaryAfterDeduction) / 100;
      const allocatedSalary = parseFloat(allocatedSalaryNumber.toFixed(2));
      const currency =
        currentSalary(row.uid, month).currency == "" ? row?.currency : currentSalary(row.uid, month).currency;

      return {
        ...row,
        position,
        workingDayInMonth,
        deduction,
        leaveWithoutPay,
        absentDays,
        daysWorked,
        monthlySalary,
        currency,
        numberOfHoursLate,
        hoursLateDeduction,
        totalSalaryForCurrentMonth,
        salaryAfterDeduction,
        difference,
        differenceNumber,
        donor,
        project,
        budgetLineId,
        percentage,
        missionId,
        allocatedSalary,
        allocatedSalaryNumber,
        contractSalary,
        idaContractPercentage,
      };
    })
    .filter((row) => {
      if (missionId == "all") {
        return true;
      } else {
        return row.missionId == missionId;
      }
    });

  const positions = uniq(data.map((d) => d.position));
  const columns = [
    { name: "index", title: "NO" },
    { name: "resourceNO", title: "Resource NO" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    {
      name: "position",
      title: "Position",
    },
    {
      name: "workingDayInMonth",
      title: "Work days in a month",
    },
    {
      name: "deduction",
      title: "Deduction",
    },
    {
      name: "leaveWithoutPay",
      title: "Leave Without Pay",
    },
    {
      name: "absentDays",
      title: "Absent Days",
    },
    {
      name: "daysWorked",
      title: "Days worked",
    },
    {
      name: "monthlySalary",
      title: "Monthly salary",
    },
    {
      name: "currency",
      title: "Currency",
    },
    {
      name: "idaContractPercentage",
      title: "Contract Percentage",
    },
    {
      name: "contractSalary",
      title: "Contract Salary",
    },
    {
      name: "numberOfHoursLate",
      title: "Number of Hours Late",
    },
    {
      name: "hoursLateDeduction",
      title: "Hours Late Deduction",
    },
    {
      name: "totalSalaryForCurrentMonth",
      title: "Total Salary for current month",
    },
    {
      name: "difference",
      title: "Difference",
    },
    {
      name: "donor",
      title: "Donor",
    },
    {
      name: "project",
      title: "Project",
    },
    {
      name: "budgetLineId",
      title: "Budget Line",
    },
    {
      name: "percentage",
      title: "Percentage",
    },
    {
      name: "allocatedSalary",
      title: "Allocated Salary",
    },
  ];

  const tableData = data.filter((d: any) => {
    if (filteredPosition.length == 0) {
      return true;
    } else {
      if (filteredPosition.includes(d.position)) {
        return true;
      } else {
        return false;
      }
    }
  });
  return (
    <div>
      <MonthPicker
        size="default"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      <Select
        size="default"
        placeholder="Search for project!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={projectList.find((p) => p.id == projectId)?.projectCode}
        onChange={(e) => {
          const id = projectList.find((p) => p.projectCode == e)?.id;
          setProjectId(id);
        }}
      >
        {projectList.map((d) => {
          return <Option value={d.projectCode}>{d.projectCode}</Option>;
        })}
      </Select>
      <Select
        size="default"
        placeholder="Search for Mission!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        value={missions.find((m) => m.id == missionId)?.missionName ?? "All"}
        onChange={(e) => {
          setMissionId(e);
        }}
      >
        {[...missions, { id: "all", missionName: "All" }].map((d) => {
          return <Option value={d.id}>{d.missionName}</Option>;
        })}
      </Select>
      <Select
        placeholder="Filter for Positions!"
        style={{ width: "20%", paddingBottom: "1%", paddingRight: "1%" }}
        mode="multiple"
        showSearch
        value={filteredPosition}
        onChange={(e: any) => {
          setFilteredPosition(e);
        }}
      >
        {positions?.map((d) => {
          return (
            <Option key={d} value={d}>
              {d}
            </Option>
          );
        })}
      </Select>
      <div>
        <Checkbox
          checked={fingerPrintActive}
          onChange={(e) => {
            setFingerPrintActive(e.target.checked);
          }}
        >
          Finger-print Activation
        </Checkbox>

        <Button
          size="large"
          icon="print"
          type="primary"
          onClick={() => printPayroll()}
          style={{ marginBottom: "1%", marginRight: "1%" }}
        >
          <Icon type="print" />
          Payroll
        </Button>
      </div>
      <Paper>
        <DevTable
          data={tableData}
          columns={columns}
          defaultHiddenColumnNames={[]}
          defaultColumnWidths={defaultColumnWidths}
          tableName={`Payroll-List`}
          selected={false}
          typicalTable={false}
          columnsExport={columns}
          summaryColumn={true}
          totalItems={[
            { columnName: "allocatedSalary", type: "sum" },
            { columnName: "difference", type: "sum" },
            { columnName: "totalSalaryForCurrentMonth", type: "sum" },
          ]}
        />
      </Paper>
      <div style={{ display: "none" }}>
        <PayrollToPrint
          key={1}
          data={tableData}
          printFnRef={print1CbRef as any}
          location={missions.find((m) => m.id == missionId)?.missionName as string}
          month={month}
          days={days}
        />
      </div>
    </div>
  );
}
