import React, { useCallback, useRef, useState } from "react";
import moment from "moment";
import { DatePicker, Spin, Button, Select, Icon, Checkbox } from "antd";
const { MonthPicker } = DatePicker;
import { useCollection } from "react-firebase-hooks/firestore";
import {
  userRef,
  vacationRef,
  projectRef,
  budgetLinesRef,
  allocationRef,
  officeRef,
  positionRef,
  shiftsRef,
} from "../../db/collectionsRef";

import { allocation, users, vacations } from "../../db/interfaces";
import printJS from "print-js";
import _, { uniq } from "lodash";
import firebase, { auth } from "firebase/app";
const { Option } = Select;
import { currentUserData } from "../../db/userGlobal";

import TimeSheetComponent from "./timeSheetComponent";
export default function TimeSheetByProject() {
  const [activeDelay, setActiveDelay] = useState(true);

  const [month, setMonth] = useState(moment().subtract(1, "months"));
  const [uid, setUid] = useState("");
  const [projectId, setProjectId] = useState("notYet");
  const [vacationSN] = useCollection(
    vacationRef
      .where("vacationStartOn", "<=", month.endOf("month").toDate())
      .where("vacationStartOn", ">=", month.startOf("month").toDate())
  );
  const [projectSN] = useCollection(projectRef);
  const [budgetLinesSN] = useCollection(budgetLinesRef);
  const [allocationsSN, loadingAllocation] = useCollection(allocationRef.where("projectId", "==", projectId));
  const [allocationSN] = useCollection(allocationRef);

  const [usersSN] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);
  const [delaySN] = useCollection(
    firebase.firestore().collectionGroup("delay").where("month", "==", month.format("MMM.YYYY").toString())
  );
  const [shiftSN] = useCollection(shiftsRef.where("month", "==", month.format("MM-YYYY").toString()));

  if (
    !vacationSN ||
    !projectSN ||
    !budgetLinesSN ||
    !allocationsSN ||
    !allocationSN ||
    !usersSN ||
    !delaySN ||
    !officeSN ||
    !shiftSN
  )
    return <Spin />;

  const projectList = projectSN?.docs.map((p) => {
    return { id: p.id, projectCode: p?.data()?.projectCode };
  });

  const allocationsUids = allocationsSN?.docs.map((al) => al.data() as allocation);

  const users = usersSN?.docs
    .filter((d) => currentUserData.fullControlOffices.includes(d.data()?.office))
    .filter((d) => uniq(allocationsUids).filter((i) => i.uid == d.id).length > 0)
    .map((d, index) => {
      return {
        ...d.data(),
        id: d.id,
        index: index + 1,
      };
    });

  const userAllocation = users.map((u: any) => {
    const allocation = allocationsUids.find((a) => a.uid == u.id) as allocation;
    return { uid: u.id, userData: { ...u, ...allocation } };
  });

  const vacationsMap = vacationSN.docs.map((vacation) => {
    return { ...vacation.data(), id: vacation.id };
  });

  const USERS_ID_FULL_NAME = userAllocation
    .filter((e) => currentUserData.fullControlOffices.includes(e?.userData.office))
    .filter((user) => user.userData.status != "disable")
    .map((u) => {
      const fullName =
        u.userData.firstName +
        " " +
        u.userData.lastName +
        " _ " +
        u.userData.arabicFullName +
        " _ " +
        u.userData.resourceNO;
      const uid = u.uid;
      return { uid, fullName };
    });

  const print = async () => {
    await printJS({
      printable: "timeSheet2",
      type: "html",
      targetStyles: ["*"],
      scanStyles: true,
      font_size: "15",
      showModal: true,
    });
  };

  return (
    <div>
      <MonthPicker
        allowClear={false}
        size="default"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      <Select
        showSearch
        placeholder="User"
        style={{ width: "20%", paddingBottom: "1%" }}
        value={[...USERS_ID_FULL_NAME, { fullName: "All", uid: "all" }].find((d) => d.uid == uid)?.fullName}
        onChange={(e: string) => {
          const id = [...USERS_ID_FULL_NAME, { fullName: "All", uid: "all" }].find((d) => d.fullName == e)
            ?.uid as string;
          setUid(id);
        }}
      >
        {[...USERS_ID_FULL_NAME, { fullName: "All", uid: "all" }]?.map((d) => {
          return <Option value={d.fullName}>{d.fullName}</Option>;
        })}
      </Select>

      <Select
        size="default"
        placeholder="Search for project!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={projectList.find((p) => p.id == projectId)?.projectCode}
        onChange={(e) => {
          const id = projectList.find((p) => p.projectCode == e)?.id;
          setProjectId(id);
        }}
      >
        {projectList.map((d) => {
          return <Option value={d.projectCode}>{d.projectCode}</Option>;
        })}
      </Select>
      {currentUserData?.fullControlOffices.length != 0 ? (
        <Checkbox
          style={{ marginLeft: "1%" }}
          checked={activeDelay}
          value={activeDelay}
          onChange={(e) => {
            setActiveDelay(!activeDelay);
          }}
        >
          Active
        </Checkbox>
      ) : null}

      <Button
        style={{ marginLeft: "1%" }}
        type="primary"
        onClick={async () => {
          print();
        }}
        htmlType="submit"
      >
        Print
      </Button>

      <div id="timeSheet2">
        {userAllocation
          .filter((user: any) => user.userData.status != "disable")
          .filter((user) => (uid == "" || uid == "all" ? true : user.uid == uid))
          .map((user) => (
            <div>
              <TimeSheetComponent
                month={month}
                uid={user.uid}
                userData={user.userData as users}
                vacationsMap={vacationsMap as vacations[]}
                projectSN={projectSN}
                budgetLinesSN={budgetLinesSN}
                allocationSN={allocationSN}
                usersSN={usersSN}
                officeSN={officeSN}
                delaySN={delaySN}
                shiftSN={shiftSN}
                activeDelay={activeDelay}
              />
              <div style={{ pageBreakAfter: "always" }} />
            </div>
          ))}
        <br />
      </div>
    </div>
  );
}
