import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Button, Popconfirm } from "antd";

import { orderRef, userRef, meetingRoomList } from "../../db/collectionsRef";
import { Link } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import AddNewRoomModel from "../Meetings_Rooms/AddNewRoomModel";
import { useTranslation } from "react-i18next";
import DevTable from "../../helpers/devex-table";

const columnsExport = [
  { name: "roomName", title: "Room Name" },
  { name: "location", title: "Location" },
  { name: "capacity", title: "Attending Capacity" },
  { name: "contains", title: "Contains" }
];

export default function MeetingsRoomTable() {
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;

  const [modalState, setModalState] = useState(false);

  const [roomsSN, loading2, error2] = useCollection(meetingRoomList.orderBy("createdAt"));

  if (!roomsSN) return <Spin />;

  const data = roomsSN.docs.map(d => {
    return { ...d.data(), id: d.id };
  });

  const columns = [
    { name: "roomName", title: "Room Name" },
    { name: "location", title: "Location" },
    { name: "capacity", title: "Attending Capacity" },
    { name: "contains", title: "Contains" },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              if (uid == admin) {
                meetingRoomList.doc(row.id).delete();
              } else {
                alert("You are not OMAR AL AZZI!");
              }
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      }
    }
  ];

  return (
    <div>
      <AddNewRoomModel modalState={modalState} setModalState={setModalState} />
      <Button style={{ margin: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewRoom")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Rooms"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
