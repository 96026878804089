import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col,
  Switch,
  TimePicker,
  Checkbox,
} from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
const { Option } = Select;
import {
  notificationsRef,
  userRef,
  orderRef,
  meetingRoomList,
  projectsRef,
  officeRef,
  missionRef,
} from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { mission, office } from "../../db/interfaces";
import { globalMissionsData } from "../../global/missionList";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  // admins: Yup.string().required("*")
  // location: Yup.string().required("*"),
  // capacity: Yup.number().required("*")
  //   contains: Yup.string().required("*")
});

function AddManagePermissions(props: Props) {
  const { t } = useTranslation();

  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const newId = officeRef.doc().id;
  const uid = auth().currentUser!.uid;
  const [usersSN] = useCollection(userRef);
  const [missionsSN] = useCollection(missionRef);

  const USERS_ID_FULL_NAME = usersSN?.docs.map((u) => {
    const fullName = u.data().firstName + " " + u.data().lastName;
    const uid = u.id;
    const resourceNO = u.data()?.resourceNO;
    return { uid, fullName, resourceNO };
  });
  const MISSIONS = missionsSN?.docs.map((u) => {
    return { ...u.data(), id: u.id } as mission;
  });

  const formik = useFormik({
    initialValues: {
      officeName: "",
      location: "",
      admins: [],
      HRs: [],
      HRProjectsManagers: [],
      viewers: [],
      approvalCEO: false,
      workStartTime: moment(),
      workEndTime: moment(),
      region: "",
      contactPersonUID: "",
      missionId: "",
      restrictedFeatures: [],
      shiftVacationPerHours: 8,
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      console.log(values.restrictedFeatures);
      const promise = officeRef.doc(newId).set(
        {
          officeName: values.officeName,
          location: values.location,
          admins: values.admins,
          HRs: values.HRs,
          HRProjectsManagers: values.HRProjectsManagers,
          viewers: values.viewers,

          approvalCEO: values.approvalCEO,
          workStartTime: values.workStartTime.toDate() as any,
          workEndTime: values.workEndTime.toDate() as any,
          region: values.region,
          contactPersonUID: values.contactPersonUID,
          missionId: values.missionId,
          cacheMissionName: globalMissionsData[values.missionId].missionName,
          restrictedFeatures: values.restrictedFeatures ?? [],
          shiftVacationPerHours: values.shiftVacationPerHours,
        },
        { merge: true }
      );
      promise
        .then(() => {
          values.admins.forEach(
            async (uid) =>
              await userRef.doc(uid).update({
                adminOffices: firebase.firestore.FieldValue.arrayUnion(newId),
              })
          );
        })
        .then(() => {
          values.HRs.forEach(
            async (uid) =>
              await userRef.doc(uid).update({
                HROffices: firebase.firestore.FieldValue.arrayUnion(newId),
              })
          );
        })
        .then(() => {
          values.HRProjectsManagers.forEach(
            async (uid) =>
              await userRef.doc(uid).update({
                HRProjectsManagersOffices: firebase.firestore.FieldValue.arrayUnion(newId),
              })
          );
        })
        .then(() => {
          values.viewers.forEach(
            async (uid) =>
              await userRef.doc(uid).update({
                viewersOffices: firebase.firestore.FieldValue.arrayUnion(newId),
              })
          );
        });
      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });

  const enterLoading = () => {
    setLoading(true);
  };
  const format = "hh:mm:ss a";

  return (
    <Drawer
      title={t("general.officePermissions")}
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit} layout="horizontal">
        <Form.Item label={t("general.officeName")}>
          <Input
            value={formik.values.officeName}
            onChange={(e) => formik.setFieldValue("officeName", e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("general.location")}>
          <Input value={formik.values.location} onChange={(e) => formik.setFieldValue("location", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.admins")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            mode="multiple"
            showSearch
            value={formik.values.admins}
            onChange={(e: any) => {
              formik.setFieldValue("admins", e);
              console.log({ e });
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.uid}>
                  {`${d.fullName}-${d.resourceNO}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.HRs")} labelAlign="left" labelCol={{ span: "100", offset: "500" }}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            mode="multiple"
            showSearch
            value={formik.values.HRs}
            onChange={(e: any) => {
              formik.setFieldValue("HRs", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.uid}>
                  {`${d.fullName}-${d.resourceNO}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.HRProjectsManagers")} labelAlign="left" labelCol={{ span: "100", offset: "500" }}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            mode="multiple"
            showSearch
            value={formik.values.HRProjectsManagers}
            onChange={(e: any) => {
              formik.setFieldValue("HRProjectsManagers", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.uid}>
                  {`${d.fullName}-${d.resourceNO}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.viewers")} labelAlign="left" labelCol={{ span: "100", offset: "500" }}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            mode="multiple"
            showSearch
            value={formik.values.viewers}
            onChange={(e: any) => {
              formik.setFieldValue("viewers", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.uid}>
                  {`${d.fullName}-${d.resourceNO}`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.startWork")}>
          <TimePicker
            use12Hours
            format={format}
            value={formik.values.workStartTime}
            onChange={(e) => formik.setFieldValue("workStartTime", e)}
          />
        </Form.Item>
        <Form.Item label={t("general.endWork")}>
          <TimePicker
            use12Hours
            format={format}
            value={formik.values.workEndTime}
            onChange={(e) => formik.setFieldValue("workEndTime", e)}
          />
        </Form.Item>
        <Form.Item labelAlign="right" label={t("general.approvalCEO")}>
          <Switch checked={formik.values.approvalCEO} onChange={(e) => formik.setFieldValue("approvalCEO", e)} />
        </Form.Item>
        <Form.Item label={t("general.contactPerson")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.contactPersonUID}
            onChange={(e: any) => {
              formik.setFieldValue("contactPersonUID", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return (
                <Option key={d.uid} value={d.uid}>
                  {d.fullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.shiftVacationPerHours")}>
          <InputNumber
            value={formik.values.shiftVacationPerHours}
            onChange={(e) => formik.setFieldValue("shiftVacationPerHours", e)}
          />
        </Form.Item>
        <Form.Item label={t("general.region")}>
          <Input value={formik.values.region} onChange={(e) => formik.setFieldValue("region", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.mission")}>
          <Select
            showSearch
            value={globalMissionsData[formik.values.missionId]?.missionName}
            onChange={(e: any) => {
              formik.setFieldValue("missionId", e);
            }}
          >
            {MISSIONS?.map((d) => {
              return (
                <Option key={d.id} value={d.id}>
                  {d.missionName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("Restricted Features")}>
          <Checkbox.Group
            disabled={false}
            onChange={(d) => formik.setFieldValue("restrictedFeatures", d)}
            value={formik.values.restrictedFeatures}
          >
            <Row>
              <Checkbox value="vacations">Vacations</Checkbox>
              <Checkbox value="overtime">Overtime</Checkbox>
              <Checkbox value="delays">Delays</Checkbox>
              <Checkbox value="cars">Cars</Checkbox>
              <Checkbox value="tasks">Tasks</Checkbox>
              <Checkbox value="workPermit">Work Permit</Checkbox>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddManagePermissions;
