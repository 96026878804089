import { API, OutputData } from "@editorjs/editorjs";
import { Button, message, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { sheet } from "../../db/interfaces";
import { sheetsRef } from "../../db/collectionsRef";
import { auth } from "firebase";
import Editor from "../Editor/Editor";

export default function RightBar() {
  const uid = auth().currentUser!.uid;
  const [instance, setInstance] = useState({} as API);
  const [timeoutId, setTimeoutId] = useState(null as any);

  const [sheetSN, loading] = useDocument(sheetsRef.doc(uid));
  const sheetData = sheetSN?.data() as sheet;

  const onSaveSheetData = async (outputData: OutputData) => {
    sheetsRef
      .doc(uid)
      .set({ uid: uid, outputData })
      .catch((e) => {});
  };

  const handleSave = async () => {
    const outputData: any = await instance?.saver?.save();
    await onSaveSheetData(outputData).then(() => message.success("Auto Saved!"));
  };

  useEffect(() => {
    clearTimeout(timeoutId);

    const id = setTimeout(() => {
      handleSave();
    }, 5000);

    setTimeoutId(id);

    return () => clearTimeout(timeoutId);
  }, [instance]);

  return (
    <div style={{ margin: "40px" }}>
      {loading ? (
        <>loading..</>
      ) : (
        <>
          <Editor holder="dashboard" data={sheetData?.outputData} setInstance={setInstance} minHeight={400} />
        </>
      )}
    </div>
  );
}
