export const colorsRoom = [
  "#36cfc9",
  "#ff7875",
  "#73d13d",
  "#ffa940",
  "#40a9ff",
  "#ff85c0",
  "#b37feb",
  "#ffbb96",
  "#8c8c8c",
  "#ff85c0",
  "#b37feb",
  "#ffbb96",
  "#8c8c8c"
  "#ff85c0",
  "#b37feb",
  "#ffbb96",
  "#8c8c8c"
  
];

export const colorsAttendee = ["#73d13d", "red"];
