import { Button, Col, Icon, message, Popconfirm, Spin, Tabs, Upload } from "antd";

import moment from "moment";
import "./signature.css";
import React, { useState } from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import SignaturePad from "react-signature-canvas";
import firebase, { auth } from "firebase/app";
import { v4 as uuidv4 } from "uuid";
import { userRef } from "../../db/collectionsRef";
import { users } from "../../db/interfaces";
// import { usersRef } from 'common/db/collectionsRef'
// import { User } from 'common/db/interfaces'

export default function SignatureTab() {
  const [trimmedDataURL, setTrimmedDataURL] = useState("");
  const [sigPad, setSigPad] = useState({});
  const [fileList, setFileList] = useState([]);
  const [saveButton, setSaveButton] = useState(false);
  const uid = auth().currentUser?.uid as string;


  const storage = firebase.storage().ref(uid).child(`${uuidv4()}-signature.png`);
  const [userSnapshot, loading] = useDocument(userRef.doc(uid ?? "loadingUser"));

  if (loading) {
    return <Spin />;
  }

  const userData = userSnapshot?.data() as users;

  const onTirm = () => {
    setTrimmedDataURL(sigPad?.getTrimmedCanvas().toDataURL("image/png"));
    setSaveButton(true);
  };

  const onSave = async () => {
    storage.putString(trimmedDataURL, "data_url").then(async (d) => {
      const downloadURL = await d.ref.getDownloadURL();
      await userRef
        .doc(uid)
        .update({
          signatureURL: downloadURL,
        })
        .then(() => message.success("Your signature has been saved well!"));
    });
  };
  return (
    <div>
      
        <div>
          <b>You can design your signature (removed background) by visit</b>{" "}
          <a href="https://www.remove.bg/upload"  target="_blank">here</a>
        </div>
        <div>
          <b>Then Upload your removed background signature by</b>{" "}
          <div>
            <Upload
              data={(data: any) => {
                storage.put(data).then(async (d) => {
                  const downloadURL = await d.ref.getDownloadURL();
                  await userRef
                    ?.doc(uid)
                    .update({
                      signatureURL: downloadURL,
                    })
                    .then(() => message.success("Your signature has been saved well!"));
                });
              }}
              listType="picture-card"
              defaultFileList={[{uid:"1", url: userData.signatureURL, status: "done", name: "signature" }]}
              className="upload-list-inline"
            >
             <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
            </Upload>
            <br />
          </div>
        </div>
    

      <Col span={30}>
        <div className={"containerSig"}>
          <b>You can draw you signature using the pad below:</b>

          <div className={"sigContainer"}>
            <SignaturePad canvasProps={{ className: "sigPad" }} ref={(ref: any) => setSigPad(ref)} />
          </div>
          <div>
            <Button
              className={"buttons"}
              onClick={() => {
                sigPad.clear();
                setSaveButton(false);
                setTrimmedDataURL("");
              }}
            >
              Clear
            </Button>
            <Button className={"buttons"} onClick={onTirm}>
              Trim
            </Button>
            {saveButton ? (
              <Button type="primary" className={"buttons"} onClick={onSave}>
                Save
              </Button>
            ) : null}
          </div>

          <div style={{ margin: "2%" }}>
            {trimmedDataURL ? <img className={"sigImage"} src={trimmedDataURL} /> : null}
            <img className={"sigImage"} src={userData?.signatureURL ?? ""} />
          </div>
        </div>
      </Col>
    </div>
  );
}
