import React from "react";
import { department } from "../../db/interfaces";
import { Empty, List } from "antd";

interface Props {
  departmentData: department;
}
export default function SOPs(props: Props) {
  const { departmentData } = props;
  return (
    <div style={{ paddingTop: "2%" }}>
    {departmentData?.SOPs?.length > 0 ? (
      <div>
        <List
          itemLayout="horizontal"
          bordered
          dataSource={departmentData?.SOPs}
          renderItem={(file: any) => (
            <List.Item>
              <div>
                <a href={file.url} target="_blank" rel="noopener noreferrer">
                  {file.name}
                </a>
                <p style={{ margin: "0px" }}>{file.attachedDescription}</p>
              </div>
            </List.Item>
          )}
        />
      </div>
    ) : (
      <Empty description={"No Attachments"} />
    )}
  </div>
  );
}
