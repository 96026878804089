import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Button, Popconfirm, Upload, message, DatePicker } from "antd";
import saveAs from "file-saver";
import {
  orderRef,
  userRef,
  meetingRoomList,
  projectsRef,
  officeRef,
  importShiftRef,
  usersShiftRef
} from "../../db/collectionsRef";
import { Link } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import AddNewRoomModel from "../Meetings_Rooms/AddNewRoomModel";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ReadExcelFile from "./importFromExcel";
import { importShift, usersShift } from "../../db/interfaces";
import DevTable from "../../helpers/devex-table";
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const onSave = (workbook: any) => {
  workbook.xlsx.writeBuffer().then((buffer: any) => {
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Meetings_Rooms_List.xlsx");
  });
};
const columnsExport = [
  { name: "importedAt", title: "Imported At" },
  { name: "importedBy", title: "Imported By" },
  { name: "fileURL", title: "URL" }
];

export default function ImportTable() {
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const [office, setOffice] = useState({});
  const [modalState, setModalState] = useState(false);
  const [modalStatePermissions, setModalStatePermissions] = useState(false);
  const [uploadingStatus, setUploadingStatus] = useState(false);
  const [dateStatus, setDateStatus] = useState(false);

  const [fileData, setFileData] = useState([]);
  const [planMonth, setPlanMonth] = useState(moment());

  const [usersSN] = useCollection(userRef);
  const [usersShiftSN] = useCollection(usersShiftRef);

  const [importSN, loading2, error2] = useCollection(importShiftRef);

  if (!importSN || !usersSN || !usersShiftSN) return <Spin />;

  const data = importSN.docs.map(d => {
    return { ...d.data(), id: d.id };
  }) as importShift[];

  const usersShiftData = usersShiftSN.docs.map(d => {
    return { ...d.data(), id: d.id };
  }) as usersShift[];

  const columns = [
    {
      name: "importedAt",
      title: "Imported At",
      getCellValue: (row: any) => moment(row.importedAt.toDate()).format("DD.MM.YYYY")
    },

    {
      name: "monthPlan",
      title: "Plan for Month",
      getCellValue: (row: any) => moment(row.monthPlan.toDate()).format("MMMM, YYYY")
    },

    {
      name: "importedBy",
      title: "Imported By",

      getCellValue: (row: any) =>
        `${usersSN.docs?.find(d => d.id == row.importedBy)?.data()?.firstName} ${
          usersSN.docs?.find(d => d.id == row.importedBy)?.data()?.lastName
        }`
    },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: importShift) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={async () => {
              await importShiftRef
                .doc(row.id)
                .delete()
                .then(() =>
                  usersShiftData
                    .filter(shift => shift.importId == row.importId)
                    .map(shift => {
                      usersShiftRef.doc(shift.id).delete();
                    })
                );
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      }
    }
  ];

  const onConfirm = async () => {
    const batch = firebase.firestore().batch();
    const importId = importShiftRef.doc();
    await batch.set(importId, {
      importId: importId.id,
      importedAt: moment().toDate(),
      importedBy: uid,
      monthPlan: planMonth.toDate()
    });

    await Promise.all(
      fileData.map((doc: any) => {
        batch.set(usersShiftRef.doc(), {
          ...doc,
          importId: importId.id
        });
      })
    );

    batch
      .commit()
      .then(x => {
        console.log("success");
      })
      .catch(e => {
        console.warn(e);
      });
  };
  return (
    <div>
      <div>
        <Upload
          accept=".xlsx"
          name="file"
          multiple={false}
          fileList={[]}
          // maxCount={1}
          beforeUpload={async (data: any) => {
            setUploadingStatus(await (await ReadExcelFile(data)).status);
            setFileData((await ReadExcelFile(data)).data);
            if (await (await ReadExcelFile(data)).status) {
              message.success("Imported Successfully");
            } else {
              message.error(`Row index ${(await ReadExcelFile(data)).rowIndex}`);
            }
          }}
        >
          <Button style={{ marginBottom: "2%" }} type={"primary"}>
            {t("general.importNewFile")}
          </Button>
        </Upload>
        {uploadingStatus ? (
          <MonthPicker
            style={{ marginRight: "1%", marginBottom: "1%" }}
            onChange={(d: any) => {
              const ifExist = data.filter(date => {
                return (
                  moment(date.monthPlan.toDate())
                    .format("MMMM-YYYY")
                    .toString() ==
                  moment(d)
                    .format("MMMM-YYYY")
                    .toString()
                );
              });

              if (ifExist.length == 0) {
                setPlanMonth(d);
                setDateStatus(true);
              } else {
                message.error("Already a plan uploaded for the same month");
                setDateStatus(false);
              }
            }}
            placeholder="Plan Month"
          />
        ) : null}

        {dateStatus ? (
          <Popconfirm
            title="Emin misiniz?"
            onConfirm={() => {
              onConfirm();
            }}
            okText="Evet"
            cancelText="Hayır"
          >
            <Button type="primary">Confirm</Button>
          </Popconfirm>
        ) : null}
      </div>

      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Imports"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
