import CheckList from "@editorjs/checklist";
import Code from "@editorjs/code";
import Delimiter from "@editorjs/delimiter";
import { OutputData } from "@editorjs/editorjs";
import Embed from "@editorjs/embed";
import Image from "@editorjs/image";
import InlineCode from "@editorjs/inline-code";
import LinkTool from "@editorjs/link";
import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import Quote from "@editorjs/quote";
import Raw from "@editorjs/raw";
import SimpleImage from "@editorjs/simple-image";
import Table from "@editorjs/table";
import Warning from "@editorjs/warning";

export const EDITOR_JS_TOOLS = {
  embed: Embed,
  // table: Table,
  list: List,
  warning: Warning,
  code: Code,
  linkTool: LinkTool,
  // image: Image,
  raw: Raw,
  quote: Quote,
  marker: Marker,
  checklist: CheckList,
  delimiter: Delimiter,
  inlineCode: InlineCode,
  // simpleImage: SimpleImage
};

export const defaultData = (props: any) => {
  return {
    blocks: [
      {
        type: "header",
        data: {
          text: `Hello ${props.firstName},`,
          level: 2,
        },
      },
      {
        type: "paragraph",
        data: {
          text: "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text.",
        },
      },

      {
        type: "delimiter",
        data: {},
      },
      {
        type: "header",
        data: {
          text: "Daily Tasks:",
          level: 2,
        },
      },
      {
        type: "checklist",
        data: {
          items: [
            { text: "First Task", checked: true },
            { text: "Second Task", checked: true },
            { text: "Third Task", checked: false },
          ],
        },
      },

      {
        type: "paragraph",
        data: {
          text: "Welcome to our new <code class='inline-code'>note-taking and task management</code> feature on AIU! This tool is designed to enhance your <mark class='cdx-marker'>productivity</mark> by providing a seamless and intuitive way to organize your <mark class='cdx-marker'><code class='inline-code'><b><i>thoughts, tasks, and important information</i></b></code></mark>. With its rich text editor capabilities, you can easily format your notes, <b>create to-do lists,</b> and add various types of content such as links, checklists. The editor's block-based structure allows you to customize and rearrange your content effortlessly, ensuring that your notes are not only functional but also visually appealing. <code class='inline-code'>Whether you're jotting down quick ideas or managing complex projects</code>, our editor is here to help you stay <mark class='cdx-marker'>organized and efficient</mark>. <b><mark class='cdx-marker'>Give it a try</mark></b> and experience a smarter way to keep track of everything that <code class='inline-code'>matters to you</code>!",
        },
      },
      {
        type: "delimiter",
        data: {},
      },
    ],
  };
};

export function dataToHTML(data: OutputData) {
  return data?.blocks
    ?.map((block: any) => {
      switch (block.type) {
        case "header":
          return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
        case "paragraph":
          return `<p>${block.data.text}</p>`;
        case "list":
          const tag = block.data.style === "ordered" ? "ol" : "ul";
          const items = block.data.items.map((item: any) => `<li>${item}</li>`).join("");
          return `<${tag}>${items}</${tag}>`;
        case "delimiter":
          return "<hr />";
        case "image":
          const { url } = block.data.file;
          const { caption, withBorder, stretched, withBackground } = block.data;
          return `<img src="${url}" alt="${caption}" style="${withBorder ? "border:1px solid;" : ""} ${
            stretched ? "width:100%;" : ""
          } ${withBackground ? "background-color:grey;" : ""}" />`;
        case "checklist":
          const checklistItems = block.data.items
            .map(
              (item: any) =>
                `<li style="list-style: none;"><input type="checkbox" ${item.checked ? "checked" : ""}/> ${
                  item.text
                }</li>`
            )
            .join("");
          return `<ul>${checklistItems}</ul>`;
        case "code":
          return `<pre><code>${block.data.code}</code></pre>`;
        case "embed":
          return `<div><iframe src="${block.data.embed}" frameborder="0" allowfullscreen></iframe><p>${block.data.caption}</p></div>`;
        case "inlineCode":
          return `<code>${block.data.text}</code>`;
        case "linkTool":
          return `<a href="${block.data.link}" target="_blank">${block.data.link}</a><p>${block.data.meta.title}</p>`;
        case "marker":
          return `<mark>${block.data.text}</mark>`;
        case "quote":
          return `<blockquote><p>${block.data.text}</p><cite>${block.data.caption}</cite></blockquote>`;
        case "raw":
          return `<div>${block.data.html}</div>`;
        case "simpleImage":
          return `<img src="${block.data.url}" alt="${block.data.caption}" />`;
        case "table":
          const tableRows = block.data.content
            .map((row: any) => {
              const rowContent = row.map((cell: any) => `<td>${cell}</td>`).join("");
              return `<tr>${rowContent}</tr>`;
            })
            .join("");
          return `<table>${tableRows}</table>`;
        case "warning":
          return `<div class="warning"><strong>${block.data.title}</strong><p>${block.data.message}</p></div>`;
        default:
          return "";
      }
    })
    .join("");
}
