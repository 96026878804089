import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase, { auth } from "firebase/app";
import { Spin, Button, Select } from "antd";
import {
  Grid,
  Table,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
  SearchPanel,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  ExportPanel,
  VirtualTable,
  TableSummaryRow,
} from "@devexpress/dx-react-grid-material-ui";
import {
  FilteringState,
  SearchState,
  PagingState,
  IntegratedPaging,
  IntegratedFiltering,
  DataTypeProvider,
  GroupingState,
  IntegratedGrouping,
  SummaryState,
  IntegratedSummary,
  SortingState,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import { GridExporter } from "@devexpress/dx-react-grid-export";
import saveAs from "file-saver";
import UsersInformation from "../models/usersInformation";
import DateRange from "@material-ui/icons/DateRange";
import { officeRef, userRef } from "../../db/collectionsRef";
import DelayModel from "../models/delayModel";
import moment from "moment";
import { useRouteMatch } from "react-router-dom";
import { delay, users } from "../../db/interfaces";
import { currentUserData } from "../../db/userGlobal";
const { Option } = Select;

const db = firebase.firestore();
export const usersRef = db.collection("users");

const onSave = (workbook: any) => {
  workbook.xlsx.writeBuffer().then((buffer: any) => {
    saveAs(new Blob([buffer], { type: "application/octet-stream" }), "Users-Dashboard-delay.xlsx");
  });
};
const FilterIcon = ({ type, ...restProps }: any) => {
  if (type === "month") return <DateRange {...restProps} />;
  return <TableFilterRow.Icon type={type} {...restProps} />;
};

export default function FingerPrintRecord() {
  const userUID = useRouteMatch<{ id: string }>().params.id;
  const exporterRef = useRef(null);

  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [pageSizes] = useState([10, 15, 20, 40, 100, 200, 400]);
  const [defaultHiddenColumnNames] = useState([]);
  const [totalSummaryItems] = useState([{ columnName: "min", type: "sum" }]);
  const [value] = useCollection(usersRef.doc(userUID).collection("access"));
  const [officeSN] = useCollection(officeRef);

  const [userSN] = useDocument(usersRef.doc(userUID));

  if (value == undefined || !userSN || !officeSN) return <Spin />;

  const userData = userSN.data() as users;
  const fullName = userData.firstName + " " + userData.lastName;
  const data: any = value.docs.map((d, index) => {
    return {
      ...d.data(),
      id: d.id,
      index: index + 1,
    };
  });
  const columnsExport = [
    { name: "index", title: "NO" },
    {
      name: "date",
      title: "Date",
      getCellValue: (r: any) => moment(r.date?.toDate()).format("DD.MM.YYYY  hh:mm:ss  a"),
    },
    {
      name: "operationDate",
      title: "Operation Date",
      getCellValue: (r: any) => moment(r.operationDate?.toDate()).format("DD.MM.YYYY  hh:mm:ss  a"),
    },
    { name: "notes", title: "Notes" },
    { name: "officeId", title: "OfficeId" },
    { name: "uid", title: "User Id" },
  ];
  const columns = [
    { name: "index", title: "NO" },
    {
      name: "date",
      title: "Date",
      getCellValue: (r: any) => moment(r.date?.toDate()).format("DD.MM.YYYY  hh:mm:ss  a"),
    },
    {
      name: "operationDate",
      title: "Operation Date",
      getCellValue: (r: any) => moment(r.operationDate?.toDate()).format("DD.MM.YYYY hh:mm:ss  a"),
    },
    { name: "notes", title: "Notes" },
    {
      name: "officeId",
      title: "OfficeId",
      getCellValue: (r: any) => officeSN?.docs?.find((d) => d.id == r.officeId)?.data().officeName,
    },
    {
      name: "action",
      title: "Delete",
      getCellValue: (r) => {
        return currentUserData.fullControlOffices.includes(userData.office) == true ? (
          <Button
            type="danger"
            onClick={() => {
              usersRef.doc(userUID).collection("access").doc(r.id).delete();
            }}
          >
            Delete
          </Button>
        ) : null;
      },
    },
  ];
  return (
    <div>
      <div
        style={{
          padding: "1%",
          background: "#fff",
          marginBottom: "1%",
          border: "thin",
          borderRadius: "10px",
          boxShadow: "2px 2px 2px 0px #c2c2c2",
        }}
      >
        <h2>{fullName}</h2>
      </div>
      <Paper>
        <Grid rows={data} columns={columns}>
          <SortingState />
          <IntegratedSorting />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <SearchState defaultValue="" />
          <FilteringState defaultFilters={[]} />
          <IntegratedFiltering />
          <DragDropProvider />
          <GroupingState />
          <SummaryState totalItems={totalSummaryItems} />

          <IntegratedGrouping />
          <IntegratedSummary />

          <Table />

          {/* <VirtualTable /> */}

          <TableHeaderRow showSortingControls />
          <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />

          <TableGroupRow />

          <Toolbar />
          <ColumnChooser />
          <GroupingPanel />

          <SearchPanel />

          <TableFilterRow
            showFilterSelector
            iconComponent={FilterIcon}
            // messages={{ month: "Month equals" }}
          />
          <TableSummaryRow />

          <PagingPanel pageSizes={pageSizes} />
          <ExportPanel startExport={startExport} />
        </Grid>
        <GridExporter ref={exporterRef} rows={data} columns={columnsExport} onSave={onSave} />
      </Paper>
      {/* <Button
        onClick={() => {
          value.forEach(d => {
            console.log(d.id);
            userRef.doc(d.id).update({
              vacationAnnualScore: 0,
              vacationSickScore: 0
            });
          });
        }}
      >
        add v
      </Button> */}
    </div>
  );
}
