import React, { useState, useEffect } from "react";
import firebase, { auth } from "firebase/app";
import { Button, Drawer, Form, message, Select, Input, Upload, Icon, Row, Spin } from "antd";
const { Option } = Select;
import { useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { departmentPositionsRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { departmentPosition } from "../../db/interfaces";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState } from "draft-js";
import { globalUsersData, USERS_ID_FULL_NAME } from "../../global/usersList";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  departmentId: string;
}
const projectSchema = Yup.object().shape({
  title: Yup.string().required("Position title is required"),
});

function AddNewDepartmentPosition(props: Props) {
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const { t } = useTranslation();
  const { modalState, setModalState, departmentId } = props;
  const [loading, setLoading] = useState(false);
  const [uploadJobDescriptionFiles, setUploadJobDescriptionFiles] = useState([] as any);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());

  const newId = departmentPositionsRef.doc().id;
  const uid = auth().currentUser!.uid;
  const [departmentPositionSN] = useCollection(departmentPositionsRef);

  const formik = useFormik({
    initialValues: {
      uid: null,
      title: "",
      referencePositionId: null,
      departmentId: "",
      overview: "",
      attached_job_description: [],
      positionTasks: [],
      orientational_video: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const promise = uploadJobDescription();
      promise.then((files) => {
        departmentPositionsRef
          .doc(newId)
          .set(
            {
              uid: values.uid ?? null,
              title: values.title,
              referencePositionId: values.referencePositionId ?? null,
              departmentId: departmentId,
              overview: html,
              attached_job_description: files ?? [],
              positionTasks: [],
              orientational_video: "",
              createdAt: new Date(),
              by: uid,
            },
            { merge: true }
          )
          .then(() => {
            setModalState(false);
            setLoading(false);
            message.success("New Position is added successfully!");
            formik.resetForm();
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error writing document: ", error);
            message.error("Failed");
          });
      });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const positions = departmentPositionSN?.docs?.map((pos) => {
    const positionData = pos.data() as departmentPosition;
    return { ...positionData, id: pos.id };
  });

  const handleFileUpload = async (files: any) => {
    try {
      const downloadURLs = await Promise.all(
        files.map(async (file: any) => {
          const fileRef = storageRef.child(`departments/${departmentId}/positions/${newId}/${file.originFileObj.name}`);
          await fileRef.put(file.originFileObj);
          return {
            name: file.originFileObj.name,
            url: await fileRef.getDownloadURL(),
          };
        })
      );
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      return [];
    }
  };

  const uploadJobDescription = async () => {
    setLoading(true);
    const promise = handleFileUpload(uploadJobDescriptionFiles);
    return promise;
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;
  return (
    <Drawer
      title={t("general.addNewPosition")}
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Spin spinning={loading} size="large" indicator={antIcon}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label={t("general.positionTitle")}>
            <Input value={formik.values.title} onChange={(e) => formik.setFieldValue("title", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.referencePosition")}>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              allowClear
              size="default"
              placeholder="Parent Position"
              showSearch
              value={formik.values.referencePositionId}
              onChange={(e) => {
                formik.setFieldValue("referencePositionId", e);
              }}
            >
              {positions?.map((d) => {
                return (
                  <Option value={d.id}>{`${d.title} - ${
                    d.uid != null ? globalUsersData[d.uid ?? ""]?.fullName : "Vacant"
                  }`}</Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label={t("general.overview")}>
            <DraftText
              onValueChange={() => {}}
              onMentionChange={() => {}}
              html={""}
              editorState={editorState}
              setEditorState={setEditorState}
            />
          </Form.Item>

          <Form.Item label={t("general.assignTo")}>
            <Select
              filterOption={(inputValue, option) => {
                return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
              }}
              allowClear
              placeholder="Assign to Employee"
              showSearch
              value={formik.values.uid}
              onChange={(e) => {
                formik.setFieldValue("uid", e);
              }}
            >
              {USERS_ID_FULL_NAME?.map((d) => {
                return <Option value={d.uid}>{d.fullName}</Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Job Description File">
            <Row gutter={12}>
              {/* <Col span={6}> */}
              <Upload
                name="attached_job_description"
                beforeUpload={() => {
                  return false;
                }}
                onChange={(e) => {
                  setUploadJobDescriptionFiles(e.fileList);
                }}
                fileList={uploadJobDescriptionFiles}
              >
                <Button>
                  <Icon type="upload" /> Click to upload
                </Button>
              </Upload>
            </Row>
          </Form.Item>

          <div>
            <Button
              className="btn blue lighten-1 z-depth-0"
              style={{ marginTop: "2%" }}
              // loading={loading}
              type="primary"
              htmlType="submit"
              // disabled={!formik.isValid}
            >
              {t("general.create")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default AddNewDepartmentPosition;
