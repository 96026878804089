import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col,
} from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { notificationsRef, userRef, orderRef, meetingRoomList, projectsRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  projectCode: Yup.string().required("*"),
  // location: Yup.string().required("*"),
  // capacity: Yup.number().required("*")
  //   contains: Yup.string().required("*")
});

function AddNewProject(props: Props) {
  const { t } = useTranslation();

  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const newId = projectsRef.doc().id;
  const uid = auth().currentUser!.uid;

  const formik = useFormik({
    initialValues: {
      projectName: "",
      projectCode: "",
      projectStart: moment(),
      projectEnd: moment(),
      donor: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = projectsRef.doc(newId).set(
        {
          projectName: values.projectName,
          projectCode: values.projectCode,
          projectStart: values.projectStart.toDate() as any,
          projectEnd: values.projectEnd.toDate() as any,
          donor: values.donor ?? "",
          createdAt: new Date(),
        },
        { merge: true }
      );
      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.addNewProject")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.projectName")}>
          <Input
            value={formik.values.projectName}
            onChange={(e) => formik.setFieldValue("projectName", e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("general.projectCode")}>
          <Input
            value={formik.values.projectCode}
            onChange={(e) => formik.setFieldValue("projectCode", e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("general.donor")}>
          <Input value={formik.values.donor} onChange={(e) => formik.setFieldValue("donor", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.projectStart")}>
          <DatePicker
            value={formik.values.projectStart}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("projectStart", e)}
          />{" "}
        </Form.Item>
        <Form.Item label={t("general.projectEnd")}>
          <DatePicker
            value={formik.values.projectEnd}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("projectEnd", e)}
          />{" "}
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewProject;
