import firebase from "firebase/app";

export async function firebaseUploadAndGetUrl(
  value: File | Blob,
  src: "docx" | "RFC",
  employeeName: string,
  empFullName: string,
  overtimeId: string
): Promise<string> {
  const storage = firebase.storage();

  let url = "";

  const filename = `${empFullName}_${overtimeId}_RFC.docx`;

  await storage
    .ref(employeeName)
    .child(filename)
    .put(value);

  const overtimeStorageRef = firebase.storage().ref(employeeName);

  url = await overtimeStorageRef.child(filename).getDownloadURL();
  const U = storage.refFromURL(url);

  U.getDownloadURL().then(url => {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
  return url;
}
