import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Button, Popconfirm, Select, DatePicker } from "antd";

import { officeRef, workOfficeRef } from "../../db/collectionsRef";

import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";

import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import AddNewWorkOffice from "./addNewWorkOfficeDialog";
import EditWorkOffice from "./editWorkOfficeDialog";
import { currentUserData } from "../../db/userGlobal";

export default function WorkOfficeTable() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [workOfficeId, setWorkOfficeId] = useState("");

  const [workOfficeSN] = useCollection(workOfficeRef);
  const [officeSN] = useCollection(officeRef);

  if (!workOfficeSN || !officeSN) return <Spin />;

  const data = workOfficeSN.docs
    .map((d) => {
      return { ...d.data(), id: d.id };
    })
    .filter((d: any) => currentUserData.HRProjectsManagersOffices.includes(globalUsersData[d.uid]?.office));

  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },

    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office]?.officeName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY"),
    // },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              workOfficeRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },

    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setWorkOfficeId(row.id);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },

    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office]?.officeName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY"),
    // },
  ];

  return (
    <div>
      <AddNewWorkOffice modalState={modalState} setModalState={setModalState} />
      {modalStateEdit ? (
        <EditWorkOffice modalState={modalStateEdit} setModalState={setModalStateEdit} workOfficeId={workOfficeId} />
      ) : null}
      {/* <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewWorkOffice")}
      </Button> */}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="deduction"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
