import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col,
  Modal
} from "antd";
const { TextArea } = Input;
import moment from "moment";

import * as Moment from "moment";
import { extendMoment } from "moment-range";
const moments = extendMoment(Moment);

import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { notificationsRef, userRef, orderRef, meetingRoomList, calendar } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import DraftText from "./TextEditor/Draft";
import { filter, uniq } from "lodash";
import { Calendar } from "../../db/interfaces";
import { RRule, RRuleSet, rrulestr } from "rrule";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  docId: string;
  changedFields: any;
}

const notificationEdit = async (calendarId: string) => {
  const notificationSN = notificationsRef.where("orderId", "==", calendarId).get();
  await notificationSN.then(d => {
    if (d.empty) {
    } else {
      d.forEach(doc =>
        notificationsRef.doc(doc.id).update({
          read: false,
          content: "The meeting you that have been invited to has been updated"
        })
      );
    }
  });
};
const testOverlapsRoomAndDates = (docId: string, changedFields: any) => {
  return calendar
    .doc(docId)
    .get()
    .then(e => {
      const rRule = changedFields.rRule == undefined ? e.data()?.rRule : changedFields.rRule;

      const newStartDate =
        changedFields.startDate == undefined ? e.data()?.startDate.toDate() : changedFields.startDate;
      const newEndDate = changedFields.endDate == undefined ? e.data()?.endDate.toDate() : changedFields.endDate;

      const yesterday = moment(e.data()?.startDate.toDate())
        .subtract(2, "hours")
        .toDate();
      const tomorrow = moment(e.data()?.startDate.toDate())
        .add(2, "hours")
        .toDate();

      return calendar
        .where("startDate", ">", yesterday)
        .where("startDate", "<", tomorrow)
        .where("roomId", "==", changedFields.roomId)
        .get()
        .then(async d => {
          let overlap = true;
          await d.forEach(doc => {
            console.log("Doc room test Update!");
            const oldStartDate = doc.data()?.startDate.toDate();
            const oldEndDate = doc.data()?.endDate.toDate();
            const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
            const dateNew: [Date, Date] = [newStartDate, newEndDate];
            const rangeOld = moments.range(dateOld);
            const rangeNew = moments.range(dateNew);
            if (rangeOld.overlaps(rangeNew)) {
              overlap = false;
              console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
            }
          });
          await d.forEach(d => {
            if (d.id == docId) {
            } else {
              console.log("Doc date change Update!");
              const oldStartDate = d.data()?.startDate.toDate();
              const oldEndDate = d.data()?.endDate.toDate();
              const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
              const dateNew: [Date, Date] = [newStartDate, newEndDate];
              const rangeOld = moments.range(dateOld);
              const rangeNew = moments.range(dateNew);
              if (rangeOld.overlaps(rangeNew)) {
                overlap = false;
                console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
              }
            }
          });
          console.log(overlap);
          if (overlap) {
            calendar
              .doc(docId)
              .update({ ...changedFields, rRule: rRule })
              .then(() => message.success("Meeting and Date has been Updated Successfully!"));
          } else {
            alert("You can not edit the Meeting room and Date!!");
          }
          return overlap;
        });
    });
};
const testOverlapsRoom = (docId: string, changedFields: any) => {
  return calendar
    .doc(docId)
    .get()
    .then(e => {
      const rRule = changedFields.rRule == undefined ? e.data()?.rRule : changedFields.rRule;
      const newStartDate = e.data()?.startDate.toDate();
      const newEndDate = e.data()?.endDate.toDate();

      const yesterday = moment(e.data()?.startDate.toDate())
        .subtract(12, "hours")
        .toDate();
      const tomorrow = moment(e.data()?.startDate.toDate())
        .add(12, "hours")
        .toDate();

      return calendar
        .where("startDate", ">", yesterday)
        .where("startDate", "<", tomorrow)
        .where("roomId", "==", changedFields.roomId)
        .get()
        .then(d => {
          let overlap = true;
          d.forEach(doc => {
            console.log("Doc Test Update!");
            const oldStartDate = doc.data()?.startDate.toDate();
            const oldEndDate = doc.data()?.endDate.toDate();
            const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
            const dateNew: [Date, Date] = [newStartDate, newEndDate];
            const rangeOld = moments.range(dateOld);
            const rangeNew = moments.range(dateNew);
            if (rangeOld.overlaps(rangeNew)) {
              overlap = false;
              console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
            }
          });
          if (overlap) {
            calendar
              .doc(docId)
              .update({ ...changedFields, rRule: rRule })
              .then(() => message.success("Updated Successfully!!!"));
          } else {
            alert("You can not edit the Meeting room!!");
          }
          return overlap;
        });
    });
};
const testOverlapsDate = (docId: string, changedFields: any) => {
  return calendar
    .doc(docId)
    .get()
    .then(e => {
      const rRule = changedFields.rRule == undefined ? e.data()?.rRule : changedFields.rRule;
      const newStartDate =
        changedFields.startDate == undefined ? e.data()?.startDate.toDate() : changedFields.startDate;
      const newEndDate = changedFields.endDate == undefined ? e.data()?.endDate.toDate() : changedFields.endDate;
      const roomId = e.data()?.roomId;

      const yesterday = moment(e.data()?.startDate.toDate())
        .subtract(12, "hours")
        .toDate();
      const tomorrow = moment(e.data()?.startDate.toDate())
        .add(12, "hours")
        .toDate();

      return calendar
        .where("startDate", ">", yesterday)
        .where("startDate", "<", tomorrow)
        .where("roomId", "==", roomId)
        .get()
        .then(doc => {
          let overlap = true;
          doc.forEach(d => {
            if (d.id == docId) {
              console.log(d.id, docId);
            } else {
              console.log("Doc Test date change Update!");
              const oldStartDate = d.data()?.startDate.toDate();
              const oldEndDate = d.data()?.endDate.toDate();
              const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
              const dateNew: [Date, Date] = [newStartDate, newEndDate];
              const rangeOld = moments.range(dateOld);
              const rangeNew = moments.range(dateNew);
              if (rangeOld.overlaps(rangeNew)) {
                overlap = false;
                console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
              }
            }
          });
          if (overlap) {
            calendar
              .doc(docId)
              .update({ ...changedFields, rRule: rRule })
              .then(() => message.success("Updated Successfully!!!"));
          } else {
            alert("You can not edit the Date!!");
          }
          return overlap;
        });
    });
};

function EditDialog(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState, docId, changedFields } = props;
  const history = useHistory();
  const newId = meetingRoomList.doc().id;
  const uid = auth().currentUser!.uid;
  const [CalendarSN] = useDocument(calendar.doc(docId));
  const [CalendarSNCollection] = useCollection(calendar);

  if (!CalendarSN || !CalendarSNCollection) {
    return <Spin />;
  }

  const { rRule, endDate, isOriginal, originalId, isOnlyOneAppointment, startDate } = CalendarSN?.data() as Calendar;
  const calendarSNObj = (CalendarSNCollection === undefined ? [] : CalendarSNCollection.docs).map(doc => {
    return {
      originalId: doc.data().originalId,
      startDate: doc.data().startDate,
      id: doc.id,
      rRule: doc.data().rRule
    };
  });
  const options = RRule.parseString(rRule);
  options.dtstart = new Date(startDate.toDate());
  const rule = new RRule(options);

  const ruleDatesArray =
    rRule == undefined
      ? [{ startDate: startDate.toDate(), endDate: endDate.toDate() }]
      : rule
          .all()
          .slice(0, rule.all().length < 50 ? rule.all().length : 50)
          .map(d => {
            const day = d.getDate();
            const month = d.getMonth();
            const year = d.getFullYear();
            const startDateM: Date = new Date(startDate.toDate());
            const endDateM: Date = new Date(endDate.toDate());
            startDateM.setDate(day);
            startDateM.setMonth(month);
            startDateM.setFullYear(year);
            endDateM.setDate(day);
            endDateM.setMonth(month);
            endDateM.setFullYear(year);
            return { startDateM, endDateM };
          });
  if (isOnlyOneAppointment == false) {
    const editAll = async () => {
      console.log("originalId:", originalId, "DocId:", docId);
      const filteredCalender = calendarSNObj.filter(e => e.originalId == originalId);
      if (changedFields.startDate || changedFields.endDate) {
        alert("You can't change date for All the Series, If you want to change date Please Click on (Only this)!");
      } else if (changedFields.roomId && !changedFields.startDate && !changedFields.endDate) {
        const yesterday = moment(startDate.toDate())
          .subtract(1, "days")
          .toDate();
        const tomorrow = moment(startDate.toDate())
          .add(180, "days")
          .toDate();
        calendar
          .where("startDate", ">", yesterday)
          // .where("startDate", "<", tomorrow)
          .where("roomId", "==", changedFields.roomId)
          .get()
          .then(async d => {
            if (d.empty) {
              console.log("empty.....");
              await filteredCalender.forEach(async d => await testOverlapsRoom(d.id, changedFields));
              await filteredCalender.forEach(d => notificationEdit(d.id));
            } else {
              console.log("Documents Size: ", d.size);
              let overlap = true;
              d.forEach(e => {
                console.log("doc");
                const oldStartDate: any = e.data().startDate.toDate();
                const oldEndDate: any = e.data().endDate.toDate();
                ruleDatesArray.forEach((newObj: any) => {
                  console.log("newObj");
                  const newStartDate: any = newObj.startDateM;
                  const newEndDate: any = newObj.endDateM;
                  const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
                  const dateNew: [Date, Date] = [newStartDate, newEndDate];
                  const rangeOld = moments.range(dateOld);
                  const rangeNew = moments.range(dateNew);
                  if (rangeOld.overlaps(rangeNew)) {
                    overlap = false;
                    console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
                  }
                });
              });
              console.log(overlap);
              if (overlap) {
                await filteredCalender.forEach(async d => await testOverlapsRoom(d.id, changedFields));
                await filteredCalender.forEach(d => notificationEdit(d.id));
              } else {
                alert("Overlaps!!!, this time has been already reserved!");
              }
            }
          });
        setModalState(false);
      } else {
        await filteredCalender.forEach(async d => {
          const rRule = changedFields.rRule == undefined ? d.rRule : changedFields.rRule;
          console.log(d);
          await calendar
            .doc(d.id)
            .update({ ...changedFields, rRule: rRule })
            .then(() => {
              message.success("Successfully Updated");
              setModalState(false);
              notificationEdit(d.id);
            });
        });
      }
    };
    const editThisAndFollowing = async () => {
      console.log("originalId:", originalId, "DocId:", docId);
      const filteredArray = filter(calendarSNObj, e => e.startDate.toDate() >= startDate.toDate());
      const filteredByOriginalId = filter(filteredArray, e => e.originalId === originalId);
      let loop = 0;

      if (changedFields.startDate || changedFields.endDate) {
        alert("You can't change date for All the Series, If you want to change date Please Click on (Only this)!");
      } else if (changedFields.roomId && !changedFields.startDate && !changedFields.endDate) {
        const yesterday = moment(startDate.toDate())
          .subtract(1, "days")
          .toDate();
        const tomorrow = moment(startDate.toDate())
          .add(180, "days")
          .toDate();
        calendar
          .where("startDate", ">", yesterday)
          // .where("startDate", "<", tomorrow)
          .where("roomId", "==", changedFields.roomId)
          .get()
          .then(async d => {
            if (d.empty) {
              console.log("empty.....");
              await filteredByOriginalId.forEach(async d => await testOverlapsRoom(d.id, changedFields));
              await filteredByOriginalId.forEach(async (d, index) => {
                // const isOriginalBoolean = docId == d.id ? true : false;//for develop
                await calendar.doc(d.id).update({
                  originalId: docId,
                  isOriginal: false,
                  isOnlyOneAppointment: false
                });
                await notificationEdit(d.id);
              });
            } else {
              console.log("Documents Size: ", d.size);
              let overlap = true;
              d.forEach(e => {
                console.log("doc");
                const oldStartDate: any = e.data().startDate.toDate();
                const oldEndDate: any = e.data().endDate.toDate();
                ruleDatesArray.forEach((newObj: any) => {
                  console.log("newObj");
                  const newStartDate: any = newObj.startDateM;
                  const newEndDate: any = newObj.endDateM;
                  const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
                  const dateNew: [Date, Date] = [newStartDate, newEndDate];
                  const rangeOld = moments.range(dateOld);
                  const rangeNew = moments.range(dateNew);
                  if (rangeOld.overlaps(rangeNew)) {
                    overlap = false;
                    console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
                  }
                });
              });
              console.log(overlap);
              if (overlap) {
                console.log("Not empty.....");
                await filteredByOriginalId.forEach(async d => await testOverlapsRoom(d.id, changedFields));
                await filteredByOriginalId.forEach(async (d, index) => {
                  // const isOriginalBoolean = docId == d.id ? true : false; // for develop
                  await calendar.doc(d.id).update({
                    originalId: docId,
                    isOriginal: false,
                    isOnlyOneAppointment: false
                  });
                  await notificationEdit(d.id);
                });
              } else {
                alert("Overlaps!!!, this time has been already reserved!");
              }
            }
          });
        setModalState(false);
      } else {
        await filteredByOriginalId.forEach(async d => {
          const rRule = changedFields.rRule == undefined ? d.rRule : changedFields.rRule;
          await calendar
            .doc(d.id)
            .update({ ...changedFields, rRule: rRule })
            .then(() => {
              message.success("Successfully Updated");
              setModalState(false);
            });
        });
        await filteredByOriginalId.forEach(async (d, index) => {
          // const isOriginalBoolean = docId == d.id ? true : false; // for develop
          await calendar.doc(d.id).update({
            originalId: docId,
            isOriginal: false,
            isOnlyOneAppointment: false
          });
          await notificationEdit(d.id);
        });
      }
    };
    const editOnlyOne = async () => {
      console.log("originalId:", originalId, "DocId:", docId);
      if (changedFields.roomId && (changedFields.startDate || changedFields.endDate)) {
        const rRuleOrg = originalId == docId ? rRule : "";
        const test = testOverlapsRoomAndDates(docId, changedFields);
        test.then(async e => {
          if (e) {
            setModalState(false);
            await calendar.doc(docId).update({
              originalId: docId,
              isOriginal: true,
              isOnlyOneAppointment: originalId == docId ? false : true,
              rRule: rRuleOrg
            });
            await notificationEdit(docId);
          }
          setModalState(false);
        });
      } else if (changedFields.roomId && !changedFields.startDate && !changedFields.endDate) {
        const rRuleOrg = originalId == docId ? rRule : "";

        const test = testOverlapsRoom(docId, changedFields);
        test.then(async e => {
          if (e) {
            setModalState(false);
            await calendar.doc(docId).update({
              originalId: docId,
              isOriginal: true,
              isOnlyOneAppointment: originalId == docId ? false : true,
              rRule: rRuleOrg
            });
            await notificationEdit(docId);
          }
          setModalState(false);
        });
      } else if ((changedFields.endDate || changedFields.startDate) && !changedFields.roomId) {
        const rRuleOrg = originalId == docId ? rRule : "";

        const test = testOverlapsDate(docId, changedFields);
        test.then(async e => {
          if (e) {
            setModalState(false);
            await calendar.doc(docId).update({
              originalId: docId,
              isOriginal: true,
              isOnlyOneAppointment: originalId == docId ? false : true,
              rRule: rRuleOrg
            });
            await notificationEdit(docId);
          }
          setModalState(false);
        });
      } else {
        const rRuleOrg = originalId == docId ? rRule : "";
        await calendar
          .doc(docId)
          .update({ ...changedFields })
          .then(() => {
            message.success("Successfully Updated");
          })
          .then(async () => {
            setModalState(false);

            await calendar.doc(docId).update({
              originalId: docId,
              isOriginal: true,
              isOnlyOneAppointment: originalId == docId ? false : true,
              rRule: rRuleOrg
            });
            await notificationEdit(docId);
          });
      }
    };
    return (
      <Modal
        width={500}
        visible={modalState}
        onCancel={() => setModalState(false)}
        closable={true}
        footer={
          <div
            style={{
              textAlign: "right"
            }}
          >
            <Button type="danger" onClick={() => setModalState(false)} style={{ marginRight: 2, marginLeft: 0 }}>
              {t("general.close")}
            </Button>

            {changedFields.startDate || changedFields.endDate ? null : (
              <Button onClick={editThisAndFollowing} style={{ marginRight: 2, marginLeft: 0 }}>
                {t("general.thisAndFollowing")}
              </Button>
            )}
            {changedFields.startDate || changedFields.endDate ? null : (
              <Button onClick={editAll} style={{ marginRight: 2, marginLeft: 0 }}>
                {t("general.editAll")}{" "}
              </Button>
            )}
            <Button onClick={editOnlyOne} type="primary" style={{ marginLeft: 0 }}>
              {t("general.editOnlyThis")}
            </Button>
          </div>
        }
      >
        <div>{t("general.editSeries")}</div>
      </Modal>
    );
  } else {
    const editOnlyOne = async () => {
      if (changedFields.roomId && (changedFields.startDate || changedFields.endDate)) {
        testOverlapsRoomAndDates(docId, changedFields);
      } else if (changedFields.roomId && !changedFields.startDate && !changedFields.endDate) {
        testOverlapsRoom(docId, changedFields);
      } else if ((changedFields.endDate || changedFields.startDate) && !changedFields.roomId) {
        testOverlapsDate(docId, changedFields);
      } else {
        const rRuleN = changedFields.rRule == undefined ? rRule : changedFields.rRule;
        if (changedFields.rRule) {
          console.log(docId);
          await calendar
            .doc(docId)
            .update({ ...changedFields, rRule: rRuleN })
            .then(() => {
              message.success("Successfully Updated");
            });
        } else {
          console.log(docId);
          await calendar
            .doc(docId)
            .update({ ...changedFields, rRule: rRuleN })
            .then(() => {
              message.success("Successfully Updated");
            });
        }
      }

      setModalState(false);
      await notificationEdit(docId);
    };

    return (
      <Modal
        //   title={t("general.addMOM")}
        okText={t("general.yes")}
        cancelText={t("general.no")}
        width={500}
        visible={modalState}
        onCancel={() => setModalState(false)}
        onOk={editOnlyOne}
        closable={true}
      >
        <div>{t("general.editAppointment")}</div>
      </Modal>
    );
  }
}

export default EditDialog;

//============ Archive
// const newStartDate = changedFields.startDate == undefined ? startDateOriginal : changedFields.startDate;
// const newEndDate = changedFields.endDate == undefined ? endDateOriginal : changedFields.endDate;

// const rRulesArray = rRuleReturnDates(rRule, newStartDate, newEndDate);
// console.log("==>", rRulesArray);
// const yesterday = moment(startDate.toDate())
//   .subtract(1, "days")
//   .toDate();
// const tomorrow = moment(startDate.toDate())
//   .add(180, "days")
//   .toDate();
// calendar
//   .where("startDate", ">", yesterday)
//   .where("startDate", "<", tomorrow)
//   .where("roomId", "==", roomId)
//   .get()
//   .then(async d => {
//     console.log("Documents Size: ", d.size);
//     let overlap = true;

//     d.forEach(doc => {
//       const IsFromOrg = filteredCalender.map(x => x.id).includes(doc.id);
//       console.log(IsFromOrg);
//       if (IsFromOrg) {
//         console.log("Test With Original!");
//       } else {
//         console.log("Test With Not Original!");
//         const oldStartDate: any = doc.data().startDate.toDate();
//         const oldEndDate: any = doc.data().endDate.toDate();
//         rRulesArray.forEach((newObj: any) => {
//           console.log("newObj");
//           const newStartDate: any = newObj.startDateM;
//           const newEndDate: any = newObj.endDateM;
//           const dateOld: [Date, Date] = [oldStartDate, oldEndDate];
//           const dateNew: [Date, Date] = [newStartDate, newEndDate];
//           const rangeOld = moments.range(dateOld);
//           const rangeNew = moments.range(dateNew);
//           if (rangeOld.overlaps(rangeNew)) {
//             overlap = false;
//             console.log("overlaps-------", "dateOld:", dateOld, "dateNew:", dateNew);
//           }
//         });
//       }
//     });
//     console.log("overlap: ", overlap);

//     if (overlap) {
//       console.log(rRulesArray[0]);
//       // await filteredCalender.forEach(async d => await testOverlapsDate(d.id, changedFields));
//       // await filteredCalender.forEach(d => notificationEdit(d.id));
//     } else {
//       alert("Overlaps!!!, this time has been already reserved!");
//     }
//   });
// setModalState(false);
