import React, { useState, useEffect } from "react";
import { auth } from "firebase/app";
import { Button, DatePicker, Drawer, Form, message, Select } from "antd";
import moment from "moment";
const { Option } = Select;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { userRef, supervisorsRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { supervisor } from "../../db/interfaces";

interface Props {
  supervisorId: string;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function EditSupervisor(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const uid = auth().currentUser!.uid;
  const [usersSN] = useCollection(userRef);
  const [supervisorSN] = useDocument(supervisorsRef.doc(props.supervisorId));
  const supervisorData = supervisorSN?.data() as supervisor;

  const USERS_ID_FULL_NAME = usersSN?.docs.map((u) => {
    const fullName = u.data().firstName + " " + u.data().lastName + " _ " + u.data().arabicFullName+ " _ " + u.data().resourceNO;
    const uid = u.id;
    return { uid, fullName };
  });

  const formik = useFormik({
    initialValues: {
      uid: "",
      office: "",
      startDate: moment(),
      // endDate: moment(),
      supervisorUid: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = supervisorsRef.doc(props.supervisorId).update({
        supervisorUid: values.supervisorUid,
        startDate: values.startDate.toDate() as any,
        // endDate: values.endDate.toDate() as any,
        createdAt: new Date(),
        by: uid,
      })
      .then(() => {
        userRef.doc(values.uid).update({
          managerUID: values.supervisorUid,
        });
      });
      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (supervisorSN && loaded) {
      formik.setValues({
        uid: supervisorData.uid,
        supervisorUid: supervisorData.supervisorUid,
        startDate: moment(supervisorData.startDate.toDate()),
        // endDate: moment(supervisorData.endDate.toDate()),
      } as any);
      setLoaded(false);

      // setItemLineCode(procurementPlan.itemLineCode)
    }
  }, [supervisorSN, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.editSupervisor")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.supervisor")}>
          <Select
           filterOption={(inputValue, option) => {
            return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
          }}
            placeholder="Select Supervisor"
            showSearch
            value={formik.values.supervisorUid}
            onChange={(e) => {
              formik.setFieldValue("supervisorUid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>
        {/* <Form.Item label={t("general.endDate")}>
          <DatePicker
            value={formik.values.endDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("endDate", e)}
          />{" "}
        </Form.Item> */}

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditSupervisor;
