import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase from "firebase/app";
import { Spin, Button } from "antd";

import PermissionsDialog from "./permissionsDialog";
import { users } from "../../db/interfaces";
import DevTable from "../../helpers/devex-table";
import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function table() {
  const [modalState, setModalState] = useState(false);
  const [UID, setUID] = useState("");

  const [columnsExport] = useState([
    { name: "id", title: "UID" },

    { name: "resourceNO", title: "ID" },
    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "office", title: "Office", getCellValue: (row) => globalOfficesData[row.office ?? ""]?.officeName },
    { name: "managerUID", title: "Supervisor", getCellValue: (row) => globalUsersData[row.managerUID ?? ""]?.fullName },
    { name: "email", title: "Email" },
    { name: "admin", title: "Admin", getCellValue: (e) => `Admin:${e.admin}, HR:${e.adminHR}, Law:${e.adminLaw}` },
  ]);
  const [columns] = useState([
    { name: "id", title: "UID" },
    { name: "resourceNO", title: "ID" },

    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "office", title: "Office", getCellValue: (row) => globalOfficesData[row.office ?? ""]?.officeName },
    { name: "managerUID", title: "Supervisor", getCellValue: (row) => globalUsersData[row.managerUID ?? ""]?.fullName },
    { name: "email", title: "Email" },
    { name: "admin", title: "Admin", getCellValue: (e) => `Admin:${e.admin}, HR:${e.adminHR}, Law:${e.adminLaw}` },
    {
      name: "action",
      title: "Action",
      getCellValue: (r) => {
        return r.admin == true ? (
          <Button
            onClick={() => {
              usersRef.doc(r.id).update({
                admin: false,
              });
            }}
          >
            Not Admin
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              usersRef.doc(r.id).update({
                admin: true,
              });
            }}
          >
            Admin
          </Button>
        );
      },
    },
    {
      name: "actionHR",
      title: "HR Action",
      getCellValue: (r) => {
        return r.adminHR == true ? (
          <Button
            onClick={() => {
              usersRef.doc(r.id).update({
                adminHR: false,
              });
            }}
          >
            Not Admin HR
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              usersRef.doc(r.id).update({
                adminHR: true,
              });
            }}
          >
            Admin HR
          </Button>
        );
      },
    },
    {
      name: "actionLaw",
      title: "Lawyer Action",
      getCellValue: (r) => {
        return r.adminLaw == true ? (
          <Button
            onClick={() => {
              usersRef.doc(r.id).update({
                adminLaw: false,
              });
            }}
          >
            Not Admin Law.
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              usersRef.doc(r.id).update({
                adminLaw: true,
              });
            }}
          >
            Admin Law
          </Button>
        );
      },
    },
    {
      name: "actionCEO",
      title: "CEO",
      getCellValue: (r) => {
        return r.CEO == true ? (
          <Button
            onClick={() => {
              usersRef.doc(r.id).update({
                CEO: false,
              });
            }}
          >
            Not CEO
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              usersRef.doc(r.id).update({
                CEO: true,
              });
            }}
          >
            CEO
          </Button>
        );
      },
    },
    {
      name: "managePermissions",
      title: "Permissions",
      getCellValue: (r: any) => {
        return (
          <Button
            onClick={() => {
              setUID(r.id);
              setModalState(true);
            }}
          >
            Manage
          </Button>
        );
      },
    },
  ]);

  const [value, loading, error] = useCollection(usersRef);

  if (value == undefined || loading) return <Spin />;

  const data = value.docs.map((d) => {
    return {
      ...d.data(),
      id: d.id,
    };
  });

  return (
    <div>
      {modalState ? <PermissionsDialog UID={UID} setModalState={setModalState} modalState={modalState} /> : null}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Users"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
