import React, { useState } from "react";
const { TabPane } = Tabs;
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Button, Popconfirm, Select, DatePicker, Tabs, Icon, Row, Col } from "antd";

import { officeRef, departmentsRef } from "../../db/collectionsRef";

import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";

import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";

import { globalDepartmentsData } from "../../global/departmentList";
import { Container } from "@material-ui/core";
import ReactPlayer from "react-player";
import { department } from "../../db/interfaces";
import { useHistory } from "react-router-dom";

interface Props {
  departmentData: department;
  subDepartmentsData: department[];
}
export default function Overview(props: Props) {
  const { departmentData, subDepartmentsData } = props;
  const history = useHistory();



  const columns = [
    {
      name: "departmentName",
      title: "Department",
    },

    {
      name: "shortcut",
      title: "Shortcut",
    },
    {
      name: "HOD",
      title: "Head of Department",
      getCellValue: (row: department) => globalUsersData[row.HOD ?? ""]?.fullName,
    },
  ];

  const onClickSubDepartment = (element: any) => {
    history.push(`/organization-structure-details/${element.row.id}`);
  };
  return (
    <div style={{ paddingTop: "3%" }}>
      <div style={{ paddingTop: "1%", paddingBottom: "1%" }}>
        <h3>Overview:</h3>
        <div style={{ margin: "2%" }} dangerouslySetInnerHTML={{ __html: departmentData?.overview }} />

      </div>
      <div style={{ paddingTop: "2%", paddingBottom: "2%" }}>
        <h3>Orientation Videos:</h3>
        {departmentData?.orientational_video?.map((file: any) => (
          <Row
            gutter={20}
            style={{
              marginLeft: "0px",
              marginRight: "0px",
              marginTop: "2%",
              borderRadius: "5px",
              background: "white",
            }}
          >
            <p>{file.attachedDescription}</p>

            <ReactPlayer controls={true} url={file.url} />
          </Row>
        ))}
      </div>
      <div style={{ paddingTop: "2%", paddingBottom: "2%" }}>
        <h3>Sub Departments:</h3>
        <DevTable
          typicalTable={true}
          data={subDepartmentsData}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="sub-departments"
          selected={false}
          columnsExport={[]}
          rowClick={onClickSubDepartment}
        />
      </div>
    </div>
  );
}
