import React, { useState, useEffect } from "react";
import { auth } from "firebase/app";
import {
  Button,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  List,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
const { Option } = Select;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userRef, departmentsRef, departmentPositionsRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { department, departmentPosition } from "../../db/interfaces";
import { useRouteMatch } from "react-router-dom";
import { Container } from "@material-ui/core";
import firebase from "firebase/app";
import AddNewDepartmentPosition from "./addNewDepartmentPositionDialog";
import PositionsTable from "./departmentPositionsTable";
import ReactPlayer from "react-player";
import { admins } from "../../db/admin";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { stateFromHTML } from "draft-js-import-html";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { USERS_ID_FULL_NAME } from "../../global/usersList";
const projectSchema = Yup.object().shape({});

function DepartmentDashboard() {
  const storage = firebase.storage();
  const storageRef = storage.ref();

  const departmentId = useRouteMatch<{ id: string }>().params.id;

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [templateFileList, setTemplateFileList] = useState([] as any);
  const [policiesFileList, setPoliciesFileList] = useState([] as any);
  const [SOPsFileList, setSOPsFileList] = useState([] as any);
  const [videoFileList, setVideoFileList] = useState([] as any);

  const [templateAttachedDescription, setTemplateAttachedDescription] = useState("");
  const [policyAttachedDescription, setPolicyAttachedDescription] = useState("");
  const [SOPAttachedDescription, setSOPAttachedDescription] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());
  const [newPositionModalState, setNewPositionModalState] = useState(false);

  const uid = auth().currentUser!.uid;
  const [usersSN] = useCollection(userRef);
  const [departmentsSN] = useCollection(departmentsRef);

  const [departmentSN] = useDocument(departmentsRef.doc(departmentId));

  const [departmentPositionsSN] = useCollection(departmentPositionsRef.where("departmentId", "==", departmentId));

  const departmentData = departmentSN?.data() as department;

  const departmentPositions = departmentPositionsSN?.docs?.map((position) => {
    return { ...position?.data(), id: position.id } as departmentPosition;
  });


  const formik = useFormik({
    initialValues: {
      departmentName: "",
      referenceDepartment: null,
      HOD: null,
      overview: "",
      templatesFiles: [],
      templates: [],
      policiesFiles: [],
      policies: [],
      SOPsFiles: [],
      SOPs: [],
      orientational_video: [],
      orientational_video_files: [],
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const promise = departmentsRef.doc(departmentId).update({
        departmentName: values.departmentName,
        overview: html,
        referenceDepartment: values.referenceDepartment ?? null,
        HOD: values.HOD ?? null,
        createdAt: new Date(),
        by: uid,
      });
      promise.then(async () => {
        setLoading(false);
        message.success("Updated Successfully!");
      });
      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (departmentSN && loaded) {
      formik.setValues({
        departmentName: departmentData?.departmentName,
        overview: departmentData?.overview,
        referenceDepartment: departmentData?.referenceDepartment,
        HOD: departmentData?.HOD,
        templates: departmentData?.templates ?? [],
        policies: departmentData?.policies ?? [],
        SOPs: departmentData?.SOPs ?? [],
        orientational_video: departmentData?.orientational_video ?? [],
      } as any);
      setLoaded(false);
      setEditorState(EditorState.createWithContent(stateFromHTML(departmentData?.overview)));
    }
  }, [departmentSN, formik, loaded]);

  const departments = departmentsSN?.docs?.map((dep) => {
    const departmentData = dep.data() as department;
    return { ...departmentData, id: dep.id };
  });

  const handleFileUpload = async (files: any, attachedDescription: string, attachmentFolder: string) => {
    try {
      const downloadURLs = await Promise.all(
        files.map(async (file: any) => {
          const fileRef = storageRef.child(
            `departments/${departmentId}/${attachmentFolder}/${file.originFileObj.name}`
          );
          await fileRef.put(file.originFileObj);
          return {
            name: file.originFileObj.name,
            url: await fileRef.getDownloadURL(),
            attachedDescription: attachedDescription ?? "",
          };
        })
      );
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      return [];
    }
  };

  const uploadTemplates = async () => {
    setLoading(true);
    const promise = handleFileUpload(formik.values.templatesFiles, templateAttachedDescription, "templates");
    promise.then((newTemplateFiles) => {
      const allTemplates = [...formik.values.templates, ...newTemplateFiles];
      formik.setFieldValue("templates", allTemplates);
      departmentsRef.doc(departmentId).update({
        templates: allTemplates,
      });
    });
    promise.then(() => {
      setTemplateAttachedDescription("");
      setTemplateFileList([]);
      message.success("Files are uploaded successfully!");
      setLoading(false);
    });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const uploadPolicies = async () => {
    setLoading(true);
    const promise = handleFileUpload(formik.values.policiesFiles, policyAttachedDescription, "policies");
    promise.then((newPolicyFiles) => {
      const allPolicies = [...formik.values.policies, ...newPolicyFiles];
      formik.setFieldValue("policies", allPolicies);
      departmentsRef.doc(departmentId).update({
        policies: allPolicies,
      });
    });
    promise.then(() => {
      setPolicyAttachedDescription("");
      setPoliciesFileList([]);
      message.success("Files are uploaded successfully!");
      setLoading(false);
    });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const uploadSOPs = async () => {
    setLoading(true);
    const promise = handleFileUpload(formik.values.SOPsFiles, SOPAttachedDescription, "SOPs");
    promise.then((newSOPFiles) => {
      const allSOPs = [...formik.values.SOPs, ...newSOPFiles];
      formik.setFieldValue("SOPs", allSOPs);
      departmentsRef.doc(departmentId).update({
        SOPs: allSOPs,
      });
    });
    promise.then(() => {
      setSOPAttachedDescription("");
      setSOPsFileList([]);
      message.success("Files are uploaded successfully!");
      setLoading(false);
    });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const uploadVideo = async () => {
    setLoading(true);
    const promise = handleFileUpload(formik.values.orientational_video_files, videoDescription, "orientational_video");
    promise.then((newVideoFiles) => {
      const allVideos = [...formik.values.orientational_video, ...newVideoFiles];
      formik.setFieldValue("orientational_video", allVideos);
      departmentsRef.doc(departmentId).update({
        orientational_video: allVideos,
      });
    });
    promise.then(() => {
      setVideoFileList([]);
      message.success("Video is uploaded successfully!");
      setLoading(false);
    });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const handleDeleteFile = async (fileName: string, attachmentFolder: string) => {
    try {
      setLoading(true);
      const fileRef = storageRef.child(`departments/${departmentId}/${attachmentFolder}/${fileName}`);
      await fileRef.delete().then(async () => {
        const currentFiles = (formik.values as any)[attachmentFolder];
        const filteredCurrentFiles = currentFiles.filter((r: any) => r.name != fileName);
        await departmentsRef.doc(departmentId).update({
          [attachmentFolder]: filteredCurrentFiles,
        });
        formik.setFieldValue(attachmentFolder, filteredCurrentFiles);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      setLoading(false);
    }
  };
  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;

  return (
    <Spin spinning={loading} size="large" indicator={antIcon}>
      <AddNewDepartmentPosition
        departmentId={departmentId}
        modalState={newPositionModalState}
        setModalState={setNewPositionModalState}
      />
      <Container maxWidth="md" style={{ background: "#ffffffc7", borderRadius: "6px" }}>
        <div style={{ padding: "5%" }}>
          <Form onSubmit={formik.handleSubmit}>
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item label={t("general.departmentName")}>
                  <Input
                    // disabled={!admins.includes(uid)}
                    value={formik.values.departmentName}
                    onChange={(e) => formik.setFieldValue("departmentName", e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("general.referenceDepartment")}>
                  <Select
                    // disabled={!admins.includes(uid)}
                    allowClear
                    size="default"
                    placeholder="Parent Department"
                    showSearch
                    value={
                      departments?.find((department) => department.id == formik.values.referenceDepartment)
                        ?.departmentName
                    }
                    onChange={(e) => {
                      const id = departments?.find((department) => department.departmentName == e)?.id as string;
                      formik.setFieldValue("referenceDepartment", id);
                    }}
                  >
                    {departments?.map((d) => {
                      return <Option value={d.departmentName}>{d.departmentName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("general.HOD")}>
                  <Select
                    filterOption={(inputValue, option) => {
                      return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
                    }}
                    allowClear
                    disabled={!admins.includes(uid)}
                    placeholder="Head of department"
                    showSearch
                    value={formik.values.HOD}
                    onChange={(e) => {
                      formik.setFieldValue("HOD", e);
                    }}
                  >
                    {USERS_ID_FULL_NAME?.map((d) => {
                      return <Option value={d.uid}>{d.fullName}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={24}>
                <Form.Item label={t("general.overview")}>
                  <DraftText
                    onValueChange={() => {}}
                    onMentionChange={() => {}}
                    html={""}
                    editorState={editorState}
                    setEditorState={setEditorState}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="center">Department Orientational Video</Divider>

            <Row gutter={20}>
              <Col span={5}>
                <Form.Item label="Upload Video">
                  <Upload
                    accept=".mov, .mp4, .wmv, .avi"
                    beforeUpload={() => {
                      return false;
                    }}
                    name="orientational_video_files"
                    onChange={(e) => {
                      setVideoFileList([...e.fileList]);
                      formik.setFieldValue("orientational_video_files", e.fileList);
                    }}
                    fileList={videoFileList}
                  >
                    <Button>
                      <Icon type="upload" /> Click to upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label="Description">
                  <Input onChange={(e) => setVideoDescription(e.target.value)} value={videoDescription} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Confirm">
                  <Button
                    onClick={uploadVideo}
                    disabled={videoDescription == "" || videoFileList.length == 0}
                    className="btn blue lighten-1 z-depth-0"
                    type="primary"
                  >
                    {t("general.upload")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {formik.values?.orientational_video?.length > 0 &&
              formik.values?.orientational_video.map((file: any) => (
                <Row
                  gutter={20}
                  style={{
                    paddingTop: "2%",
                    paddingBottom: "2%",
                    marginTop: "2%",
                    borderRadius: "5px",
                    background: "white",
                  }}
                >
                  <Col span={9}>
                    <p>{file.attachedDescription}</p>
                    <Popconfirm
                      title={"Are you sure?"}
                      onConfirm={() => handleDeleteFile(file.name, "orientational_video")}
                      onCancel={() => console.log("cancel")}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="danger">
                        <Icon type="delete" /> Remove
                      </Button>
                    </Popconfirm>
                  </Col>
                  <Col span={10}>
                    <ReactPlayer width={"500px"} height={"284px"} controls={true} url={file.url} />
                  </Col>
                </Row>
              ))}
            <Divider orientation="center">Department Positions</Divider>
            <PositionsTable data={departmentPositions ?? []} departmentId={departmentId} department={departmentData} />

            <Button
              style={{ marginTop: "2%" }}
              className="btn blue lighten-1 z-depth-0"
              type="primary"
              onClick={() => {
                setNewPositionModalState(true);
              }}
            >
              Add New Position
            </Button>

            <Divider orientation="center">Templates Attachments</Divider>

            <Row gutter={20}>
              <Col span={5}>
                <Form.Item label="Templates Attachments">
                  <Upload
                    beforeUpload={() => {
                      return false;
                    }}
                    name="templatesFiles"
                    onChange={(e) => {
                      setTemplateFileList([...e.fileList]);
                      formik.setFieldValue("templatesFiles", e.fileList);
                    }}
                    fileList={templateFileList}
                  >
                    <Button>
                      <Icon type="upload" /> Click to upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label="Description">
                  <Input
                    onChange={(e) => setTemplateAttachedDescription(e.target.value)}
                    value={templateAttachedDescription}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Confirm">
                  <Button
                    onClick={uploadTemplates}
                    disabled={templateAttachedDescription == "" || templateFileList.length == 0}
                    className="btn blue lighten-1 z-depth-0"
                    type="primary"
                  >
                    {t("general.upload")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {formik.values?.templates?.length > 0 && (
              <div>
                <List
                  itemLayout="horizontal"
                  bordered
                  dataSource={formik.values?.templates}
                  renderItem={(file: any) => (
                    <List.Item>
                      <div>
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                        <p style={{ margin: "0px" }}>{file.attachedDescription}</p>
                      </div>
                      <Popconfirm
                        title={"Are you sure?"}
                        onConfirm={() => handleDeleteFile(file.name, "templates")}
                        onCancel={() => console.log("cancel")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" style={{ color: "red" }}>
                          <Icon type="delete" />
                        </Button>
                      </Popconfirm>
                    </List.Item>
                  )}
                />
              </div>
            )}

            <Divider orientation="center">Policies Attachments</Divider>

            <Row gutter={20}>
              <Col span={5}>
                <Form.Item label="Policies Attachments">
                  <Upload
                    beforeUpload={() => {
                      return false;
                    }}
                    name="policiesAttachment"
                    onChange={(e) => {
                      setPoliciesFileList([...e.fileList]);
                      formik.setFieldValue("policiesFiles", e.fileList);
                    }}
                    fileList={policiesFileList}
                  >
                    <Button>
                      <Icon type="upload" /> Click to upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label="Description">
                  <Input
                    onChange={(e) => setPolicyAttachedDescription(e.target.value)}
                    value={policyAttachedDescription}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Confirm">
                  <Button
                    onClick={uploadPolicies}
                    disabled={policyAttachedDescription == "" || policiesFileList.length == 0}
                    className="btn blue lighten-1 z-depth-0"
                    type="primary"
                  >
                    {t("general.upload")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {formik.values?.policies?.length > 0 && (
              <div>
                <List
                  itemLayout="horizontal"
                  bordered
                  dataSource={formik.values?.policies}
                  renderItem={(file: any) => (
                    <List.Item>
                      <div>
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                        <p style={{ margin: "0px" }}>{file.attachedDescription}</p>
                      </div>
                      <Popconfirm
                        title={"Are you sure?"}
                        onConfirm={() => handleDeleteFile(file.name, "policies")}
                        onCancel={() => console.log("cancel")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" style={{ color: "red" }}>
                          <Icon type="delete" />
                        </Button>
                      </Popconfirm>
                    </List.Item>
                  )}
                />
              </div>
            )}

            <Divider orientation="center">SOPs Attachments</Divider>

            <Row gutter={20}>
              <Col span={5}>
                <Form.Item label="SOPs Attachments">
                  <Upload
                    beforeUpload={() => {
                      return false;
                    }}
                    name="SOPsAttachment"
                    onChange={(e) => {
                      setSOPsFileList([...e.fileList]);
                      formik.setFieldValue("SOPsFiles", e.fileList);
                    }}
                    fileList={SOPsFileList}
                  >
                    <Button>
                      <Icon type="upload" /> Click to upload
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={15}>
                <Form.Item label="Description">
                  <Input onChange={(e) => setSOPAttachedDescription(e.target.value)} value={SOPAttachedDescription} />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label="Confirm">
                  <Button
                    onClick={uploadSOPs}
                    disabled={SOPAttachedDescription == "" || SOPsFileList.length == 0}
                    className="btn blue lighten-1 z-depth-0"
                    type="primary"
                  >
                    {t("general.upload")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>

            {formik.values?.SOPs?.length > 0 && (
              <div>
                <List
                  itemLayout="horizontal"
                  bordered
                  dataSource={formik.values?.SOPs}
                  renderItem={(file: any) => (
                    <List.Item>
                      <div>
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                        <p style={{ margin: "0px" }}>{file.attachedDescription}</p>
                      </div>
                      <Popconfirm
                        title={"Are you sure?"}
                        onConfirm={() => handleDeleteFile(file.name, "SOPs")}
                        onCancel={() => console.log("cancel")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link" style={{ color: "red" }}>
                          <Icon type="delete" />
                        </Button>
                      </Popconfirm>
                    </List.Item>
                  )}
                />
              </div>
            )}
            <Divider />

            <div>
              <Button
                className="btn blue lighten-1 z-depth-0"
                type="primary"
                htmlType="submit"
                disabled={!formik.isValid}
              >
                {t("general.save")}
              </Button>
            </div>
          </Form>
        </div>
      </Container>
    </Spin>
  );
}

export default DepartmentDashboard;
