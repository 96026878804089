import moment, { Moment } from "moment";
import { salaries } from "./userGlobal";
import _ from "lodash";

export const currentSalary = (userUID: string, date: Moment) => {
  // Filter the logs by the userUID using Lodash's _.filter
  const userLogs = _.filter(salaries, { uid: userUID });

  // Sort the logs by the startDate using Lodash's _.sortBy
  const sortedLogs = _.sortBy(userLogs, (log) => log.startDate.valueOf());

  // Find the log that matches the targetDate using Lodash's _.findLast
  const salaryLog = _.findLast(sortedLogs, (log) => date.isSameOrAfter(moment(log.startDate.toDate()).startOf("day")));

  // If a matching log is found, return the managerUID; otherwise, return null
  return salaryLog ? { salary: salaryLog.salary, currency: salaryLog.currency } : { salary: "", currency: "" };
};
