import React, { useState, useEffect } from "react";
import firebase, { auth } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import { Spin, Button, message } from "antd";
import { useDocument } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

const projectSchema = Yup.object().shape({
  title: Yup.string().required("*"),
  content: Yup.string().required("*"),
  outcomes: Yup.string().required("*"),
  outputs: Yup.string().required("*"),
  activities: Yup.string().required("*"),
  enddate: Yup.string().required("*")
});

function EditOrder(s: RouteComponentProps) {
  const [isCreate, setIsCreate] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [id, setId] = useState(useRouteMatch<{ id: string }>().params.id);
  const db = firebase.firestore();

  const [snapshot, error] = useDocument(db.collection("projects").doc(id || "no id"));

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
      outcomes: "",
      outputs: "",
      activities: "",
      enddate: ""
    },
    validationSchema: projectSchema,
    onSubmit: v => handleSubmit(v)
  });

  useEffect(() => {
    if (snapshot?.exists) {
      const data = snapshot!.data()!;
      console.log("Document data:", snapshot?.data());
      formik.setValues({
        activities: data.activities,
        title: data.title,
        content: data.content,
        outcomes: data.outcomes,
        outputs: data.outputs,
        enddate: data.enddate
      });

      setIsCreate(false);

      console.log("exist");
    } else {
      console.log("not exist");
    }
  }, [snapshot]);

  const handleSubmit = (value: {
    title: string;
    content: string;
    outcomes: string;
    outputs: string;
    activities: string;
    enddate: string;
  }) => {
    const db = firebase.firestore();

    const promise = db
      .collection("projects")
      .doc(id)
      .set(
        {
          date: firebase.firestore.Timestamp.fromDate(new Date()),
          title: value.title,
          content: value.content,
          outcomes: value.outcomes,
          outputs: value.outputs,
          activities: value.activities,
          manager: auth().currentUser!.uid,
          enddate: value.enddate
        },
        { merge: true }
      );
    promise.then(() => {
      if (isCreate == true) {
        console.log("Document successfully written!");
        message.success("Project has been Created successfully");
        history.push(`/`);
      } else {
        console.log("Document successfully written!");
        message.success("Project Details has been changed successfully");
        history.push(`/`);
      }
    });
    promise.catch(error => {
      console.error("Error writing document: ", error);
      message.error("Failed");
    });
  };

  const enterLoading = () => {
    setLoading(true);
  };

  if (snapshot == undefined) {
    return (
      <div className="container center">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="container">
      <form onSubmit={formik.handleSubmit}>
        <br />
        <div>
          {isCreate ? (
            <h5 className="grey-text text-darken-3">Create a New Project</h5>
          ) : (
            <h5 className="grey-text text-darken-3">Edit Project</h5>
          )}
        </div>

        <div>
          <input
            type="text"
            id="title"
            value={formik.values.title}
            onChange={e => formik.setFieldValue("title", e.target.value)}
          />
          <label htmlFor="title">Project Title</label>
        </div>
        <span>{formik.errors.title}</span>

        <div>
          <textarea
            id="content"
            className="materialize-textarea"
            value={formik.values.content}
            onChange={e => formik.setFieldValue("content", e.target.value)}
          />
          <label htmlFor="content">About The Project Goal</label>
        </div>
        <span>{formik.errors.content}</span>

        <div>
          <textarea
            id="outcomes"
            className="materialize-textarea"
            value={formik.values.outcomes}
            onChange={e => formik.setFieldValue("outcomes", e.target.value)}
          />
          <label htmlFor="outcomes">Project Outcomes</label>
        </div>
        <span>{formik.errors.outcomes}</span>

        <div>
          <textarea
            id="outputs"
            className="materialize-textarea"
            value={formik.values.outputs}
            onChange={e => formik.setFieldValue("outputs", e.target.value)}
          />
          <label htmlFor="outputs">Project Outputs </label>
        </div>
        <span>{formik.errors.outputs}</span>

        <div>
          <textarea
            id="activities"
            className="materialize-textarea"
            value={formik.values.activities}
            onChange={e => formik.setFieldValue("activities", e.target.value)}
          />
          <label htmlFor="activities">Project Activities</label>
        </div>
        <span>{formik.errors.activities}</span>

        <div>
          <input
            type="date"
            id="enddate"
            value={formik.values.enddate}
            onChange={e => formik.setFieldValue("enddate", e.target.value)}
          />
          <label htmlFor="enddate">End Date </label>
          <span>{formik.errors.enddate}</span>
        </div>
        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {isCreate ? <a>Create</a> : <a>Save Changes</a>}
          </Button>
        </div>
        {/* { this.state.loading ? <Spin/>: null} */}
      </form>
    </div>
  );
}

export default EditOrder;
