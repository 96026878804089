import { projectRef } from "../db/collectionsRef";
import {project } from "../db/interfaces";

interface ProjectsWithId extends project {
  id: string;
}

export const globalProjectsData: Record<string, ProjectsWithId> & { loaded?: boolean } = {};

projectRef.onSnapshot(data => {
  Object.defineProperty(globalProjectsData, "loaded", { enumerable: false, value: true });
  data.docs.forEach(doc => {
    globalProjectsData[doc.id] = { ...doc.data(), id: doc.id } as ProjectsWithId;
  });
});
