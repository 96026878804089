import React, { useState } from "react";
import moment from "moment";
import { DatePicker, Spin, Button, Select, Checkbox } from "antd";
const { MonthPicker } = DatePicker;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import {
  userRef,
  vacationRef,
  projectRef,
  budgetLinesRef,
  allocationRef,
  officeRef,
  shiftsRef,
} from "../../db/collectionsRef";
import { users } from "../../db/interfaces";
import printJS from "print-js";
import _ from "lodash";
import firebase, { auth } from "firebase/app";
const { Option } = Select;
import "./style.css";
import { currentUserData } from "../../db/userGlobal";

import TimeSheetComponent from "./timeSheetComponent";
import { USERS_ID_FULL_NAME } from "../../global/usersList";
export default function TimeSheet() {
  const [month, setMonth] = useState(moment().subtract(1, "months"));
  const [uid, setUid] = useState(auth().currentUser!.uid);
  const [activeDelay, setActiveDelay] = useState(true);

  const [vacationSN] = useCollection(
    vacationRef
      .where("employeeUID", "==", uid)
      .where("vacationStartOn", "<=", month.endOf("month").toDate())
      .where("vacationStartOn", ">=", month.startOf("month").toDate())
  );

  const [projectSN] = useCollection(projectRef);
  const [budgetLinesSN] = useCollection(budgetLinesRef);
  const [allocationSN] = useCollection(allocationRef.where("uid", "==", uid));
  const [usersSN] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);

  const [userSN] = useDocument(userRef.doc(uid));
  const [delaySN] = useCollection(
    firebase
      .firestore()
      .collectionGroup("delay")
      .where("userUID", "==", uid)
      .where("month", "==", month.format("MMM.YYYY").toString())
  );

  const [shiftSN] = useCollection(shiftsRef.where("month", "==", month.format("MM-YYYY").toString()));

  const CurrentUid = auth().currentUser!.uid;
  const [currentUserSN] = useDocument(userRef.doc(CurrentUid));

  if (
    !vacationSN ||
    !userSN ||
    !projectSN ||
    !budgetLinesSN ||
    !allocationSN ||
    !usersSN ||
    !delaySN ||
    !officeSN ||
    !currentUserSN ||
    !shiftSN
  )
    return <Spin />;

  // const USERS_ID_FULL_NAME = usersSN.docs
  //   .filter((e) => {
  //     return currentUserData.fullControlOffices.includes(e.data()?.office) || e.id == uid;
  //   })
  //   .map((u) => {
  //     const fullName =
  //       u.data().resourceNO +
  //       " _ " +
  //       u.data().firstName +
  //       " " +
  //       (u.data()?.fatherName ?? "") +
  //       " " +
  //       u.data().lastName +
  //       " _ " +
  //       u.data().arabicFullName;

  //     const uid = u.id;
  //     return { uid, fullName };
  //   });

  const userData = userSN.data() as users;

  const vacationsMap = vacationSN.docs.map((vacation) => {
    return { ...vacation.data(), id: vacation.id };
  });

  const print = async () => {
    await printJS({
      printable: "timeSheet",
      type: "html",
      targetStyles: ["*"],
      scanStyles: true,
      font_size: "15",
      documentTitle: `aiu.ida-org.com/print-timeSheet/${uid}/true/${moment(month).format("MM-YYYY")}`,
      showModal: true,
    });
  };
  return (
    <div>
      <MonthPicker
        size="large"
        style={{ marginBottom: "1%", marginRight: "1%" }}
        placeholder="Select month"
        value={month}
        format={"MM-YYYY"}
        onChange={(e: any) => {
          setMonth(moment(e));
        }}
      />
      {currentUserData?.fullControlOffices.length != 0 ? (
        <Select
          filterOption={(inputValue, option) => {
            return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
          }}
          showSearch
          style={{ width: "20%", paddingBottom: "1%" }}
          value={uid}
          onChange={(e: string) => {
            setUid(e);
          }}
        >
          {USERS_ID_FULL_NAME?.map((d: any) => {
            return <Option value={d.uid}>{d.fullName}</Option>;
          })}
        </Select>
      ) : null}
      {currentUserData?.fullControlOffices.length != 0 ? (
        <Checkbox
          style={{ marginLeft: "1%" }}
          value={activeDelay}
          checked={activeDelay}
          onChange={(e) => {
            setActiveDelay(!activeDelay);
          }}
        >
          Active
        </Checkbox>
      ) : null}

      <Button
        style={{ marginLeft: "1%" }}
        type="primary"
        onClick={async () => {
          print();
        }}
        htmlType="submit"
      >
        Print
      </Button>

      <div id="timeSheet">
        <TimeSheetComponent
          month={month}
          uid={uid}
          userData={userData as users}
          vacationsMap={vacationsMap as any}
          projectSN={projectSN}
          budgetLinesSN={budgetLinesSN}
          allocationSN={allocationSN}
          usersSN={usersSN}
          officeSN={officeSN}
          delaySN={delaySN}
          shiftSN={shiftSN}
          activeDelay={activeDelay}
        />
        <br />
      </div>
    </div>
  );
}
