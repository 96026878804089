//HIEkoatU8KDPh7dilnyIr7Yi

import React from "react";
import Calendar from "@ericz1803/react-google-calendar";
import { css } from "@emotion/react";





export default function calendar(){
  const API_KEY = "AIzaSyCdu5AhNCoRCez3CTGSuHwQ3JhYEzNduNM";
  let calendars = [
    {calendarId: "424809899757-9hh0jpn34l1edar70mla34r3ifd048tc.apps.googleusercontent.com"},
    // {
    //   calendarId: "299966604889-9rg7748jvjmg3rfccrfmse0na6qdq95t.apps.googleusercontent.com",
    //   color: "#B241D1" //optional, specify color of calendar 2 events
    // }
  ];
  
  let styles = {
    //you can use object styles (no import required)
    calendar: {
      borderWidth: "3px", //make outer edge of calendar thicker
    },
    
    //you can also use emotion's string styles
    today: css`
     /* highlight today by making the text red and giving it a red border */
      color: red;
      border: 1px solid red;
    `
  }
        return (
      <div>
        <Calendar  apiKey={API_KEY} calendars={calendars}  styles={styles}/>
      </div>
    )
}
