module.exports = [
   {
      "title":"HEMATOLOGY",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"BLOOD FILM",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"WBC morphology",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "WBC's :are Normal.",
                     "Blood film shows normal WBC morphology.",
                     "Blood film shows numerous activated lymphocytes.",
                     "Blood film shows left shift of the neutrophils and band forms with toxic granulation (may be seen in infection).",
                     "Blood film shows hyper segmented neutiophils.",
                     "Blood film shows mycloid cells. Melamyelocytes. ",
                     "Myelocytes. promyelocytes and myeloblasts.",
                     "Blood film shows malignant small lymphoid cells (may be chronic lymphocytic leukaemia).",
                     "Blood film shows prolymphocytes (may be prolymphocytic leukacmia).",
                     "Blood film shows suspected lympholasts (may be acut lymphoblastic leukacmia).",
                     "Blood film shows suspected blasts."
                  ],
                  "children":[
                     {
                        "title":"-",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Normal",
                           "Taxic granulation . few atypical lymphocyte",
                           "Neutrophelia ",
                           "Hyper segmented of neutrophile",
                           "Eosinophelia",
                           "Leukocytosis"
                        ],
                        "children":null
                     },
                     {
                        "title":"-",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Normal",
                           "Number is normal, Neutrophils are at the high accepted reference value according to age.",
                           "Slight increase in number.",
                           "Leucocytosis especially in Lymphocytes.",
                           "Leucocytosis especially in Neutrophils.",
                           "Little percentage of Reactive lymphocytes ( viral  or bacterial infection .. etc.).",
                           "There is slight increase in Monocytes.",
                           "There is slight increase in Eosinophils."
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"R.B.C morphology",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Noromchromia Hyperchromasia (some deep staining red cells) Hyperchromasia (some deep staining red cells)",
                     "(Hypochromia (RBC palely stain  ",
                     "Ansiochromasia (dimorphic)",
                     "Hyperchromasia (some deep staining red cells)"
                  ],
                  "children":[
                     {
                        "title":"-",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Normal. ",
                           "Muderate degrees of hypochromia",
                           "Normochromic normocytic",
                           "Anisocytosis and poikilocytosis sever degrees of hypochromia ",
                           "Anisocytosis and poikilocytosis moderate degrees of hypochromia",
                           "Anisocytosis mainly microcytic",
                           "Anisochromia",
                           "Sever degrees of hypochromia"
                        ],
                        "children":null
                     },
                     {
                        "title":"-",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Normal. ",
                           "Aniso chromia severe degrees of hypochromia",
                           "Anemia hypochromic Microcytic Anisocytoses Poykilocytoses",
                           "Anemia normochromic normoocytic Anisocytoses Poykilocytoses",
                           "",
                           "",
                           ""
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"Platelets",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"-",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Normal.",
                           "Thrombocytosis.",
                           "Thrombocytopenia."
                        ],
                        "children":null
                     }
                  ]
               }
            ]
         },
         {
            "title":"Platelets Count",
            "Unit":"NaN",
            "Normal value":[
               "(150-400)\u06371000 p/mm3"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Platelets Count",
                  "Unit":"NaN",
                  "Normal value":[
                     "(150-450)\u06371000/mm3"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Cross matching",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Blood Unit number",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Blood Group",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "A+",
                     "A-",
                     "B+",
                     "B-",
                     "O+",
                     "O-",
                     "AB+",
                     "AB-",
                     ""
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Compatiblity",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Compatible",
                     "Uncompatible"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Haemoglobin Electrophoresis",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"G6 PD",
            "Unit":"NaN",
            "Normal value":[
               "Up to    118",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"RBC Indices",
            "Unit":"\u00b5m\u00b3",
            "Normal value":[
               "75-100 mic.m/mm3"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"MCH",
                  "Unit":"NaN",
                  "Normal value":[
                     "27-34 pg"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"MCHC",
                  "Unit":"NaN",
                  "Normal value":[
                     "31 - 36 g/dl"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"MCV",
                  "Unit":"NaN",
                  "Normal value":[
                     "80 - 97 fl"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"RBC Count",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Hemoglobin",
                  "Unit":"NaN",
                  "Normal value":[
                     "Men   13.5-18   g/dl",
                     "Women 11.5-16.5 g/dl",
                     "Child 11.5-15   g/dl"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Haematocrit",
                  "Unit":"NaN",
                  "Normal value":[
                     "Men    40-54 %",
                     "Women  36-47 %",
                     "Child  37-44 %"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"RBC",
                  "Unit":"NaN",
                  "Normal value":[
                     "Men   4.5-6.5 c/mm3",
                     "Women 3.9-5.6 c/mm3",
                     "Child 4.2-5.2 c/mm3"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"WBC & Differential",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"W.B.C",
                  "Unit":"NaN",
                  "Normal value":[
                     "Adult   4.000 - 10.000/mm3"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Neutrophils",
                  "Unit":"%",
                  "Normal value":[
                     "43 - 76 %"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Lymphocytes",
                  "Unit":"%",
                  "Normal value":[
                     "17 - 48 %"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Monocytes",
                  "Unit":"%",
                  "Normal value":[
                     "1 - 6 %"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Eosinophils",
                  "Unit":"%",
                  "Normal value":[
                     "1 - 3 %"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Basophils",
                  "Unit":"%",
                  "Normal value":[
                     "0 - 1 %"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"CBC",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"WBC&Differential",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"W.B.C",
                        "Unit":"NaN",
                        "Normal value":[
                           "3.600-10.000/cmm3"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Neutrophils",
                        "Unit":"NaN",
                        "Normal value":[
                           "43 - 76 %"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Lymphocytes",
                        "Unit":"NaN",
                        "Normal value":[
                           "17 - 48 %"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Monocytes",
                        "Unit":"NaN",
                        "Normal value":[
                           "1 - 6 %"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Eosinophils",
                        "Unit":"NaN",
                        "Normal value":[
                           "1 - 3 %"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Basophils",
                        "Unit":"NaN",
                        "Normal value":[
                           "0 - 1 %"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"RBC Count",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Hemoglobin",
                        "Unit":"NaN",
                        "Normal value":[
                           "Men   13.5-18   g/dl",
                           "Women 11.5-16.5 g/dl",
                           "Child 11.5-15   g/dl"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Haematocrit",
                        "Unit":"NaN",
                        "Normal value":[
                           "Men    40-54 %",
                           "Women  36-47 %",
                           "Child  37-44 %"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"RBC",
                        "Unit":"NaN",
                        "Normal value":[
                           "Men   4.5-6.5 c/mm3",
                           "Women 3.9-5.6 c/mm3",
                           "Child 4.2-5.2 c/mm3"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     }
                  ]
               }
            ]
         },
         {
            "title":"BLOOD GROUP",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"BLOOD GROUP",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "A +",
                     "A -",
                     "O +",
                     "O -",
                     "B +",
                     "B -   ",
                     "AB +",
                     "AB -"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"RH",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Positive",
                     "Negative"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Reticulocytes Count",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Reticulocytes",
                  "Unit":"%",
                  "Normal value":[
                     "0.5-2.0 %",
                     "Newborn 2-6 %"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Leishmania",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"LE Cells",
            "Unit":"NaN",
            "Normal value":[
               "Negative ",
               ""
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"E.S.R.",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"E.S.R",
                  "Unit":"NaN",
                  "Normal value":[
                     "Men      Up to  14 mm/Hr",
                     "Women    Up to  20 mm/Hr",
                     "children Up to  10 mm/Hr"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         }
      ]
   },
   {
      "title":"SEROLOGY",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"Wright Test after Add 2-ME",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"   B. abortus",
                  "Unit":"NaN",
                  "Normal value":[
                     "Neg-1/40"
                  ],
                  "Value list":[
                     "Negative",
                     "1/20 ",
                     "1/40",
                     "1/80",
                     "1/160",
                     "1/320",
                     "1/640",
                     "1/1280",
                     "1/1600",
                     "1/3200"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"   B. melitensis",
                  "Unit":"NaN",
                  "Normal value":[
                     "Neg-1/40"
                  ],
                  "Value list":[
                     "Negative",
                     "1/20 ",
                     "1/40",
                     "1/80",
                     "1/160",
                     "1/320",
                     "1/640",
                     "1/1280",
                     "1/1600",
                     "1/3200",
                     ""
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Wright Test",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"   B. abortus",
                  "Unit":"NaN",
                  "Normal value":[
                     "Neg-1/40"
                  ],
                  "Value list":[
                     "Negative",
                     "1/20 ",
                     "1/40",
                     "1/60",
                     "1/80",
                     "1/120",
                     "1/160",
                     "1/320",
                     "1/640",
                     "1/1280",
                     "1/1600",
                     "1/3200"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"   B. melitensis",
                  "Unit":"NaN",
                  "Normal value":[
                     "Neg-1/40"
                  ],
                  "Value list":[
                     "Negative",
                     "1/20 ",
                     "1/40",
                     "1/60",
                     "1/80",
                     "1/120",
                     "1/160",
                     "1/320",
                     "1/640",
                     "1/1280",
                     "1/1600",
                     "1/3200"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Widal Test",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"   S. Typhi O",
                  "Unit":"NaN",
                  "Normal value":[
                     "Neg-1/80"
                  ],
                  "Value list":[
                     "Negative",
                     "1/20 ",
                     "1/40",
                     "1/60",
                     "1/80",
                     "1/120",
                     "1/160",
                     "1/320",
                     "1/640",
                     "1/1280",
                     "1/1600",
                     "1/3200"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"   S. Typhi H",
                  "Unit":"NaN",
                  "Normal value":[
                     "Neg-1/80"
                  ],
                  "Value list":[
                     "Negative",
                     "1/20 ",
                     "1/40",
                     "1/60",
                     "1/80",
                     "1/120",
                     "1/160",
                     "1/320",
                     "1/640",
                     "1/1280",
                     "1/1600",
                     "1/3200"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"   S. Paratyphi A",
                  "Unit":"NaN",
                  "Normal value":[
                     "Neg-1/80"
                  ],
                  "Value list":[
                     "Negative",
                     "1/20 ",
                     "1/40",
                     "1/60",
                     "1/80",
                     "1/120",
                     "1/160",
                     "1/320",
                     "1/640",
                     "1/1280",
                     "1/1600",
                     "1/3200"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"   S. Paratyphi B",
                  "Unit":"NaN",
                  "Normal value":[
                     "Neg-1/80"
                  ],
                  "Value list":[
                     "Negative",
                     "1/20 ",
                     "1/40",
                     "1/60",
                     "1/80",
                     "1/120",
                     "1/160",
                     "1/320",
                     "1/640",
                     "1/1280",
                     "1/1600",
                     "1/3200"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"ASLO Quantitative",
            "Unit":"NaN",
            "Normal value":[
               "Up to 200 Iu/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"C.R.P",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"R.F",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"CRP Quantitative",
            "Unit":"NaN",
            "Normal value":[
               "Adult Up to 5 mg/l",
               "Neonates Up to 10  mg/l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"VDRL",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"LISTERIOSIS",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"LISTERIOSIS (O)",
                  "Unit":"NaN",
                  "Normal value":[
                     "Up to 1/80"
                  ],
                  "Value list":[
                     "Negative",
                     "1/20",
                     "1/40",
                     "1/80",
                     "1/160",
                     "1/320",
                     "1/640"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"LISTERIOSIS (H)",
                  "Unit":"NaN",
                  "Normal value":[
                     "Up to 1/80"
                  ],
                  "Value list":[
                     "Negative",
                     "1/20",
                     "1/40",
                     "1/80",
                     "1/160",
                     "1/320",
                     "1/640"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"RF Quantitative",
            "Unit":"NaN",
            "Normal value":[
               "Up to 30 IU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Echanococcus Abs",
            "Unit":"NaN",
            "Normal value":[
               "Up to 1/160"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"C",
            "Unit":"NaN",
            "Normal value":[
               "Negative"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Direct  Coombs",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Indirect Coombs",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"CRP - hs",
            "Unit":"NaN",
            "Normal value":[
               "Up to    1.1 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         }
      ]
   },
   {
      "title":"BIOCHEMISTRY",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"Glucose",
            "Unit":"NaN",
            "Normal value":[
               "70 - 110 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Glucose ( 2 hrs.)",
            "Unit":"NaN",
            "Normal value":[
               "70 - 140 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Urea",
            "Unit":"NaN",
            "Normal value":[
               "15 - 39 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Creatinine",
            "Unit":"NaN",
            "Normal value":[
               "Men       0.7-1.3 mg/dl",
               "Women     0.6-1.1 mg/dl",
               "children  0.3-1.0 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Cholesterol",
            "Unit":"NaN",
            "Normal value":[
               "Up to 200 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Triglycerides",
            "Unit":"NaN",
            "Normal value":[
               "Up to 150 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Total Protein",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Albumin",
                  "Unit":"NaN",
                  "Normal value":[
                     "3.5-5.4 g/dl"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Globulin",
                  "Unit":"NaN",
                  "Normal value":[
                     "2.5-3.8 g/dl"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Total protein",
                  "Unit":"NaN",
                  "Normal value":[
                     "6.5-8.5 g/dl"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"SGPT (ALT)",
            "Unit":"NaN",
            "Normal value":[
               "Up to 41 U/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"SGOT (AST)",
            "Unit":"NaN",
            "Normal value":[
               "Up to 40 U/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Alkaline Phosphatase",
            "Unit":"NaN",
            "Normal value":[
               "children (1 - 14years) :< 645  U/L",
               "Adults : 98 - 279  U/L",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Gamma GT",
            "Unit":"NaN",
            "Normal value":[
               "Men   Up to 55 U/L",
               "Women Up to 38 U/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Calcium",
            "Unit":"NaN",
            "Normal value":[
               "8.6 - 10.2 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Acid Phosphatase",
            "Unit":"NaN",
            "Normal value":[
               "3 - 11.7 IU"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"CPK",
            "Unit":"NaN",
            "Normal value":[
               "Men     24 - 195 U/L",
               "Women   24 - 170 U/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"CK . MB",
            "Unit":"NaN",
            "Normal value":[
               "Up to 25 U/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Amylase",
            "Unit":"NaN",
            "Normal value":[
               "Up to 120 U/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Sodium",
            "Unit":"NaN",
            "Normal value":[
               "135 - 145 mmol/l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Potassium",
            "Unit":"NaN",
            "Normal value":[
               "3.5 - 5 mmol/l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Chloride",
            "Unit":"NaN",
            "Normal value":[
               "Adult   96 - 106 mmol/L",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Bicarbonate ( HCO3 )",
            "Unit":"NaN",
            "Normal value":[
               "Adult    22-29",
               "Newborn  17.2-23.6",
               "Infants  19-23.9"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Iron",
            "Unit":"NaN",
            "Normal value":[
               "Men    59 - 158 ug/dl",
               "Women  37 - 145 ug/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Magnesium",
            "Unit":"NaN",
            "Normal value":[
               "1.7 - 2.4 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Transferrin",
            "Unit":"NaN",
            "Normal value":[
               "200-350 mg%",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Vitamin B12",
            "Unit":"NaN",
            "Normal value":[
               "174 - 878 Pg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Ferritin",
            "Unit":"NaN",
            "Normal value":[
               "Men    68 - 434 ng/dl",
               "Women  24- 278  ng/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"LDH",
            "Unit":"NaN",
            "Normal value":[
               "207 -414 U/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"D - Xylose",
            "Unit":"NaN",
            "Normal value":[
               "Adult    (after2 hr)More than 33 mg/dl",
               "children (after1 hr):",
               "             < 6 mth > 15 mg/dl",
               "             > 6 mth  30 - 50 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Cupper ( Cu )",
            "Unit":"NaN",
            "Normal value":[
               "Women 14-19 Year   70-140 ug/dl",
               "Men   14-19 Year   64 - 114   ug/dl",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Fructosamine",
            "Unit":"NaN",
            "Normal value":[
               "205-285 mmol/l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Lipase",
            "Unit":"NaN",
            "Normal value":[
               "up to 38 U/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Protein Electrophoresis",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Lipids Electrophoresis",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"LDL-Cholesterol",
            "Unit":"NaN",
            "Normal value":[
               "Up to 155 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"HDL-Cholesterol",
            "Unit":"NaN",
            "Normal value":[
               "Adult More than 35 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Glucose( Random",
            "Unit":"mg/dL",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Proten in Urine (Random)",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Phenotoin",
            "Unit":"NaN",
            "Normal value":[
               "10-20 Mic.g/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"C Peptide",
            "Unit":"NaN",
            "Normal value":[
               "1.1-5 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Uric Acid",
            "Unit":"NaN",
            "Normal value":[
               "Men      3.5 - 7.2 mg/dl",
               "Women    2.6 - 6.0 mg/dl",
               "children 2.0 - 5.5 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Valbroic Acid (Depakene)",
            "Unit":"NaN",
            "Normal value":[
               "50-100 u/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Zinc",
            "Unit":"NaN",
            "Normal value":[
               "children : 6 - 9 yr   78-105 mg/dl",
               "children : 10-13 yr   78 - 118 md/dl",
               "men :      14-19 yr   65-118 mg/dl",
               "weman :    14-19 yr   59-116 mg/dl",
               "adult :               46-150 mg/dl",
               "",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Glucose Tolerance Test",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Glucose Fasting",
                  "Unit":"mg/dL",
                  "Normal value":[
                     "70-110 mg/dl"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Glucose After 1/2 hr",
                  "Unit":"mg/dL",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Glucose After 1 hr",
                  "Unit":"mg/dL",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Glucose After 1.30 hr",
                  "Unit":"mg/dL",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Glucose After 2 hr",
                  "Unit":"mg/dL",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Aldolase",
            "Unit":"NaN",
            "Normal value":[
               "Up to 7.6 U\\l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Renin",
            "Unit":"NaN",
            "Normal value":[
               "Erect  4.4 - 40.1 mUI/L",
               "Supine 2.8 - 39.9 mUI/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Lithium",
            "Unit":"NaN",
            "Normal value":[
               "Theropeutic 0.6-1.2 mEq/l",
               "Toxic Level more Than 2 mEq/l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Folic Acid",
            "Unit":"NaN",
            "Normal value":[
               "3-17 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Seruloplasmin",
            "Unit":"NaN",
            "Normal value":[
               "15-60 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"HbA1c",
            "Unit":"%",
            "Normal value":[
               "non diabetics 4 - 6 %",
               "goal 4 - 6.5 %",
               "good control 6.5 - 8 %",
               "action suggested  > 8 %"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Phenobarbital",
            "Unit":"NaN",
            "Normal value":[
               "15-40 ug/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Carbamazepine (Tegretol)",
            "Unit":"NaN",
            "Normal value":[
               "4 - 12 mg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Albumin",
            "Unit":"NaN",
            "Normal value":[
               "3.5-5.4 g/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Amylase in urine",
            "Unit":"NaN",
            "Normal value":[
               "Up to 321 u/l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"High sensitive Troponin I ",
            "Unit":"NaN",
            "Normal value":[
               "High sensitive Troponin I  NSTEM*",
               "Negative : < 2 ng/L",
               "            ",
               "positive :  > 100 ng/L",
               "",
               "(2-100) ng/L  Surveillance",
               "Revaluation after 2 hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"TIBC",
            "Unit":"NaN",
            "Normal value":[
               "Adult  250 - 425 ug/dl",
               "Child  100 - 400 ug/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Bilirubin",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Bilirubin Total",
                  "Unit":"mg/dL",
                  "Normal value":[
                     "All Ages  0.2 - 1.0 mg/dl",
                     "Neonatal  1.0 - 10  mg/dl"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Bilirubin Direct",
                  "Unit":"NaN",
                  "Normal value":[
                     "All Ages  0.0 - 0.2 mg/dl",
                     "Neonatal  0.0 - 0.8 mg/dl"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Bilirubin Indirect",
                  "Unit":"NaN",
                  "Normal value":[
                     "All Ages   0.2 - 0.8 mg/dl",
                     "Neonatal   1.0 - 10  mg/dl"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"i Calcium",
            "Unit":"NaN",
            "Normal value":[
               "1.22 -1.52 mmol/l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Creatinine in Urine",
            "Unit":"mg/dL",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Phosphorus",
            "Unit":"NaN",
            "Normal value":[
               "Adults 2.5-4.5 mg/dl",
               "children 4-7 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Calcium in Urine",
            "Unit":"mg/dL",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Uric Acid in Urine",
            "Unit":"mg/dL",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Sodium in Urine",
            "Unit":"mmol/L",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Glucose 3 h",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "This test was "
            ],
            "children":[
               
            ]
         },
         {
            "title":"Phosphorus in Urine",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"1-25 OH D2",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Glucose in Urine (Random)",
            "Unit":"mg/dL",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"25 hydroxy Vitamin D",
            "Unit":"NaN",
            "Normal value":[
               "9.5 - 52 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Chloride in Urine",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"BNP",
            "Unit":"NaN",
            "Normal value":[
               "Up to  100 pg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Ammonia",
            "Unit":"NaN",
            "Normal value":[
               "Men     Up to    94",
               "Women   Up to    82",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Vitamin D Total",
            "Unit":"NaN",
            "Normal value":[
               "9.5 - 52  ng/ml",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Lactate",
            "Unit":"NaN",
            "Normal value":[
               "Adult  Up to 22 mg/dl",
               "children Up to 26  mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"a1Antitrypsin",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         }
      ]
   },
   {
      "title":"MICROBIOLOGY",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"Culture",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"ANTIBIOTIC SENSITIVITY",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Azithromycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cealothin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Ciprofloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefotaxime",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Ofloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Ceftazidime",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Amikacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Genatmycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Tobramycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Co - Trimoxazole",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Erythromycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Nalidixic acid",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Ceftriaxone",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefalexin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefaclor",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Norfloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"*",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R= Resistant  ,  I=  Intermediate Sensitivity ,        S= Sensitive  "
                        ],
                        "children":null
                     },
                     {
                        "title":"Amoxi + Clavulanate",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "S",
                           "R",
                           "I"
                        ],
                        "children":null
                     },
                     {
                        "title":"Amp. + Sulbactam",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefipime",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cephradine",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefazolin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Clarithromycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Pefloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefuroxime",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "S",
                           "R",
                           "I"
                        ],
                        "children":null
                     },
                     {
                        "title":"Levofloxcacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "S",
                           "R",
                           "I"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefadroxil",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Lomefloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "S",
                           "R",
                           "I"
                        ],
                        "children":null
                     },
                     {
                        "title":"Vancomycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Vancomycin"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"Result Of Culture",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"NaN",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "                         No organism was isolated after 48 hrs.",
                           "                         Gram Positive Cocci ( Staphylococcus )",
                           "                         Gram Negative Bacilli ( E.Coli )",
                           "                         Gram Negative Bacilli ( Psuedomonas )",
                           "                         Gram Negative Bacilli ( Klebsiella)                                 ",
                           "                          Gram Positive Cocci ( diplococcus )",
                           "                         Gram Positive Cocci ( Streptococcus )",
                           "                         The bacteria isolated are commensals (Normal flora)",
                           " Note :We recommend to use Azithromycine at the high possible dose. ",
                           "The isolated organisms were fungi (candida albicans ) which could'nt make an antibiogram for ."
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"SPECIMEN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"NaN",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "AFB Culture",
                           "Ascetic Fluid Culture",
                           "Breast Secretion Cuture",
                           "Breast Secretion exam",
                           "Cervical Culture",
                           "Cervical Smear",
                           "CSF Culture",
                           "Direct Exam for Scrapings of Nails",
                           "Wound swab",
                           "Eosinophils in Sputum",
                           "Fungal Culture",
                           "Fungal Smear",
                           "Leishmania Organism Detection",
                           "Lymph Node Aspirates Culture",
                           "Lymph Node Aspirates Smear",
                           "Malaria Organism Detection",
                           "Mycoplasma Culture",
                           "Pharyngeal Swab Culture",
                           "Pleural Fluid Culture",
                           "Pus Culture",
                           "Seminal Fluid Culture",
                           "Sputum Culture",
                           "Stomach Aspirates Culture",
                           "Stomach Aspirates Smear",
                           "Synovial Fluid Culture",
                           "Urethral Discharge Culture",
                           "Uterocervical Discharge Culture",
                           "Vaginal Swab Culture",
                           "Ear Swab Culture"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"Direct Exam",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "The detection for acid resistant bacilli was Negative",
                     "The detection for acid resistant bacilli was Positive"
                  ],
                  "children":[
                     {
                        "title":"NaN",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "                                                     ",
                           "                                  Gram Positive Cocci ( diplococcus )",
                           "                                  Gram Positive Cocci ( Staphylococcus )",
                           "                                  Gram Negative Bacilli ( E.Coli )",
                           "                                  No organism was isolated after 48 hrs.",
                           "                                 Gram Positive Cocci ( Streptococcus )",
                           "                                Negative ( non pathogenic Bacteria  )",
                           "                             Negative ( non pathogenic staphylococcus )         "
                        ],
                        "children":null
                     }
                  ]
               }
            ]
         },
         {
            "title":"Urine Culture",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"ANTIBIOTIC  SENSITIVITY",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Cephradine",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefalexin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefaclor",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefotaxime",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Ceftazidime",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Ceftriaxone",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefalothin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Genatmycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Tobramycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Amikacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Nalidixic acid",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Norfloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Ciprofloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Ofloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Co - Trimoxazole",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Erythromycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Azithromycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"*",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           " R= Resistant  ,    I=  Intermediate Sensitivity ,         S= Sensitive"
                        ],
                        "children":null
                     },
                     {
                        "title":"Amoxi + Clavulanate",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Ampi + Sulbactam",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefipime",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefazolin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Clarithromycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Pefloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefuroxime",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Levofloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Cefadroxil",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Lomefloxacin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     },
                     {
                        "title":"Vancomycin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "R",
                           "I",
                           "S"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"Result Of Culture",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"NaN",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "                   No organism was isolated after 48 hrs.",
                           "                   Gram Positive Cocci ( Staphylococcus )",
                           "                   Gram Negative Bacilli ( Psuedomonas )",
                           "                   Gram Negative Bacilli ( E.Coli )",
                           "                   Gram Negative Bacilli ( Klebsiella )",
                           "                   Gram Negative Bacilli ( Proteus )",
                           "                   Negative ( non pathogenic Bacteria )",
                           "The isolated organisms were fungi (candida albicans ) which could'nt make an antibiogram for ."
                        ],
                        "children":null
                     },
                     {
                        "title":"Bacteria count",
                        "Unit":"ml",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Less than 10'000 / ",
                           "10'000 / ",
                           "20'000 / ",
                           "25'000 / ",
                           "50'000 / ",
                           "75'000 / ",
                           "More than 100'000 / "
                        ],
                        "children":null
                     }
                  ]
               }
            ]
         },
         {
            "title":"AFB Smear",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Acid Fast Bacillus is: ( Negative ) ",
                     "Acid Fast Bacillus is: ( Positive ) "
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Vaginal Swab Smear",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Presence of : Gram Positive Cocci ( Streptococcus )",
                     "                      Gram Positive Cocci ( Staphylococcus )",
                     "                      Gram Positive Cocci ( Diplococcus )",
                     "                      No organisim seen"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "                      W.B.C :",
                     "                      Numerons  W.B.C"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "                      Epth Cells :"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "                     Pus Cells :"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Bacterial Smear",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Note"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Pharyngeal Smear",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "                      No Organism Seen",
                     "Presence of : Gram Positive Cocci ( Streptococcus )",
                     "                      Gram Positive Cocci ( Staphylococcus )"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "                      W.B.C :"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "                      Epth Cells :"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Pus Smear",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Presence of : Gram Positive Cocci ( Streptococcus )",
                     "                      Gram Positive Cocci ( Staphylococcus )"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "                      W.B.C :"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "                      Epth Cells :"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Sputum Smear",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Presence of : Gram Positive Cocci ( Streptococcus )",
                     "                      Gram Positive Cocci ( Staphylococcus )",
                     "                      No organisim seen"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "                      W.B.C :"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "                      Epth Cells :"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         }
      ]
   },
   {
      "title":"COAGULATION",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"Prothrombin Time",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Prothrombin Time",
                  "Unit":"sec.",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Mor than 60",
                     "",
                     ""
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"INR",
                  "Unit":"NaN",
                  "Normal value":[
                     "0.64 - 1.17"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Prothrombin activity",
                  "Unit":"%",
                  "Normal value":[
                     "70 - 120"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Control",
                  "Unit":"sec.",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Bleeding Time",
            "Unit":"NaN",
            "Normal value":[
               "1-4 Min"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Coagulation Time",
            "Unit":"NaN",
            "Normal value":[
               "3-9 Min"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"PTTK",
            "Unit":"NaN",
            "Normal value":[
               "23-33 Sec"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Fibrinogen",
            "Unit":"NaN",
            "Normal value":[
               "200 - 400 mg/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Factor VII",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"FDP",
            "Unit":"NaN",
            "Normal value":[
               "Up to    5 mg/dl",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Antihrombin III",
            "Unit":"%",
            "Normal value":[
               "80 - 120"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Protein C",
            "Unit":"%",
            "Normal value":[
               "40 - 140  %"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Protein S",
            "Unit":"%",
            "Normal value":[
               "60 - 150 %"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Factor VIII",
            "Unit":"%",
            "Normal value":[
               "60-150 %",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Factor II",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Factor  V",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Factor  IX",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Factor  X",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"D - Dimer",
            "Unit":"NaN",
            "Normal value":[
               "Up to 0.5",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Lupus Anti Coagulant",
            "Unit":"NaN",
            "Normal value":[
               "30 - 40 %",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         }
      ]
   },
   {
      "title":"TUMOR MARKERS",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"Alpha Fetoprotein",
            "Unit":"NaN",
            "Normal value":[
               "Up to 11.3 IU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"CA 19.9",
            "Unit":"NaN",
            "Normal value":[
               "Up to    39 U/mL",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"CA 15.3",
            "Unit":"NaN",
            "Normal value":[
               "Up to 25 U/ML"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"CA 125",
            "Unit":"NaN",
            "Normal value":[
               "Up to 35 U/mL"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"CEA",
            "Unit":"NaN",
            "Normal value":[
               "SMOKERS    UP TO 6.5 ng/ml",
               "NON SMOKERS UP TO  5 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Free PSA",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Total PSA",
                  "Unit":"NaN",
                  "Normal value":[
                     "Up to 4 ng/ml"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Free PSA",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Free PSA / Total PSA",
                  "Unit":"NaN",
                  "Normal value":[
                     "More than 0.2"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"Total PSA",
            "Unit":"NaN",
            "Normal value":[
               "Up to 4 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":" B2 Microglobulin",
            "Unit":"NaN",
            "Normal value":[
               "1010 - 1730 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         }
      ]
   },
   {
      "title":"IMMUNOLOGY",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"C3 Complement",
            "Unit":"NaN",
            "Normal value":[
               "90-180 mg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"IgG",
            "Unit":"NaN",
            "Normal value":[
               "800-1800 mg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"IgM",
            "Unit":"NaN",
            "Normal value":[
               "40-230 ng/100m"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"IgA",
            "Unit":"NaN",
            "Normal value":[
               "70-400 mg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"-",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti HBe",
            "Unit":"NaN",
            "Normal value":[
               "> 1.1 Ru/ml  positive"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"CMV IgM",
            "Unit":"NaN",
            "Normal value":[
               "Up to 1.1 IU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Toxoplasmose Ab. IgG",
            "Unit":"NaN",
            "Normal value":[
               "Negative :    <  4 IU/ml",
               "Equiveiant:  4 - 8  IU/ml",
               "Positive: more than  8  IU/ml"
            ],
            "Value list":[
               "",
               ""
            ],
            "children":[
               
            ]
         },
         {
            "title":"Toxoplasmose Abs. IgM",
            "Unit":"NaN",
            "Normal value":[
               "Negative :    <  0;55 IU/ml",
               "Equiveiant:  0.55 - 0.65  IU/ml",
               "Positive: more than  0.65  IU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"CMV IgG",
            "Unit":"NaN",
            "Normal value":[
               "Up to 1.1 IU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti HAV IgM",
            "Unit":"NaN",
            "Normal value":[
               "Up to 1.1 Ru/ml ( Negative )"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti HCV",
            "Unit":"NaN",
            "Normal value":[
               "Up to 0.5 U Negative"
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti  HBc IgM",
            "Unit":"NaN",
            "Normal value":[
               "Up to 10 U Negative"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"ANA",
            "Unit":"NaN",
            "Normal value":[
               "Up to 1/40 Ru/ml"
            ],
            "Value list":[
               "Negative",
               " 1/40",
               " 1/80",
               " 1/160",
               " 1/320",
               " 1/640",
               " 1/1280"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti DNA",
            "Unit":"NaN",
            "Normal value":[
               "Up to  1/10 Ru/ml"
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti Gliadin",
            "Unit":"NaN",
            "Normal value":[
               "UP TO 20 RU/ML"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti HBc Total",
            "Unit":"NaN",
            "Normal value":[
               "> 1.15 RU/ml Negative"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti Endomysium antibodies",
            "Unit":"NaN",
            "Normal value":[
               "Up to    1/5 "
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"C4 Complement",
            "Unit":"NaN",
            "Normal value":[
               "10-40 mg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"IgE",
            "Unit":"NaN",
            "Normal value":[
               "Adult Up to 100 IU/ml",
               "Child :",
               "   0-1 Year Up to 29 IU/ml",
               "   1-2 Year up to 49 IU/ml",
               "   2-3 Year up to 45 IU/ml",
               "   3-9 Year up to 52 IU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"TB Serum ( 38 KDAg )",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Chlamydia Ab. IgG",
            "Unit":"NaN",
            "Normal value":[
               "Up to 11 Ru/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Chlamydia Ab. IgM",
            "Unit":"NaN",
            "Normal value":[
               "Up to 1.1 Ru/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Rubella Ab. IgG",
            "Unit":"NaN",
            "Normal value":[
               "Up to 10 IU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Rubella Ab. IgM",
            "Unit":"NaN",
            "Normal value":[
               "Up to 1.1 IU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Tuberculin test",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"HBs Ag",
            "Unit":"NaN",
            "Normal value":[
               "Up to 1.0 ( Negative )"
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti HAV Total",
            "Unit":"NaN",
            "Normal value":[
               "More than 1.1  Negative"
            ],
            "Value list":[
               "Negative ",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"HIV (AIDS)",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Syphlis",
            "Unit":"NaN",
            "Normal value":[
               "Negative"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti HBs",
            "Unit":"NaN",
            "Normal value":[
               "Negative Upto 10 IU/I"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Herpise I &II  IgG",
            "Unit":"NaN",
            "Normal value":[
               "Up to 20 U"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Herpise I &II  IgM",
            "Unit":"NaN",
            "Normal value":[
               "Up to 1.1 U"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"HBe Ag",
            "Unit":"NaN",
            "Normal value":[
               "> 1.0 U/ml Positive"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti Cardiolipin IgG",
            "Unit":"NaN",
            "Normal value":[
               "Up to 20 RU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti Cardiolipin IgM",
            "Unit":"NaN",
            "Normal value":[
               "Up to 12 RU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Mitochondrial ( AMA )",
            "Unit":"NaN",
            "Normal value":[
               " Neg-1/10"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti Phospholipid IgG",
            "Unit":"NaN",
            "Normal value":[
               "Up to 20 RU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti Phospholipid IgM",
            "Unit":"NaN",
            "Normal value":[
               "Up to 12 RU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Helicobacter Pylori",
            "Unit":"NaN",
            "Normal value":[
               "Up to 1.1 ru/l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti   tTG  (IgA)",
            "Unit":"NaN",
            "Normal value":[
               "Up to 20 RU/ML"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Smooth Muscle Ab ( ASMA)",
            "Unit":"NaN",
            "Normal value":[
               "Neg-Up to 1/20"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti G B M",
            "Unit":"NaN",
            "Normal value":[
               "Up to 12 Negative",
               "More than 18 Positive"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti LKM",
            "Unit":"NaN",
            "Normal value":[
               "Up to  12 RU/L Negative",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti Cardiolipin Total",
            "Unit":"NaN",
            "Normal value":[
               "Adult Up to 20 Ru\\ml",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti Phospholipid Total",
            "Unit":"NaN",
            "Normal value":[
               "Adult Up to 20 Ru\\ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti c.c.p",
            "Unit":"NaN",
            "Normal value":[
               "Up to 5 RU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"A.C.E",
            "Unit":"NaN",
            "Normal value":[
               "8 - 65  U/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"*",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NOTE: Both Toxo tests were analysed using Roche Elecsys 2010 analyzer."
            ],
            "children":[
               
            ]
         },
         {
            "title":"*",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "We recommend not to compare our results with other labs, because our Method maybe different."
            ],
            "children":[
               
            ]
         },
         {
            "title":"ANA Profile",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"ANA Profile",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"nRNP/Sm",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Sm",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"SS-A",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Ro-52",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"SS-B",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Scl-70",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"PM-Scl",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Jo-1",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"CENP B",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"PCNA",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"dsDNA",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Nucleosomes",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Histones",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Rib.p-Protein",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"AMA-M2",
                  "Unit":"NaN",
                  "Normal value":[
                     "Negative "
                  ],
                  "Value list":[
                     "Negative",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"P -  ANCA",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"C -  ANCA",
            "Unit":"NaN",
            "Normal value":[
               "Negative "
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti - centromere",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti - Jo- 1",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti - Sel-70",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti - sem",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti - U1RNP",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"SS- A/Ro Autoantibodies",
            "Unit":"NaN",
            "Normal value":[
               "Up to    1",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"SS-B/La Autoantibodies",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"EBV Ab IgG Epstein -Barr",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"EBV Ab IgM Epstein -Barr",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Varicella Zoster Ab IgG",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"-",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"-",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti  Mullerian Hormone (AMH)",
            "Unit":"NaN",
            "Normal value":[
               "41-50 Years   Up to 6.30 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         }
      ]
   },
   {
      "title":"HORMONES",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"TSH",
            "Unit":"NaN",
            "Normal value":[
               "0.27-4.2 uIU/ml",
               "2-12 mounth 0.27-8.0  uIU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"TSHs",
            "Unit":"NaN",
            "Normal value":[
               "0.27-4.2 uIU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"T3",
            "Unit":"NaN",
            "Normal value":[
               "0.8-2 ng/ml",
               "2-12 mounth 0.81 - 02.81  ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"T4",
            "Unit":"NaN",
            "Normal value":[
               "5.1-14.1 ug/dl",
               "2-12mounth 5.37 - 16.0 ug/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"FT4",
            "Unit":"NaN",
            "Normal value":[
               "9.01 - 23.16 pmol/l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"FT3",
            "Unit":"NaN",
            "Normal value":[
               "Adult :4.0- 8.3 pmol/L"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Prolactin",
            "Unit":"NaN",
            "Normal value":[
               "Men   3 - 25  ng/ml",
               "Women 5 - 35  ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"FSH",
            "Unit":"NaN",
            "Normal value":[
               "Men     1.5 - 12.4 mIU/ml",
               "Women :",
               "    Folic phas     3.5 - 12.5 mIU/ml",
               "    Ovul.phas      4.7-21.5 mlU/ml",
               "    Luteal.phas    1.7-7.7 mlU/ml",
               "    Post.menopause 25.8-134.8 mlU/ml ",
               "1-5 years          0.2-1.8    mlu/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"LH",
            "Unit":"NaN",
            "Normal value":[
               "Men    1.7-8.6 mlU/ml",
               "Women :",
               "    Folic.phas  2.4-12.6 mlU/ml",
               "    Ovul.phas  14.0-95.6 mlU/ml",
               "    Luteal.phas 1.0-11.4 mlU/ml",
               "    Post.phas   7.7-58.5 mlU/ml ",
               "1-5 Year        0.1-0.5  mlu/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Estradiol",
            "Unit":"NaN",
            "Normal value":[
               "Men   7.63-42.62 pg/ml",
               "Women :",
               "   Folic.phas  12.5-166 pg/ml",
               "   Oval.phas   85.8-498 pg/ml",
               "   Luteal.phas 43.8-211 pg/ml",
               "   Post.menop <5.0.54.7 pg/ml",
               "children (1-10 year)",
               "   Boys  : < 5.00-20.0 pg/ml",
               "   Girls :   6.00-27.0 pg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Progesterone",
            "Unit":"NaN",
            "Normal value":[
               "Men    0.2-1.4 ng/ml",
               "Women :",
               "   Oval.phas   0.8-3 ng/ml",
               "   Folic.phas  0.2-1.5 ng/ml",
               "   Luteal.phas 1.7-27 ng/ml",
               "   Postmen opause 0.1-0.8 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Testosterone",
            "Unit":"NaN",
            "Normal value":[
               "Men    2.7 - 10.3  ng/ml",
               "Women 0.23 - 0.73 ng/ml",
               "Boys :",
               " < 1  year: 0.12-0.21",
               " 1-6  year: 0.03-0.32",
               " 7-12 year: 0.03-0.68",
               "13-17 year: 0.28-11.1",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Free Testosterone",
            "Unit":"NaN",
            "Normal value":[
               "Men 8.69-54.69 pg/ml",
               "Women 0.29-3.18 pg/ml",
               "  Ovul.phas 0.55-2.01 pg/ml",
               "  Folic.phas 0.45-3.17 pg/ml",
               "  Luteal.phas 0.46-2.48 pg/ml",
               "  Post.phas 0.29-1.73 pg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"B.HCG",
            "Unit":"NaN",
            "Normal value":[
               "Men:     Up to 2.6 mIU/ml",
               "Women   ",
               "Postmenopausal: Up to 8.3 mIU/ml",
               "Premenopausal:  Up to 5.3 mIU/ml",
               "Pregnant:   3th Week:  5.8-71.2",
               "            4th Week:  9.5-750",
               "            5th Week:  217-7138  "
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Digoxin",
            "Unit":"NaN",
            "Normal value":[
               "0.8 - 2.1 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Cortisol AM",
            "Unit":"NaN",
            "Normal value":[
               "5-25 mic/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Free Cortisol",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Alodsterone",
            "Unit":"NaN",
            "Normal value":[
               "Standing  :40-310 pg/ml",
               "Recumbend :10-160 pg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"ACTH",
            "Unit":"NaN",
            "Normal value":[
               "Up to 46 pg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Delta 4 - Androstene Dione",
            "Unit":"NaN",
            "Normal value":[
               "Men   0.7 - 3.6 ng/ml",
               "Women   0.3 - 3.5"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Insulin",
            "Unit":"NaN",
            "Normal value":[
               "2.6 - 24.4 mug/mL"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"PTH",
            "Unit":"NaN",
            "Normal value":[
               "15-65 pg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti peroxydase Ab",
            "Unit":"NaN",
            "Normal value":[
               "Up to 35 IU/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"DHEA-s",
            "Unit":"NaN",
            "Normal value":[
               "Refer to the attached paper"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"17-Hydroxy Progesteron",
            "Unit":"NaN",
            "Normal value":[
               "Men    0.61-3.34 ng/ml",
               "Women :",
               "    Oral Contrea 0.14-1.11 ng/ml",
               "    Folic.phas   0.4-1.2   ng/ml",
               "    Luteal.phas  1.26-4.28 ng/ml",
               "    Meno Pouse     0.25-1.36 ng/ml",
               "    Upto 1 Year  1.06-40.41ng/ml",
               "    1-3 Years    0.07-1.53 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti thyroglobulin Ab",
            "Unit":"NaN",
            "Normal value":[
               "Up to 115 IU/ML"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Anti microsomal Ab",
            "Unit":"NaN",
            "Normal value":[
               "UP to 1/10"
            ],
            "Value list":[
               "Negative ",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Rennin",
            "Unit":"NaN",
            "Normal value":[
               "Supine : 0.51-2.64 ng/ml hr",
               "Erct   : 0.98-4.18 ng/ml hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"IGF",
            "Unit":"NaN",
            "Normal value":[
               "Refer to  the attached paper"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH after Excersise",
            "Unit":"NaN",
            "Normal value":[
               "More than 7 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH After Glucose Load",
            "Unit":"NaN",
            "Normal value":[
               "0-5 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH After Rest",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH After Stimulation",
            "Unit":"NaN",
            "Normal value":[
               "More than 7 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH Fasting at Rest",
            "Unit":"NaN",
            "Normal value":[
               "0.06-5 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Cortisol PM",
            "Unit":"NaN",
            "Normal value":[
               "2.5-12.5 mic/dl"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Prolactin Pooling",
            "Unit":"NaN",
            "Normal value":[
               "Men      4.1-18.4 ng/ml",
               "Women    3.4-24.1 ng/ml",
               "children 3.9-25.4 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Thyroglobulin",
            "Unit":"NaN",
            "Normal value":[
               "0.38-68 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH After Stimulation 1/2 hr",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH After Stimulation  1 hr",
            "Unit":"NaN",
            "Normal value":[
               "More than 7 ng/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH After Stimulation 1.30 hr",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH After Stimulation 45 min",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH After Stimulation 2 hr",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Cortisol (Random)",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "mic/dl"
            ],
            "children":[
               
            ]
         },
         {
            "title":"GH",
            "Unit":"ng/ml",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Insulin after 1/2 hr",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Insulin after 1 hr",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Insulin after 1-1/2 hr",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Insulin after 2 hr",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Thyrocalcitonin",
            "Unit":"NaN",
            "Normal value":[
               "Up to 13 pg/ml"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Cortisol After Stimulation",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "Mic/dl"
            ],
            "children":[
               
            ]
         }
      ]
   },
   {
      "title":"SEMINAL FLUID ANALYSIS",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"SPERMOGRAM",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"TOTAL MOTILE FUNCTIONAL SPERM",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Type A (Very Active )",
                        "Unit":"%",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Type B ( Active )",
                        "Unit":"%",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Type C    (Slower )",
                        "Unit":"%",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Persent motile",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Persent Dead",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"MICROSCOPIC EXAMINATION",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"WBCs",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"RBCs",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"PHYSICAL EXAMINATION",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Color&Turbidity",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Milky",
                           "Creamy-normal",
                           "Creamy-milk",
                           "Colorless ",
                           "Light - Yellow ",
                           "Yellow ",
                           "Amber ",
                           "Dark Amber ",
                           "Orange ",
                           "Reddish ",
                           "Pink ",
                           "Brownish ",
                           "Greenish"
                        ],
                        "children":null
                     },
                     {
                        "title":"Volume",
                        "Unit":"ml",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "1.0 ",
                           "1.5 ",
                           "2.0 ",
                           "2.1 ",
                           "2.5 ",
                           "2.8 ",
                           "3.0 ",
                           "3.1 ",
                           "3.2 ",
                           "3.5",
                           "3.8 ",
                           "4.0 ",
                           "4.1 ",
                           "4.2 ",
                           "4.5 ",
                           "4.8 ",
                           "5.0 ",
                           "5.5 ",
                           "5.8 ",
                           "6.0 ",
                           "",
                           "",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Liquefaction after",
                        "Unit":"min.",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "20 ",
                           "25 ",
                           "30 ",
                           "35 ",
                           "40 ",
                           "45 ",
                           "50 ",
                           "55 ",
                           "60 ",
                           "90 ",
                           "2 Hrs.",
                           "3 Hrs.",
                           "> 3 Hrs."
                        ],
                        "children":null
                     },
                     {
                        "title":"pH",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "7.0",
                           "7.1",
                           "7.2",
                           "7.3",
                           "7.4",
                           "7.5",
                           "7.6",
                           "7.8",
                           "7.9",
                           "8.0",
                           "8.1",
                           "8.2",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Viscosity",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "    Normal",
                           "    Viscosity",
                           "    Slightely Viscosity",
                           "    High Viscosity",
                           "Watery within          minuts"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"MORPHOLOGY",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Abnormal",
                        "Unit":"%",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Normal",
                        "Unit":"%",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Abnormal  forms 1",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Large head",
                           "Tail reducing in length",
                           "Tail absent",
                           "Head absent",
                           "Pin head"
                        ],
                        "children":null
                     },
                     {
                        "title":"Abnormal  forms 2",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Large head             ",
                           "Head absent",
                           "Pin head"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"SPERM COUNT",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Number",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Azo spermy",
                           "Million/ml"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"NaN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Azospermy after centrifugation",
                     "Azo Spermia"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         }
      ]
   },
   {
      "title":" FLUID ANALYSIS",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"Fluid",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Chemical Examination",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Glucose",
                        "Unit":"mg/dL",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"T.proteins",
                        "Unit":"g/dL",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Albumin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"Physical Exsamination",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Volume",
                        "Unit":"ml",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Appearance",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Clear",
                           "Turbid",
                           "Slightly-Turbid"
                        ],
                        "children":null
                     },
                     {
                        "title":"Colour",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Yellow",
                           "Colorless",
                           "Orange",
                           "Reddish",
                           "Dark Yellow"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"Microscopic Exsamination",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"R.B.C Count",
                        "Unit":"/mm\u00b3",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Negative"
                        ],
                        "children":null
                     },
                     {
                        "title":"Differential Count",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"W.B.C Count",
                        "Unit":"/mm\u00b3",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Negative"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"SPECIMEN",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Synovial Fluid Analysis",
                     "Pleural Fluid Analysis",
                     "Ascites"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Other",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Amylase",
                  "Unit":"U/L",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"LDH",
                  "Unit":"U/L",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"-",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"-",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"-",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"-",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"C.S.F Analysis",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Physical Exsamination",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Volume",
                        "Unit":"ml",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Appearance",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Clear",
                           "Turbid",
                           "Slightly-Turbid"
                        ],
                        "children":null
                     },
                     {
                        "title":"Color",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Yellow",
                           "Colorless",
                           "Orange",
                           "Radish",
                           "Dark Yellow"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"Microscopic Exsamination",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"W.B.C Count",
                        "Unit":"/mm\u00b3",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Negative"
                        ],
                        "children":null
                     },
                     {
                        "title":"R.B.C Count",
                        "Unit":"/mm\u00b3",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Negative"
                        ],
                        "children":null
                     },
                     {
                        "title":"Differential Count",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"Chemical Examination",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Glucose",
                        "Unit":"mg/dL",
                        "Normal value":[
                           "45-70 mg/dl"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"T.protins",
                        "Unit":"mg/dL",
                        "Normal value":[
                           "Adult    15-45  mg/dl",
                           "children 30-100 mg/dl"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"Other",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     
                  ]
               }
            ]
         }
      ]
   },
   {
      "title":"URINE 24h",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"Creatinine Clearance",
            "Unit":"NaN",
            "Normal value":[
               "Women   80 - 125 ml/mint",
               "Men     90 - 140 ml/mint"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"VMA",
            "Unit":"NaN",
            "Normal value":[
               "Up to 13.6 mg/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"17 - Ketosteroids",
            "Unit":"NaN",
            "Normal value":[
               "Men   6 - 22 mg/24hr",
               "Women 4 - 16 mg/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"17 - Hydroxycorticoids",
            "Unit":"NaN",
            "Normal value":[
               "Men   3-12 ng/24hr",
               "Women 2-7  ng/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"protin Urine 24 hr",
            "Unit":"NaN",
            "Normal value":[
               "Up to150 mg/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Potassium Urine 24 hr",
            "Unit":"NaN",
            "Normal value":[
               "26-123 mEq/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Calcium Urine 24 hr",
            "Unit":"NaN",
            "Normal value":[
               "100-300 mg/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Phosphorus Urine 24 hr",
            "Unit":"NaN",
            "Normal value":[
               "0.4-1.3 g/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Uric Acid Urine 24 hr",
            "Unit":"NaN",
            "Normal value":[
               "250-750 mg/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Urine Volume 24/hr",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "ml/24 hr"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Urine Creatinine 24/hr",
            "Unit":"NaN",
            "Normal value":[
               "1-1.5 g/24 hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Cortisol",
            "Unit":"NaN",
            "Normal value":[
               "36-137 ng/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Cuppre Urine 24 hr",
            "Unit":"NaN",
            "Normal value":[
               "Up to35 ug/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Sodium urine 24 hr",
            "Unit":"NaN",
            "Normal value":[
               "27-287 mEq/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"5.HIAA",
            "Unit":"NaN",
            "Normal value":[
               "2-8 ng/24 hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"AFB",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "Acid Fast Bacillus is: ( Negative ) ",
               "Acid Fast Bacillus is: ( Positive ) "
            ],
            "children":[
               
            ]
         },
         {
            "title":"Free Cortisol",
            "Unit":"NaN",
            "Normal value":[
               "36 - 137 ug/24hr"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Microalbumin urea",
            "Unit":"NaN",
            "Normal value":[
               "Random  upto 18 mg/l"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"Metanephrine",
            "Unit":"NaN",
            "Normal value":[
               "Up to  1.0 mg/24 h",
               ""
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         }
      ]
   },
   {
      "title":"-",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         
      ]
   },
   {
      "title":"PREGNANCY TEST",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"Sensitive Pregnancy Test",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         }
      ]
   },
   {
      "title":"COMPLETE URINE ANALYSIS",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"URINE ANALYSIS",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"PHYSICAL EXAMINATION",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Color",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Yellow",
                           "Dark Yellow",
                           "Colorless",
                           "Light - Yellow",
                           "Orange",
                           "Reddish",
                           "Amber",
                           "Dark-amber",
                           "Bink",
                           "Brownish",
                           "Greenish",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"PH",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "5.0",
                           "5.5",
                           "6.0",
                           "6.5",
                           "7.0",
                           "7.5",
                           "8.0",
                           "8.5",
                           "9.0"
                        ],
                        "children":null
                     },
                     {
                        "title":"Aspect",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Clear",
                           "Turbid",
                           "Slightly-Turbid",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Sp. Gr.",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "1.000",
                           "1.005",
                           "1.010",
                           "1.015",
                           "1.020",
                           "1.025",
                           "1.030",
                           "1.035",
                           "1.040",
                           "",
                           "",
                           "",
                           "",
                           "",
                           "",
                           ""
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"CHEMICAL EXAMINATION",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Leukocytes",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Trace",
                           "(+)",
                           "(++)",
                           "(+++)"
                        ],
                        "children":null
                     },
                     {
                        "title":"Protein",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Trace",
                           "(+)",
                           "(++)",
                           "(+++)",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Blood",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Trace",
                           "(+)",
                           "(++)",
                           "(+++)",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Glucose",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Trace",
                           "(+)",
                           "(++)",
                           "(+++)",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Ketones",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Trace",
                           "(+)",
                           "(++)",
                           "(+++)",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Nitrite",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Trace",
                           "(+)",
                           "(++)",
                           "(+++)",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Bilirubin",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Trace",
                           "(+)",
                           "(++)",
                           "(+++)",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Urobilinogen",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Trace",
                           "(+)",
                           "(++)",
                           "(+++)",
                           ""
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"MICRISCOPIC EXAMINATION",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"R.B.C",
                        "Unit":"NaN",
                        "Normal value":[
                           "/HPF"
                        ],
                        "Value list":[
                           "Excess",
                           "Very  Excess"
                        ],
                        "children":null
                     },
                     {
                        "title":"W.B.C",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "Excess",
                           "Very  Excess"
                        ],
                        "children":null
                     },
                     {
                        "title":"Bacteria",
                        "Unit":"NaN",
                        "Normal value":[
                           "/HPF"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Rare",
                           "(+)",
                           "(++)",
                           "(+++)",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Pus Cells",
                        "Unit":"NaN",
                        "Normal value":[
                           "/HPF"
                        ],
                        "Value list":[
                           "-",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Mucus",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Trace",
                           "(+)",
                           "(++)",
                           "(+++)",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Candida",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "-",
                           "Negative",
                           "Rare",
                           "(+)",
                           "(++)",
                           "(+++)",
                           ""
                        ],
                        "children":null
                     },
                     {
                        "title":"Crystals",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Casts",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Others",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Epithelial Cells",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     }
                  ]
               }
            ]
         }
      ]
   },
   {
      "title":"COMPLETE STOOL ANALYSIS",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"Occlut Blood",
            "Unit":"NaN",
            "Normal value":[
               "",
               ""
            ],
            "Value list":[
               "  Negative",
               "  Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"STOOL ANALYSIS",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Protozoa",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Candida",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     },
                     {
                        "title":"Entamoeba Coli (Cysts)",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     },
                     {
                        "title":"Entamoeba Coli (Trophs)",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     },
                     {
                        "title":"Entamoeba Histolytica (Cysts)",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     },
                     {
                        "title":"Entamoeba Histolytica (Trophs)",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     },
                     {
                        "title":"Giardia (Cysts)",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     },
                     {
                        "title":"Giardia (Trophs)",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"OVA",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"Ascaris",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     },
                     {
                        "title":"Enterobius Vermicularis",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     },
                     {
                        "title":"Hymenolepis Nana",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     },
                     {
                        "title":"Trichuris trichuria",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "   +",
                           "   ++",
                           "   +++"
                        ],
                        "children":null
                     }
                  ]
               },
               {
                  "title":"Other",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "NaN"
                  ],
                  "children":[
                     {
                        "title":"R.B.C",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"W.B.C",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"Pus Cells",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     },
                     {
                        "title":"-",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "                                               No parasites seen"
                        ],
                        "children":null
                     },
                     {
                        "title":"PH",
                        "Unit":"NaN",
                        "Normal value":[
                           "NaN"
                        ],
                        "Value list":[
                           "NaN"
                        ],
                        "children":null
                     }
                  ]
               }
            ]
         }
      ]
   },
   {
      "title":"Polymerase Chain Reaction ( PCR )",
      "Unit":"NaN",
      "Normal value":[
         "NaN"
      ],
      "Value list":[
         "NaN"
      ],
      "children":[
         {
            "title":"HBV  DNA PCR",
            "Unit":"NaN",
            "Normal value":[
               "10.000 - 500.000   IU/ml"
            ],
            "Value list":[
               "Undetectable (0)"
            ],
            "children":[
               
            ]
         },
         {
            "title":"HCV  RNA  PCR",
            "Unit":"NaN",
            "Normal value":[
               "10.000 - 500.000  IU/ml"
            ],
            "Value list":[
               "Undetectable (0)"
            ],
            "children":[
               
            ]
         },
         {
            "title":"HCV  RNA  PCR ( Genotyping )",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"HLA B27",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "Negative",
               "Positive"
            ],
            "children":[
               
            ]
         },
         {
            "title":"FMF (PCR)",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               {
                  "title":"Test Result",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Normal",
                     "Positive"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Genotype",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Wild type",
                     "Homozygous",
                     "Heterozygous"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Phenotype",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "Intact",
                     "Apparently intact",
                     "Affected"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Mutation Position",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "No mutation"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Mutation Effect",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "------------------------------",
                     "Replacement the amino acid"
                  ],
                  "children":[
                     
                  ]
               },
               {
                  "title":"Conclusion",
                  "Unit":"NaN",
                  "Normal value":[
                     "NaN"
                  ],
                  "Value list":[
                     "The patient is free from FMF.",
                     "The patient has FMF.",
                     "The patient carries the FMF trait."
                  ],
                  "children":[
                     
                  ]
               }
            ]
         },
         {
            "title":"HLA Crossmatching",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         },
         {
            "title":"TB  PCR",
            "Unit":"NaN",
            "Normal value":[
               "NaN"
            ],
            "Value list":[
               "NaN"
            ],
            "children":[
               
            ]
         }
      ]
   }
];