import React, { useState } from "react";
import { Icon, Spin, Descriptions, Tag, List, Modal, Button } from "antd";
import { globalTasksData } from "../../global/taskList";
import { globalDepartmentPositionsData } from "../../global/departmentPositionList";
import { globalUsersData } from "../../global/usersList";
import DevTable from "../../helpers/devex-table";
import { useCollection } from "react-firebase-hooks/firestore";
import { positionTasksRef } from "../../db/collectionsRef";
import ViewTaskDialog from "./viewTaskDialog";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  positionId: any;
}

function ViewPositionDialog(props: Props) {
  const { modalState, setModalState, positionId } = props;

  const [taskModalState, setTaskModalState] = useState(false);
  const [taskData, setTaskData] = useState({});

  const [positionTasksSN, loading] = useCollection(positionTasksRef.where("positionId", "==", positionId));
  const positionTasks = positionTasksSN?.docs?.map((positionTask) => {
    return { ...positionTask?.data(), id: positionTask.id };
  });
  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;
  const COLORS = ["#00C49F", "#FFBB28", "#0088FE", "#FF8042", "#8B008B", "#FF1493", "#B22222", "#ADD8A9"];

  const position = globalDepartmentPositionsData[positionId ?? ""];

  const columns = [
    {
      name: "taskId",
      title: "Responsibility",
      getCellValue: (row: any) =>
        `${globalTasksData[row.taskId ?? ""]?.code} - ${globalTasksData[row.taskId ?? ""]?.title}`,
    },
    {
      name: "role",
      title: "Role",
      getCellValue: (row: any) => {
        return row.role?.map((r: any, index: any) => (
          <Tag style={{ marginBottom: "1%", marginTop: "1%" }} color={COLORS[index]} ref={r}>
            {r}
          </Tag>
        ));
      },
    },
    {
      name: "tags",
      title: "Tags",
      getCellValue: (row: any) => {
        return row.tags?.map((r: any) => {
          const randomIndex = Math.floor(Math.random() * COLORS.length);
          return (
            <Tag style={{ marginBottom: "1%", marginTop: "1%" }} color={COLORS[randomIndex]} ref={r}>
              {r}
            </Tag>
          );
        });
      },
    },
    {
      name: "view",
      title: "View",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setTaskModalState(true);
              setTaskData(row);
            }}
            type="link"
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <Modal
      title={position.title}
      closable={true}
      width={1000}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
      }}
      footer={false}
    >
      {taskModalState && (
        <ViewTaskDialog modalState={taskModalState} setModalState={setTaskModalState} taskData={taskData} />
      )}

      <Spin spinning={loading} size="large" indicator={antIcon}>
        <div>
          <Descriptions
            layout="horizontal"
            size="middle"
            bordered
            column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item span={3} label="Position Title">
              {position.title}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Assigned To">
              {position.uid ? (
                globalUsersData[position.uid ?? ""]?.fullName
              ) : (
                <Button type="primary">Request For Staff</Button>
              )}
            </Descriptions.Item>
            <Descriptions.Item span={6} label="Referenced To">
              {globalDepartmentPositionsData[position.referencePositionId ?? ""]?.title}
              {" - "}
              {globalUsersData[globalDepartmentPositionsData[position.referencePositionId ?? ""]?.uid ?? ""]?.fullName}
            </Descriptions.Item>
            <Descriptions.Item span={6} label="Overview">
              <div style={{ margin: "1%" }} dangerouslySetInnerHTML={{ __html: position.overview }} />
            </Descriptions.Item>
          </Descriptions>

          <DevTable
            data={positionTasks ?? []}
            columns={columns}
            defaultHiddenColumnNames={[]}
            defaultColumnWidths={[]}
            tableName="Responsibilities"
            selected={false}
            typicalTable={true}
            columnsExport={[]}
            pagesize={10}
          />

          {position?.attached_job_description.length > 0 && (
            <div style={{ marginTop: "2%" }}>
              <List
                header={"Attachments:"}
                itemLayout="horizontal"
                bordered
                dataSource={position?.attached_job_description}
                renderItem={(file: any) => (
                  <List.Item>
                    <div>
                      <a href={file.url} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          )}
        </div>
        <Tag color={"#00C49F"}>R: Responsible </Tag>
        <Tag color="#FFBB28">A: Accountable </Tag>
        <Tag color="#0088FE">C: Consulted </Tag>
        <Tag color="#FF8042">I: Informed </Tag>
      </Spin>
    </Modal>
  );
}

export default ViewPositionDialog;
