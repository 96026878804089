import { Layout, Menu, Icon, Spin, Badge, Dropdown, Avatar, Alert, Divider } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import logoIDA from "./logo.png";
import navbarBackgroundImg from "./AIU_Header-01-1.png";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { notificationsRef } from "../../db/collectionsRef";
import { sortBy } from "lodash";
import { useTranslation } from "react-i18next";
import { admins } from "../../db/admin";
import { currentUserData } from "../../db/userGlobal";
import { users } from "../../db/interfaces";
import SubMenu from "antd/lib/menu/SubMenu";
import { Container } from "@material-ui/core";

export const TitleContext = React.createContext<[string, (title: string) => void]>(["Not Feedback", () => {}]);

const { Header, Sider, Footer, Content } = Layout;

const signedout = () => {
  firebase
    .auth()
    .signOut()
    .then(function () {})
    .catch(function (error) {});
};
const notificationReadAll = () => {
  const uid = auth().currentUser!.uid;
  notificationsRef
    .where("notifyTo", "==", uid)
    .where("read", "==", false)
    .get()
    .then((d) => d.forEach((doc) => notificationsRef.doc(doc.id).update({ read: true })));
};
export interface Props {
  children: React.ReactNode;
}
const usersRef = firebase.firestore().collection("users");

function LayoutApp({ children }: Props) {
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  console.log(uid);
  const [userSnapshot, loading] = useDocument(usersRef.doc(uid));
  const [usersSnapshot] = useCollection(usersRef);
  const [notificationsSnapshot, loading2] = useCollection(
    notificationsRef.where("notifyTo", "==", uid).where("read", "==", false)
  );
  const [collapsed, setCollapsed] = useState(false);
  const history = useHistory();
  const [title, setTitle] = useState("IDA");
  const [closeAlert, setCloseAlert] = useState(false);
  const [closeAlertSig, setCloseAlertSig] = useState(false);

  const antIcon = <Icon type="loading" style={{ fontSize: 1 }} spin />;

  if (!userSnapshot || !notificationsSnapshot || !usersSnapshot) {
    return (
      <div style={{ textAlign: "center", marginTop: "25%" }}>
        <Spin size="large" indicator={antIcon} />
      </div>
    );
  }

  const userData = userSnapshot?.data() as users;
  const badgeSignature = !currentUserData.signatureURL;
  const badgeStatue =
    !currentUserData.fatherName ||
    !currentUserData.motherName ||
    !currentUserData.arabicFullName ||
    !currentUserData.dateOfBirth ||
    !currentUserData.placeOfBirth ||
    !currentUserData.currentAddress ||
    !currentUserData.phoneNumber;
  const messaging = firebase.messaging();

  const userFname = userSnapshot?.data()?.firstName;
  const userLname = userSnapshot?.data()?.lastName;
  const email = userSnapshot?.data()?.email;

  const notificationsLength = notificationsSnapshot?.size ?? 0;
  const CEO = userSnapshot?.data()?.CEO;
  const LawAdmin = userSnapshot?.data()?.adminLaw;
  const token: string[] = userSnapshot?.data()?.token ?? [];

  const ADMIN_OFFICER_OFFICES = currentUserData.adminOffices;
  const HR_OFFICER_OFFICES = currentUserData.HROffices;
  const HR_PM_OFFICES = currentUserData.HRProjectsManagersOffices;
  const VIEWER = currentUserData.viewersOffices;

  messaging
    .requestPermission()
    .then(() => {
      messaging
        .getToken()
        .then((currentToken) => {
          if (token.includes(currentToken)) {
            console.log("you already exist array test!");
          } else {
            console.log(currentToken);
            usersRef.doc(uid).update({
              token: firebase.firestore.FieldValue.arrayUnion(currentToken),
            });
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
      console.log("have permission");
    })
    .catch((e) => console.log("Errore...", e));

  const notifications = notificationsSnapshot == undefined ? [] : notificationsSnapshot.docs;
  const sortedNotifications = sortBy(notifications, (item) => item.data().postedAt.toDate()).reverse();
  const removeNotification = (id: string) => {
    notificationsRef.doc(id).update({
      read: true,
    });
  };

  const notificationMenu =
    sortedNotifications.length == 0 ? (
      <Menu>
        <Menu.Item>
          <span>{t("general.noNotifications")}</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => history.push(`/notification`)}>
          <span>{t("general.showAll")}</span>
        </Menu.Item>
      </Menu>
    ) : (
      <Menu>
        {sortedNotifications.slice(0, 10).map((item) => {
          const rout =
            item.data().kind == "mention" || item.data().kind == "calendarInvitation"
              ? "calenderDetails"
              : item.data().kind == "newRequestVacation"
              ? "vacation"
              : item.data().kind == "messageVacation"
              ? "vacation"
              : item.data().content == "New Compensation request is waiting for your Approval!"
              ? "overtime"
              : item.data().kind == "messageOvertime" || item.data().kind == "newRequestOvertime"
              ? "overtime"
              : item.data().kind == "newRequest" || item.data().kind == "messageOrder"
              ? "order"
              : item.data().kind;
          return item.data().kind == "newRequest" ? (
            <Menu.Item
              onClick={() => {
                history.push(`/${rout}/${item.data().orderId}`);
                removeNotification(item.id);
              }}
            >
              <span style={{ color: "#01aced" }}>{`${
                usersSnapshot?.docs?.find((d) => d.id == item.data().notifyFrom)?.data().firstName
              } ${usersSnapshot?.docs?.find((d) => d.id == item.data().notifyFrom)?.data().lastName} `}</span>
              <span style={{ fontSize: "12px", color: "#ccc" }}>{item.data().postedAt.toDate().toLocaleString()}</span>
              <br />
              <div
                style={{
                  marginTop: 10,
                }}
                dangerouslySetInnerHTML={{ __html: item.data().content }}
              />
            </Menu.Item>
          ) : item.data().kind == "messageOrder" ? (
            <Menu.Item
              onClick={() => {
                history.push(`/order/${item.data().orderId}`);
                removeNotification(item.id);
              }}
            >
              <span style={{ color: "#01aced" }}>{`${
                usersSnapshot?.docs?.find((d) => d.id == item.data().notifyFrom)?.data().firstName
              } ${usersSnapshot?.docs?.find((d) => d.id == item.data().notifyFrom)?.data().lastName} `}</span>
              <span> {t("general.sentNewMessage")} </span>
              <br />
              <span style={{ fontSize: "12px", color: "#ccc" }}>{item.data().postedAt.toDate().toLocaleString()}</span>
              <br />
              <div
                style={{
                  marginTop: 10,
                }}
                dangerouslySetInnerHTML={{ __html: item.data().content }}
              />
            </Menu.Item>
          ) : item.data().kind == "mention" || item.data().kind == "calendarInvitation" ? (
            <Menu.Item
              onClick={() => {
                history.push(`/calenderDetails/${item.data().orderId}`);
                removeNotification(item.id);
              }}
            >
              <span style={{ color: "#01aced" }}>{`${
                usersSnapshot?.docs?.find((d) => d.id == item.data().notifyFrom)?.data().firstName
              } ${usersSnapshot?.docs?.find((d) => d.id == item.data().notifyFrom)?.data().lastName} `}</span>
              {item.data()?.kind == "mention" ? (
                <span> {t("general.mentioned")} </span>
              ) : (
                <span>{item.data()?.content} </span>
              )}{" "}
              <br />
              <span style={{ fontSize: "12px", color: "#ccc" }}>{item.data().postedAt.toDate().toLocaleString()}</span>
            </Menu.Item>
          ) : item.data().kind == "newRequestOvertime" || item.data().kind == "messageOvertime" ? (
            <Menu.Item
              onClick={() => {
                history.push(`/overtime/${item.data().orderId}`);
                removeNotification(item.id);
              }}
            >
              <span style={{ color: "#01aced" }}>{`${
                usersSnapshot?.docs?.find((d) => d.id == item.data().notifyFrom)?.data().firstName
              } ${usersSnapshot?.docs?.find((d) => d.id == item.data().notifyFrom)?.data().lastName} `}</span>
              <span style={{ fontSize: "12px", color: "#ccc" }}>{item.data().postedAt.toDate().toLocaleString()}</span>
              <br />
              <div
                style={{
                  marginTop: 10,
                }}
                dangerouslySetInnerHTML={{ __html: item.data().content }}
              />
            </Menu.Item>
          ) : (
            <Menu.Item
              onClick={() => {
                history.push(`/${rout}/${item.data().orderId}`);
                removeNotification(item.id);
              }}
            >
              <span style={{ color: "#01aced" }}>{`${
                usersSnapshot?.docs?.find((d) => d.id == item.data().notifyFrom)?.data().firstName
              } ${usersSnapshot?.docs?.find((d) => d.id == item.data().notifyFrom)?.data().lastName} `}</span>
              <span style={{ fontSize: "12px", color: "#ccc" }}>{item.data().postedAt.toDate().toLocaleString()}</span>
              <br />
              <div
                style={{
                  marginTop: 10,
                }}
                dangerouslySetInnerHTML={{ __html: item.data().content }}
              />
            </Menu.Item>
          );
        })}

        <Menu.Divider />
        <Menu.Item onClick={() => history.push(`/notification`)}>
          <span>{t("general.showAll")}</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => notificationReadAll()}>
          <span style={{ color: "red" }}>{t("general.readAll")}</span>
        </Menu.Item>
      </Menu>
    );

  const menu = (
    <Menu style={{ width: "300px" }}>
      <Menu.Item>
        <Icon type="user" />
        <span>
          {userFname + " " + userLname}
          {""}
        </span>
      </Menu.Item>
      <Menu.Item
        key="6"
        onClick={() => {
          history.push(`/profile`);
        }}
      >
        <Badge dot={badgeStatue}>
          <Icon type="user" />

          <span>{t("general.profile")}</span>
        </Badge>
      </Menu.Item>
      <Menu.Item
        key="8"
        onClick={() => {
          history.push(`/signature`);
        }}
      >
        <Badge dot={badgeSignature}>
          <Icon type="edit" />

          <span>{t("general.mysignature")}</span>
        </Badge>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="7"
        onClick={() => {
          signedout(), history.push(`/signin`);
        }}
      >
        <Icon type="logout" />
        <span>{t("general.logOut")}</span>
      </Menu.Item>
    </Menu>
  );
  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <Layout>
        <Header
          style={{
            backgroundImage: `url(${navbarBackgroundImg})`,
            backgroundPosition: "center",
            position: "sticky",
            top: 0,
            zIndex: +3,
            width: "100%",
          }}
        >
          <img style={{ width: "90px", marginBottom: "7px" }} src={logoIDA} />

          <div style={{ float: "right" }}>
            <div style={{ width: "120px" }}>
              <Dropdown overlay={notificationMenu} placement="bottomRight">
                <Badge count={notificationsLength}>
                  <Avatar size="large" style={{ background: "#33b2f5" }}>
                    <Icon
                      type="bell"
                      style={{
                        fontSize: "18px",
                        color: "#ffff",
                        marginRight: "9%",
                      }}
                    />
                  </Avatar>
                </Badge>
              </Dropdown>

              <Dropdown overlay={menu} placement="bottomRight">
                <Badge dot={badgeStatue}>
                  <Avatar
                    size="large"
                    src={currentUserData.personalPhoto}
                    style={{
                      backgroundColor: userSnapshot?.data()?.gender == "male" ? "#87d068" : "#db7093",
                      marginLeft: "14%",
                    }}
                  >
                    {currentUserData.personalPhoto ?? userFname.slice(0, 1) + userLname.slice(0, 1)}
                  </Avatar>
                </Badge>
              </Dropdown>
            </div>
          </div>
        </Header>

        <Layout>
          <Sider
            collapsible
            collapsed={collapsed}
            width={225}
            onCollapse={toggle}
            style={{
              backgroundColor: "white",
            }}
          >
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={["1"]}
              style={{ background: "white" }}
              // style={{
              //   backgroundImage: `url(${navbarBackgroundImg})`,
              //   backgroundSize: "cover",
              //   backgroundPosition: "center",
              // }}
            >
              <Menu.Item
                key="4"
                onClick={() => {
                  history.push(`/`);
                }}
              >
                <Icon type="home" />
                <span>{t("general.home")}</span>
              </Menu.Item>
              <Menu.Item
                key="22"
                onClick={() => {
                  history.push(`/my-approvals`);
                }}
              >
                <Icon type="edit" />
                <span>{t("general.myApprovals")}</span>
              </Menu.Item>
              {LawAdmin ? (
                <Menu.Item
                  key="2"
                  onClick={() => {
                    history.push(`/order-table`);
                  }}
                >
                  <Icon type="folder" />
                  <span>{t("general.workPermit")}</span>
                </Menu.Item>
              ) : null}
              <Menu.Item
                key="15"
                onClick={() => {
                  history.push(`/vacation`);
                }}
              >
                <Icon type="form" />
                <span>{t("general.vacationRequest")}</span>
              </Menu.Item>
              <Menu.Item
                key="14"
                onClick={() => {
                  history.push(`/vacations-table`);
                }}
              >
                <Icon type="exception" />
                <span>{t("general.vacationsTable")}</span>
              </Menu.Item>
              {HR_OFFICER_OFFICES.length != 0 || HR_PM_OFFICES.length != 0 ? (
                <SubMenu
                  key="sub2"
                  title={
                    <div>
                      <Icon type="team" /> <span>{t("general.usersDashboard")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="30"
                    onClick={() => {
                      history.push(`/usersControl-table`);
                    }}
                  >
                    <span>{"By Office"}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="31"
                    onClick={() => {
                      history.push(`/usersControl-byProject-table`);
                    }}
                  >
                    <span>{"By Project"}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {HR_OFFICER_OFFICES.length != 0 || HR_PM_OFFICES.length != 0 || ADMIN_OFFICER_OFFICES.length != 0 ? (
                <SubMenu
                  key="sub3"
                  title={
                    <div>
                      <Icon type="team" /> <span>{t("general.usersTimeSheet")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="40"
                    onClick={() => {
                      history.push(`/timesheet-byOffice`);
                    }}
                  >
                    <span>{"By Office"}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="41"
                    onClick={() => {
                      history.push(`/timesheet-byProject`);
                    }}
                  >
                    <span>{"By Project"}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}

              {admins.includes(uid) ? ( // TODO:
                <Menu.Item
                  key="8"
                  onClick={() => {
                    history.push(`/meeting-room`);
                  }}
                >
                  <Icon type="build" />
                  <span>{t("general.meetingRoom")}</span>
                </Menu.Item>
              ) : null}

              {CEO ||
              HR_OFFICER_OFFICES.length != 0 ||
              HR_PM_OFFICES.length != 0 ||
              ADMIN_OFFICER_OFFICES.length != 0 ||
              VIEWER.length != 0 ? (
                <SubMenu
                  key="sub1"
                  title={
                    <div>
                      <Icon type="clock-circle" /> <span>{t("general.attendees")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="absent"
                    onClick={() => {
                      history.push(`/absent-table`);
                    }}
                  >
                    <span>{t("general.absentTable")}</span>
                  </Menu.Item>

                  <Menu.Item
                    key="delay"
                    onClick={() => {
                      history.push(`/delay-table`);
                    }}
                  >
                    <span>{t("general.delayWindow")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}

              {CEO ||
              HR_OFFICER_OFFICES.length != 0 ||
              HR_PM_OFFICES.length != 0 ||
              ADMIN_OFFICER_OFFICES.length != 0 ||
              VIEWER.length != 0 ? null : (
                <Menu.Item
                  key="18"
                  onClick={() => {
                    history.push(`/user-delay/${uid}`);
                  }}
                >
                  <Icon type="clock-circle" />
                  <span>{t("general.myDelays")}</span>
                </Menu.Item>
              )}
              {CEO ||
              HR_OFFICER_OFFICES.length != 0 ||
              HR_PM_OFFICES.length != 0 ||
              ADMIN_OFFICER_OFFICES.length != 0 ||
              VIEWER.length != 0 ? null : (
                <Menu.Item
                  key="myFingerPrint"
                  onClick={() => {
                    history.push(`/fingerPrint/${uid}`);
                  }}
                >
                  <Icon type="paper-clip" />
                  <span>{t("general.myFingerPrint")}</span>
                </Menu.Item>
              )}

              {admins.includes(uid) ? ( // TODO:
                <Menu.Item
                  key="5"
                  onClick={() => {
                    history.push(`/t`);
                  }}
                >
                  <Icon type="table" />
                  <span>{t("general.users")}</span>
                </Menu.Item>
              ) : null}
              <Menu.Item
                key="10"
                onClick={() => {
                  history.push(`/meeting-table`);
                }}
              >
                <Icon type="filter" />
                <span>{t("general.myMeetings")}</span>
              </Menu.Item>
              <Menu.Item
                key="9"
                onClick={() => {
                  history.push(`/meeting-calendar`);
                }}
              >
                <Icon type="calendar" />
                <span>{t("general.calendar")}</span>
              </Menu.Item>

              {/* {admins.includes(uid) ? ( // TODO:
              <Menu.Item
                key="27"
                onClick={() => {
                  history.push(`/imports-table`);
                }}
              >
                <Icon type="profile" />
                <span>{t("general.imports")}</span>
              </Menu.Item>
            ) : null} */}

              <Menu.Item
                key="65"
                onClick={() => {
                  history.push(`/reset-password`);
                }}
              >
                <Icon type="lock" />
                <span>{t("general.resetPassword")}</span>
              </Menu.Item>

              <br />
              {HR_PM_OFFICES.length != 0 || HR_OFFICER_OFFICES.length != 0 ? (
                <SubMenu
                  key="sub5"
                  title={
                    <div>
                      <Icon type="team" /> <span>{t("general.employeeManagement")}</span>
                    </div>
                  }
                >
                  {HR_PM_OFFICES.length != 0 && (
                    <Menu.Item
                      key="42"
                      onClick={() => {
                        history.push(`/deductions-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.deductions")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES.length != 0 && (
                    <Menu.Item
                      key="43"
                      onClick={() => {
                        history.push(`/salary-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.salaries")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES.length != 0 && (
                    <Menu.Item
                      key="44"
                      onClick={() => {
                        history.push(`/workOffice-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.workOffices")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES.length != 0 && (
                    <Menu.Item
                      key="45"
                      onClick={() => {
                        history.push(`/supervisor-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.supervisors")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES.length != 0 && (
                    <Menu.Item
                      key="26"
                      onClick={() => {
                        history.push(`/positions-table`);
                      }}
                    >
                      <Icon type="interaction" />
                      <span>{t("general.positionsTable")}</span>
                    </Menu.Item>
                  )}{" "}
                  {HR_PM_OFFICES.length != 0 && (
                    <Menu.Item
                      key="46"
                      onClick={() => {
                        history.push(`/payment-type-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.paymentTypes")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES.length != 0 && (
                    <Menu.Item
                      key="47"
                      onClick={() => {
                        history.push(`/contract-percentage-table`);
                      }}
                    >
                      <Icon type="database" />
                      <span>{t("general.contractPercentages")}</span>
                    </Menu.Item>
                  )}
                  {HR_PM_OFFICES.length != 0 && (
                    <Menu.Item
                      key="51"
                      onClick={() => {
                        history.push(`/accountants-table`);
                      }}
                    >
                      <Icon type="interaction" />
                      <span>{t("general.accountants")}</span>
                    </Menu.Item>
                  )}
                  <Menu.Item
                    key="52"
                    onClick={() => {
                      history.push(`/shifts-table`);
                    }}
                  >
                    <Icon type="calendar" />
                    <span>{t("general.shiftsManagement")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {HR_PM_OFFICES.length != 0 ? (
                <SubMenu
                  key="sub6"
                  title={
                    <div>
                      <Icon type="database" /> <span>{t("general.allocationManagement")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="19"
                    onClick={() => {
                      history.push(`/project-table`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.projects")}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="20"
                    onClick={() => {
                      history.push(`/budgetLines-table`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.budgetLines")}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="21"
                    onClick={() => {
                      history.push(`/allocations-table`);
                    }}
                  >
                    <Icon type="folder-open" />
                    <span>{t("general.allocationTable")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}

              {admins.includes(uid) ? ( // TODO:
                <Menu.Item
                  key="22"
                  onClick={() => {
                    history.push(`/office-table`);
                  }}
                >
                  <Icon type="bank" />
                  <span>{t("general.offices")}</span>
                </Menu.Item>
              ) : null}

              {HR_PM_OFFICES.length != 0 ? (
                <SubMenu
                  key="sub7"
                  title={
                    <div>
                      <Icon type="database" /> <span>{t("general.payroll")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="49"
                    onClick={() => {
                      history.push(`/payroll-byOffice`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.byOffice")}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="50"
                    onClick={() => {
                      history.push(`/payroll-byProject`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.byProject")}</span>
                  </Menu.Item>
                  <Menu.Item
                    key="53"
                    onClick={() => {
                      history.push(`/payroll-byCluster`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.byCluster")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}

              {HR_PM_OFFICES.length != 0 ? (
                <SubMenu
                  key="sub9"
                  title={
                    <div>
                      <Icon type="database" /> <span>{t("general.payslip")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="49"
                    onClick={() => {
                      history.push(`/payslip-byOffice`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.byOffice")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {admins.includes(uid) ? ( // TODO:
                <SubMenu
                  key="sub8"
                  title={
                    <div>
                      <Icon type="database" /> <span>{t("general.SOPs")}</span>
                    </div>
                  }
                >
                  <Menu.Item
                    key="49"
                    onClick={() => {
                      history.push(`/departments-table`);
                    }}
                  >
                    <Icon type="database" />
                    <span>{t("general.departments")}</span>
                  </Menu.Item>
                </SubMenu>
              ) : null}
              {userData?.permissions?.cases.includes("admin") ||
              userData?.permissions?.cases.includes("program") ||
              userData?.permissions?.cases.includes("CEO") ? (
                <Menu.Item
                  key="31"
                  onClick={() => {
                    history.push(`/case-table`);
                  }}
                >
                  <Icon type="diff" />
                  <span>{t("general.cases")}</span>
                </Menu.Item>
              ) : null}

              {admins.includes(uid) ? ( // TODO:
                <Menu.Item
                  key="32"
                  onClick={() => {
                    history.push(`/missions`);
                  }}
                >
                  <Icon type="apartment" />
                  <span>{t("general.missions")}</span>
                </Menu.Item>
              ) : null}
              {admins.includes(uid) ? ( // TODO:
                <Menu.Item
                  key="33"
                  onClick={() => {
                    history.push(`/car-table`);
                  }}
                >
                  <Icon type="car" />
                  <span>{t("general.cars")}</span>
                </Menu.Item>
              ) : null}
              <Divider />
              <Menu.Item
                key="7"
                onClick={() => {
                  signedout(), history.push(`/signin`);
                }}
              >
                <Icon type="logout" />
                <span>{t("general.logOut")}</span>
              </Menu.Item>
            </Menu>
          </Sider>

          <Container maxWidth="xl">
            <Layout
              style={{
                marginLeft: collapsed ? 0 : 0,
                // marginBottom: "0%",
                transition: "all 0.2s",
                marginTop: 0,
                padding: 20,
              }}
            >
              <Content>
                <TitleContext.Provider value={[title, setTitle]}>
                  {!closeAlert && badgeStatue ? (
                    <div
                      onClick={() => {
                        history.push("/profile");
                        setCloseAlert(true);
                      }}
                    >
                      <Alert
                        showIcon
                        banner={false}
                        message="Please Complete your profile information"
                        description="Go to your profile by clicking here!"
                        style={{ width: "50%" }}
                        type="warning"
                        closable={true}
                      />
                    </div>
                  ) : null}
                  {!closeAlertSig && badgeSignature ? (
                    <div
                      onClick={() => {
                        history.push("/signature");
                        setCloseAlertSig(true);
                      }}
                    >
                      <Alert
                        showIcon
                        banner={false}
                        message="Please Upload your Signature"
                        description="Go to your Signature Panel by clicking here!"
                        style={{ width: "50%" }}
                        type="error"
                        closable={true}
                      />
                    </div>
                  ) : null}

                  <div style={{ marginTop: 16, minHeight: "1000px" }}>{children}</div>
                </TitleContext.Provider>
              </Content>
              <Footer style={{ textAlign: "center" }}>{t("general.footer")}</Footer>
            </Layout>
          </Container>
        </Layout>
      </Layout>
    </div>
  );
}

export default LayoutApp;
