import React, { useEffect, useState } from "react";
import { Modal, Select, Spin } from "antd";
import { caseRef, missionRef, userRef, usersRef } from "../../db/collectionsRef";
import { Case } from "../../db/interfaces";
import { auth } from "firebase";
import moment from "moment";
import { useCollection } from "react-firebase-hooks/firestore";
import DevTable from "../../helpers/devex-table";
import EditCase from "./EditCase";
const { Option } = Select;

interface Props {
  status: string;
  office: string;
  assignedTo: string;
  setStatus: any;
  setOffice: any;
  setAssignedTo: any;
  columns: any[];
  columnsExport: any[];
  modalState: boolean;
  setModalState: any;
  startDate: any;
  endDate: any;
  loading: boolean;
  setLoading: any;
}

function TableDialog(props: Props) {
  const {
    setStatus,
    setOffice,
    setAssignedTo,
    endDate,
    startDate,
    status,
    office,
    assignedTo,
    columns,
    columnsExport,
    modalState,
    setModalState,
    loading,
    setLoading,
  } = props;
  const [modalStateCase, setModalStateCase] = useState(false);
  const [caseDoc, setCaseDoc] = useState({} as Case);
  const [usersSN] = useCollection(userRef);

  const [caseOfficeSN] = useCollection(
    caseRef
      .where("createdAt", ">=", startDate.toDate())
      .where("createdAt", "<=", endDate.toDate())
      .where("cacheOffice", "==", office ?? "not")
  );
  const [caseStatusSN] = useCollection(
    caseRef
      .where("createdAt", ">=", startDate.toDate())
      .where("createdAt", "<=", endDate.toDate())
      .where("status", "==", status ?? "not")
  );
  const [caseAssignedToSN] = useCollection(
    caseRef
      .where("createdAt", ">=", startDate.toDate())
      .where("createdAt", "<=", endDate.toDate())
      .where("cacheAssignedTo", "==", assignedTo ?? "not")
  );
  
  if (!caseOfficeSN || !caseStatusSN || !caseAssignedToSN || !usersSN) return <Spin />;

  const dataOffice = caseOfficeSN.docs.map((d) => {
    return { ...d.data(), id: d.id };
  });
  const dataStatus = caseStatusSN.docs.map((d) => {
    return { ...d.data(), id: d.id };
  }); 
  const dataAssignedTo = caseAssignedToSN.docs.map((d) => {
    return { ...d.data(), id: d.id };
  });

  const data = dataStatus.length != 0 ? dataStatus : dataOffice.length != 0 ? dataOffice : dataAssignedTo;

  return (
    <Modal
      closable={true}
      width={1200}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
        setStatus("");
        setOffice("");
        setAssignedTo("");
      }}
    >
      <div>
        {modalStateCase ? (
          <EditCase modalState={modalStateCase} setModalState={setModalStateCase} caseDoc={caseDoc} />
        ) : null}
        <DevTable
          data={data}
          columns={columns}     
          defaultHiddenColumnNames={[]}
          tableName="cases"
          selected={false}
          typicalTable={false}
          defaultColumnWidths={[]}
          columnsExport={columnsExport}
          rowClick={({ args, row }) => {
            setCaseDoc(row);
            setModalStateCase(true);
          }}
        />
      </div>
    </Modal>
  );
}

export default TableDialog;
