export const convertToHours = (num: any) => {
  const hours = Math.floor(num / 60);
  const min = num % 60;
  const minFixed = min.toFixed();
  return num >= 0 ? ("000" + hours).slice(-3) + ":" + ("00" + minFixed).slice(-2) : "";
};
export const convertToHoursMin = (num: any) => {
  const hours = Math.floor(num / 60);
  const min = num % 60;
  const minFixed = min.toFixed();
  return num >= 0 ? ("00" + hours).slice(-2) + ":" + ("00" + minFixed).slice(-2) : "";
};
