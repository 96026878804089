import React, { useState, useRef, useCallback, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import firebase, { firestore, auth } from "firebase/app";
import { Spin, Button, Select, Popconfirm } from "antd";
const { Option } = Select;
import UsersInformation from "../models/usersInformation";
import { userRef, officeRef, positionRef, projectRef } from "../../db/collectionsRef";
import { currentUserData } from "../../db/userGlobal";
import { uniq } from "lodash";
import NewUser from "../models/newUser";
import moment from "moment";
import { admins } from "../../db/admin";
import DevTable from "../../helpers/devex-table";
import NewUserByUsername from "../models/newUserByUsername";
import { globalOfficesData } from "../../global/officeList";

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function UsersDashboard() {
  const uid = auth().currentUser!.uid;

  const [officeId, setOfficeId] = useState(currentUserData.office);
  const [UID, setUID] = useState("");
  const [modalState, setModalState] = useState(false);
  const [modalStateNewUser, setModalStateNewUser] = useState(false);
  const [modalStateNewUserByUsername, setModalStateNewUserByUsername] = useState(false);

  const [defaultHiddenColumnNames] = useState([
    "gender",
    "department",
    "vacationPaternityScore",
    "vacationMaternityScore",
    "vacationMarriageScore",
    "vacationDeathScore",
  ]);

  const [users, loading, error] = useCollection(usersRef.where("office", "==", officeId));
  const [AllUsers] = useCollection(usersRef);

  const [officeSN] = useCollection(officeRef);
  const [projectSN] = useCollection(projectRef);

  if (!projectSN || !AllUsers || users == undefined || loading || !officeSN || !currentUserData.fullControlOffices)
    return <Spin />;

  const UserOffices = [...(currentUserData.fullControlOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map((id) => {
    const officeName = officeSN?.docs?.find((d) => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });
  const projectList = projectSN?.docs.map((p) => {
    return { id: p.id, projectCode: p?.data()?.projectCode };
  });
  const allUsersData = AllUsers?.docs
    .filter((d) => currentUserData.fullControlOffices.includes(d.data()?.office))
    .map((d, index) => {
      return {
        ...d.data(),
        id: d.id,
        index: index + 1,
      };
    });

  const data = users?.docs
    .filter((d) => currentUserData.fullControlOffices.includes(d.data()?.office))
    .map((d, index) => {
      return {
        ...d.data(),
        id: d.id,
        index: index + 1,
      };
    });

  const columnsExport = [
    {
      name: "status",
      title: "Status",
    },
    { name: "resourceNO", title: "Resource NO" },

    { name: "region", title: "Region" },

    { name: "firstName", title: "firstName" },
    { name: "lastName", title: "lastName" },
    { name: "gender", title: "gender" },
    { name: "email", title: "email" },
    // admins.includes(uid) && { name: "password", title: "password" },
    { name: "admin", title: "admin" },
    { name: "position", title: "position" },
    { name: "managerUID", title: "managerUID" },
    { name: "office", title: "office" },
    { name: "vacationAnnualScore", title: "vacationAnnualScore" },
    { name: "vacationSickScore", title: "vacationSickScore" },
    { name: "vacationCompensationScore", title: "vacationCompensationScore" },
    { name: "vacationDeathScore", title: "vacationDeathScore" },
    { name: "vacationMarriageScore", title: "vacationMarriageScore" },
    { name: "vacationPaternityScore", title: "vacationPaternityScore" },
    { name: "vacationMaternityScore", title: "vacationMaternityScore" },
    { name: "department", title: "department" },
    { name: "adminHR", title: "adminHR" },
    { name: "token", title: "token" },
    { name: "adminLaw", title: "adminLaw" },
    { name: "CEO", title: "CEO" },
    { name: "idaAllocationPercentage", title: "Contract Percentage" },
    { name: "adminOffices", title: "adminOffices" },
    { name: "HROffices", title: "HROffices" },
    { name: "personalPhoto", title: "personalPhoto" },
    { name: "refNO", title: "refNO" },
    { name: "arabicFullName", title: "arabicFullName" },
    { name: "fatherName", title: "fatherName" },
    { name: "motherName", title: "motherName" },
    { name: "dateOfBirth", title: "dateOfBirth" },
    { name: "placeOfBirth", title: "placeOfBirth" },
    { name: "permanentAddress", title: "permanentAddress" },
    { name: "currentAddress", title: "currentAddress" },
    { name: "salary", title: "salary" },
    { name: "currency", title: "currency" },

    { name: "bankName", title: "Bank Name" },
    { name: "bankAccountName", title: "(Bank)Full Name" },
    { name: "bankFirstName", title: "(Bank)First Name" },
    { name: "bankLastName", title: "(Bank)Last Name" },
    { name: "IBAN", title: "Iban" },
    { name: "bankAccountNumber", title: "Account Number" },
    { name: "customerNumber", title: "Customer Number" },

    { name: "startingDate", title: "startingDate" },
    { name: "endingDate", title: "endingDate" },
    { name: "phoneNumber", title: "phoneNumber" },
    { name: "identityNo", title: "identityNo" },
    { name: "emergencyContact1", title: "emergencyContact1" },
    { name: "emergencyContact2", title: "emergencyContact2" },
    { name: "status", title: "status" },
    { name: "signatureURL", title: "signatureURL" },
  ];
  const columns = [
    { name: "index", title: "NO" },
    { name: "resourceNO", title: "Resource NO" },

    { name: "region", title: "Region" },

    { name: "firstName", title: "First Name" },
    { name: "lastName", title: "Last Name" },
    { name: "identityNo", title: "Identity No" },

    { name: "email", title: "Email" },
    // admins.includes(uid) && { name: "password", title: "Password" },
    { name: "department", title: "Department" },
    { name: "fatherName", title: "Mother Name" },
    { name: "arabicFullName", title: "Arabic FullName" },
    {
      name: "dateOfBirth",
      title: "Date Of Birth",
      getCellValue: (row: any) => {
        return row.dateOfBirth ? moment(row.dateOfBirth.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    { name: "placeOfBirth", title: "Place Of Birth" },
    { name: "permanentAddress", title: "Permanent Address" },
    { name: "currentAddress", title: "Current Address" },
    { name: "phoneNumber", title: "Phone Number" },
    { name: "emergencyContact1", title: "EmergencyContact1" },
    { name: "emergencyContact2", title: "EmergencyContact2" },

    { name: "bankName", title: "Bank Name" },
    { name: "bankAccountName", title: "(Bank)Full Name" },
    { name: "bankFirstName", title: "(Bank)First Name" },
    { name: "bankLastName", title: "(Bank)Last Name" },
    { name: "IBAN", title: "Iban" },
    { name: "bankAccountNumber", title: "Account Number" },
    { name: "customerNumber", title: "Customer Number" },

    { name: "salary", title: "Salary" },
    { name: "currency", title: "Currency" },
    {
      name: "startingDate",
      title: "Starting Date",
      getCellValue: (row: any) => {
        return row.startingDate ? moment(row.startingDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },
    {
      name: "endingDate",
      title: "Ending Date",
      getCellValue: (row: any) => {
        return row.endingDate ? moment(row.endingDate.toDate()).format("DD.MM.YYYY") : "";
      },
    },

    {
      name: "managerUID",
      title: "Supervisor",
      getCellValue: (row: any) =>
        `${AllUsers.docs?.find((d) => d.id == row.managerUID)?.data()?.firstName} ${
          AllUsers.docs?.find((d) => d.id == row.managerUID)?.data()?.lastName
        }`,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (e) => officeSN.docs.find((d) => d.id == e.office)?.data().officeName,
    },
    { name: "position", title: "Position" },
    { name: "vacationAnnualScore", title: "Annual Score", getCellValue: (e) => e.vacationAnnualScore.toFixed(3) },
    { name: "vacationSickScore", title: "Sick Score" },
    {
      name: "vacationCompensationScore",
      title: "Compensation Score",
      getCellValue: (e) => e.vacationCompensationScore.toFixed(3),
    },
    { name: "vacationDeathScore", title: "Death Leave Score", getCellValue: (e) => e.vacationDeathScore.toFixed(3) },
    { name: "vacationMarriageScore", title: "Marriage Score", getCellValue: (e) => e.vacationMarriageScore.toFixed(3) },
    {
      name: "vacationMaternityScore",
      title: "Maternity Score",
      getCellValue: (e) => e.vacationMaternityScore.toFixed(3),
    },
    {
      name: "vacationPaternityScore",
      title: "Paternity Score",
      getCellValue: (e) => e.vacationPaternityScore.toFixed(3),
    },
    {
      name: "status",
      title: "Status",
    },
    { name: "idaAllocationPercentage", title: "Contract Percentage" },

    {
      name: "action",
      title: "Edit User Info",
      getCellValue: (r) => {
        return (
          currentUserData.HRProjectsManagersOffices.includes(r.office) && (
            <Button
              onClick={() => {
                setModalState(true);
                setUID(r.id);
              }}
            >
              Add More
            </Button>
          )
        );
      },
    },
    {
      name: "reset",
      title: "Reset",
      getCellValue: (r) => {
        return (
          <Popconfirm
            title={"Are you sure?"}
            onConfirm={async () => {
              if (r.email && !r.username) {
                await firebase.auth().sendPasswordResetEmail(r.email);
              } else {
                userRef.doc(r.id).update({ password: "ida12345" });
              }
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary">Reset</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "disable",
      title: "Disable/Enable",
      getCellValue: (r) => {
        return (
          currentUserData.HRProjectsManagersOffices.includes(r.office) && (
            <Popconfirm
              title={"Are you sure?"}
              onConfirm={async () => {
                await userRef.doc(r.id).update({
                  status: r.status == "active" || !r.status ? "disable" : "active",
                });
              }}
              onCancel={() => console.log("cancel")}
              okText="Yes"
              cancelText="No"
            >
              <Button type={r.status == "disable" ? "primary" : "danger"}>
                {r.status == "disable" ? "Active" : "Disable"}
              </Button>
            </Popconfirm>
          )
        );
      },
    },
    {
      name: "delete",
      title: "Delete",
      getCellValue: (r) => {
        return (
          currentUserData.HRProjectsManagersOffices.includes(r.office) &&
          (r.email && !r.username ? (
            <Popconfirm
              title={"Are you sure?"}
              onConfirm={async () => {
                await userRef
                  .doc(r.id)
                  .update({
                    status: "delete",
                  })
                  .then(() => {
                    positionRef
                      .where("uid", "==", r.id)
                      .onSnapshot((sn) => sn.docs?.map((d) => positionRef.doc(d.id).delete()));
                  });
              }}
              onCancel={() => console.log("cancel")}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          ) : (
            <Popconfirm
              title={"Are you sure?"}
              onConfirm={async () => {
                await userRef.doc(r.id).delete();
              }}
              onCancel={() => console.log("cancel")}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          ))
        );
      },
    },
    {
      name: "score",
      title: "Score",
      getCellValue: (r) => {
        return (
          admins.includes(uid) && (
            <Popconfirm
              title={"Are you sure?"}
              onConfirm={async () => {
                userRef.doc(r.id).update({
                  vacationAnnualScore: r.vacationAnnualScore + 1.167,
                });
              }}
              onCancel={() => console.log("cancel")}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">Add Score</Button>
            </Popconfirm>
          )
        );
      },
    },
    {
      name: "vacation",
      title: "Vacations",
      getCellValue: (r) => {
        return (
          currentUserData.fullControlOffices.includes(r.office) && (
            <Popconfirm
              title={"Are you sure?"}
              onConfirm={async () => {
                await userRef.doc(r.id).update({
                  vacationAbility: r.vacationAbility == undefined || r.vacationAbility ? false : true,
                });
              }}
              onCancel={() => console.log("cancel")}
              okText="Yes"
              cancelText="No"
            >
              <Button type={r.vacationAbility == undefined || r.vacationAbility ? "danger" : "primary"}>
                {r.vacationAbility == undefined || r.vacationAbility ? "Disable" : "Enable"}
              </Button>
            </Popconfirm>
          )
        );
      },
    },
  ];
  return (
    <div>
      {modalState ? <UsersInformation modalState={modalState} setModalState={setModalState} UID={UID} /> : null}
      {modalStateNewUser ? <NewUser modalState={modalStateNewUser} setModalState={setModalStateNewUser} /> : null}
      {modalStateNewUserByUsername ? (
        <NewUserByUsername modalState={modalStateNewUserByUsername} setModalState={setModalStateNewUserByUsername} />
      ) : null}
      {/* 
      <Button
        onClick={async () => {
          await data
            .filter((f: any) => f.status != "disable")
            .map((user: any) => {
              // const UID = user.id;
              const annual = user?.vacationAnnualScore;

              userRef.doc(user.id).update({
                // employmentType: "fullTime",
                // partTimeSchedule,
                vacationAnnualScore: annual + 1.167,
                // employmentType: "fullTime"
                // paymentType: "cash"
              });
            });
        }}
      >
        add vacations
      </Button> */}

      <Select
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={
          [...UserListOffices, { officeName: "All", id: "notYet" }].find((office) => office.id == officeId)?.officeName
        }
        onChange={(e) => {
          const id = [...UserListOffices, { officeName: "All", id: "notYet" }].find((office) => office.officeName == e)
            ?.id as string;
          setOfficeId(id);
        }}
      >
        {[...UserListOffices, { officeName: "All", id: "notYet" }].map((d) => {
          return <Option value={d.officeName}>{d.officeName}</Option>;
        })}
      </Select>
      {currentUserData.HRProjectsManagersOffices.length != 0 && (
        <>
          <Button
            style={{ margin: "2px" }}
            size="small"
            icon="plus"
            type="primary"
            onClick={() => setModalStateNewUser(true)}
          >
            User By Email
          </Button>
          <Button
            style={{ margin: "2px" }}
            size="small"
            icon="plus"
            type="primary"
            onClick={() => setModalStateNewUserByUsername(true)}
          >
            User By Username
          </Button>{" "}
        </>
      )}
      <Paper>
        <DevTable
          data={officeId == "notYet" ? allUsersData : data}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName={`Users_List_${globalOfficesData[officeId ?? ""]?.officeName}_${moment().format("MMM").toString()}`}
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
