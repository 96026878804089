import React, { useState, useEffect } from "react";
import { auth } from "firebase/app";
import { Button, DatePicker, Drawer, Form, InputNumber, message, Select } from "antd";
import moment from "moment";
const { Option } = Select;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { userRef, contractPercentageRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { contractPercentage } from "../../db/interfaces";
import { USERS_ID_FULL_NAME } from "../../global/usersList";

interface Props {
  contractPercentageId: string;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function EditContractPercentage(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const uid = auth().currentUser!.uid;
  const [usersSN] = useCollection(userRef);
  const [contractPercentageSN] = useDocument(contractPercentageRef.doc(props.contractPercentageId));
  const contractPercentageData = contractPercentageSN?.data() as contractPercentage;



  const formik = useFormik({
    initialValues: {
      uid: "",
      startDate: moment(),
      // endDate: moment(),
      contractPercentage: 0,
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = contractPercentageRef.doc(props.contractPercentageId).update({
        contractPercentage: values.contractPercentage,
        startDate: values.startDate.toDate() as any,
        // endDate: values.endDate.toDate() as any,
        createdAt: new Date(),
        by: uid,
      });
      // .then(() => {
      //   userRef.doc(values.uid).update({
      //     idaAllocationPercentage: values.contractPercentage,
      //   });
      // });
      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (contractPercentageSN && loaded) {
      formik.setValues({
        uid: contractPercentageData.uid,
        contractPercentage: contractPercentageData.contractPercentage,
        startDate: moment(contractPercentageData.startDate.toDate()),
        // endDate: moment(contractPercentageData.endDate.toDate()),
      } as any);
      setLoaded(false);
    }
  }, [contractPercentageSN, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.editContractPercentage")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.contractPercentage")}>
          <InputNumber
            min={0}
            value={formik.values.contractPercentage}
            onChange={(e) => formik.setFieldValue("contractPercentage", e)}
          />
        </Form.Item>

        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>
        {/* <Form.Item label={t("general.endDate")}>
          <DatePicker
            value={formik.values.endDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("endDate", e)}
          />{" "}
        </Form.Item> */}

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditContractPercentage;
