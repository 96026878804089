import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col,
  Modal
} from "antd";
const { TextArea } = Input;
import moment from "moment";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import { notificationsRef, userRef, orderRef, meetingRoomList, calendar } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import DraftText from "./TextEditor/Draft";
import { filter, uniq } from "lodash";
import { Calendar } from "../../db/interfaces";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  docId: string;
}

function DeleteDialog(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState, docId } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [HTMLText, setHTMLText] = useState("");
  const [mentions, setMentions] = useState(Array);
  const newId = meetingRoomList.doc().id;
  const uid = auth().currentUser!.uid;
  const [CalendarSN] = useDocument(calendar.doc(docId));
  const [CalendarSNCollection] = useCollection(calendar);
  const notificationDelete = async (calendarId: string) => {
    const notificationSN = notificationsRef.where("orderId", "==", calendarId).get();
    await notificationSN.then(d => {
      d.forEach(doc => notificationsRef.doc(doc.id).delete());
    });
  };

  if (!CalendarSN || !CalendarSNCollection) {
    return <Spin />;
  }

  const { rRule, isOriginal, originalId, isOnlyOneAppointment, startDate } = CalendarSN?.data() as Calendar;
  const calendarSNObj = (CalendarSNCollection === undefined ? [] : CalendarSNCollection.docs).map(doc => {
    return {
      originalId: doc.data().originalId,
      startDate: doc.data().startDate,
      id: doc.id
    };
  });
  if (isOnlyOneAppointment == false) {
    const deleteAll = async () => {
      console.log(originalId);
      const id = docId == originalId ? docId : originalId;
      let loop = 0;
      const calendarArray = calendarSNObj.filter(e => e.originalId == id).map(x => x.id);

      calendarArray.forEach((d, index) => {
        console.log(d);
        calendar
          .doc(d)
          .delete()
          .then(() => notificationDelete(d));
        loop = index + 1;
        return loop;
      });

      if (calendarArray.length == loop) {
        message.success("Successfully Deleted!");
      }

      setModalState(false);
    };
    const deleteThisAndFollowing = () => {
      console.log(originalId);
      const filteredArray = filter(calendarSNObj, e => e.startDate.toDate() >= startDate.toDate());
      const filteredByOriginalId = filter(filteredArray, e => e.originalId === originalId);
      let loop = 0;

      filteredByOriginalId.forEach((d, index) => {
        console.log("id:", d.id);
        calendar
          .doc(d.id)
          .delete()
          .then(() => notificationDelete(d.id));
        loop = index + 1;
        return loop;
      });

      if (filteredByOriginalId.length == loop) {
        message.success("Successfully Deleted!");
      }

      setModalState(false);
    };
    const deleteOnlyOne = () => {
      console.log(docId);
      calendar
        .doc(docId)
        .delete()
        .then(() => notificationDelete(docId))
        .then(() => message.success("Successfully Deleted!!"));
      setModalState(false);
    };
    return (
      <Modal
        width={500}
        visible={modalState}
        onCancel={() => setModalState(false)}
        closable={true}
        footer={
          <div
            style={{
              textAlign: "right"
            }}
          >
            <Button type="danger" onClick={() => setModalState(false)} style={{ marginRight: 8 }}>
              {t("general.close")}
            </Button>

            <Button onClick={deleteThisAndFollowing} style={{ marginRight: 8 }}>
              {t("general.thisAndFollowing")}
            </Button>
            <Button onClick={deleteAll} style={{ marginRight: 8 }}>
              {t("general.yesAll")}{" "}
            </Button>
            <Button onClick={deleteOnlyOne} type="primary">
              {t("general.onlyThis")}
            </Button>
          </div>
        }
      >
        <div>{t("general.deleteSeries")}</div>
      </Modal>
    );
  } else {
    const deleteOnlyOne = () => {
      calendar
        .doc(docId)
        .delete()
        .then(() => notificationDelete(docId))
        .then(() => message.success("Successfully Deleted!"));
      setModalState(false);
    };

    return (
      <Modal
        //   title={t("general.addMOM")}
        okText={t("general.yes")}
        cancelText={t("general.no")}
        width={500}
        visible={modalState}
        onCancel={() => setModalState(false)}
        onOk={deleteOnlyOne}
        closable={true}
      >
        <div>{t("general.deleteAppointment")}</div>
      </Modal>
    );
  }
}

export default DeleteDialog;
