import React, { useState, useEffect } from "react";
import { auth } from "firebase/app";
import { Button, Drawer, Form, Input, message, Select } from "antd";
const { Option } = Select;
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { departmentsRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { department } from "../../db/interfaces";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from "draft-js-export-html";
import { USERS_ID_FULL_NAME } from "../../global/usersList";

interface Props {
  departmentId: string;
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function EditDepartment(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const uid = auth().currentUser!.uid;
  const [departmentsSN] = useCollection(departmentsRef);
  const [departmentSN] = useDocument(departmentsRef.doc(props.departmentId));
  const departmentData = departmentSN?.data() as department;
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());

  const formik = useFormik({
    initialValues: {
      departmentName: "",
      shortcut: "",
      referenceDepartment: null,
      HOD: null,
      overview: "",
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = departmentsRef.doc(props.departmentId).update({
        departmentName: values.departmentName ?? "",
        shortcut: values.shortcut ?? "",
        overview: html,
        referenceDepartment: values.referenceDepartment ?? null,
        HOD: values.HOD ?? null,
        createdAt: new Date(),
        by: uid,
      });

      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (departmentSN && loaded) {
      formik.setValues({
        departmentName: departmentData.departmentName,
        shortcut: departmentData.shortcut ?? "",
        overview: departmentData.overview,
        referenceDepartment: departmentData.referenceDepartment,
        HOD: departmentData.HOD,
      } as any);
      setLoaded(false);
      setEditorState(EditorState.createWithContent(stateFromHTML(departmentData.overview)));
    }
  }, [departmentSN, formik, loaded]);

  const enterLoading = () => {
    setLoading(true);
  };
  const departments = departmentsSN?.docs?.map((dep) => {
    const departmentData = dep.data() as department;
    return { ...departmentData, id: dep.id };
  });
  return (
    <Drawer
      title={t("general.editDepartment")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.departmentName")}>
          <Input
            value={formik.values.departmentName}
            onChange={(e) => formik.setFieldValue("departmentName", e.target.value)}
          />
        </Form.Item>
        <Form.Item label={t("general.shortcut")}>
          <Input value={formik.values.shortcut} onChange={(e) => formik.setFieldValue("shortcut", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.referenceDepartment")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            allowClear
            size="default"
            placeholder="Parent Department"
            showSearch
            value={formik.values.referenceDepartment}
            onChange={(e) => {
              formik.setFieldValue("referenceDepartment", e);
            }}
          >
            {departments?.map((d) => {
              return <Option value={d.id}>{d.departmentName}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.HOD")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            allowClear
            placeholder="Head of department"
            showSearch
            value={formik.values.HOD}
            onChange={(e) => {
              formik.setFieldValue("HOD", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item label={t("general.overview")}>
          <DraftText
            onValueChange={() => {}}
            onMentionChange={() => {}}
            html={""}
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default EditDepartment;
