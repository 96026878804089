import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import firebase, { auth } from "firebase/app";
import { Spin, Button, Select } from "antd";
import { missionRef, userRef } from "../../db/collectionsRef";
import moment from "moment";
import { useRouteMatch } from "react-router-dom";
import { delay, mission, users } from "../../db/interfaces";
import { currentUserData } from "../../db/userGlobal";
import MissionDialog from "./missionDialog";
import { globalUsersData } from "../../global/usersList";
import DevTable from "../../helpers/devex-table";

const db = firebase.firestore();
export const usersRef = db.collection("users");

export default function MissionsTable() {
  const [modalState, setModalState] = useState(false);
  const [mission, setMission] = useState({} as any);
  const uid = auth().currentUser?.uid;

  const [defaultHiddenColumnNames] = useState([]);
  const [missionSN] = useCollection(missionRef);

  if (missionSN == undefined) return <Spin />;

  const data = missionSN?.docs.map((d, index) => {
    return {
      ...d.data(),
      id: d.id
    } as mission;
  });

  const columnsExport = [
    { name: "missionName", title: "Mission Name" },
    {
      name: "missionCEO",
      title: "Mission CEO",
      getCellValue: (r: mission) => {
        return r.missionCEO.map(
          (uid, index) => `${index + 1}) ${globalUsersData[uid]?.firstName + " " + globalUsersData[uid]?.lastName}`
        );
      }
    },

    { name: "missionLocation", title: "Mission Location" },
    { name: "weekendDays", title: "Weekend" },
    { name: "holidayDates", title: "Official Holidays" }
  ];
  const columns = [
    { name: "missionName", title: "Mission Name" },
    {
      name: "missionCEO",
      title: "Mission CEO",
      getCellValue: (r: mission) => {
        return r.missionCEO.map((uid, index) => (
          <>
            <b>{`${index + 1}) ${globalUsersData[uid]?.firstName + " " + globalUsersData[uid]?.lastName}`}</b>
            <br />
          </>
        ));
      }
    },
    { name: "missionLocation", title: "Mission Location" },
    {
      name: "weekendDays",
      title: "Weekend",
      getCellValue: (r: mission) => {
        return r.weekendDays.map((day, index) => (
          <>
            <b>{`${index + 1}) ${day}`}</b>
            <br />
          </>
        ));
      }
    },
    {
      name: "holidayDates",
      title: "Official Holidays",
      getCellValue: (r: mission) => {
        return r.holidayDates.map((date, index) => (
          <>
            <b>{`${index + 1}) ${date}`}</b>
            <br />
          </>
        ));
      }
    },

    {
      name: "action",
      title: "Edit",
      getCellValue: (r: mission) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setMission(r);
              setModalState(true);
            }}
          >
            Edit
          </Button>
        );
      }
    }
  ];
  return (
    <div>
      <Button
        style={{ marginBottom: "1%" }}
        type={"primary"}
        onClick={() => {
          setModalState(true);
          setMission(undefined);
        }}
      >
        Add Mission
      </Button>
      {modalState ? <MissionDialog modalState={modalState} setModalState={setModalState} mission={mission} /> : null}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={defaultHiddenColumnNames}
          tableName="Missions"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
