import React from "react";
import { Icon, Spin, Descriptions, Tag, List, Modal } from "antd";
import { globalTasksData } from "../../global/taskList";
import { globalDepartmentPositionsData } from "../../global/departmentPositionList";
import { globalUsersData } from "../../global/usersList";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  taskData: any;
}

function ViewTaskDialog(props: Props) {
  const { modalState, setModalState, taskData } = props;

  const task = globalTasksData[taskData?.taskId ?? ""];
  const position = globalDepartmentPositionsData[taskData?.positionId ?? ""];

  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;
  const COLORS = ["#00C49F", "#FFBB28", "#0088FE", "#FF8042", "#8B008B", "#FF1493", "#B22222", "#ADD8A9"];

  return (
    <Modal
      title={`${task.code} - ${task.title}`}
      closable={true}
      width={800}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
      }}
      footer={false}
    >
      <Spin spinning={false} size="large" indicator={antIcon}>
        <div style={{ padding: "2%" }}>
          <Descriptions
            layout="horizontal"
            size="middle"
            bordered
            column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item span={2} label="Code">
              {task.code}
            </Descriptions.Item>
            <Descriptions.Item span={4} label="Title">
              {task.title}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Position">
              {position.title}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Assigned To">
              {position.uid ? globalUsersData[position.uid ?? ""]?.fullName : "Vacant"}
            </Descriptions.Item>
            <Descriptions.Item span={6} label="Roles">
              {taskData?.role?.map((r: any, index: any) => (
                <Tag style={{ marginBottom: "1%", marginTop: "1%" }} color={COLORS[index]}>
                  {r}
                </Tag>
              ))}
            </Descriptions.Item>
            <Descriptions.Item span={6} label="Tags">
              {taskData?.tags?.map((r: any) => {
                const randomIndex = Math.floor(Math.random() * COLORS.length);
                return (
                  <Tag style={{ marginBottom: "1%", marginTop: "1%" }} color={COLORS[randomIndex]}>
                    {r}
                  </Tag>
                );
              })}
            </Descriptions.Item>
            <Descriptions.Item span={6} label="Description">
              <div style={{ margin: "1%" }} dangerouslySetInnerHTML={{ __html: task.description }} />
            </Descriptions.Item>
          </Descriptions>
          {task?.attached_files.length > 0 && (
            <div style={{ marginTop: "2%" }}>
              <List
                header={"Attachments:"}
                itemLayout="horizontal"
                bordered
                dataSource={task?.attached_files}
                renderItem={(file: any) => (
                  <List.Item>
                    <div>
                      <a href={file.url} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          )}
        </div>
        <Tag color={"#00C49F"}>R: Responsible </Tag>
        <Tag color="#FFBB28">A: Accountable </Tag>
        <Tag color="#0088FE">C: Consulted </Tag>
        <Tag color="#FF8042">I: Informed </Tag>
      </Spin>
    </Modal>
  );
}

export default ViewTaskDialog;
