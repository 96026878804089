export default {
  common: {
    workPermit: "اذن العمل",
    ok: "نعم",
    requestedAt: "Requested At",
    status: "Status",
    statusControl: "Status Control",
    firstTime: "First Time",
    requestedBy: "Requested By",
    orderId: "Order Id",
    permitExpiryDate: "Expiry Date",

    TC: "TC",
    firstName: "Name",
    lastName: "Last Name",
    fatherName: "Father Name",
    motherName: "Mother Name",
    gender: "Gender",
    birthPlace: "Birth Place",
    birthDate: "Birth Date",
    marriedStatus: "Married Status",
    nationality: "Nationality",
    femaleChildrenNumber: "Female Children Number",
    maleChildrenNumber: "Male Children Number",
    address: "Address",
    wifeNationality: "Wife Nationality",
    wifeFirstName: "Wife Name",
    wifeLastName: "Wife Surname",
    wifeTC: "Wife TC",
    phone: "Phone Number",
    email: "Email",
    universityNameAddress: "University Name/Address",
    degree: "Degree",
    degreeField: "Degree Field",
    degreeSpecialization: "Degree Specialization",
    degreeFaculty: "Faculty",
    passportNO: "Passport NO",
    passportExpiryDate: "Passport Expiry Date",
    passportAuth: "Passport Auth",
    position: "Position",
    salary: "Salary",
    cityInSyria: "City In Syria",
    durationInTurkey: "Duration In Turkey",
    language: "Language"
  }
};
