import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  notification,
  TimePicker
} from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileUploader from "react-firebase-file-uploader";

import { notificationsRef, userRef, orderRef, vacationRef, overtimeRef } from "../../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { generateAndUploadWord } from "../../orders/generateAndUploadWord";
import { vacations, users, overtime } from "../../../db/interfaces";
import { currentUserData } from "../../../db/userGlobal";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  orderId: string;
}
const projectSchema = Yup.object().shape({
  overtimeDate: Yup.date().required("*")

});

function EditOvertime(props: Props) {
  const { t } = useTranslation();

  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const uid = auth().currentUser!.uid;


  const {
    position,
    managerUID,
    office,
  } = currentUserData
  const [overtimeSN] = useDocument(overtimeRef.doc(props.orderId));
  const overtimeData = overtimeSN?.data() as overtime;

  const formik = useFormik({
    initialValues: {
      compensationHours: 0,
      overtimeDate: moment(),
      tasks: "",
      overtimeEndHours: moment(),
      overtimeStartHours: moment(),
      taskOvertime : moment()
    },
    validationSchema: projectSchema,
    onSubmit: async (values, form) => {
      const promise = overtimeRef.doc(props.orderId).set(
        {
          employeeUID: uid,
          position:position,
          managerUID:managerUID,

          employeeSignatureDate: firebase.firestore.Timestamp.fromDate(new Date()),
          managerSignatureDate: "",
          adminSignatureDate: "",
          HRSignatureDate: "",
          CEOSignatureDate: "",
          office:office,
          RFCURL: "",
          // HRUserUID: "",
          // adminUID: "",

          // HRUserUID: "",
          status: "Requested",
          requestedAt: firebase.firestore.Timestamp.fromDate(new Date()),
          tasks: values.tasks,
          overtimeDate: values.overtimeDate.toDate() as any,
          overtimeStartHours: values.overtimeStartHours.toDate() as any,
          overtimeEndHours: values.overtimeEndHours.toDate() as any,
          taskOvertime: values.taskOvertime.toDate() as any,
          compensationHours: values.compensationHours,
        },
        { merge: true }
      );
      promise     
        .then(() => {
          message.success("Your request has been submitted successfully");
          console.log("Document successfully written!");
          setModalState(false);
          setLoading(false);
          history.push(`/overtime/${props.orderId}`);
          setLoading(false);
        })

        .then(() => form.resetForm());
      promise.catch(error => {
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    }
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (overtimeSN && loaded) {
      formik.setValues({
        compensationHours: overtimeData.compensationHours,
        overtimeDate: moment(overtimeData.overtimeDate.toDate()),
        tasks: overtimeData.tasks,
        overtimeEndHours: moment(overtimeData.overtimeEndHours.toDate()),
        overtimeStartHours: moment(overtimeData.overtimeStartHours.toDate()),
        taskOvertime: moment(overtimeData.taskOvertime.toDate()),
      } as any);
      setLoaded(false);

    }
  }, [overtimeSN, formik, loaded]);
  const enterLoading = () => {
    setLoading(true);
  };
  const format = "hh:mm a";

  const dateFormat = "YYYY/MM/DD";
  return (
    <Drawer title="Compensation Request" width={600} visible={modalState} onClose={() => setModalState(false)}>
      <Form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Descriptions
              size={"default"}
              layout="horizontal"
              bordered
              column={{ xxl: 6, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              {/* --Over Time Request --- */}

              <Descriptions.Item label={t("general.overtimeDate")} span={6}>
                <DatePicker
                  value={formik.values.overtimeDate}
                  format={dateFormat}
                  onChange={e => formik.setFieldValue("overtimeDate", e)}
                />{" "}
              </Descriptions.Item>

              <Descriptions.Item label={t("general.overtimeStartHours")} span={6}>
                <TimePicker
                  use12Hours
                  format={format}
                  value={formik.values.overtimeStartHours}
                  onChange={e => formik.setFieldValue("overtimeStartHours", e)}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.overtimeEndHours")} span={6}>
                <TimePicker
                  use12Hours
                  value={formik.values.overtimeEndHours}
                  onChange={e => formik.setFieldValue("overtimeEndHours", e)}
                  format={format}
                />
              </Descriptions.Item>

              <Descriptions.Item label={t("general.compensationHours")} span={6}>
                <InputNumber
                  max={20}
                  min={0}
                  value={formik.values.compensationHours}
                  onChange={e => formik.setFieldValue("compensationHours", e)}
                />
              </Descriptions.Item>
              <Descriptions.Item label={t("general.taskOvertime")} span={6}>
                <DatePicker
                  value={formik.values.taskOvertime}
                  format={dateFormat}
                  onChange={e => formik.setFieldValue("taskOvertime", e)}
                />{" "}
              </Descriptions.Item>
              <Descriptions.Item label={t("general.tasks")} span={6}>
                <TextArea
                  value={formik.values.tasks}
                  autoSize={{ minRows: 8, maxRows: 20 }}
                  onChange={e => formik.setFieldValue("tasks", e.target.value)}
                />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
        <div>
          <Button
            style={{ margin: "1%" }}
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>

      </Form>
    </Drawer>
  );
}

export default EditOvertime;
