import React from "react";
import { Link } from "react-router-dom";
import { Card, Badge } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  employeeUID: string;

  status: string;
}

export default function OvertimeSummary(props: Props) {
  const { t } = useTranslation();
  const { id, employeeUID, status } = props;
  const statusBadge =
    status == "Requested"
      ? "warning"
      : status == "Pending"
      ? "processing"
      : status == "Rejected" || status == "Canceled"
      ? "default"
      : status == "Accepted"
      ? "success"
      : "error";

  return (
    <Link to={`/overtime/${id}`}>
      <Card
        title={employeeUID}
        bordered={false}
        style={{
          width: 300,
          marginTop: "10%",
          borderRadius: "10px",
          boxShadow: "6px 7px 17px 3px rgba(0, 0, 0, 0.09)",
          background: "#fff"
        }}
      >
        <Badge status={statusBadge} text={status} />
        <br />
      </Card>
    </Link>
  );
}
