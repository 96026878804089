import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Button, Popconfirm, Select, DatePicker } from "antd";
const { Option } = Select;

import { userRef, officeRef, positionRef, deductionRef, salariesRef } from "../../db/collectionsRef";

import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import AddNewDeduction from "./addNewSalaryDialog";
import { currentUserData } from "../../db/userGlobal";
import { uniq } from "lodash";
import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import AddNewSalary from "./addNewSalaryDialog";
import EditSalary from "./editSalaryDialog";
const { MonthPicker } = DatePicker;

export default function SalariesTable() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [officeId, setOfficeId] = useState("");
  const [month, setMonth] = useState(moment());
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [salaryId, setSalaryId] = useState("");

  const [salariesSN] = useCollection(salariesRef.where("office", "==", officeId));
  const [officeSN] = useCollection(officeRef);

  if (!salariesSN || !officeSN) return <Spin />;

  const data = salariesSN.docs
  .filter((d) => currentUserData.HRProjectsManagersOffices.includes(d.data()?.office))
  .map((d) => {
    return { ...d.data(), id: d.id };
  });
  const UserOffices = [...(currentUserData.HRProjectsManagersOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map((id) => {
    const officeName = officeSN?.docs?.find((d) => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });
  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },
    {
      name: "salary",
      title: "Salary",
    },
    {
      name: "currency",
      title: "Currency",
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office]?.officeName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY"),
    // },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              salariesRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setSalaryId(row.id);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      }
    }
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },
    {
      name: "salary",
      title: "Salary",
    },
    {
      name: "salary",
      title: "Salary",
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[row.office]?.officeName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY"),
    // },
  ];

  return (
    <div>
      <Select
        size="default"
        placeholder="Search for Office!"
        style={{ width: "25%", paddingBottom: "1%", paddingRight: "1%" }}
        showSearch
        value={[...UserListOffices].find((office) => office.id == officeId)?.officeName}
        onChange={(e) => {
          const id = [...UserListOffices].find((office) => office.officeName == e)?.id as string;
          setOfficeId(id);
        }}
      >
        {[...UserListOffices].map((d) => {
          return <Option value={d.officeName}>{d.officeName}</Option>;
        })}
      </Select>
      <AddNewSalary modalState={modalState} setModalState={setModalState} />
      {modalStateEdit ? (
        <EditSalary
          modalState={modalStateEdit}
          setModalState={setModalStateEdit}
          salaryId={salaryId}
        />
      ) : null}{" "}
      {/* <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewSalary")}
      </Button> */}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="deduction"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
