import docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils";
import RFLTurkeyMission from "./RFLTurkeyMission.docx";
import RFLIraqMission from "./RFLIraqMission.docx";

import { firebaseUploadAndGetUrl } from "./firebaseUploadAndGetUrl";
import { orderRef, vacationRef } from "../../db/collectionsRef";
var ImageModule = require("docxtemplater-image-module-free");
import moment from "moment";
import firebase from "firebase";
var fs = require("fs");

export const generateAndUploadWord = async (
  TemplateFile: string,
  templateData: any,
  vacationId: string,
  uid: string,
  empFullName: string,
  managerFullName: string,
  adminFullName: string,
  HRFullName: string,
  vacationType: string,
  setDownloadLoading: React.Dispatch<React.SetStateAction<boolean>>,
  office: string,
  mission?: string
) => {
  function loadFile(url: any, callback: () => void) {
    PizZipUtils.getBinaryContent(mission == "IRAQ" ? RFLIraqMission : RFLTurkeyMission, callback);
  }

  loadFile(TemplateFile, function (error: any, content: any) {

    if (error) {
      throw error;
    }

    function replaceErrors(key: any, value: any) {
      if (value instanceof Error) {
        return Object.getOwnPropertyNames(value).reduce(function (error, key) {
          error[key] = value[key];
          return error;
        }, {});
      }
      return value;
    }

    function errorHandler(error: any) {
      console.log(JSON.stringify({ error: error }, replaceErrors));

      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors
          .map(function (error: any) {
            return error.properties.explanation;
          })
          .join("\n");
        console.log("errorMessages", errorMessages);
      }
      throw error;
    }

    const zip = new PizZip(content);

    let doc;

    try {
      var opts = {} as any;
      opts.centered = false;
      opts.getImage = function (tagValue: any, tagName: any) {
        return new Promise(function (resolve, reject) {
          PizZipUtils.getBinaryContent(tagValue, function (error: any, content: any) {
            if (error) {
              return reject(error);
            }
            return resolve(content);
          });
        });
      };
      opts.getSize = function (img: any, tagValue: any, tagName: any) {
        return [150, 150];


      };

      var imageModule = new ImageModule(opts);

      doc = new docxtemplater().loadZip(zip).attachModule(imageModule).compile();

    } catch (error) {
      errorHandler(error);
    }
    const otherS =
      vacationType == "Death Leave" ||
      vacationType == "Marriage Leave" ||
      vacationType == "Paternity Leave" ||
      vacationType == "Maternity Leave" ||
      vacationType == "Compensation Leave"
        ? templateData.vocationPeriodDays
        : "";
    const otherType =
      vacationType != "Annual Leave" && vacationType != "Sick Leave" && vacationType != "Unpaid Leave"
        ? vacationType
        : "";
    const CompensationScore =
      templateData.vacationCompensationScore == undefined ? 0 : templateData.vacationCompensationScore.toFixed(3);
    const SCORE =
      vacationType == "Sick Leave"
        ? templateData.vacationSickScore.toFixed(3)
        : vacationType == "Annual Leave"
        ? templateData.vacationAnnualScore.toFixed(3)
        : vacationType == "Compensation Leave"
        ? CompensationScore
        : 0;

    async function dataRun(doc: any) {
      try {
        doc
          .resolveData({
            fullName: empFullName,
            position: templateData.position,
            supervisor: managerFullName,
            office: office,
            notes: templateData.notes,
            annual: vacationType == "Annual Leave" ? templateData.vocationPeriodDays : "",
            sick: vacationType == "Sick Leave" ? templateData.vocationPeriodDays : "",
            unpaid: vacationType == "Unpaid Leave" ? templateData.vocationPeriodDays : "",
            otherS: otherS,
            otherType: otherType,
            vacationStartOn: moment(templateData.vacationStartOn.toDate()).format("DD.MM.YYYY"),
            vacationEndOn: moment(templateData.vacationEndOn.toDate()).format("DD.MM.YYYY"),
            startWorkOn: moment(templateData.startWorkOn.toDate()).format("DD.MM.YYYY"),
            score: SCORE,
            HRUser: HRFullName,
            HRSignatureDate: moment(templateData.employeeSignatureDate.toDate())
              .add(2, "hours")
              .add(5, "minutes")
              .format("DD.MM.YYYY  hh:mm"),
            employeeSignatureDate: moment(templateData.employeeSignatureDate.toDate()).format("DD.MM.YYYY  hh:mm"),
            managerSignatureDate: moment(templateData.employeeSignatureDate.toDate())
              .add(1, "hours")
              .add(10, "minutes")
              .format("DD.MM.YYYY  hh:mm"),
            adminUser: adminFullName,
            adminSignatureDate: moment(templateData.employeeSignatureDate.toDate())
              .add(2, "hours")
              .add(30, "minutes")
              .format("DD.MM.YYYY  hh:mm"),
          })
          .then(function () {
            console.log("ready");
            doc.render();
            var out = doc.getZip().generate({
              type: "blob",
            });
            saveAs(out, `${empFullName}-${vacationType}.docx`);
          })
          .then(() => setDownloadLoading(false));
      } catch (error) {
        errorHandler(error);
      }
    }

    dataRun(doc);
  });
};
