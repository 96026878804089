import React, { useState } from "react";
import firebase, { auth } from "firebase/app";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Form, Input, Button, Checkbox, message, Icon } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import logo from "./aiu-logo.png";
import "./style.css";

const SignInSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required").min(6).max(15),
});

const isValidEmail = (text: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(text);
};

export default function SignIn(s: RouteComponentProps) {
  const [loading, setLoading] = useState(false);
  const history = useHistory(); // IT MUST BE OUT OF THE FUNCTION

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SignInSchema,
    onSubmit: (v) => handleSubmit(v),
  });

  const handleSubmit = (valuesS: { email: string; password: string }) => {
    if (isValidEmail(valuesS.email)) {
      loginByEmail(valuesS);
    } else {
      loginByUsername(valuesS);
    }
  };

  const loginByEmail = (valuesS: { email: string; password: string }) => {
    const promise = firebase.auth().signInWithEmailAndPassword(valuesS.email, valuesS.password);
    promise.then((user) => {
      console.log("signed in");
      message.success("Signed In successfully");
      history.push(`/`);
    });

    promise.catch((error) => {
      console.log(error);
      var errorMessage = error.message;
      message.error(errorMessage);
      setLoading(false);
    });
  };

  const loginByUsername = (valuesS: { email: string; password: string }) => {
    const username = valuesS.email;
    const password = valuesS.password;

    fetch("https://us-central1-administrationapp-cdc55.cloudfunctions.net/loginByUsername", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("here")
        // Handle the response from the Firebase Cloud Function
        // Add further logic for handling login success or failure here
        const promise = firebase.auth().signInWithCustomToken(data.customToken);
        promise.then((user) => {
          console.log("signed in");
          message.success("Signed In successfully");
          history.push(`/`);
        });
        setLoading(false);
      })
      .catch((error) => {
        message.error('Username or Password is not Correct!')
        console.error("Error calling Firebase Function:", error);
        setLoading(false);
      });
  };
  const enterLoading = () => {
    setLoading(true);
  };

  return (
    <div className={"boxForm"}>
      <div
        style={{
          width: "450px",
          marginTop: "6%",
          padding: "2%",
          border: "solid",
          borderWidth: "thin",
          borderColor: "rgb(128 128 128 / 17%)",
          borderRadius: "11px",
          background: "rgb(245 245 245 / 36%)",
          boxShadow: "0px 1px 10px 2px rgba(0, 0, 0, 0.18)",
        }}
      >
        <img style={{ width: "50%", marginBottom: "8%", marginTop: "0%" }} src={logo} />

        <Form labelCol={{ span: 8 }} onSubmit={formik.handleSubmit} className="login-form">
          <Form.Item>
            <Input
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Email Address"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Input.Password
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
              value={formik.values.password}
              onChange={(e) => formik.setFieldValue("password", e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox>Remember me</Checkbox>
            <a className="login-form-forgot" href="/forget-password">
              Forgot password
            </a>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
              onClick={enterLoading}
              loading={loading}
              disabled={!formik.values.email || !formik.values.password}
            >
              Log in
            </Button>
            {/* Or <a href="/signup">register now!</a> */}
          </Form.Item>
        </Form>
        <div style={{ textAlign: "center" }}>
          <b>
            <h2 style={{ color: "grey" }}>Administration Software</h2>
          </b>
          <p style={{ color: "#1890ff" }}>Made by AIU: Omar Azzi</p>
        </div>
      </div>
    </div>
  );
}
