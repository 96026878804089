import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, message, Modal, Row, Spin, Statistic, Switch } from "antd";
import ReactPlayer from "react-player";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { usersRef } from "../../db/collectionsRef";
import { delay, users } from "../../db/interfaces";
import { useFormik } from "formik";
import TextArea from "antd/lib/input/TextArea";
import { auth } from "firebase";
import moment from "moment";

interface Props {
  delay: delay;
  modalState: boolean;
  setModalState: any;
}

function DelayDisableDialog(props: Props) {
  const { delay, modalState, setModalState } = props;
  const uid = auth().currentUser?.uid;

  const formik = useFormik({
    initialValues: {
      notes: "",
    },
    onSubmit: async (values, form) => {
      await usersRef
        .doc(delay.userUID)
        .collection("delay")
        .doc(delay.id)
        .update({
          notes: values.notes,
          canceledBy: uid,
          canceledDate: moment().toDate(),
          status: "disabled",
        })
        .then(() => {
          message.success("Done!");
          setModalState(false);
        });
    },
  });

  return (
    <Modal
      closable={true}
      width={800}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
      }}
      onOk={() => {
        formik.submitForm();
      }}
    >
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label="Notes">
            <TextArea placeholder={"Notes"} onChange={(e) => formik.setFieldValue("notes", e.target.value)} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default DelayDisableDialog;
