import React, { useEffect, useState } from "react";
import { Modal, Select, Spin } from "antd";
import { caseRef, missionRef, userRef, usersRef } from "../../db/collectionsRef";
import { Case } from "../../db/interfaces";
import { auth } from "firebase";
import moment from "moment";
import { useCollection } from "react-firebase-hooks/firestore";
import DevTable from "../../helpers/devex-table";
import EditCase from "./EditCase";
const { Option } = Select;

interface Props {
  barFilters: any;
  setBarFilters: any;
  columns: any[];
  columnsExport: any[];
  modalState: boolean;
  setModalState: any;
  startDate: any;
  endDate: any;
}

function TableBarDialog(props: Props) {
  const { endDate, startDate, barFilters, setBarFilters, columns, columnsExport, modalState, setModalState } = props;
  const [modalStateCase, setModalStateCase] = useState(false);
  const [caseDoc, setCaseDoc] = useState({} as Case);
  // const uid = auth().currentUser?.uid;
  const [usersSN] = useCollection(userRef);
  // const USERS_ID_FULL_NAME = usersSN?.docs.map((u) => {
  //   const fullName = u.data()?.firstName + " " + u.data()?.lastName+ " _ " + u.data().resourceNO;
  //   const uid = u.id;
  //   return { uid, fullName };
  // });
  caseRef
    .where("createdAt", ">=", startDate.toDate())
    .where("createdAt", "<=", endDate.toDate())
    .where("cacheOffice", "==", barFilters.office ?? "not")
    .where("status", "==", barFilters.status ?? "not")
    .get();
  caseRef
    .where("createdAt", ">=", startDate.toDate())
    .where("createdAt", "<=", endDate.toDate())
    .where("cacheAssignedTo", "==", barFilters.cacheAssignedTo ?? "not")
    .where("status", "==", barFilters.status ?? "not")
    .get();

  const [caseOfficeAndStatusSN] = useCollection(
    caseRef
      .where("createdAt", ">=", startDate.toDate())
      .where("createdAt", "<=", endDate.toDate())
      .where("cacheOffice", "==", barFilters.office ?? "not")
      .where("status", "==", barFilters.status ?? "not")
  );

  const [caseAssignedToAndStatusSN] = useCollection(
    caseRef
      .where("createdAt", ">=", startDate.toDate())
      .where("createdAt", "<=", endDate.toDate())
      .where("cacheAssignedTo", "==", barFilters.assignedTo ?? "not")
      .where("status", "==", barFilters.status ?? "not")
  );
  if (!caseOfficeAndStatusSN || !caseAssignedToAndStatusSN || !usersSN) return <Spin />;

  const dataOfficeAndStatus = caseOfficeAndStatusSN.docs.map((d) => {
    return { ...d.data(), id: d.id };
  });
  const dataAssignedToAndStatus = caseAssignedToAndStatusSN.docs.map((d) => {
    return { ...d.data(), id: d.id };
  });

  // console.log({
  //   dataOfficeAndStatus,
  //   dataAssignedToAndStatus,
  // });

  const data = dataOfficeAndStatus.length != 0 ? dataOfficeAndStatus : dataAssignedToAndStatus;

  return (
    <Modal
      closable={true}
      width={1200}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);

        setBarFilters({ status: "", assignedTo: "", office: "" });
      }}
    >
      <div>
        {modalStateCase ? (
          <EditCase modalState={modalStateCase} setModalState={setModalStateCase} caseDoc={caseDoc} />
        ) : null}
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="cases"
          selected={false}
          typicalTable={false}
          defaultColumnWidths={[]}
          columnsExport={columnsExport}
          rowClick={({ args, row }) => {
            setCaseDoc(row);
            setModalStateCase(true);
          }}
        />
      </div>
    </Modal>
  );
}

export default TableBarDialog;
