import React, { useState } from "react";
import firebase from "firebase/app";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Form, Input, Button, Checkbox, message, Icon } from "antd";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./style.css";
import { currentUserData } from "../../db/userGlobal";
import { userRef } from "../../db/collectionsRef";

const SignInSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Required"),
  newPassword: Yup.string().min(6, "Password must be at least 6 characters").required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Required"),
});

export default function ResetPasswordByUsername() {
  const [loading, setLoading] = useState(false);
  const history = useHistory(); // IT MUST BE OUT OF THE FUNCTION
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },

    validationSchema: SignInSchema,

    onSubmit: (v) => handleSubmit(v),
  });

  const handleSubmit = (valuesS: any) => {
    if (currentUserData.password == valuesS.oldPassword) {
      userRef
        .doc(currentUserData.uid)
        .update({
          password: valuesS.newPassword,
        })
        .then(() => {
          message.success("Password been reset successfully");
          history.push(`/`);
          setLoading(false);
        });
    }
  };

  const enterLoading = () => {
    setLoading(true);
  };

  return (
    <div className={"boxForm"}>
      <div
        style={{
          width: "450px",
          marginTop: "6%",
          borderWidth: "thin",
          borderColor: "rgb(128 128 128 / 17%)",
          borderRadius: "11px",
          background: "rgb(245 245 245 / 36%)",
        }}
      >
        <Form labelCol={{ span: 8 }} onSubmit={formik.handleSubmit} className="login-form">
          <Form.Item>
            <Input
              id="oldPassword"
              prefix={<Icon type="eye" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Old Password"
              onChange={(e) => formik.setFieldValue("oldPassword", e.target.value)}
            />
            <h6 style={{ textAlign: "left", color: "red" }}>{formik.errors.oldPassword}</h6>
          </Form.Item>
          <Form.Item>
            <Input
              id="newPassword"
              prefix={<Icon type="eye" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="New Password"
              onChange={(e) => formik.setFieldValue("newPassword", e.target.value)}
            />
            <h6 style={{ textAlign: "left", color: "red" }}>{formik.errors.newPassword}</h6>
          </Form.Item>
          <Form.Item>
            <Input
              id="confirmPassword"
              prefix={<Icon type="eye" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Confirm New Password"
              onChange={(e) => formik.setFieldValue("confirmPassword", e.target.value)}
            />
            <h6 style={{ textAlign: "left", color: "red" }}>{formik.errors.confirmPassword}</h6>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ width: "100%" }}
              onClick={enterLoading}
              loading={loading}
              disabled={!formik.isValid}
            >
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
