import React, { useState, useEffect } from "react";
import firebase, { auth } from "firebase/app";
import {
  Button,
  Drawer,
  Form,
  message,
  Select,
  Input,
  Upload,
  Icon,
  Row,
  Spin,
  Checkbox,
  List,
  Popconfirm,
  Col,
} from "antd";
const { TextArea } = Input;
import { useFormik } from "formik";
import * as Yup from "yup";
import { tasksRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { task } from "../../db/interfaces";
import DraftText from "../Meetings_Rooms/TextEditor/Draft";
import { stateToHTML } from "draft-js-export-html";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  departmentId: string;
  task: task;
}
const projectSchema = Yup.object().shape({
  // departmentName: Yup.string().required("Department Name is required"),
  // HOD: Yup.string().required("Head of department is required"),
});

function EditTask(props: Props) {
  const storage = firebase.storage();
  const storageRef = storage.ref();
  const { t } = useTranslation();
  const { modalState, setModalState, departmentId, task } = props;
  const [loading, setLoading] = useState(false);
  const [uploadTaskFiles, setUploadTaskFiles] = useState([] as any);
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createWithContent(stateFromHTML("")));
  const html = stateToHTML(editorState.getCurrentContent());
  
  const uid = auth().currentUser!.uid;

  const formik = useFormik({
    initialValues: {
      departmentId: "",
      code: "",
      title: "",
      description: "",
      attached_files: [],
      duration: 0,
      sharable: false,
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const promise = tasksRef.doc(task.id).update({
        departmentId: departmentId,
        code: values.code,
        title: values.title,
        description: html,
        attached_files: values.attached_files ?? [],
        duration: values.duration,
        sharable: values.sharable,
        createdAt: new Date(),
        by: uid,
      });
      promise.then(() => {
        setModalState(false);
        setLoading(false);
        message.success("Task is Updated successfully!");
        formik.resetForm();
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (task && loaded) {
      formik.setValues({
        departmentId: task.departmentId,
        code: task.code,
        title: task.title,
        description: task.description,
        attached_files: task.attached_files,
        duration: task.duration,
        sharable: task.sharable,
      } as any);
      setLoaded(false);
      setEditorState(EditorState.createWithContent(stateFromHTML(task.description)));

    }
  }, [task, formik, loaded]);

  const handleFileUpload = async (files: any) => {
    try {
      const downloadURLs = await Promise.all(
        files.map(async (file: any) => {
          const fileRef = storageRef.child(`departments/${departmentId}/tasks/${task.id}/${file.originFileObj.name}`);
          await fileRef.put(file.originFileObj);
          return {
            name: file.originFileObj.name,
            url: await fileRef.getDownloadURL(),
          };
        })
      );
      return downloadURLs;
    } catch (error) {
      console.error("Error uploading files:", error);
      return [];
    }
  };

  const uploadTask = async () => {
    setLoading(true);
    const promise = handleFileUpload(uploadTaskFiles);
    promise
      .then((newTaskFiles) => {
        formik.setFieldValue("attached_files", [...formik.values.attached_files, ...newTaskFiles]);
      })
      .then(() => {
        setUploadTaskFiles([]);
        message.success("Files are uploaded successfully!");
        setLoading(false);
      });
    promise.catch(() => {
      message.error("Something went wrong!");
      setLoading(false);
    });
  };

  const handleDeleteFile = async (fileName: string, attachmentFolder: string) => {
    try {
      setLoading(true);
      const fileRef = storageRef.child(`departments/${departmentId}/tasks/${task.id}/${fileName}`);
      await fileRef.delete().then(async () => {
        const currentFiles = (formik.values as any)[attachmentFolder];
        const filteredCurrentFiles = currentFiles.filter((r: any) => r.name != fileName);
        formik.setFieldValue(attachmentFolder, filteredCurrentFiles);
        setLoading(false);
      });
    } catch (error) {
      console.error("Error deleting file:", error);
      setLoading(false);
    }
  };

  const antIcon = <Icon type="loading" style={{ fontSize: 75 }} spin />;
  return (
    <Drawer
      title={t("general.editResponsibility")}
      width={700}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Spin spinning={loading} size="large" indicator={antIcon}>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Item label={t("general.code")}>
            <Input value={formik.values.code} onChange={(e) => formik.setFieldValue("code", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.title")}>
            <Input value={formik.values.title} onChange={(e) => formik.setFieldValue("title", e.target.value)} />
          </Form.Item>
          <Form.Item label={t("general.description")}>
          <DraftText
              onValueChange={() => {}}
              onMentionChange={() => {}}
              html={""}
              editorState={editorState}
              setEditorState={setEditorState}
            />

            {/* <TextArea
              value={formik.values.description}
              autoSize={{ minRows: 3, maxRows: 5 }}
              onChange={(e) => formik.setFieldValue("description", e.target.value)}
            /> */}
          </Form.Item>
          <Form.Item label={t("general.sharable")}>
            <Checkbox
              checked={formik.values.sharable}
              onChange={(e) => {
                formik.setFieldValue("sharable", e.target.checked);
              }}
            >
              Is this Responsibility sharable with other departments?
            </Checkbox>
          </Form.Item>
          <Form.Item label="Responsibility Files">
            <Row gutter={12}>
              <Col span={6}>
                <Upload
                  name="attached_files"
                  beforeUpload={() => {
                    return false;
                  }}
                  onChange={(e) => {
                    setUploadTaskFiles(e.fileList);
                  }}
                  fileList={uploadTaskFiles}
                >
                  <Button>
                    <Icon type="upload" /> Click to upload
                  </Button>
                </Upload>
              </Col>
              <Col span={6}>
                <Button
                  onClick={uploadTask}
                  disabled={uploadTaskFiles.length == 0}
                  className="btn blue lighten-1 z-depth-0"
                  type="primary"
                >
                  {t("general.upload")}
                </Button>
              </Col>
            </Row>
          </Form.Item>

          {formik.values?.attached_files?.length > 0 && (
            <div>
              <List
                itemLayout="horizontal"
                bordered
                dataSource={formik.values?.attached_files}
                renderItem={(file: any) => (
                  <List.Item>
                    <div>
                      <a href={file.url} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </a>
                      {/* <p style={{ margin: "0px" }}>{file.attachedDescription}</p> */}
                    </div>
                    <Popconfirm
                      title={"Are you sure?"}
                      onConfirm={() => handleDeleteFile(file.name, "attached_files")}
                      onCancel={() => console.log("cancel")}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="link" style={{ color: "red" }}>
                        <Icon type="delete" />
                      </Button>
                    </Popconfirm>
                  </List.Item>
                )}
              />
            </div>
          )}

          <div>
            <Button
              className="btn blue lighten-1 z-depth-0"
              style={{ marginTop: "2%" }}
              // loading={loading}
              type="primary"
              htmlType="submit"
              // disabled={!formik.isValid}
            >
              {t("general.save")}
            </Button>
          </div>
        </Form>
      </Spin>
    </Drawer>
  );
}

export default EditTask;
