import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Form, message, Modal, Row, Spin, Statistic, Switch } from "antd";
import ReactPlayer from "react-player";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { userRef } from "../../db/collectionsRef";
import { users } from "../../db/interfaces";
import { useFormik } from "formik";

interface Props {
  UID: string;
  modalState: boolean;
  setModalState: any;
}

function PermissionsDialog(props: Props) {
  const { UID, modalState, setModalState } = props;
  const [userSN, loading] = useDocument(userRef.doc(UID));
  const userData = userSN?.data() as users;

  const formik = useFormik({
    initialValues: {
      cases: [],
    },
    onSubmit: async (values, form) => {
      const permissions = values;
      console.log({ permissions });
      await userRef
        .doc(UID)
        .update({
          permissions: permissions,
        })
        .then(() => {
          message.success("Saved Successfully!");
          setModalState(false);
        });
    },
  });

  const [loaded, setLoaded] = useState(true);
  useEffect(() => {
    if (userSN && loaded) {
      formik.setValues({
        cases: userData?.permissions ? userData?.permissions.cases : [],
      } as any);
      setLoaded(false);
    }
  }, [userSN, formik, loaded]);

  return (
    <Modal
      closable={true}
      width={800}
      visible={modalState}
      onCancel={async () => {
        setModalState(false);
      }}
      footer={[]}
    >
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col span={20}>
              <Statistic title="First Name" value={userData?.firstName + " " + userData?.lastName} />
            </Col>
          </Row>

          <Form.Item label="Case">
            <Checkbox.Group onChange={(e) => formik.setFieldValue("cases", e)} value={formik.values.cases}>
              <Checkbox value="meal">Meal</Checkbox>
              <Checkbox value="program">Program</Checkbox>
              <Checkbox value="admin">Admin</Checkbox>
              <Checkbox value="CEO">CEO</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item>
            <Button
              className="btn blue lighten-1 z-depth-0"
              onClick={() => {}}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default PermissionsDialog;
