import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import firebase, { auth, database } from "firebase/app";
import { Spin, Button, message, Descriptions, Badge, Input, Select, Popconfirm, Icon, DatePicker } from "antd";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import Discussion from "../../discussion/discussion";
import { orderRef, userRef, projectRef, discussionsRef, notificationsRef } from "../../db/collectionsRef";
import { admin } from "../../db/admin";
import { async } from "q";
import moment from "moment";
import EditOrder from "../models/EditOrder";
import printJS from "print-js";
import { format } from "../../db/dateFormat";
import { useTranslation } from "react-i18next";

interface Props {
  data: any;
  orderType: string;
  formName: string;
}

export default function PrintOrder() {
  const { t } = useTranslation();
  const id = useRouteMatch<{ id: string }>().params.id;
  const [orderSnapshot, error] = useDocument(orderRef.doc(id));
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

  if (!orderSnapshot) {
    return <Spin size="large" indicator={antIcon} />;
  }
  const data = orderSnapshot!.data()!;
  const orderType = orderSnapshot?.data()?.orderType;
  const formName = orderType == "AFAD Kart" ? "GEÇİCİ KORUMA KİMLİĞİ BAŞVURU FORMU" : "ÇALIŞMA İZNİ";

  const print = async () => {
    await printJS({
      header: `${formName}`,
      printable: "table",
      type: "html",
      targetStyles: ["*"],
      documentTitle: `${formName}`,

      onPrintDialogClose: () => window.close()
    });
  };

  return (
    <div style={{ padding: "1%" }}>
      <Button
        style={{ marginLeft: "1%" }}
        type="primary"
        onClick={async () => {
          print();
        }}
        htmlType="submit"
      >
        {t("general.print")}
      </Button>{" "}
      <div id="table" style={{ alignContent: "center", alignItems: "center" }}>
        <table style={{ border: "1px solid black", width: "100%" }}>
          <tr style={{ border: "1px solid black" }}>
            <th style={{ border: "1px solid black", width: "30%", padding: "5px" }}>TC</th>
            <th style={{ border: "1px solid black", padding: "5px" }}>{data.TC}</th>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Adı</td>
            <td style={{ border: "1px solid black", padding: "5px" }}>{data.firstName}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Soyadı</td>
            <td style={{ border: "1px solid black", padding: "5px" }}>{data.lastName}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Baba Adı</td>
            <td style={{ border: "1px solid black", padding: "5px" }}>{data.fatherName}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Ana Adı</td>
            <td style={{ border: "1px solid black", padding: "5px" }}>{data.motherName}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Cinsiyeti : </td>
            <td style={{ border: "1px solid black", padding: "5px" }}>{data.gender}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Doğum Yeri :</td>
            <td style={{ border: "1px solid black", padding: "5px" }}>{data.birthPlace}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Doğum Tarihi :</td>
            <td style={{ border: "1px solid black", padding: "5px" }}>
              {" "}
              {moment(data.birthDate.toDate()).format(format)}
            </td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Medeni Hali :</td>
            <td style={{ border: "1px solid black", padding: "5px" }}> {data.marriedStatus}</td>
          </tr>
          {orderType != "Turk Vatandasi" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Uyruğu :</td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.nationality}</td>{" "}
            </tr>
          ) : null}

          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Adresi :</td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.residenceAddress}</td>
            </tr>
          ) : null}
          {orderType == "Ikamet Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Kız Çocuk Sayısı : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.femaleChildrenNumber}</td>
            </tr>
          ) : null}
          {orderType == "Ikamet Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Erkek Çocuk Sayısı : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.maleChildrenNumber}</td>
            </tr>
          ) : null}
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Eşinin Uyruğu : </td>
            <td style={{ border: "1px solid black", padding: "5px" }}> {data.wifeNationality}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Eşinin Adı : </td>
            <td style={{ border: "1px solid black", padding: "5px" }}> {data.wifeFirstName}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Eşinin Soyadı : </td>
            <td style={{ border: "1px solid black", padding: "5px" }}> {data.wifeLastName}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>Eşinin TC Kimlik No : </td>
            <td style={{ border: "1px solid black", padding: "5px" }}> {data.wifeTC}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}>E-Posta Adresi : </td>
            <td style={{ border: "1px solid black", padding: "5px" }}> {data.email}</td>
          </tr>
          <tr style={{ border: "1px solid black" }}>
            <td style={{ border: "1px solid black", padding: "5px" }}> Cep Telefon : </td>
            <td style={{ border: "1px solid black", padding: "5px" }}> {data.phone}</td>
          </tr>
          {orderType == "Ikamet Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>İkamet Adresi : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.residenceAddress}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Görevi : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{data.position}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Ücreti : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{data.salary}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Yabancının Suriyede Yaşadığı Bölge-Şehir : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{data.cityInSyria}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Tahsil : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.degree}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Yabancının Üniversitesi : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{data.universityNameAddress}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Yabancının Üniversite Fakültesi : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{data.degreeFaculty}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Yabancının mesleği : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{data.degreeField}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Türkiye'de Bulunulan Süre : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{data.durationInTurkey}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Kız Çocuk Sayısı : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.femaleChildrenNumber}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Erkek Çocuk Sayısı : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.maleChildrenNumber}</td>
            </tr>
          ) : null}
          {orderType == "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}> Dil: </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.language}</td>
            </tr>
          ) : null}

          {orderType != "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Mezun Olunan Okul Adı / Yeri:</td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.universityNameAddress}</td>
            </tr>
          ) : null}
          {orderType != "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Tahsil: </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.degree}</td>
            </tr>
          ) : null}
          {orderType != "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Diploma Mesleği : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.degreeField}</td>
            </tr>
          ) : null}
          {orderType != "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>İhtisas Konusu : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.degreeSpecialization}</td>
            </tr>
          ) : null}
          {orderType == "Ikamet Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Pasaport No : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.passportNO}</td>
            </tr>
          ) : null}
          {orderType == "Ikamet Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Son Geçerlilik Tarihi : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>
                {" "}
                {moment(data.passportExpiryDate.toDate()).format(format)}
              </td>
            </tr>
          ) : null}
          {orderType == "Ikamet Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Pasaportun Verildiği Makam : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.passportAuth}</td>
            </tr>
          ) : null}
          {orderType != "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Görevi : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}> {data.position}</td>
            </tr>
          ) : null}
          {orderType != "AFAD Kart" ? (
            <tr style={{ border: "1px solid black" }}>
              <td style={{ border: "1px solid black", padding: "5px" }}>Ücreti : </td>
              <td style={{ border: "1px solid black", padding: "5px" }}>{data.salary}</td>
            </tr>
          ) : null}
        </table>
        {orderType == "Ikamet Kart" ? (
          <div>
            <p style={{ textAlign: "left", margin: 2 }}>İkamet İzni Fotokopisi</p>
            <p style={{ textAlign: "left", margin: 2 }}>Pasaport Fotokopisi</p>
            <p style={{ textAlign: "left", margin: 2 }}>Fotograf</p>
            <p style={{ textAlign: "left", margin: 2 }}>Türkçe Tercüme Edilmiş Noter Tasdikli Diploma Sureti -Asıl</p>
          </div>
        ) : null}
        {orderType == "AFAD Kart" ? (
          <div>
            <p style={{ textAlign: "left", margin: 2 }}>KİMLİK FOTOKOPİSİ VE 1 ADET FOTOGRAF EKLENECEKTİR</p>
          </div>
        ) : null}
        {orderType == "Turk Vatandasi" ? (
          <div>
            <p style={{ textAlign: "left", margin: 2 }}>KİMLİK FOTOKOPİSİ</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
