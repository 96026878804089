import * as firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyC9TeJHDZf578KVjHeL5vSgQzTGdgNvqqU",
  authDomain: "administrationapp-cdc55.firebaseapp.com",
  databaseURL: "https://administrationapp-cdc55.firebaseio.com",
  projectId: "administrationapp-cdc55",
  storageBucket: "administrationapp-cdc55.appspot.com",
  messagingSenderId: "395633621915",
  appId: "1:395633621915:web:eba0c6cc9c45c32936ff3e"
};

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore!();
firestore.settings({});
firestore.enablePersistence({ synchronizeTabs: true });
//firebase.firestore().settings({});
export default firebaseConfig;
