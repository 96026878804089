import { userRef } from "../db/collectionsRef";
import { users } from "../db/interfaces";

interface UsersWithId extends users {
  id: string;
  fullName: string;
}

export const globalUsersData: Record<string, UsersWithId> & { loaded?: boolean } = {};
export let USERS_ID_FULL_NAME: any = [];

userRef.onSnapshot((data) => {
  Object.defineProperty(globalUsersData, "loaded", { enumerable: false, value: true });
  data.docs.forEach((doc) => {
    globalUsersData[doc.id] = {
      ...doc.data(),
      id: doc.id,
      fullName: doc.data().firstName + " " + doc.data().lastName,
    } as UsersWithId;
  });

  USERS_ID_FULL_NAME = data.docs
    .map((u) => {
      return { ...u.data(), id: u.id };
    })
    .filter((u: any) => u.status != "disable")
    .map((u: any) => {
      const fullName =
        u.firstName + " " + u.fatherName + " " + u.lastName + " _ " + u.arabicFullName + " _ " + u.resourceNO;
      const uid = u.id;
      return { uid, fullName };
    });
});
