import { Comment, Avatar, Form, Button, List, Input, Spin } from "antd";
import moment from "moment";
import { useState } from "react";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { userRef, notificationsRef } from "../../db/collectionsRef";

import { auth } from "firebase";
import { sortBy } from "lodash";
import { Link } from "react-router-dom";

export default function MyApprovals() {
  const uid = auth().currentUser!.uid;
  const [xLimit, setXLimit] = useState(30);
  const [showAll, setShowAll] = useState(false);
  const [notificationsSN, loadingN] = useCollection(
    notificationsRef.where("notifyTo", "==", uid)
    // .where("read", "==", false)
  );
  const [userSN, loading2] = useCollection(userRef);

  if (!userSN || !notificationsSN) {
    return <Spin />;
  }

  const notifications =
    notificationsSN === undefined
      ? []
      : notificationsSN.docs
          .filter(d => {
            return d.data().kind == "newRequest" || d.data().kind == "newRequestOvertime";
          })
          .filter(d => {
            if (showAll) {
              return d;
            } else {
              return d.data().read == false;
            }
          });

  const sortedCommentsSnapshot = sortBy(notifications, item => item.data().postedAt.toDate()).reverse();

  const dataSource = sortedCommentsSnapshot.map(item => {
    const rout =
      item.data().kind == "mention" || item.data().kind == "calendarInvitation"
        ? "calenderDetails"
        : item.data().content == "New Vacation request is waiting for your Approval!" ||
          item.data().content == "Vacation request has been Modified and is waiting for your Approval!" ||
          item.data().content == "Your Vacation request has been Approved!"
        ? "vacation"
        : item.data().kind == "messageVacation"
        ? "vacation"
        : item.data().content == "New Compensation request is waiting for your Approval!"
        ? "overtime"
        : item.data().kind == "messageOvertime" || item.data().kind == "newRequestOvertime"
        ? "overtime"
        : "order";
    return (
      <Link to={`/${rout}/${item.data().orderId}`}>
        <div
          onClick={() => {
            notificationsRef.doc(item.id).update({
              read: true
            });
          }}
          style={{
            padding: "20px",
            color: "rgba(0, 0, 0, 0.65)",
            background: item.data().read ? "#a0dbc0" : "white",
            borderRadius: "5px",
            // boxShadow: "6px 7px 17px 3px rgba(0, 0, 0, 0.09)",
            margin: "25px 0px"
          }}
          key={item.id}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              WebkitBoxPack: "start",
              justifyContent: "flex-start",
              fontSize: "14px",
              alignItems: "center"
            }}
          >
            <span style={{ color: item.data().read ? "#fff" : "red" }}>{`${
              userSN?.docs?.find(d => d.id == item.data()?.notifyFrom)?.data().firstName
            } ${userSN?.docs?.find(d => d.id == item.data()?.notifyFrom)?.data().lastName} `}</span>

            <span
              style={{
                fontSize: "12px",
                color: item.data().read ? "#fff" : "green",
                whiteSpace: "nowrap",
                padding: "0.5%"
              }}
            >
              <span>
                {item
                  ?.data()
                  ?.postedAt.toDate()
                  .toLocaleString()}
              </span>
            </span>
          </div>
          <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: item.data().content }} />
        </div>
      </Link>
    );
  });

  return (
    <div>
      <Button type="primary" onClick={() => setShowAll(!showAll)}>
        Show All
      </Button>
      <div>{dataSource}</div>
    </div>
  );
}
