import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Button, Popconfirm } from "antd";
import { officeRef, paymentTypesRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import { globalUsersData } from "../../global/usersList";
import AddNewPaymentType from "./addNewPaymentDialog";
import EditPaymentType from "./editPaymentDialog";
import { currentUserData } from "../../db/userGlobal";
import { globalOfficesData } from "../../global/officeList";

export default function PaymentTypesTable() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [paymentTypeId, setPaymentTypeId] = useState("");

  const [paymentTypeSN] = useCollection(paymentTypesRef);
  const [officeSN] = useCollection(officeRef);

  if (!paymentTypeSN || !officeSN) return <Spin />;

  const data = paymentTypeSN.docs
    .map((d) => {
      return { ...d.data(), id: d.id };
    })
    .filter((d: any) => currentUserData.HRProjectsManagersOffices.includes(globalUsersData[d.uid]?.office));
  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[globalUsersData[row.uid]?.office ?? ""]?.officeName,
    },

    {
      name: "paymentType",
      title: "Payment Type",
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY"),
    // },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              paymentTypesRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setPaymentTypeId(row.id);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[globalUsersData[row.uid]?.office ?? ""]?.officeName,
    },

    {
      name: "paymentType",
      title: "Payment Type",
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY"),
    // },
  ];

  return (
    <div>
      <AddNewPaymentType modalState={modalState} setModalState={setModalState} />
      {modalStateEdit ? (
        <EditPaymentType modalState={modalStateEdit} setModalState={setModalStateEdit} paymentTypeId={paymentTypeId} />
      ) : null}
      {/* <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewPaymentType")}
      </Button> */}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Payment Types"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
