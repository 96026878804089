import React, { useState, useEffect } from "react";
import { auth } from "firebase/app";
import { Button, DatePicker, Drawer, Form, message, Select, Input, InputNumber } from "antd";
import moment from "moment";
const { Option } = Select;
import { useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";
import { userRef, officeRef, contractPercentageRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import { uniq } from "lodash";
import { currentUserData } from "../../db/userGlobal";
import { USERS_ID_FULL_NAME } from "../../global/usersList";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({});

function AddNewContractPercentage(props: Props) {
  const { t } = useTranslation();
  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);

  const newId = contractPercentageRef.doc().id;
  const uid = auth().currentUser!.uid;
  const [usersSN] = useCollection(userRef);
  const [officeSN] = useCollection(officeRef);



  const formik = useFormik({
    initialValues: {
      uid: "",
      office: "",
      startDate: moment(),
      endDate: moment(),
      contractPercentage: 0,
    },
    validationSchema: projectSchema,
    onSubmit: async (values) => {
      const promise = contractPercentageRef
        .doc(newId)
        .set(
          {
            uid: values.uid,
            contractPercentage: values.contractPercentage,
            startDate: values.startDate.toDate() as any,
            endDate: values.endDate.toDate() as any,
            createdAt: new Date(),
            by: uid,
          },
          { merge: true }
        )
        .then(() => {
          userRef.doc(values.uid).update({
            idaAllocationPercentage: values.contractPercentage,
          });
        });
      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch((error) => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    },
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";
  const UserOffices = [...(currentUserData.fullControlOffices ?? []), currentUserData.office];
  const WithoutDuplicates = uniq(UserOffices);
  const UserListOffices = WithoutDuplicates.map((id) => {
    const officeName = officeSN?.docs?.find((d) => d.id == id)?.data()?.officeName;
    return { officeName, id };
  });
  return (
    <Drawer
      title={t("general.addNewContractPercentage")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.employee")}>
          <Select
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            placeholder="Select Employee"
            showSearch
            value={formik.values.uid}
            onChange={(e) => {
              formik.setFieldValue("uid", e);
            }}
          >
            {USERS_ID_FULL_NAME?.map((d) => {
              return <Option value={d.uid}>{d.fullName}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item label={t("general.contractPercentage")}>
          <InputNumber
            min={0}
            value={formik.values.contractPercentage}
            onChange={(e) => formik.setFieldValue("contractPercentage", e)}
          />
        </Form.Item>

        <Form.Item label={t("general.startDate")}>
          <DatePicker
            value={formik.values.startDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("startDate", e)}
          />{" "}
        </Form.Item>
        <Form.Item label={t("general.endDate")}>
          <DatePicker
            value={formik.values.endDate}
            format={dateFormat}
            onChange={(e) => formik.setFieldValue("endDate", e)}
          />{" "}
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewContractPercentage;
