import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Button, Popconfirm, Select, DatePicker } from "antd";

import { officeRef, departmentsRef } from "../../db/collectionsRef";

import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";

import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";
import AddNewDepartment from "./addNewDepartmentDialog";
import EditDepartment from "./editDepartmentDialog";
import { globalDepartmentsData } from "../../global/departmentList";

export default function DepartmentsTable() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [departmentId, setDepartmentId] = useState("");

  const [departmentSN] = useCollection(departmentsRef);
  const [officeSN] = useCollection(officeRef);

  if (!departmentSN || !officeSN) return <Spin />;

  const data = departmentSN.docs.map((d) => {
    return { ...d.data(), id: d.id };
  });

  const columns = [
    {
      name: "departmentName",
      title: "Department Name",
    },
    {
      name: "shortcut",
      title: "Shortcut",
    },
    {
      name: "HOD",
      title: "HOD",
      getCellValue: (row: any) => globalUsersData[row.HOD]?.firstName + " " + globalUsersData[row.HOD]?.lastName,
    },

    {
      name: "referenceDepartment",
      title: "Parent Department",
      getCellValue: (row: any) => globalDepartmentsData[row.referenceDepartment]?.departmentName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              departmentsRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },

    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setDepartmentId(row.id);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "departmentName",
      title: "Department Name",
    },
    {
      name: "shortcut",
      title: "Shortcut",
    },
    {
      name: "HOD",
      title: "HOD",
      getCellValue: (row: any) => globalUsersData[row.HOD]?.firstName + " " + globalUsersData[row.HOD]?.lastName,
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },

    {
      name: "referenceDepartment",
      title: "Parent Department",
      getCellValue: (row: any) => globalDepartmentsData[row.referenceDepartment]?.departmentName,
    },
  ];

  return (
    <div>
      <AddNewDepartment modalState={modalState} setModalState={setModalState} />
      {modalStateEdit ? (
        <EditDepartment modalState={modalStateEdit} setModalState={setModalStateEdit} departmentId={departmentId} />
      ) : null}
      <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewDepartment")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="deduction"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
