import React, { useState, useRef, useCallback } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Tabs, Button, Popconfirm } from "antd";

import { orderRef, userRef, meetingRoomList, projectsRef, officeRef, carRef } from "../../db/collectionsRef";
import { Link } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import AddNewRoomModel from "../Meetings_Rooms/AddNewRoomModel";
import { useTranslation } from "react-i18next";

import DevTable from "../../helpers/devex-table";
import { car } from "../../db/interfaces";
import EditCar from "./editCar";
import AddCar from "./addCar";
import { globalOfficesData } from "../../global/officeList";
import { globalUsersData } from "../../global/usersList";

const columnsExport = [
  { name: "name", title: "Car Name" },
  { name: "description", title: "Description" },
  { name: "capacity", title: "Capacity" },
  { name: "offices", title: "Offices" },
  { name: "drivers", title: "Drivers" },
  { name: "manager", title: "Manager" },
  { name: "status", title: "Status" },
];

export default function CarsTable() {
  const { t } = useTranslation();
  const uid = auth().currentUser!.uid;
  const [car, setCar] = useState({} as car | undefined);
  const [modalStateAdd, setModalStateAdd] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);

  const [carsSN, loading] = useCollection(carRef);

  if (!carsSN) return <Spin />;

  const data = carsSN.docs.map((d) => {
    return { ...d.data(), id: d.id } as car;
  });

  const columns = [
    { name: "name", title: "Car Name" },
    { name: "description", title: "Description" },
    { name: "capacity", title: "Capacity" },
    {
      name: "offices",
      title: "Offices",
      getCellValue: (r: car) => {
        return r.offices.map((id, index) => (
          <>
            <b>{`${index + 1}) ${globalOfficesData[id]?.officeName}`}</b>
            <br />
          </>
        ));
      },
    },
    {
      name: "drivers",
      title: "Drivers",
      getCellValue: (r: car) => {
        return r.drivers.map((uid, index) => (
          <>
            <b>{`${index + 1}) ${globalUsersData[uid]?.firstName + " " + globalUsersData[uid]?.lastName}`}</b>
            <br />
          </>
        ));
      },
    },
    {
      name: "manager",
      title: "Manager",
      getCellValue: (r: car) => globalUsersData[r.manager]?.firstName + " " + globalUsersData[r.manager]?.lastName,
    },
    { name: "status", title: "Status" },

    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              carRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "Edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setCar(row);
              setModalStateAdd(true);
            }}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      {modalStateAdd ? <AddCar modalState={modalStateAdd} setModalState={setModalStateAdd} car={car} /> : null}
      <Button
        style={{ marginBottom: "1%" }}
        type={"primary"}
        onClick={() => {
          setCar(undefined);
          setModalStateAdd(true);
        }}
      >
        {t("general.addNewCar")}
      </Button>
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Cars"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
