import { budgetLinesRef } from "../db/collectionsRef";
import {budgetLines } from "../db/interfaces";

interface BudgetLinesWithId extends budgetLines {
  id: string;
}

export const globalBudgetLinesData: Record<string, BudgetLinesWithId> & { loaded?: boolean } = {};

budgetLinesRef.onSnapshot(data => {
  Object.defineProperty(globalBudgetLinesData, "loaded", { enumerable: false, value: true });
  data.docs.forEach(doc => {
    globalBudgetLinesData[doc.id] = { ...doc.data(), id: doc.id } as BudgetLinesWithId;
  });
});
