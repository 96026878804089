export default {
  general: {
    shiftVacationPerHours:"Shift hours per vacations",
    shiftsType:"Shift Type",
    month:"Month",
    shiftsDates:"Date of Shift",
    addNewShift:"Add New Shift",
    shiftsManagement: "Shift Management",
    viewers: "Viewers",
    HRProjectsManagers: "HR Projects Mangers",
    bankFirstName: "First Name(Bank)",
    bankLastName: "Last Name (Bank)",
    customerNumber: "Customer Number",
    editResponsibility: "Edit Responsibility",
    editPosition: "Edit Position",
    sharable: "Sharable Task",
    shortcut: "Shortcut",
    code: "Code",
    title: "Title",
    addNewTask: "Add New Task",
    addNewResponsibility: "Add New Responsibility",
    positionTitle: "Title",
    referencePosition: "Reference Position",
    assignTo: "Assign To",
    SOPs: "Management",
    addNewDepartment: "Add New Department",
    editDepartment: "Edit Department",
    departmentName: "Department Name",
    referenceDepartment: "Parent Department",
    HOD: "Head Of Department",
    overview: "Overview",
    departments: "Departments",

    editContractPercentage: "Edit Contract Percentage",
    editPaymentType: "Edit Payment Type",
    editSupervisor: "Edit Supervisor",
    editWorkOffice: "Edit Work Office",
    resourceNO: "Resource NO",
    paymentTypes: "Payment Types",
    contractPercentages: "Contract Percentages",
    addNewPaymentType: "Add New Payment Type",
    addNewContractPercentage: "Add New Contract Percentage",
    contractPercentage: "Contract Percentage",
    supervisors: "Supervisors",
    addNewSupervisor: "Add New Supervisor",
    resetPassword: "Reset Password",
    payslip: "Payslip",
    payroll: "Payroll",
    byOffice: "By Office",
    byCluster: "By Cluster",
    byProject: "By Project",
    allocationManagement: "Allocation Settings",
    employeeManagement: "Staff Settings ",
    workOffices: "Work Offices",
    addNewWorkOffice: "Add New Work Office",
    addNewSalary: "Add New Salary",
    salaries: "Salaries",
    addNewDeduction: "Add New Deduction",
    deductions: "Deductions",
    usersTimeSheet: "Time-Sheets",
    idaAllocationPercentage: "IDA allocation percentage",
    partTimeSchedule: "Part-Time Schedule",
    employmentType: "Employment Type",
    caseView: "Case View",
    assignedTo: "Assigned To",
    caseNO: "Case NO",
    paymentType: "Payment Type",
    caseDate: "Case Date",
    findings: "Findings",
    Resoluation: "Final Resolution",
    cars: "Cars Management",
    addNewCar: "Add Car",
    carName: "Car Name",
    description: "Description",
    capacity: "Capacity",
    drivers: "Drivers",
    carInformation: "Car Information",
    accountants: "Accountants",
    addNewAccountant: "Add New Accountant",
    mission: "Mission Area",
    missions: "Mission Management",
    cases: "MEAL Cases",
    contactPerson: "Focal Point",
    myFingerPrint: "FP Records",
    attendees: "Attendees",
    imports: "Imports",
    absentTable: "Absents Table",
    importNewFile: "Upload New Sheet",
    region: "Region(Syria/Turkey)",
    startWork: "Start Work",
    endWork: "End Work",
    fingerPrintId: "Finger Print Id",
    calendar: "Calendar",
    requestedAt: "Requested At",
    status: "Status",
    statusControl: "Status Control",
    firstTime: "First Time",
    requestedBy: "Requested By",
    orderId: "Order Id",
    permitExpiryDate: "Expiry Date",
    selectYourRC: "Please select your residence card?",
    workPermit: "Work Permit",
    ok: "OK",
    TC: "TC",
    firstName: "Name",
    lastName: "Last Name",
    fatherName: "Father Name",
    motherName: "Mother Name",
    gender: "Gender",
    birthPlace: "Birth Place",
    birthDate: "Birth Date",
    marriedStatus: "Married Status",
    nationality: "Nationality",
    femaleChildrenNumber: "Female Children Number",
    maleChildrenNumber: "Male Children Number",
    address: "Address",
    wifeNationality: "Wife/Husband Nationality",
    wifeFirstName: "Wife/Husband Name",
    wifeLastName: "Wife/Husband Surname",
    wifeTC: "Wife/Husband TC",
    phone: "Phone Number",
    email: "Email",
    universityNameAddress: "University Name/Address",
    degree: "Degree",
    degreeField: "Degree Field",
    degreeSpecialization: "Degree Specialization",
    degreeFaculty: "Faculty",
    passportNO: "Passport NO",
    passportExpiryDate: "Passport Expiry Date",
    passportAuth: "Passport Auth",
    position: "Position",
    salary: "Salary",
    cityInSyria: "City In Syria",
    durationInTurkey: "Duration In Turkey",
    language: "Language",
    ////////
    successfulDelete: "Document Successfully Deleted!",
    uploadCard: "Upload Permit",
    upload: "Upload",
    successfulUpload: "File has been Uploaded Successfully!",
    renewalRequest: "Renewal Request",
    areUSure: "Are you Sure!? ",
    resubmit: "Re-Submit",
    receiveDoc: "Receive Doc",
    sendDoc: "Send Doc",
    rejected: "Rejected",
    approved: "Approved",
    canceled: "Canceled",
    freeze: "Freeze",
    leaveJob: "Leave Job",
    unfreeze: "Unfreeze",
    orderHasBeenRejected: "Order Has Been Rejected!",
    staffOutOfWork: "Staff Out Of Work!",
    expiryDate: "Expiry Date",
    confirm: "Confirm",
    actions: "Actions",
    edit: "Edit",
    remove: "Remove",
    print: "Print",
    addComment: "Add Comment",
    showAll: "Show All!",
    noNotifications: "No Notifications!",
    profile: "Profile",
    logOut: "Log Out",
    home: "Home",
    borderRequests: "Border Requests",
    users: "Users",
    footer: "Independent Doctor Association- by Omer Azzi",
    create: "Create",
    workStartOn: "Start Working on",
    attachments: "Attachments",
    save: "Save",
    sentNewMessage: "has sent you a new message!",
    addNewRoom: "Add New Meeting Room",
    roomName: "Room Name",
    location: "Location",
    capacitySits: "Capacity Sits",
    contains: "Contains",
    meetingRoom: "Meetings Rooms",
    myMeetings: "My Meetings Details",
    send: "Send",
    addMOM: "Write Minute of Meeting",
    success: "Success",
    invitation: "has invited you to Attend a Meeting!",
    mentioned: "has mentioned you!",
    addMOMButton: "Add MOM",
    submitted: "Submitted",
    view: "View More",
    startJobOn: "Start Job on",
    readAll: "Read All!",
    deleteSeries: "Do you want to Delete only This appointments?",
    deleteAppointment: "Are you sure you want to delete the Appointment?",
    yesAll: "Delete All",
    onlyThis: "Only This",
    close: "Close",
    yes: "Yes",
    no: "No",
    thisAndFollowing: "This and Following",
    editSeries: "Do you want to Edit only This Appointment?",
    editAll: "Edit All",
    editOnlyThis: "Only This",
    editAppointment: "Are you Sure you want to Edit this Appointment?",
    trackingNumber: "Tracking Number",
    printContract: "Print Contract",
    uploadContract: "Upload Signed Contract",
    fullName: "Full Name",
    manager: "Supervisor",
    vacationType: "Vacation Type",
    vacationStartOn: "Vacation Start On",
    vacationEndOn: "Vacation End On",
    startWorkOn: "Start Work On",
    vacationAnnualScore: "Annual Score",
    vacationSickScore: "Sick Score",
    vacationCompensationScore: "Compensation",
    HRSignatureDate: "HR Approval Date",
    employeeSignatureDate: "Employee Signature",
    managerSignatureDate: "Supervisor Approval Date",
    adminSignatureDate: "Admin Approval Date",
    vocationPeriodDays: "Days",
    adminUser: "Admin Responsible",
    HRUser: "HR Responsible",
    printOrder: "Print Form",
    vacationsTable: "Vacations Table",
    vacationRequest: "Vacation Request",
    usersDashboard: "Users Dashboard",
    department: "Department",
    supervisor: "Supervisor",
    uploadAttach: "Attach a file",
    notes: "Notes",
    dailyDelay: "Today's Delay",
    monthlyDelay: "Month Delay",
    dayDate: "Delay Date",
    min: "Minutes",
    delayWindow: "Delay Dashboard",
    myDelays: "My Delays",
    overtimeDate: "Date of Overtime",
    overtimeStartHours: "Starting Hours",
    overtimeEndHours: "Ending Hours",
    compensationHours: "Duration/hours",
    tasks: "Tasks",
    taskOvertime: "Earliest Deadline",
    projects: "Projects",
    projectName: "Project Name",
    projectCode: "Project Code",
    projectStart: "Start Date",
    projectEnd: "End Date",
    addNewProject: "Add new Project",
    budgetLines: "Budget Lines",
    addNewBudgetLine: "Add New Budget Line",
    budgetLine: "Budget Line Code",
    employee: "Employee Full Name",
    allocation: "Project Allocation",
    startDate: "Start Date",
    endDate: "End Date",
    addNewAllocation: "Add New Allocation",
    percentageOfProjectsAllocation: "% of Projects Allocation",
    allocationTable: "Allocations",
    editNewAllocation: "Edit Allocation Record",
    halfDay: "Half-Day",
    myApprovals: "My Approvals",
    offices: "Offices",
    admins: "Admins",
    HRs: "HR Officers",
    officePermissions: "Office Permissions",
    addNewOffice: "Add New Office",
    officeName: "Office Name",
    identityNo: "Identity No",
    arabicFullName: "Arabic Full Name",
    placeOfBirth: "Place Of Birth",
    currentAddress: "Current Address",
    emergencyContact1: "Emergency Contact1",
    bankAccountName: "Bank Account Name",
    IBAN: "IBAN",
    vacationMarriageScore: "Marriage Score",
    vacationPaternityScore: "Paternity Score",
    vacationMaternityScore: "Maternity Score",
    vacationDeathScore: "Death Score",
    bankAccountNumber: "Bank Account Number",
    bankName: "Bank Name",
    emergencyContact2: "Emergency Contact2",
    phoneNumber: "PhoneNumber",
    permanentAddress: "Permanent Address",
    dateOfBirth: "Date Of Birth",
    endingDate: "Ending Date",
    startingDate: "Starting Date",
    currency: "Currency",
    password: "Password",
    office: "Office",
    personalPhoto: "Personal Photo",
    approvalCEO: "Has a CEO approval!!",
    CEO: "CEO",
    CEOSignatureDate: "CEO Approval Date",
    mysignature: "My Signature",
    addNewPosition: "Add New Position",
    editNewPosition: "Edit Position",
    positionsTable: "Positions",
  },

  dashboard: {
    organizationStructure: "Organization Structure",
    workPermitWindow: "Work Permit Window",
    clickHereToOpenNewWindow: "Click Here To Open New Window",
    meetingRoom: "Meeting Room",
    meetingCalendar: "Meetings Calendar",
    myMeetings: "My Meetings Details",
    vacationRequest: "Vacation Request",
    carsMovements: "Car Movement Request",
    crossBorder: "Cross Border Request",
    hims: "Health Information System HIMS",
    logisticsSoftware: "Logistics Software",
    overTimeRequest: "Overtime Request",
    timeSheet: "Time Sheet",
  },
  attachments: {
    permitCardPhoto: "Permit Card Photo",
    prevPermitCardPhoto: "Previous Permit Card",
    passportPhoto: "Passport Copy",
    personalPhoto: "Personal Photo (Biometric)",
    diplomaNotarized: "Diploma translated to Turkish and Notarized, Copy for Original Diploma!",
    IDandPhoto: "Identify Card and Photo",
    ID: "Identify Card Copy",
    diploma: "Diploma",
  },
};
