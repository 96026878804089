// import { ordersRef, SCItemsRef, SCRef } from 'common/db/collectionsRef'
// import { SCItem, ServiceContract } from 'common/db/interfaces'
import docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils";
import RFCTurkeyMission from "./RFCTurkeyMission.docx";
import RFCIraqMission from "./RFCIraqMission.docx";

import { firebaseUploadAndGetUrl } from "./firebaseUploadAndGetUrl";
import {  overtimeRef } from "../../db/collectionsRef";
import moment from "moment";

export const generateAndUploadWord = async (
  TemplateFile: string,
  templateData: any,
  overtimeId: string,
  uid: string,
  empFullName: string,
  managerFullName: string,
  adminFullName: string,
  HRFullName: string,
  setDownloadLoading: React.Dispatch<React.SetStateAction<boolean>>,
  office: string,
  mission?: string
) => {
  function loadFile(url: any, callback: () => void) {
    PizZipUtils.getBinaryContent(mission == "IRAQ" ? RFCIraqMission : RFCTurkeyMission, callback);
  }

  loadFile(TemplateFile, function (error: any, content: any) {
    console.log({ error, content });

    if (error) {
      throw error;
    }

    function replaceErrors(key: any, value: any) {
      if (value instanceof Error) {
        return Object.getOwnPropertyNames(value).reduce(function (error, key) {
          error[key] = value[key];
          return error;
        }, {});
      }
      return value;
    }

    function errorHandler(error: any) {
      console.log(JSON.stringify({ error: error }, replaceErrors));

      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors
          .map(function (error: any) {
            return error.properties.explanation;
          })
          .join("\n");
        console.log("errorMessages", errorMessages);
      }
      throw error;
    }

    const zip = new PizZip(content);

    let doc;

    try {
      doc = new docxtemplater(zip);
    } catch (error) {
      errorHandler(error);
    }

    async function dataRun(doc: any) {
      try {
        doc.setData({
          fullName: empFullName,
          position: templateData.position,
          supervisor: managerFullName,
          office: office,
          overtimeDate: moment(templateData.overtimeDate.toDate()).format("DD.MM.YYYY"),
          overtimeStartingHours: moment(templateData.overtimeStartHours.toDate()).format("hh:mm a"),
          overtimeEndingHours: moment(templateData.overtimeEndHours.toDate()).format("hh: mm a"),
          overtimeDuration: templateData.compensationHours,
          tasks: templateData.tasks,
          HRUser: HRFullName,
          HRSignatureDate: moment(templateData.employeeSignatureDate.toDate())
            .add(2, "hours")
            .add(5, "minutes")
            .format("DD.MM.YYYY  hh:mm"),
          employeeSignatureDate: moment(templateData.employeeSignatureDate.toDate()).format("DD.MM.YYYY  hh:mm"),
          managerSignatureDate: moment(templateData.employeeSignatureDate.toDate())
            .add(1, "hours")
            .add(10, "minutes")
            .format("DD.MM.YYYY  hh:mm"),
          adminUser: adminFullName,
          adminSignatureDate: moment(templateData.employeeSignatureDate.toDate())
            .add(2, "hours")
            .add(30, "minutes")
            .format("DD.MM.YYYY  hh:mm"),
        });

        const fileR = await doc.render();

        const file = fileR.getZip().generate({ type: "nodebuffer" });

        const url = await firebaseUploadAndGetUrl(file, "RFC", templateData.employeeUID, empFullName, overtimeId);

        console.log("i am hereeee");
        await overtimeRef
          .doc(overtimeId)
          .update({
            RFCURL: url,
          })
          .then(() => setDownloadLoading(false));
      } catch (error) {
        errorHandler(error);
      }
    }

    dataRun(doc);
  });
};
