import {
  contractPercentage,
  globalUser,
  paymentType,
  position,
  salary,
  supervisor,
  workOffice,
} from "../db/interfaces";
import {
  userRef,
  officeRef,
  positionRef,
  salariesRef,
  workOfficeRef,
  supervisorsRef,
  paymentTypesRef,
  contractPercentageRef,
} from "./collectionsRef";
import { auth } from "firebase/app";
import { uniq } from "lodash";
import moment from "moment";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";

export let currentUserData = {} as globalUser;
export let currentPosition = "";
export let positions = [] as any;
export let currentSalary = "";
export let salaries = [] as any;

export let currentOffice = "";
export let offices = [] as any;

export let currentSupervisor = "";
export let supervisors = [] as any;

export let currentPaymentType = "";
export let paymentTypes = [] as any;

export let currentContractPercentage = "";
export let contractPercentages = [] as any;

auth().onAuthStateChanged(async (user) => {
  if (!user) {
    return;
  }
  positionRef.onSnapshot((data) => {
    // data.docs.map(doc => doc.data() as position)
    positions.splice(0, positions.length, ...data.docs.map((doc) => doc.data() as position));
  });
  positionRef
    .where("uid", "==", user.uid)
    .onSnapshot(
      (sn) =>
        (currentPosition = sn.docs
          ?.filter((p) =>
            moment(new Date()).isBetween(
              moment(p.data()?.startDate.toDate()).startOf("day"),
              moment().endOf("day"),
              undefined,
              "[]"
            )
          )
          .map((d) => d.data()?.position)[0])
    );

  salariesRef.onSnapshot((data) => {
    salaries.splice(0, salaries.length, ...data.docs.map((doc) => doc.data() as salary));
  });
  salariesRef
    .where("uid", "==", user.uid)
    .onSnapshot(
      (sn) =>
        (currentSalary = sn.docs
          ?.filter((p) =>
            moment(new Date()).isBetween(
              moment(p.data()?.startDate.toDate()).startOf("day"),
              moment().endOf("day"),
              undefined,
              "[]"
            )
          )
          .map((d) => d.data()?.salary)[0])
    );

  workOfficeRef.onSnapshot((data) => {
    offices.splice(0, offices.length, ...data.docs.map((doc) => doc.data() as workOffice));
  });
  workOfficeRef
    .where("uid", "==", user.uid)
    .onSnapshot(
      (sn) =>
        (currentOffice = sn.docs
          ?.filter((p) =>
            moment(new Date()).isBetween(
              moment(p.data()?.startDate.toDate()).startOf("day"),
              moment().endOf("day"),
              undefined,
              "[]"
            )
          )
          .map((d) => d.data()?.office)[0])
    );

  supervisorsRef.onSnapshot((data) => {
    supervisors.splice(0, supervisors.length, ...data.docs.map((doc) => doc.data() as supervisor));
  });
  supervisorsRef
    .where("uid", "==", user.uid)
    .onSnapshot(
      (sn) =>
        (currentSupervisor = sn.docs
          ?.filter((p) =>
            moment(new Date()).isBetween(
              moment(p.data()?.startDate.toDate()).startOf("day"),
              moment().endOf("day"),
              undefined,
              "[]"
            )
          )
          .map((d) => d.data()?.supervisorUid)[0])
    );

  paymentTypesRef.onSnapshot((data) => {
    paymentTypes.splice(0, paymentTypes.length, ...data.docs.map((doc) => doc.data() as paymentType));
  });
  paymentTypesRef
    .where("uid", "==", user.uid)
    .onSnapshot(
      (sn) =>
        (currentPaymentType = sn.docs
          ?.filter((p) =>
            moment(new Date()).isBetween(
              moment(p.data()?.startDate.toDate()).startOf("day"),
              moment().endOf("day"),
              undefined,
              "[]"
            )
          )
          .map((d) => d.data()?.paymentType)[0])
    );

  contractPercentageRef.onSnapshot((data) => {
    contractPercentages.splice(
      0,
      contractPercentages.length,
      ...data.docs.map((doc) => doc.data() as contractPercentage)
    );
  });
  contractPercentageRef
    .where("uid", "==", user.uid)
    .onSnapshot(
      (sn) =>
        (currentContractPercentage = sn.docs
          ?.filter((p) =>
            moment(new Date()).isBetween(
              moment(p.data()?.startDate.toDate()).startOf("day"),
              moment().endOf("day"),
              undefined,
              "[]"
            )
          )
          .map((d) => d.data()?.contractPercentage)[0])
    );

  userRef.doc(user.uid).onSnapshot((userData) => {
    currentUserData = {
      ...userData.data(),
      uid: user.uid,
      adminOffices: userData.data()?.adminOffices ?? [],
      HROffices: userData.data()?.HROffices ?? [],
      HRProjectsManagersOffices: userData.data()?.HRProjectsManagersOffices ?? [],
      viewersOffices: userData.data()?.viewersOffices ?? [],
      fullControlOffices: uniq([
        ...(userData.data()?.adminOffices ?? []),
        ...(userData.data()?.HROffices ?? []),
        ...(userData.data()?.viewersOffices ?? []),
        ...(userData.data()?.HRProjectsManagersOffices ?? []),
      ]),
      position: userData.data()?.position,
      salary: userData.data()?.salary ,
      fullName: userData.data()?.firstName + " " + userData.data()?.lastName,
    } as globalUser;
  });
});

// console.log(positions)
