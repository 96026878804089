import { Badge } from "antd";
import React, { Fragment } from "react";

interface Props {
  data: any;
  onClickCard: (id: string) => void;
  clickable: boolean;
  badge: boolean;
}
export const PositionCard = (props: Props) => {
  const { data, onClickCard, clickable, badge } = props;

  return (
    <ul>
      {data.map((item: any) => {
        return (
          <Fragment key={item.id}>
            <li
              onClick={(event) => {
                event.stopPropagation();
                if (clickable) {
                  onClickCard(item.id);
                }
              }}
            >
              <div className="card" style={{ cursor: clickable ? "pointer" : "default", opacity: item.opacity }}>
                <div className="image">
                  <Badge
                    dot={false}
                    status={
                      item.name == "Vacant"
                        ? "default"
                        : item.status == "vacation"
                        ? "error"
                        : item.status == "outdoorTask"
                        ? "warning"
                        : "success"
                    }
                    style={{
                      position: "absolute",
                      top: "66px",
                      left: "53px",
                      width: "15px",
                      height: "15px",
                      display: badge == false ? "none" : "flex",
                    }}
                  >
                    <img
                      src={
                        item.avatar == "" || item.avatar == undefined
                          ? "https://firebasestorage.googleapis.com/v0/b/administrationapp-cdc55.appspot.com/o/Screenshot%202023-09-18%20at%2016.39.32.png?alt=media&token=2bcd03d6-17c8-42f3-9d9f-7521aa2aeeec"
                          : item.avatar
                      }
                      style={{
                        borderColor: item.color,
                      }}
                    />
                  </Badge>
                </div>
                <div
                  className="card-body"
                  style={{
                    background: `${item.color}15`,
                  }}
                >
                  <h4>{item.name}</h4>
                  <p> {item.position}</p>
                </div>
                <div
                  className="card-footer"
                  style={{
                    background: item.color,
                  }}
                >
                  {item.departmentName}
                </div>
                <div></div>
              </div>
              {item.children?.length > 0 && (
                <PositionCard data={item.children} onClickCard={onClickCard} clickable={clickable} badge={badge} />
              )}
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
};
