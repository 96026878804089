/* eslint-disable react/jsx-key */
import {
  DataTypeProvider,
  FilteringState,
  GridColumnExtension,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  IntegratedSummary,
  PagingState,
  SearchState,
  SelectionState,
  SortingState,
  SummaryState,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import {
  ColumnChooser,
  DragDropProvider,
  GroupingPanel,
  PagingPanel,
  Table,
  TableColumnVisibility,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow,
  TableSelection,
  TableSummaryRow,
  Toolbar,
  SearchPanel,
  TableRowDetail,
  ExportPanel,
  TableColumnResizing,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import Input from "@material-ui/core/Input";
import { withStyles } from "@material-ui/core/styles";
import DateRange from "@material-ui/icons/DateRange";
import { Button, DatePicker } from "antd";
import moment from "moment";
import React, { useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { GridExporter } from "@devexpress/dx-react-grid-export";
// import { downloadExcel } from "./export-csv";
// import { exportTableToExcel } from "./exportTableToExcel";
// import useReactRouter from 'use-react-router'

export default function DefaultDevExtremeGridComponents({
  table,
  defaultHiddenColumnNames = [],
  stringFields = [],
  withSelection = false,
  groups = true,
  filter = true,
  pagination = false,
  allowHidingColumns = true,
  columnExtensions,
  summaryColumn = false,
  pageSize,
  selectedItemsIds,
  SetSelectedItemsIds,
  extendedTable = false,
  rowDetails,
  tableName,
  data,
  typicalTable = false,
  defaultColumnWidths,

  totalItems,
  columnsExport,
  rowClick,
}: {
  table?: string;
  defaultHiddenColumnNames?: string[];
  datesFields?: string[];
  numberFields?: string[];
  stringFields?: string[];
  withSelection?: boolean;
  groups?: boolean;
  filter?: boolean;
  pagination?: boolean;
  allowHidingColumns?: boolean;
  columnExtensions?: Array<GridColumnExtension>;
  summaryColumn?: boolean;
  pageSize?: number;
  selectedItemsIds?: any[];
  SetSelectedItemsIds?: any;
  extendedTable?: boolean;
  rowDetails?: any;
  tableName: any;
  data: any;
  typicalTable?: boolean;
  defaultColumnWidths?: any;

  totalItems?: any[];
  columnsExport?: any;
  rowClick?: any;
}) {
  const FilterIcon = ({ type, ...restProps }: any) => {
    if (type === "month") return <DateRange {...restProps} />;
    return <TableFilterRow.Icon type={type} {...restProps} />;
  };
  const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
      saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${tableName}.xlsx`);
    });
  };

  const exporterRef = useRef(null);

  const startExport = useCallback(
    (options) => {
      exporterRef.current.exportGrid(options);
    },
    [exporterRef]
  );
  const NewRowComp = ({ row, children, tableRow, table }: Table.DataRowProps & { table: string }) => {
    const history = useHistory();
    const props = { row, children, tableRow };

    return <Table.Row {...props} onDoubleClick={(args: any) => rowClick({ args, row })} />;
  };

  const RowCompWithBackgroundColor = ({ children, tableRow }: Table.RowProps) => {
    const props = { children, tableRow };

    return <Table.Row {...(props as any)} style={{ backgroundColor: "#f0f0f7" }} />;
  };

  const NewCellComp = (props: Table.DataCellProps & { table: string }) => {
    return <Table.Cell {...props} style={{ whiteSpace: "break-spaces" }} />;
  };

  return [
    extendedTable == true ? <RowDetailState /> : null,

    <SearchState defaultValue="" key={30} />,
    filter && <FilteringState defaultFilters={[]} key={9} />,
    <IntegratedFiltering
    //  columnExtensions={[filteringColumnExtensions]}
    />,
    <SelectionState
      selection={selectedItemsIds}
      onSelectionChange={(e: any) => {
        SetSelectedItemsIds(e);
      }}
      key={1}
    />,
    <DragDropProvider key={1} />,
    withSelection && <IntegratedSelection key={2} />,
    <SortingState defaultSorting={[]} key={3} />,
    <IntegratedSorting key={4} />,
    groups && <GroupingState defaultGrouping={[]} key={5} />,
    groups && <IntegratedGrouping key={6} />,
    pagination && <PagingState defaultCurrentPage={0} pageSize={pageSize ?? 25} key={7} />,
    pagination && <IntegratedPaging key={8} />,
    <Table
      messages={{ noData: "No Data" }}
      cellComponent={NewCellComp as any}
      key={11}
      rowComponent={(props) => <NewRowComp {...props} table={table as any} />}
      columnExtensions={columnExtensions}
    />,
    defaultColumnWidths && defaultColumnWidths.length > 0 ? (
      <TableColumnResizing columnWidths={defaultColumnWidths} key={35} />
    ) : null,

    summaryColumn && <SummaryState totalItems={totalItems} />,
    summaryColumn && <IntegratedSummary />,
    summaryColumn && <TableSummaryRow />,

    withSelection && <TableSelection showSelectAll key={12} />,
    typicalTable == true
      ? null
      : filter && (
          <TableFilterRow
            // cellComponent={(props) => {
            //   console.log({ props });
            //   return;
            // }}
            rowComponent={(props) => {
              return <RowCompWithBackgroundColor {...props} />;
            }}
            showFilterSelector
            iconComponent={FilterIcon}
            key={13}
            messages={{ filterPlaceholder: "Filter" }}
          />
        ),
    pagination && <PagingPanel key={14} />,
    // <VirtualTable />,

    <TableHeaderRow showSortingControls key={15} />,
    allowHidingColumns && <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} key={16} />,
    groups && <TableGroupRow key={17} />,
    typicalTable == true ? null : (allowHidingColumns || groups) && <Toolbar key={18} />,
    typicalTable == true ? null : <SearchPanel messages={{ searchPlaceholder: "Search" }} key={31} />,

    typicalTable == true ? null : allowHidingColumns && <ColumnChooser key={19} />,
    typicalTable == true ? null : groups && <GroupingPanel key={20} />,

    typicalTable == true ? null : <ExportPanel startExport={startExport} key={23} />,
    extendedTable == true ? <TableRowDetail contentComponent={rowDetails} key={24} /> : null,
    <GridExporter ref={exporterRef} rows={data} columns={columnsExport} onSave={onSave} />,
  ];
}
