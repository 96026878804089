import React, { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Col, Input, Row, Select, Tabs } from "antd";
const { Option } = Select;
import { Container } from "@material-ui/core";
import DepartmentChart from "./department-chart/department-chart";
import PositionChart from "./position-chart/position-chart";
import AllTasks from "./allTasks";
import { departmentsRef, outdoorTasksRef, positionTasksRef, tagsRef, vacationRef } from "../../db/collectionsRef";
const { TabPane } = Tabs;
import moment from "moment";
import AllPolicies from "./allPolicies";
import { department } from "../../db/interfaces";
import AllTemplates from "./allTemplates";
import AllSOPs from "./allSOPs";

const Chart = () => {
  const [filters, setFilters] = useState({ roles: [], tags: [], task: "" });
  const [departmentSN] = useCollection(departmentsRef);

  const [tagsSN] = useCollection(tagsRef);
  const [tasksSN] = useCollection(positionTasksRef);
  const [vacationListSN] = useCollection(
    vacationRef
      .where("vacationStartOn", "<=", moment().endOf("month").toDate())
      .where("vacationStartOn", ">=", moment().startOf("month").toDate())
  );

  const [outdoorTaskSN] = useCollection(
    outdoorTasksRef
      .where("startDate", "<=", moment().endOf("day").toDate())
      .where("startDate", ">=", moment().startOf("day").toDate())
  );

  const vacationList = vacationListSN?.docs.map((v) => {
    return { ...v.data(), id: v.id };
  });
  const outdoorTaskList = outdoorTaskSN?.docs.map((v) => {
    return { ...v.data(), id: v.id };
  });
  const departmentData = departmentSN?.docs?.map((department) => {
    return { ...department.data(), id: department.id } as department;
  });

  const todaysVacations = vacationList
    ?.filter((v: any) => {
      const vacationStartOn = moment(v.vacationStartOn.toDate()).startOf("day");
      const vacationEndOn = moment(v.vacationEndOn.toDate()).endOf("day");
      return moment().isBetween(vacationStartOn, vacationEndOn, undefined, "[]");
    })
    .map((v: any) => {
      return { uid: v.employeeUID, type: "vacation" };
    });

  const todaysOutdoorTasks = outdoorTaskList
    ?.filter((v: any) => {
      const startDate = moment(v.startDate.toDate()).startOf("day");
      const endDate = moment(v.endDate.toDate()).endOf("day");
      return moment().isBetween(startDate, endDate, undefined, "[]");
    })
    .map((v: any) => {
      return { uid: v.employeeUID, type: "outdoorTask" };
    });

  const todaysStatusStaff: any = [...(todaysVacations ?? ([] as any)), ...(todaysOutdoorTasks ?? ([] as any))];
  const tagsArray = tagsSN?.docs.map((tag) => {
    return { id: tag.id };
  });

  const tasksArray = tasksSN?.docs.map((task) => {
    return { ...task?.data(), id: task.id } as any;
  });

  const filteredData = tasksArray?.filter((item) => {
    const titleLower = item.task.title.toLowerCase();
    const descriptionLower = item.task.description.toLowerCase();
    const searchStringLower = filters.task.toLowerCase();

    const taskMatches = titleLower.includes(searchStringLower) || descriptionLower?.includes(searchStringLower);
    const tagsMatch = filters.tags.length === 0 || filters.tags.some((tag) => item.tags?.includes(tag));

    const rolesMatch = filters.roles.length === 0 || filters.roles.some((rol) => item.role?.includes(rol));

    const filtersApplied = filters.task || filters.tags.length > 0 || filters.roles.length > 0;

    return filtersApplied && taskMatches && tagsMatch && rolesMatch;
  });
  const departmentIdsFiltered = filteredData?.map((task) => task.departmentId);
  const positionIdsFiltered = filteredData?.map((task) => task.positionId);
  const taskIdsFiltered = filteredData?.map((task) => task.id);
  const filtersApplied: any = filters.task || filters.tags.length > 0 || filters.roles.length > 0;

  return (
    <div>
      <Container maxWidth="xl" style={{ background: "white", borderRadius: "6px", padding: "3%" }}>
        <div style={{ paddingBottom: "2%" }}>
          <Row>
            <Col span={6}>
              <Input
                placeholder="Responsibility Filter"
                value={filters.task as string}
                onChange={(e) => setFilters({ ...filters, task: e.target.value })}
                style={{ width: "96%" }}
              />
            </Col>
            <Col span={6}>
              <Select
                mode="tags"
                style={{ width: "96%" }}
                placeholder="Tags Filter"
                value={filters.tags}
                onChange={(e) => {
                  setFilters({ ...filters, tags: e });
                }}
              >
                {tagsArray?.map((task) => {
                  return <Option value={task.id}>{task.id}</Option>;
                })}
              </Select>
            </Col>
            <Col span={6}>
              <Select
                mode="multiple"
                style={{ width: "96%" }}
                placeholder="Roles Filter"
                value={filters.roles}
                onChange={(e) => setFilters({ ...filters, roles: e })}
              >
                <Option value="R">Responsible</Option>
                <Option value="A">Accountable</Option>
                <Option value="C">Consulted</Option>
                <Option value="I">Informed</Option>
              </Select>
            </Col>
          </Row>
        </div>
        <Tabs defaultActiveKey="1" onChange={(d) => console.log(d)}>
          <TabPane tab="Departments" key="1">
            <DepartmentChart filters={departmentIdsFiltered ?? []} filtersApplied={filtersApplied} />
          </TabPane>
          <TabPane tab="Positions" key="2">
            <PositionChart
              filters={positionIdsFiltered ?? []}
              todaysStatusStaff={todaysStatusStaff ?? []}
              filtersApplied={filtersApplied}
            />
          </TabPane>
          <TabPane tab="Responsibilities" key="3">
            <AllTasks filters={filters} taskIdsFiltered={taskIdsFiltered ?? []} />
          </TabPane>
          <TabPane tab="Policies" key="4">
            <AllPolicies filters={filters} departmentData={departmentData ?? []} />
          </TabPane>
          <TabPane tab="Templates" key="5">
            <AllTemplates filters={filters} departmentData={departmentData ?? []} />
          </TabPane>
          <TabPane tab="SOPs" key="6">
            <AllSOPs filters={filters} departmentData={departmentData ?? []} />
          </TabPane>
        </Tabs>
      </Container>
    </div>
  );
};

export default Chart;
