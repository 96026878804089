import React, { useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import firebase, { auth } from "firebase/app";
import { Spin, Button, message, Descriptions, Badge, Input, Select, Popconfirm, Icon, DatePicker } from "antd";
import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import Discussion from "../../discussion/discussion";
import {
  orderRef,
  userRef,
  projectRef,
  discussionsRef,
  notificationsRef,
  overtimeRef,
  vacationRef,
  officeRef,
} from "../../db/collectionsRef";
import FileUploader from "react-firebase-file-uploader";
import moment from "moment";
import EditOrder from "../models/EditOrder";
import { format } from "../../db/dateFormat";
import { useTranslation } from "react-i18next";
import RFCTurkeyMission from "./RFCTurkeyMission.docx";
import RFCIraqMission from "./RFCIraqMission.docx";

import { generateAndUploadWord } from "./generateAndUploadWord";
import EditOvertime from "./OverTimeModules/EditOvertime";
import { overtime } from "../../db/interfaces";
import { currentUserData } from "../../db/userGlobal";
import { globalOfficesData } from "../../global/officeList";
import { globalMissionsData } from "../../global/missionList";
const storage = firebase.storage();

const db = firebase.firestore();
const { Option } = Select;

export default function OvertimeDetails() {
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
  const { t } = useTranslation();
  const history = useHistory();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [loading, setLoading] = useState(false);

  const [uploadingContract, setUploadingContract] = useState(false);
  const [file, setFile] = useState("");

  const id = useRouteMatch<{ id: string }>().params.id;
  const [overtimeSnapshot, error] = useDocument(overtimeRef.doc(id));
  const [officeSN] = useCollection(officeRef);

  const [usersSnapshot] = useCollection(userRef);
  const [overtimeAll] = useCollection(overtimeRef);

  const [discussionSN] = useCollection(discussionsRef.where("orderId", "==", id));
  const [notificationSN] = useCollection(notificationsRef.where("orderId", "==", id));

  const uid = auth().currentUser!.uid;
  const notificationSNObject = (notificationSN === undefined ? [] : notificationSN.docs).map((doc) => {
    return {
      orderId: doc.data().orderId,
      id: doc.id,
    };
  });
  const discussionSNObject = (discussionSN === undefined ? [] : discussionSN.docs).map((doc) => {
    return {
      orderId: doc.data().orderId,
      id: doc.id,
    };
  });

  const deleteOrder = async () => {
    history.push(`/overtime`);
    await discussionSNObject
      .filter((e) => e.orderId === id)
      .map((x) => x.id)
      .forEach((docId) => {
        discussionsRef.doc(docId).delete();
      });
    await notificationSNObject
      .filter((e) => e.orderId === id)
      .map((x) => x.id)
      .forEach((docId) => {
        notificationsRef.doc(docId).delete();
      });
    const promise = db.collection("overtime").doc(id).delete();

    promise.then(() => {
      message.success(t("general.successfulDelete"));
      console.log("Document successfully deleted!");
      // location.reload()
    });
    promise.catch((error: any) => {
      console.error("Error removing document: ", error);
    });
  };

  const enterLoading = () => {
    setLoading(true);
  };

  if (!overtimeSnapshot || !officeSN || !overtimeAll) {
    return <Spin size="large" />;
  }

  const data = overtimeSnapshot!.data()!;

  const userId = data.employeeUID;
  const overtimeId = id;
  const office = officeSN.docs.find((d) => d.id == overtimeSnapshot?.data()?.office)?.data().officeName;
  const approvalCEO = officeSN.docs.find((d) => d.id == overtimeSnapshot?.data()?.office)?.data().approvalCEO;
  const employeeUID = overtimeSnapshot?.data()?.employeeUID;
  const managerUID = overtimeSnapshot?.data()?.managerUID;
  const adminUID = overtimeSnapshot?.data()?.adminUID;
  const HRUserUID = overtimeSnapshot?.data()?.HRUserUID;
  const CEOUID = overtimeSnapshot?.data()?.CEOUID;
  const officeId = data?.office;

  const empFullName = `${usersSnapshot?.docs.find((d) => d.id == employeeUID)?.data().firstName} ${
    usersSnapshot?.docs.find((d) => d.id == employeeUID)?.data().lastName
  }`;
  const managerFullName = `${usersSnapshot?.docs.find((d) => d.id == managerUID)?.data().firstName} ${
    usersSnapshot?.docs.find((d) => d.id == managerUID)?.data().lastName
  }`;
  const adminFullName = `${usersSnapshot?.docs.find((d) => d.id == adminUID)?.data().firstName} ${
    usersSnapshot?.docs.find((d) => d.id == adminUID)?.data().lastName
  }`;
  const HRFullName = `${usersSnapshot?.docs.find((d) => d.id == HRUserUID)?.data().firstName} ${
    usersSnapshot?.docs.find((d) => d.id == HRUserUID)?.data().lastName
  }`;
  const ceoFUllName = `${usersSnapshot?.docs.find((d) => d.id == CEOUID)?.data().firstName} ${
    usersSnapshot?.docs.find((d) => d.id == CEOUID)?.data().lastName
  }`;

  const status = overtimeSnapshot?.data()?.status;
  const statusBadge =
    status == "Requested"
      ? "warning"
      : status == "Pending"
      ? "processing"
      : status == "Rejected" || status == "Canceled"
      ? "default"
      : status == "Accepted"
      ? "success"
      : "error";

  const overtimeStorageRef = firebase.storage().ref(employeeUID);
  const dateFormat = "YYYY/MM/DD hh:mm:ss";
  const increment = (data.compensationHours * 1.5) / 8;

  const cancelOvertime = () => {
    userRef.doc(userId).update({
      vacationCompensationScore: firebase.firestore.FieldValue.increment(-increment),
      vacationCompensationCanceledDate: new Date(),
    });
  };
  const addToVacationScore = async () => {
    userRef.doc(userId).update({
      vacationCompensationScore: firebase.firestore.FieldValue.increment(increment),
      vacationCompensationAcceptedDate: new Date(),
    });
  };
  return (
    <div style={{ marginTop: "10px" }}>
      {modalState ? <EditOvertime modalState={modalState} setModalState={setModalState} orderId={id} /> : null}

      {/* //------------------- */}
      <Descriptions
        size={"default"}
        title={`Compensation Request`}
        layout="horizontal"
        bordered={true}
        column={{ xs: 8, sm: 6, md: 4 }}
      >
        <Descriptions.Item label={t("general.requestedAt")}>
          {moment(data.requestedAt.toDate()).format(dateFormat)}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.status")}>
          <Badge status={statusBadge} text={status} />
        </Descriptions.Item>
      </Descriptions>
      {/* //------------------- */}

      <Descriptions size={"default"} layout="horizontal" bordered={true} column={{ xs: 8, sm: 6, md: 4 }}>
        <Descriptions.Item label={t("general.fullName")} span={6}>
          {empFullName}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.position")} span={2}>
          {data.position}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.manager")} span={2}>
          {managerFullName}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.location")} span={2}>
          {data.office == "HQ" ? data.office : officeSN.docs.find((d) => d.id == data.office)?.data().officeName}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.overtimeDate")} span={2}>
          {moment(data.overtimeDate.toDate()).format(format)}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.overtimeStartHours")} span={2}>
          {moment(data.overtimeStartHours.toDate()).format("hh:mm a")}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.overtimeEndHours")} span={2}>
          {moment(data.overtimeEndHours.toDate()).format("hh:mm a")}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.compensationHours")} span={2}>
          {data.compensationHours.toFixed(1)}{" "}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.taskOvertime")} span={2}>
          {moment(data.taskOvertime.toDate()).format(format)}{" "}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.tasks")} span={6}>
          {data.tasks}{" "}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.employeeSignatureDate")} span={2}>
          {data.employeeSignatureDate == undefined || data.employeeSignatureDate == "" ? (
            <Button
              style={{ width: "150px" }}
              type="primary"
              onClick={() =>
                overtimeRef.doc(overtimeId).update({
                  employeeSignatureDate: new Date(),
                })
              }
            >
              Approve
            </Button>
          ) : (
            <p> {moment(data.employeeSignatureDate.toDate()).format(dateFormat)} </p>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.managerSignatureDate")} span={2}>
          {data.managerSignatureDate == undefined || data.managerSignatureDate == "" ? (
            data.managerUID == uid ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={() =>
                  overtimeRef.doc(overtimeId).update({
                    managerSignatureDate: new Date(),
                    status: "Pending",
                  })
                }
              >
                Approve
              </Button>
            ) : null
          ) : (
            <p> {moment(data.managerSignatureDate.toDate()).format(dateFormat)} </p>
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.adminUser")} span={2}>
          {data.HRSignatureDate == "" ? null : data.adminSignatureDate == undefined || data.adminSignatureDate == "" ? (
            currentUserData.adminOffices.includes(data.office) ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={async () => {
                  overtimeRef
                    .doc(overtimeId)
                    .update({
                      adminSignatureDate: new Date(),
                      status: approvalCEO ? "Pending" : "Accepted",
                      adminUID: uid,
                      overtimeAccepted: data.compensationHours,
                    })
                    .then(() => {
                      if (!approvalCEO) {
                        addToVacationScore();
                      }
                    });
                }}
              >
                Approve
              </Button>
            ) : null
          ) : (
            <p> {adminFullName} </p>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.HRUser")} span={2}>
          {data.managerSignatureDate == "" ? null : data.HRSignatureDate == undefined || data.HRSignatureDate == "" ? (
            currentUserData.HROffices.includes(data.office) ? (
              <Button
                style={{ width: "150px", height: "50px" }}
                type="primary"
                onClick={() =>
                  overtimeRef.doc(overtimeId).update({
                    HRSignatureDate: new Date(),
                    HRUserUID: uid,
                  })
                }
              >
                Approve
              </Button>
            ) : null
          ) : (
            <p> {HRFullName} </p>
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t("general.adminSignatureDate")} span={2}>
          {data.adminSignatureDate == "" ? null : (
            <p> {moment(data.adminSignatureDate.toDate()).format(dateFormat)} </p>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={t("general.HRSignatureDate")} span={2}>
          {data.HRSignatureDate == "" ? null : <p> {moment(data.HRSignatureDate.toDate()).format(dateFormat)} </p>}
        </Descriptions.Item>

        {approvalCEO ? (
          <Descriptions.Item label={t("general.CEO")} span={2}>
            {data.adminSignatureDate == "" ? null : data.CEOSignatureDate == undefined ||
              data.CEOSignatureDate == "" ? (
              globalMissionsData[globalOfficesData[officeId].missionId]?.missionCEO.includes(uid) ? (
                <Button
                  style={{ width: "150px", height: "50px" }}
                  type="primary"
                  onClick={async () => {
                    overtimeRef
                      .doc(overtimeId)
                      .update({
                        CEOSignatureDate: new Date(),
                        status: "Accepted",
                        CEOUID: uid,
                        overtimeAccepted: data.compensationHours,
                      })
                      .then(() => addToVacationScore());
                  }}
                >
                  Approve
                </Button>
              ) : null
            ) : (
              <p> {ceoFUllName} </p>
            )}
          </Descriptions.Item>
        ) : null}
        {approvalCEO ? (
          <Descriptions.Item label={t("general.CEOSignatureDate")} span={2}>
            {data.CEOSignatureDate == "" || !data.CEOSignatureDate ? null : (
              <p> {moment(data.CEOSignatureDate.toDate()).format(dateFormat)} </p>
            )}
          </Descriptions.Item>
        ) : null}

        <Descriptions.Item label={t("general.actions")} span={6}>
          <div>
            {data.status == "Requested" || data.status == "Pending" ? (
              <Button type="primary" style={{ margin: "1%" }} onClick={() => setModalState(true)}>
                Edit
              </Button>
            ) : null}
            {data.status == "Requested" || uid == "Jmlv0ajWF9RoGv4jWsvosBIBS1s1" ? (
              <Button type="danger" style={{ margin: "1%" }} onClick={() => deleteOrder()}>
                Delete
              </Button>
            ) : null}
            {data.employeeUID == uid ? null : data.status == "Pending" || data.status == "Requested" ? (
              <Button
                style={{ margin: "1%" }}
                type="default"
                onClick={() => {
                  overtimeRef.doc(overtimeId).update({
                    status: "Rejected",
                    rejectedBy: uid,
                  });
                }}
              >
                Reject
              </Button>
            ) : null}
            {data.status == "Accepted" ? (
              <div>
                {!currentUserData.HROffices?.includes(data.office) ? null : (
                  <Button
                    style={{ margin: "1%" }}
                    type="danger"
                    onClick={() => {
                      overtimeRef
                        .doc(overtimeId)
                        .update({
                          status: "Canceled",
                          canceledBy: uid,
                        })
                        .then(() => {
                          cancelOvertime();
                        });
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  style={{ margin: "1%" }}
                  loading={downloadLoading}
                  type="primary"
                  onClick={async () => {
                    if (data.RFCURL) {
                      setDownloadLoading(true);

                      const U = storage.refFromURL(data.RFCURL);

                      await U.getDownloadURL().then((url) => {
                        var link = document.createElement("a");
                        if (link.download !== undefined) {
                          link.setAttribute("href", url);
                          link.setAttribute("target", "_blank");
                          link.style.visibility = "hidden";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }
                      });
                      setDownloadLoading(false);
                    } else {
                      setDownloadLoading(true);
                      generateAndUploadWord(
                        globalOfficesData[data.office]?.cacheMissionName == "IRAQ" ? RFCIraqMission : RFCTurkeyMission,
                        data,
                        overtimeId,
                        uid,
                        empFullName,
                        managerFullName,
                        adminFullName,
                        HRFullName,
                        setDownloadLoading,
                        office,
                        globalOfficesData[data.office]?.cacheMissionName
                      );
                    }
                  }}
                >
                  <Icon type="download" />
                  Print RFC
                </Button>
                {data.RFCURL !== "" ? (
                  <label
                    style={{
                      background: "rgb(24 144 255)",
                      padding: "1%",
                      marginRight: "3%",
                      borderColor: "rgb(24 144 255)",
                      borderRadius: "6px",
                      color: "white",
                    }}
                  >
                    <Icon type="upload" />
                    <span style={{ padding: "2%" }}>{t("general.uploadContract")}</span>
                    {uploadingContract ? <Spin indicator={antIcon} style={{ color: "#fff" }} /> : null}
                    {data.archivedRFC == undefined || data.archivedRFC == "" ? null : (
                      <Icon type="check" style={{ color: "#fff", padding: "1%" }} />
                    )}

                    <FileUploader
                      hidden
                      storageRef={overtimeStorageRef}
                      onUploadStart={() => setUploadingContract(true)}
                      onUploadSuccess={async (fileName: string) => {
                        setUploadingContract(false);
                        setFile(fileName);

                        const downloadURL = await overtimeStorageRef.child(fileName).getDownloadURL();
                        await overtimeRef
                          .doc(overtimeId)
                          .update({
                            archivedRFC: downloadURL,
                          })
                          .then(async () => {
                            await message.success(t("general.successfulUpload"));
                          });
                      }}
                    />
                  </label>
                ) : null}
                {data.archivedRFC ? (
                  <Button
                    onClick={async () => {
                      const U = storage.refFromURL(data.archivedRFC);

                      await U.getDownloadURL().then((url) => {
                        var link = document.createElement("a");
                        if (link.download !== undefined) {
                          link.setAttribute("href", url);
                          link.setAttribute("target", "_blank");
                          link.style.visibility = "hidden";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }
                      });
                    }}
                  >
                    Download Archived RFC
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
        </Descriptions.Item>
      </Descriptions>
      {/* //------------------- */}

      <Discussion userId={userId} orderId={overtimeId} type={"overtime"} officeId={data.office} />
    </div>
  );
}
