import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import { useCollection } from "react-firebase-hooks/firestore";
import { Spin, Button, Popconfirm } from "antd";
import { contractPercentageRef, officeRef, paymentTypesRef, supervisorsRef } from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DevTable from "../../helpers/devex-table";
import { globalUsersData } from "../../global/usersList";
import AddNewContractPercentage from "./addNewContractPercentageDialog";
import EditContractPercentage from "./editContractPercentageDialog";
import { currentUserData } from "../../db/userGlobal";
import { globalOfficesData } from "../../global/officeList";

export default function ContractPercentageTable() {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState(false);
  const [modalStateEdit, setModalStateEdit] = useState(false);
  const [contractPercentageId, setContractPercentageId] = useState("");

  const [contractPercentage] = useCollection(contractPercentageRef);
  const [officeSN] = useCollection(officeRef);

  if (!contractPercentage || !officeSN) return <Spin />;

  const data = contractPercentage.docs
    .map((d) => {
      return { ...d.data(), id: d.id };
    })
    .filter((d: any) => currentUserData.HRProjectsManagersOffices.includes(globalUsersData[d.uid]?.office));

  const columns = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[globalUsersData[row.uid]?.office ?? ""]?.officeName,
    },

    {
      name: "contractPercentage",
      title: "Contract Percentage",
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY"),
    // },
    {
      name: "actions",
      title: "Actions",
      getCellValue: (row: any) => {
        return (
          <Popconfirm
            title={t("general.areUSure")}
            onConfirm={() => {
              contractPercentageRef.doc(row.id).delete();
            }}
            onCancel={() => console.log("cancel")}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        );
      },
    },
    {
      name: "edit",
      title: "Edit",
      getCellValue: (row: any) => {
        return (
          <Button
            onClick={() => {
              setModalStateEdit(true);
              setContractPercentageId(row.id);
            }}
            type="primary"
          >
            Edit
          </Button>
        );
      },
    },
  ];
  const columnsExport = [
    {
      name: "uid",
      title: "UID",
    },
    {
      name: "name",
      title: "Name",
      getCellValue: (row: any) => globalUsersData[row.uid]?.firstName + " " + globalUsersData[row.uid]?.lastName,
    },
    {
      name: "office",
      title: "Office",
      getCellValue: (row: any) => globalOfficesData[globalUsersData[row.uid]?.office ?? ""]?.officeName,
    },

    {
      name: "contractPercentage",
      title: "Contract Percentage",
    },
    {
      name: "by",
      title: "Raised By",
      getCellValue: (row: any) => globalUsersData[row.by]?.firstName + " " + globalUsersData[row.by]?.lastName,
    },

    {
      name: "startDate",
      title: "Start Date",
      getCellValue: (row: any) => moment(row.startDate.toDate()).format("DD.MM.YYYY"),
    },
    // {
    //   name: "endDate",
    //   title: "End Date",
    //   getCellValue: (row: any) => moment(row.endDate.toDate()).format("DD.MM.YYYY"),
    // },
  ];

  return (
    <div>
      <AddNewContractPercentage modalState={modalState} setModalState={setModalState} />
      {modalStateEdit ? (
        <EditContractPercentage
          modalState={modalStateEdit}
          setModalState={setModalStateEdit}
          contractPercentageId={contractPercentageId}
        />
      ) : null}
      {/* <Button style={{ marginBottom: "1%" }} type={"primary"} onClick={() => setModalState(true)}>
        {t("general.addNewContractPercentage")}
      </Button> */}
      <Paper>
        <DevTable
          data={data}
          columns={columns}
          defaultHiddenColumnNames={[]}
          tableName="Contract Percentages"
          selected={false}
          typicalTable={false}
          columnsExport={columnsExport}
        />
      </Paper>
    </div>
  );
}
