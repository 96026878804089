import React from "react";
import { Link } from "react-router-dom";
import { Card, Badge } from "antd";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  employeeUID: string;
  vacationStartOn: any;
  vacationEndOn: any;
  status: string;
  vacationType:string;
}

export default function VacationSummary(props: Props) {
  const { t } = useTranslation();
  const { id, employeeUID, vacationStartOn, vacationEndOn, status, vacationType } = props;
  const statusBadge =
    status == "Requested"
      ? "warning"
      : status == "Pending"
      ? "processing"
      : status == "Rejected" || status == "Canceled"
      ? "default"
      : status == "Accepted"
      ? "success"
      : "error";

  return (
    <Link to={`/vacation/${id}`}>
      <Card
        title={`${employeeUID}`}
        bordered={false}
        style={{
          width: "100%",
          marginTop: "5%",
          borderRadius: "10px",
          boxShadow: "6px 7px 17px 3px rgba(0, 0, 0, 0.09)",
          background: "#fff"
        }}
      >
        <Badge status={statusBadge} text={status} />       <b style={{color: status=="Accepted"? "#76c556" : status=="Pending"? "rgb(94 179 255)" :"#faad13" }}>{vacationType}</b>
        <p>{`${t("general.vacationStartOn")}: ${vacationStartOn}`}</p>
        <p>{`${t("general.vacationEndOn")}: ${vacationEndOn}`}</p>
      </Card>
    </Link>
  );
}
