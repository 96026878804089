import React, { useState, useEffect } from "react";
import firebase, { auth, firestore } from "firebase/app";
import { RouteComponentProps, useRouteMatch, useHistory } from "react-router-dom";
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  InputNumber,
  message,
  Select,
  Spin,
  Input,
  Descriptions,
  Badge,
  Icon,
  Row,
  Col
} from "antd";
const { TextArea } = Input;
import moment from "moment";
const { Option } = Select;

import { useDocument, useCollection } from "react-firebase-hooks/firestore";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  notificationsRef,
  userRef,
  orderRef,
  meetingRoomList,
  projectsRef,
  budgetLinesRef
} from "../../db/collectionsRef";
import { useTranslation } from "react-i18next";

interface Props {
  modalState: boolean;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
}
const projectSchema = Yup.object().shape({
  budgetLine: Yup.string().required("*")
  // location: Yup.string().required("*"),
  // capacity: Yup.number().required("*")
  //   contains: Yup.string().required("*")
});

function AddNewBudgetLine(props: Props) {
  const { t } = useTranslation();

  const { modalState, setModalState } = props;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const newId = meetingRoomList.doc().id;
  const uid = auth().currentUser!.uid;
  const [projectsSN] = useCollection(projectsRef);

  const projectsMap = projectsSN?.docs.map(d => {
    return {
      id: d.id,
      code: d.data()?.projectCode
    };
  });
  const formik = useFormik({
    initialValues: {
      budgetLine: "",
      projectId: ""
    },
    validationSchema: projectSchema,
    onSubmit: async values => {
      const promise = budgetLinesRef.doc(newId).set(
        {
          budgetLine: values.budgetLine,
          projectId: values.projectId,

          createdAt: new Date()
        },
        { merge: true }
      );
      promise.then(() => {
        setModalState(false), setLoading(false);
      });

      promise.catch(error => {
        setLoading(false);
        console.error("Error writing document: ", error);
        message.error("Failed");
      });
    }
  });
  useEffect(() => {
    return () => {
      if (formik.isValid == false) {
        setLoading(false);
      }
    };
  }, [formik.isValid]);

  const enterLoading = () => {
    setLoading(true);
  };
  const dateFormat = "YYYY/MM/DD";

  return (
    <Drawer
      title={t("general.addNewBudgetLine")}
      width={500}
      height={350}
      visible={modalState}
      placement={"right"}
      onClose={() => setModalState(false)}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Item label={t("general.budgetLine")}>
          <Input value={formik.values.budgetLine} onChange={e => formik.setFieldValue("budgetLine", e.target.value)} />
        </Form.Item>
        <Form.Item label={t("general.projectCode")}>
          <Select
            showSearch
            filterOption={(inputValue, option) => {
              return (option.props.children as any).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
            }}
            value={formik.values.projectId}
            onChange={e => formik.setFieldValue("projectId", e)}
          >
            {projectsMap?.map(d => {
              return <Option value={d.id}>{d.code}</Option>;
            })}
          </Select>
        </Form.Item>

        <div>
          <Button
            className="btn blue lighten-1 z-depth-0"
            onClick={() => {
              enterLoading();
            }}
            loading={loading}
            type="primary"
            htmlType="submit"
            disabled={!formik.isValid}
          >
            {t("general.create")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
}

export default AddNewBudgetLine;
